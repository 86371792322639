import { client } from '@config/apollo';
import gql from 'graphql-tag';
import { call, put, select } from 'redux-saga/effects';
import { Types } from '../ducks/region';
import { Types as ToastTypes } from '../ducks/toast';

export function* createRegion(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { key, description, cities, active } = action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($region: RegionInput!) {
          createRegion(region: $region) {
            id
          }
        }
      `,
      variables: {
        region: {
          key,
          description,
          active,
          cities: cities || [],
        },
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Região criada com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* removeRegion(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { id } = action;
    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation removeRegion($id: String!) {
          removeRegion(id: $id) {
            ok
          }
        }
      `,
      variables: {
        id,
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Região excluída com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* updateRegion(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { id, key, description, active } = action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation updateRegion($id: String!, $region: RegionUpdateInput!) {
          updateRegion(id: $id, region: $region) {
            ok
          }
        }
      `,
      variables: {
        id,
        region: {
          description,
          active,
          key,
        },
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Região alterada com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* fetchRegions(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    yield put({ type: Types.LOAD_QUERY, load: true });

    const response = yield call(cli.query, {
      query: gql`
        query ($first: Int, $after: String) {
          regions(first: $first, after: $after) {
            edges {
              node {
                id
                key
                description
                active
              }
            }
            pageInfo {
              hasNextPage
              after
              before
            }
          }
        }
      `,
      variables: {
        first: 50,
        after: action.cursor,
      },
    });

    const last = yield call(cli.query, {
      query: gql`
        query ($first: Int) {
          regions(first: $first) {
            edges {
              node {
                key
              }
            }
          }
        }
      `,
      variables: {
        first: 1,
      },
    });

    const pageInfo = response.data.regions.pageInfo;
    const results = response.data.regions.edges;

    let nextKey = 1;
    if (last.data?.regions?.edges?.length > 0) {
      nextKey = parseInt(last.data.regions.edges[0].node.key) + 1;
    }

    yield put({
      type: Types.SET_PAGE_INFO,
      pageInfo: { ...pageInfo, cursor: pageInfo.after },
    });

    if (action.cursor) {
      yield put({ type: Types.NEXT_RESULTS, results });
    } else {
      yield put({ type: Types.SET_RESULTS, results });
    }
    if (action.success) action.success(nextKey);
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_QUERY, load: false });
  }
}

export function* getRegions(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { filter } = action;

    const response = yield call(cli.query, {
      query: gql`
        query ($first: Int, $after: String, $filter: RegionFilterInput) {
          regions(first: $first, after: $after, filter: $filter) {
            edges {
              node {
                id
                key
                description
                active
              }
            }
            pageInfo {
              hasNextPage
              after
              before
            }
          }
        }
      `,
      variables: {
        first: action.first || 50,
        after: action.cursor,
        filter,
      },
    });

    const results = response.data.regions.edges;

    if (action.success) action.success(results);
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  }
}
