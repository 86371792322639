export default function Button({
  type = 'button',
  variant = 'default',
  disabled = false,
  fullWidth = false,
  isLoading = false,
  compact = false,
  children,
  ...rest
}) {
  const variants = {
    default:
      'bg-gray-200 hover:bg-gray-300 focus-visible:outline-gray-200 text-gray-700',
    primary:
      'bg-blue-600 hover:bg-blue-500 focus-visible:outline-blue-600 text-white',
    dark: 'bg-gray-600 hover:bg-gray-500 focus-visible:outline-gray-600 text-white',
    positive:
      'bg-green-600 hover:bg-green-500 focus-visible:outline-green-600 text-white',
    negative:
      'bg-red-600 hover:bg-red-500 focus-visible:outline-red-600 text-white',
  };

  return (
    <button
      type={type}
      disabled={disabled}
      {...rest}
      className={`${fullWidth ? 'w-full ' : ''}${compact ? 'py-1 ' : 'py-2 '}${
        variants[variant]
      }
      ${
        disabled
          ? 'opacity-80 cursor-not-allowed'
          : 'hover:brightness-95 cursor-pointer'
      }
      flex justify-center items-center gap-2 rounded-md px-3 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2`}
    >
      {isLoading && (
        <span
          data-variant={variant}
          data-compact={compact}
          className="animate-spin inline-block w-4 h-4 border-[3px] border-current border-t-transparent
          text-white
          data-[variant=default]:text-gray-600
          data-[compact=true]:w-3
          data-[compact=true]:h-3
          rounded-full"
          role="status"
          aria-label="loading"
        />
      )}
      {children}
    </button>
  );
}
