import { TableForm } from '@components';
import moment from 'moment';
import { setDataObject as setInvoice } from '@redux/ducks/invoice';
import { useDispatch } from 'react-redux';

const IncidentList = ({ ...rest }) => {
  const dispatch = useDispatch();

  function handleDetailInvoice(invoiceNumber) {
    dispatch(
      setInvoice({
        key: invoiceNumber,
      })
    );
  }

  return (
    <TableForm
      {...rest}
      headers={[
        {
          label: 'Nota',
          getValue: item => (
            <span
              className="text-blue-500 cursor-pointer"
              onClick={() => handleDetailInvoice(item.node.invoice.invoiceNo)}
            >
              {item.node.invoice.invoiceNo}
            </span>
          ),
        },
        {
          label: 'Motorista',
          getValue: item =>
            item.node.driver &&
            (`${item.node.driver.firstName} ${
              item.node.driver.lastName || ''
            }` ||
              ''),
        },
        { label: 'Descrição', getValue: item => item.node.type.description },
        { label: 'Observação', getValue: item => item.node.note },
        { label: 'Natureza', getValue: item => item.node.type.purpose },
        {
          label: 'Aberto',
          getValue: item =>
            item.node.start &&
            moment(item.node.start).format('DD/MM/YYYY-HH:mm'),
        },
        {
          label: 'Fechado',
          getValue: item => {
            const { end, closedBy } = item.node;
            const userName = closedBy
              ? `${closedBy.firstName} ${closedBy.lastName || ''} - `
              : '';
            if (end) {
              return `${userName}${moment(item.node.end).format(
                'DD/MM/YYYY-HH:mm'
              )}`;
            }
          },
        },
      ]}
      keyRow={item => item.node.id}
    />
  );
};

export default IncidentList;
