import { Maps, CardImages } from '@components';
import moment from 'moment';
import { HOST, PROTOCOL_HTTP } from '@config/apollo';
import { useRef } from 'react';
const url = `${PROTOCOL_HTTP}://${HOST}`;

export const DetailReturn = ({ source, close }) => {
  const returnIvoice = source.return;
  const backsidePhoto = `${url}/${returnIvoice.backsidePhoto}`;
  const imagesRef = useRef(null);

  return (
    <div>
      <div className="mt-6">
        <dl className="grid grid-cols-1 sm:grid-cols-3">
          {returnIvoice.driver ? (
            <>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-bold leading-6 text-gray-900">
                  Transportadora
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-500 sm:mt-2">
                {returnIvoice.shipper.company.corporateName}
                </dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-bold leading-6 text-gray-900">
                  Motorista
                </dt>
                <dd className="mt-1 text-sm leading-6 text-gray-500 sm:mt-2">
                {returnIvoice.driver?.firstName} {returnIvoice.driver?.lastName}
                </dd>
              </div>
            </>
          ) : (
            <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-bold leading-6 text-gray-900">
                Entrega efetuada por um interno:
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-500 sm:mt-2">
                {returnIvoice.createdBy?.firstName} {returnIvoice.createdBy?.lastName}
              </dd>
            </div>
          )}
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-bold leading-6 text-gray-900">
              Data da Rejeição
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-500 sm:mt-2">
              {returnIvoice?.date &&
                moment(returnIvoice?.date).format('DD/MM/YYYY-HH:mm')}
            </dd>
          </div>

          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-3 sm:px-0">
            <dt className="text-sm font-bold leading-6 text-gray-900">
              Imagens
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">
              <CardImages
                imgs={[backsidePhoto]}
                size="small"
                source="/uploads/returns/"
                ref={imagesRef}
                close={() => close()}
              />
            </dd>
          </div>
          <div className="border-t border-gray-100 px-4 py-6 sm:col-span-3 sm:px-0">
            <Maps position={returnIvoice?.location} />
          </div>
        </dl>
      </div>
    </div>
  );
};
