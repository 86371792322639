import { client } from '@config/apollo';
import gql from 'graphql-tag';
import { call, put, select } from 'redux-saga/effects';
import { Types } from '../ducks/city';
import { Types as ToastTypes } from '../ducks/toast';

export function* fetchCities(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    yield put({ type: Types.LOAD_QUERY, load: true });

    const response = yield call(cli.query, {
      query: gql`
        query ($first: Int, $after: String, $filter: CityFilterInput) {
          cities(first: $first, after: $after, filter: $filter) {
            edges {
              node {
                id
                code
                name
                state
              }
            }
            pageInfo {
              hasNextPage
              after
              before
            }
          }
        }
      `,
      variables: {
        first: 50,
        after: action.cursor,
        filter: action.filter,
      },
    });

    const pageInfo = response.data.cities.pageInfo;
    const results = response.data.cities.edges;

    yield put({
      type: Types.SET_PAGE_INFO,
      pageInfo: { ...pageInfo, cursor: pageInfo.after },
    });

    if (action.cursor) {
      yield put({ type: Types.NEXT_RESULTS, results });
    } else {
      yield put({ type: Types.SET_RESULTS, results });
    }
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
  } finally {
    yield put({ type: Types.LOAD_QUERY, load: false });
  }
}

export function* getCities(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { filter } = action;

    const response = yield call(cli.query, {
      query: gql`
        query ($first: Int, $after: String, $filter: CityFilterInput) {
          cities(first: $first, after: $after, filter: $filter) {
            edges {
              node {
                id
                code
                name
                state
              }
            }
            pageInfo {
              hasNextPage
              after
              before
            }
          }
        }
      `,
      variables: {
        first: action.first || 50,
        after: action.cursor,
        filter,
      },
    });

    const results = response.data.cities.edges;

    if (action.success) action.success(results);
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  }
}
