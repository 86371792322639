import validate from './validate';
import { FormCreate } from '@components';

const Form = FormCreate('LogisticCenterRegionsForm', validate);

const number = value => value && parseInt(value.replace(/[^0-9]/g, ''), 10);

function LogisticCenterRegionsForm({ getRegions, ...rest }) {
  return (
    <Form
      {...rest}
      fields={[
        {
          name: 'regionName',
          component: 'search',
          type: 'search',
          label: 'Região:',
          edit: false,
          inputProps: {
            keyExtractor: item => item.node.key,
            textExtractor: item => item.node.description,
            onSearch: search => {
              return new Promise((resolve, reject) => {
                getRegions({
                  search,
                  success: results => {
                    resolve(results);
                  },
                  error: error => {
                    reject(error);
                  },
                });
              });
            },
            onSelect: (item, reduxFormChange) => {
              reduxFormChange('regionName', item.node.description);
              reduxFormChange('regionId', item.node.id);
            },
          },
        },
        {
          name: 'dispatch',
          component: 'input',
          type: 'text',
          label: 'Numero de dias entrega direta:',
          inputProps: {
            placeholder: 'Informe o numero de dias entrega direta',
          },
          normalize: number,
        },
        {
          name: 'redispatch',
          component: 'input',
          type: 'text',
          label: 'Numero de dias entrega com redespacho:',
          inputProps: {
            placeholder: 'Informe o numero de dias entrega com redespacho',
          },
          normalize: number,
        },
        {
          name: 'regionId',
          component: 'input',
          type: 'text',
          display: 'none',
        },
      ]}
    />
  );
}

export default LogisticCenterRegionsForm;
