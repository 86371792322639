import { client } from '@config/apollo';
import gql from 'graphql-tag';
import { call, put, select } from 'redux-saga/effects';
import { Types } from '../ducks/incident';
import { Types as ToastTypes } from '../ducks/toast';

export function* fetchIncidents(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    yield put({
      type: Types.LOAD_QUERY,
      load: true,
    });

    const response = yield call(cli.query, {
      query: gql`
        query ($last: Int, $before: String, $filter: IncidentFilterInput!) {
          allIncidents(last: $last, before: $before, filter: $filter) {
            edges {
              node {
                id
                driver {
                  firstName
                  lastName
                  user {
                    phone
                  }
                }
                createdBy {
                  firstName
                  lastName
                }
                invoice {
                  key
                  invoiceNo
                  serialNo
                }
                type {
                  description
                  purpose
                }
                start
                end
                note
                closedBy {
                  firstName
                  lastName
                  phone
                }
              }
            }
            pageInfo {
              hasNextPage
              after
              before
            }
          }
        }
      `,
      variables: {
        last: 50,
        before: action.cursor,
        filter: action.filter,
      },
    });

    const pageInfo = response.data.allIncidents.pageInfo;
    const results = response.data.allIncidents.edges;

    yield put({
      type: Types.SET_PAGE_INFO,
      pageInfo: {
        ...pageInfo,
        cursor: pageInfo.before,
      },
    });

    if (action.cursor) {
      yield put({
        type: Types.NEXT_RESULTS,
        results,
      });
    } else {
      yield put({
        type: Types.SET_RESULTS,
        results,
      });
    }
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
  } finally {
    yield put({
      type: Types.LOAD_QUERY,
      load: false,
    });
  }
}

export function* terminateIncident(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { incident, message } = action;

    if (!message || message.length <= 0) {
      throw Error(
        'Favor preencher o campo solução do incidente para finalizar o incidente.'
      );
    }

    yield put({
      type: Types.LOAD_MUTATION,
      load: true,
    });

    //Termina o incident
    yield call(cli.mutate, {
      mutation: gql`
        mutation ($incident: ID!) {
          terminateIncident(incident: $incident) {
            id
          }
        }
      `,
      variables: {
        incident: incident.id,
      },
    });
    //
    //Cria log
    yield call(cli.mutate, {
      mutation: gql`
        mutation ($invoiceLog: InvoiceLogInput!) {
          createInvoiceLog(invoiceLog: $invoiceLog) {
            ok
          }
        }
      `,
      variables: {
        invoiceLog: {
          description: message,
          key: incident.invoice.key,
        },
      },
    });
    //
    //Envia mensagem
    if (incident.driver) {
      yield call(cli.mutate, {
        mutation: gql`
          mutation ($message: MessageInput) {
            sendMessage(message: $message) {
              ok
            }
          }
        `,
        variables: {
          message: {
            cpf: incident.driver.cpf,
            subject: `Orientações pendência NF: ${incident.invoice.invoiceNo}`,
            content: message,
          },
        },
      });
    }
    ////

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Incidente finalizado com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({
      type: Types.LOAD_MUTATION,
      load: false,
    });
  }
}

export function* subscribeToNewIncident(dispatch) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  const response = yield call(cli.subscribe.bind(cli), {
    query: gql`
      subscription {
        newIncident {
          id
        }
      }
    `,
  });

  response.subscribe({
    next() {
      dispatch({
        type: Types.SET_NEW_INCIDENT,
        newIncident: true,
      });
    },
  });
}

export function* createIncident(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { type, start, invoiceKey, note } = action.data;

    yield put({
      type: Types.LOAD_MUTATION,
      load: true,
    });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($incident: IncidentInput!) {
          createIncident(incident: $incident) {
            id
          }
        }
      `,
      variables: {
        incident: {
          type,
          invoiceKey,
          start,
          note,
        },
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Ocorrência criada com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({
      type: Types.LOAD_MUTATION,
      load: false,
    });
  }
}
