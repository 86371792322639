import { useEffect, useState } from 'react';

import { connect, useDispatch } from 'react-redux';
import { fetchExpense, resetState } from '@redux/ducks/expense';
import { fetchExpenseTypes } from '@redux/ducks/expenseType';

import Filter from '../../components/Filter';
import { ShipperSelector } from '@components';
import ExpenseList from '../../components/ExpenseList';
import ExpenseDetail from '@pages/Expenses/Accompaniments/Detail';

import moment from 'moment';

const ExpenseEffect = ({ results, load_query, pageInfo, expense }) => {
  const [shipper, setShipper] = useState(undefined);
  const [isExpenseDetailModalOpen, setIsExpenseDetailModalOpen] =
    useState(false);
  const [expenseDetailData, setExpenseDetailData] = useState({});
  const [from, setFrom] = useState(new Date(moment().subtract(30, 'days')));
  const [to, setTo] =useState( new Date());
  const [status, setStatus] =useState(2);
  const initialStateFilter = {
    from,
    to,
    status,
    expense: expense,
    type: null,
  };
  const dispatch = useDispatch();

  function handleSearch(data) {
    setTo(data.to)
    setFrom(data.from)
    setStatus(data.status)
    dispatch(
      fetchExpense({
        type: data.type,
        to: data.to,
        from: data.from,
        status: data.status,
        shipper,
      })
    );
  }

  function onDetailReject(data) {
    setExpenseDetailData(data.node);
    setIsExpenseDetailModalOpen(true);
  }

  const next = () => {
    dispatch(
      fetchExpense({
        from,
        to,
        status,
        expense: expense,
        type: null,
        cursor: pageInfo.before,
        shipper: shipper || undefined
      })
    )
  }

  useEffect(() => {
    dispatch(resetState());
    dispatch(fetchExpenseTypes({}));
    handleSearch(initialStateFilter);
  }, []);

  if (initialStateFilter.expense.length === 0){
    return null
  }

  return (
    <>
      <Filter
        initialState={initialStateFilter}
        onSearch={handleSearch}
        showApproved={true}
      />
      <ShipperSelector
        onSelect={selection => setShipper(selection.id)}
        onClear={() => setShipper(undefined)}
      />
      <ExpenseList
        source={results}
        btnNew={false}
        onDetailReject={data => onDetailReject(data)}
        load={load_query}
        next={next}
        hasNext={pageInfo.hasNextPage}
      />

      <ExpenseDetail
        modalTitle="Não aprovado ou indeferido"
        open={isExpenseDetailModalOpen}
        onClose={() => setIsExpenseDetailModalOpen(false)}
        expenseDetailData={expenseDetailData}
        hide={['cost', 'description', 'type']}
      />
    </>
  );
};

function mapStateToProps(state) {
  const { results, load_query, pageInfo } = state.expense;
  const { results: expense } = state.expenseType;

  return {
    results,
    load_query,
    pageInfo,
    expense,
  };
}

export default connect(mapStateToProps)(ExpenseEffect);
