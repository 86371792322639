class CnpjCpf {
  validate(value) {
    let v = this.removeMask(value);
    if (v.length !== 11 && v.length !== 14) {
      return false;
    }

    if (v.length === 11) {
      return this.validateCPF(value);
    } else {
      return this.validateCNPJ(value);
    }
  }

  validateCPF(value) {
    let v = this.removeMask(value);
    let c = v.substr(0, 9);
    let dv = v.substr(9, 2);
    let d1 = 0;
    for (let i = 0; i < 9; i++) {
      d1 += c.charAt(i) * (10 - i);
    }

    if (d1 == 0) return false;

    d1 = 11 - (d1 % 11);
    if (d1 > 9) d1 = 0;
    if (dv.charAt(0) != d1) {
      return false;
    }
    d1 *= 2;
    for (let i = 0; i < 9; i++) {
      d1 += c.charAt(i) * (11 - i);
    }
    d1 = 11 - (d1 % 11);
    if (d1 > 9) d1 = 0;
    if (dv.charAt(1) != d1) {
      return false;
    }
    return true;
  }

  validateCNPJ(value) {
    let v = this.removeMask(value);
    let a = [];
    let b = 0;
    let c = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    for (let i = 0; i < 12; i++) {
      a[i] = v.charAt(i);
      b += a[i] * c[i + 1];
    }
    let x = b % 11;
    if (x < 2) {
      a[12] = 0;
    } else {
      a[12] = 11 - x;
    }
    b = 0;
    for (let y = 0; y < 13; y++) {
      b += a[y] * c[y];
    }
    x = b % 11;
    if (x < 2) {
      a[13] = 0;
    } else {
      a[13] = 11 - x;
    }
    if (v.charAt(12) != a[12] || v.charAt(13) != a[13]) {
      return false;
    }
    return true;
  }

  mask(value) {
    let v = this.removeMask(value);
    if (v.length !== 11 && v.length !== 14) {
      return '';
    }

    if (v.length === 11) {
      return this.maskCPF(value);
    } else {
      return this.maskCNPJ(value);
    }
  }

  maskCPF(value) {
    let CPF = this.removeMask(value);
    return (
      CPF.substring(0, 3) +
      '.' +
      CPF.substring(3, 6) +
      '.' +
      CPF.substring(6, 9) +
      '-' +
      CPF.substring(9, 11)
    );
  }

  maskAnonymizedCPF(CPF) {
    return (
      CPF.substring(0, 3) +
      '.' +
      CPF.substring(3, 6) +
      '.' +
      CPF.substring(6, 9) +
      '-' +
      CPF.substring(9, 11)
    );
  }

  maskCNPJ(value) {
    let CNPJ = this.removeMask(value);
    return (
      CNPJ.substring(0, 2) +
      '.' +
      CNPJ.substring(2, 5) +
      '.' +
      CNPJ.substring(5, 8) +
      '/' +
      CNPJ.substring(8, 12) +
      '-' +
      CNPJ.substring(12, 14)
    );
  }

  removeMask(value) {
    value = String(value);
    return (value || '').replace(/\D/g, '');
  }
}

export default new CnpjCpf();
