export const Types = {
  SET_CUSTOMER: 'customer/SET_CUSTOMER',
  SET_RESULTS: 'customer/SET_RESULTS',
  NEXT_RESULTS: 'customer/NEXT_RESULTS',
  SET_PAGE_INFO: 'customer/SET_PAGE_INFO',
  SET_ERROR_QUERY: 'customer/SET_ERROR_QUERY',
  SET_ERROR_MUTATION: 'customer/SET_ERROR_MUTATION',
  SET_SUCCESS_QUERY: 'customer/SET_SUCCESS_QUERY',
  SET_SUCCESS_MUTATION: 'customer/SET_SUCCESS_MUTATION',
  LOAD_QUERY: 'customer/LOAD_QUERY',
  LOAD_MUTATION: 'customer/LOAD_MUTATION',
  FETCH_CUSTOMER: 'customer/FETCH_TRANSPORT_QUEUE',
  RESET_STATE: 'customer/RESET_STATE',
  USER_LOGOUT: 'USER_LOGOUT',
};

const initialState = {
  customer: undefined,
  results: [],
  pageInfo: {},
  load_query: false,
  load_mutation: false,
  error_query: {},
  error_mutation: {},
  success_query: {},
  success_mutation: {},
};

export default function customer(state = initialState, action) {
  switch (action.type) {
    case Types.SET_CUSTOMER:
      return { ...state, customer: action.customer };

    case Types.SET_RESULTS:
      return { ...state, results: action.results };
    case Types.NEXT_RESULTS:
      return { ...state, results: [...state.results, ...action.results] };
    case Types.SET_PAGE_INFO:
      return { ...state, pageInfo: action.pageInfo };
    case Types.SET_ERROR_QUERY:
      return { ...state, error_query: action.error };
    case Types.SET_ERROR_MUTATION:
      return { ...state, error_mutation: action.error };
    case Types.SET_SUCCESS_QUERY:
      return { ...state, success_query: action.success };
    case Types.SET_SUCCESS_MUTATION:
      return { ...state, success_mutation: action.success };
    case Types.LOAD_QUERY:
      return { ...state, load_query: action.load };
    case Types.LOAD_MUTATION:
      return { ...state, load_mutation: action.load };
    case Types.RESET_STATE:
    case Types.USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export function fetchCustomer({ cnpj, success, error, finish }) {
  return {
    type: Types.FETCH_CUSTOMER,
    cnpj,
    success,
    error,
    finish,
  };
}

export function resetState() {
  return {
    type: Types.RESET_STATE,
  };
}
