import { FormCreate } from '@components';
import { validate } from './validate';
import GetInfoCnpj from '@utils/GetInfoCnpj';
import { useDispatch } from 'react-redux';
import { getCities } from '@redux/ducks/city';

const Form = FormCreate('CompaniesForm', validate);

export default function CompanyForm({ refreshFormData, ...rest }) {
  const dispatch = useDispatch();

  async function handleGetDataCnpj(cnpj) {
    const response = await GetInfoCnpj(cnpj);
    if (response && response.data) {
      const { nome_fantasia, razao_social, ddd_telefone_1 } = response.data;
      if (refreshFormData) {
        refreshFormData({
          commercialName: nome_fantasia,
          corporateName: razao_social,
          phone: ddd_telefone_1,
        });
      }
    }
  }

  return (
    <Form
      {...rest}
      fields={[
        {
          name: 'active',
          component: 'input',
          type: 'toogle',
          label: 'active:',
          edit: false,
          display: 'none',
        },
        {
          name: 'id',
          component: 'input',
          type: 'text',
          label: 'ID:',
          edit: false,
          display: 'none',
        },
        {
          name: 'cnpj',
          component: 'input',
          type: 'text',
          edit: false,
          label: 'CNPJ:',
          inputProps: {
            placeholder:
              'Informe o CNPJ da empresa no formato 00.000.000/0000-00',
            onBlur: e => handleGetDataCnpj(e.target.value),
          },
        },
        {
          name: 'commercialName',
          component: 'input',
          type: 'text',
          label: 'Fantasia:',
          inputProps: {
            placeholder: 'Digite aqui o nome fantasia da empresa',
          },
        },
        {
          name: 'corporateName',
          component: 'input',
          type: 'text',
          label: 'Razão social:',
          inputProps: {
            placeholder: 'Digite aqui a razão social da empresa',
          },
        },
        {
          name: 'contactFirstName',
          component: 'input',
          edit: false,
          type: 'text',
          label: 'Nome do contratante:',
          inputProps: {
            placeholder:
              'Digite aqui o nome do contratante contratante responsável',
          },
        },
        {
          name: 'contactLastName',
          component: 'input',
          edit: false,
          type: 'text',
          label: 'Sobrenome do contratante:',
          inputProps: {
            placeholder: 'Digite aqui o sobrenome do contratante responsável',
          },
        },
        {
          name: 'city',
          component: 'search',
          type: 'search',
          label: 'Cidade:',
          inputProps: {
            keyExtractor: item => item.node.code,
            textExtractor: item => item.node.name,
            onSearch: search => {
              return new Promise((resolve, reject) => {
                dispatch(
                  getCities({
                    search,
                    success: results => {
                      resolve(results);
                    },
                    error: error => {
                      reject(error);
                    },
                  })
                );
              });
            },
            onSelect: (item, reduxFormChange) => {
              reduxFormChange('city', item.node.name);
              reduxFormChange('cityCode', item.node.code);
              reduxFormChange('cityId', item.node.id);
            },
          },
        },
        {
          name: 'email',
          component: 'input',
          edit: false,
          type: 'text',
          label: 'Email do contratante para o envio do convite:',
          inputProps: {
            placeholder: 'Digite aqui o email para envio do convite',
          },
        },
        {
          name: 'phone',
          component: 'input',
          type: 'text',
          label: 'Telefone:',
          inputProps: {
            mask: '(99) 99999-9999',
            maskChar: '_',
            placeholder: 'Digite aqui o número de contato da empresa',
          },
        },
        {
          name: 'stateInscription',
          component: 'input',
          edit: false,
          type: 'text',
          label: 'Inscrição estadual:',
          inputProps: {
            placeholder: 'Digite aqui a inscrição estadual da empresa',
          },
        },
        {
          name: 'neighborhood',
          component: 'input',
          type: 'text',
          label: 'Bairro:',
          inputProps: {
            placeholder: 'Digite aqui o bairro da empresa',
          },
        },
        {
          name: 'address',
          component: 'input',
          type: 'text',
          label: 'Endereço:',
          inputProps: {
            placeholder: 'Digite aqui o endereço da empresa',
          },
        },
        {
          name: 'licenseSlots',
          component: 'input',
          type: 'number',
          label: 'Licenças:',
          inputProps: {
            placeholder: 'Digite aqui quantas licenças serão usadas',
          },
        },
      ]}
    />
  );
}
