export default function Action({
  caption,
  name,
  type = 'text',
  onFireAction,
  ...rest
}) {
  return (
    <div>
      {!!caption && (
        <label
          htmlFor={name}
          className="block text-sm font-medium leading-6 text-gray-900 mb-1"
        >
          {caption}
        </label>
      )}
      <div className="flex rounded-md shadow-sm">
        <div className="relative flex flex-grow items-stretch">
          <input
            type={type}
            className="block w-full rounded-none rounded-l-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            {...rest}
          />
        </div>
        <button
          type="button"
          onClick={onFireAction}
          className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        >
          Buscar
        </button>
      </div>
    </div>
  );
}
