function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Tabs = ({ children }) => (
  <nav className="flex space-x-4" aria-label="Tabs">
    {children}
  </nav>
);

Tabs.Item = ({ children, active = false, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className={classNames(
      active
        ? 'bg-gray-100 text-gray-700'
        : 'text-gray-500 hover:text-gray-700',
      'rounded-md px-3 py-2 text-sm font-medium'
    )}
  >
    {children}
  </button>
);

Tabs.displayName = 'Tabs';
Tabs.Item.displayName = 'Tabs.Item';

export default Tabs;
