import { FormCreate } from '@components';
import validate from './validate';
import { useDispatch } from 'react-redux';
import GetInfoCnpj from '@utils/GetInfoCnpj';
import { useEffect, useState } from 'react';

const Form = FormCreate('ShipperForm', validate, { active: true });

const number = value => value && value.replace(/[^0-9]/g, '');

const ShipperForm = ({ refreshFormData, getCities, ...rest }) => {
  const dispatch = useDispatch();
  const [cityId, setCityId] = useState();

  async function getCity(search, uf) {
    return new Promise(resolve => {
      dispatch(
        getCities({
          search,
          success: results => {
            resolve(results);
          },
        })
      );
    }).then(response => {
      const city = response.find(
        item =>
          item.node.name.toUpperCase() === search && item.node.state === uf
      );

      if (city) setCityId(city.node.id);
    });
  }

  useEffect(() => {
    if (cityId) {
      if (refreshFormData) {
        refreshFormData(
          {
            cityId,
          },
          false
        );
      }
    }
  }, [cityId]);

  async function handleGetDataCnpj(cnpj) {
    const response = await GetInfoCnpj(cnpj);

    if (response && response.data) {
      const {
        nome_fantasia,
        razao_social,
        ddd_telefone_1,
        bairro,
        logradouro,
        numero,
        municipio,
        uf,
      } = response.data;

      if (!uf) return;

      if (refreshFormData) {
        getCity(municipio, uf);
        refreshFormData(
          {
            cnpj: cnpj,
            commercialName: nome_fantasia,
            corporateName: razao_social,
            phone: ddd_telefone_1,
            neighborhood: bairro,
            address: `${logradouro},${numero}`,
          },
          false
        );
      }
    }
  }

  return (
    <Form
      {...rest}
      lbSubmit={'Gravar'}
      fields={[
        {
          name: 'shipperId',
          component: 'input',
          type: 'text',
          label: 'ID:',
          edit: false,
          display: 'none',
        },
        {
          name: 'cnpj',
          component: 'input',
          edit: false,
          type: 'text',
          label: 'CNPJ:',
          inputProps: {
            mask: '99.999.999/9999-99',
            placeholder: 'Informe o CNPJ',
            onBlur: e => handleGetDataCnpj(e.target.value),
          },
          normalize: number,
        },
        {
          name: 'corporateName',
          component: 'input',
          type: 'text',
          label: 'Razão social:',
          inputProps: {
            placeholder: 'Informe a razão social',
          },
        },
        {
          name: 'commercialName',
          component: 'input',
          type: 'text',
          label: 'Fantasia:',
          inputProps: {
            placeholder: 'Informe a fantasia',
          },
        },
        {
          name: 'stateInscription',
          component: 'input',
          type: 'text',
          label: 'Inscrição estadual:',
          inputProps: {
            placeholder: 'Informe a inscrição estadual',
          },
        },
        [
          {
            name: 'city',
            component: 'search',
            type: 'search',
            label: 'Cidade:',
            inputProps: {
              keyExtractor: item => item.node.code,
              textExtractor: item => item.node.name,
              onSearch: search => {
                return new Promise((resolve, reject) => {
                  dispatch(
                    getCities({
                      search,
                      success: results => {
                        resolve(results);
                      },
                      error: error => {
                        reject(error);
                      },
                    })
                  );
                });
              },
              onSelect: (item, reduxFormChange) => {
                reduxFormChange('city', item.node.name);
                reduxFormChange('cityCode', item.node.code);
                reduxFormChange('cityId', item.node.id);
              },
            },
          },
          {
            name: 'neighborhood',
            component: 'input',
            type: 'text',
            label: 'Bairro:',
            inputProps: {
              placeholder: 'Informe o bairro',
            },
          },
        ],
        {
          name: 'address',
          component: 'input',
          type: 'text',
          label: 'Endereço:',
          inputProps: {
            placeholder: 'Informe o endereço',
          },
        },
        {
          name: 'contactFirstName',
          component: 'input',
          type: 'text',
          label: 'Primeiro nome do contato:',
          inputProps: {
            placeholder: 'Informe o primeiro nome da pessoa de contato',
          },
        },
        {
          name: 'contactLastName',
          component: 'input',
          type: 'text',
          label: 'Sobrenome do contato:',
          inputProps: {
            placeholder: 'Informe o sobrenome da pessoa de contato',
          },
        },
        {
          name: 'email',
          component: 'input',
          edit: false,
          type: 'text',
          label: 'Email:',
          inputProps: {
            placeholder: 'Informe o email',
          },
        },
        {
          name: 'phone',
          component: 'input',
          type: 'text',
          label: 'Telefone:',
          inputProps: {
            mask: '55 (99) 99999-9999',
            placeholder: 'Informe o telefone',
          },
          normalize: number,
        },
      ]}
    />
  );
};

export default ShipperForm;
