const validate = values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Campo obrigatório!';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'E-mail inválido! ';
  }
  return errors;
};

export default validate;
