const validate = values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Favor informar email do usuário!';
  }
  if (!values.firstName) {
    errors.firstName = 'Favor informar o nome do usuário!';
  }
  if (!values.states) {
    errors.states = 'Favor informar estados!';
  }
  return errors;
};

export default validate;
