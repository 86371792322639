import { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getShippers } from '@redux/ducks/shipper';
import { SearchModal, Button } from '@components';

const ShipperSelector = ({ onClear, onSelect, onSearch }) => {
  const [fieldText, setFieldText] = useState('');
  const dispatch = useDispatch();

  const handleClearField = () => {
    setFieldText('');
    if (onClear) onClear();
  };

  const handleFetchShippers = search => {
    return new Promise((resolve, reject) => {
      dispatch(
        getShippers({
          search,
          success: results => {
            resolve(results);
          },
          error: error => {
            reject(error);
          },
        })
      );
    });
  };

  const handleSelectShipper = item => {
    setFieldText(item.node.company.corporateName);
    if (onSelect) {
      onSelect({
        id: item.node.id,
        name: item.node.company.corporateName,
      });
    }
  };

  const handleKeyExtraction = item => item.node.id;

  const handleTextExtraction = item =>
    `${item.node.code ? '(' + item.node.code + ') ' : ''}${
      item.node.company.corporateName
    } - ${item.node.company.commercialName || ''}`;

  return (
    <form className="rounded-md px-3 py-2 text-sm font-medium">
      <div className="flex sm:items-end gap-2  sm:flex-row">
        <div className="flex flex-col w-60">
          <SearchModal
            caption="Transportadora"
            value={fieldText}
            keyExtractor={handleKeyExtraction}
            textExtractor={handleTextExtraction}
            onSearch={handleFetchShippers}
            onSelect={handleSelectShipper}
          />
        </div>
        {onSearch && <Button onClick={() => onSearch()}>Consultar</Button>}
        <Button onClick={handleClearField}>Limpar</Button>
      </div>
    </form>
  );
};

export default connect(null)(ShipperSelector);
