import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  fetchLogisticCenterRegions,
  saveLogisticCenterRegion,
  removeLogisticCenterRegion,
} from '@redux/ducks/logisticCenterRegions';
import { getRegions } from '@redux/ducks/region';

import { Confirm, TableForm, Button } from '@components';
import LogisticCenterRegionsForm from './components/LogisticCenterRegionsForm';

import { useNavigate, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

const LogisticCenterRegions = ({
  results,
  pageInfo,
  load_query,
  load_mutation,
  fetchLogisticCenterRegions,
  getRegions,
  saveLogisticCenterRegion,
  removeLogisticCenterRegion,
}) => {
  const [ordenedResults, setOrdenedResults] = useState([])
  const navigate = useNavigate();
  const params = useParams();
  const listRef = useRef(null);
  const formRef = useRef(null);
  const confirmRef = useRef(null);
  const logisticCenterName = results[0]?.node?.logisticCenter?.name;

  function initialize() {
    window.scroll(0, 0);
    listRef.current.closeForm();
    fetchLogisticCenterRegions({ cnpj: params.cnpj });
  }

  function handleOnSave(data, edit) {
    return new Promise((resolve, reject) => {
      saveLogisticCenterRegion({
        data: { ...data, logisticCenterCnpj: params.cnpj },
        edit,
        success: () => {
          initialize();
          resolve();
        },
        error: error => {
          reject(error);
        },
      });
    });
  }

  async function handleEdit(logisticCenterRegion) {
    await listRef.current.openForm();
    const { id, region, eta } = logisticCenterRegion;

    setTimeout(() => {
      formRef?.current?.edit({
        id,
        regionId: region.id,
        regionName: region.description,
        dispatch: eta?.dispatch,
        redispatch: eta?.redispatch,
      });
    }, 500);
  }

  function handleRemove(logisticCenterRegion) {
    const { id } = logisticCenterRegion;
    confirmRef.current.open(
      'Deseja realmente remover a região selecionada?',
      () => {
        removeLogisticCenterRegion({
          id,
          success: () => {
            initialize();
          },
        });
      }
    );
  }

  function handleBack() {
    navigate('/logistic-centers');
  }

  function handleGoToShippers(logisticCenterRegion) {
    const { id } = logisticCenterRegion;
    navigate(`/logistic-centers/${params.cnpj}/region/${id}`);
  }

  function handleSearch(search) {
    fetchLogisticCenterRegions({ cnpj: params.cnpj, search });
  }

  useEffect(() => {
    initialize();
  }, []);

  useEffect(()=>{
    const ordenated = results.sort(function(a, b) {
      var keyA = parseInt(a.node.region.key);
      var keyB = parseInt(b.node.region.key);
      return keyA - keyB;
    });

    setOrdenedResults(ordenated)
  },[results])

  return (
    <>
      <TableForm
        formHeader={{
          title: `Regiões do centro logístico ${
            logisticCenterName ? `| ${logisticCenterName}` : ''
          }`,
          subtitle: 'Gerencie e visualize as regiões do centro logístico',
        }}
        titleModal="Cadastro de centro logístico"
        source={ordenedResults}
        hasNext={pageInfo.hasNextPage}
        load={load_query}
        onSearch={handleSearch}
        ref={listRef}
        onNew={() => listRef.current.openForm()}
        headers={[
          { label: 'Região', getValue: item => item.node.region.key },
          {
            label: 'Descrição',
            getValue: item => item.node.region.description,
          },
          {
            label: 'Entrega direta',
            getValue: item => item.node?.eta?.dispatch,
          },
          { label: 'Redespacho', getValue: item => item.node?.eta?.redispatch },
          {
            label: 'Ações',
            props: {
              align: 'center',
              width: '330px',
            },
            getValue: item => (
              <div className="flex gap-2 justify-end">
                <Button
                  compact
                  key={item.node.id}
                  onClick={() => handleEdit(item.node)}
                >
                  Editar
                </Button>
                <Button compact onClick={() => handleGoToShippers(item.node)}>
                  Transportadoras
                </Button>
                <Button
                  compact
                  variant="negative"
                  onClick={() => handleRemove(item.node)}
                >
                  Remover
                </Button>
              </div>
            ),
          },
        ]}
        keyRow={item => item.node.id}
        back={true}
        lbBack="Centros Logísticos"
        onBack={handleBack}
      >
        <LogisticCenterRegionsForm
          getRegions={getRegions}
          formRef={formRef}
          onSubmit={handleOnSave}
          loadMutation={load_mutation}
        />
      </TableForm>
      <Confirm ref={confirmRef} />
    </>
  );
};

function mapStateToProps({ logisticCenterRegions }) {
  const { results, pageInfo, load_query, load_mutation } =
    logisticCenterRegions;

  return {
    results,
    pageInfo,
    load_query,
    load_mutation,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchLogisticCenterRegions,
      getRegions,
      saveLogisticCenterRegion,
      removeLogisticCenterRegion,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogisticCenterRegions);
