import { TableForm } from '@components';
import { setDataObject as setInvoice } from '@redux/ducks/invoice';
import { useDispatch } from 'react-redux';

import moment from 'moment';

const LogTable = ({ ...rest }) => {
  const dispatch = useDispatch();

  function handleDetailInvoice(invoiceNumber) {
    dispatch(
      setInvoice({
        key: invoiceNumber,
      })
    );
  }

  return (
    <TableForm
      {...rest}
      keyRow={item => item.id}
      headers={[
        {
          label: 'Operador',
          getValue: item =>
            `${item.createdBy.firstName} ${item.createdBy.lastName}`,
        },
        {
          label: 'Nota Fiscal',
          getValue: item => (
            <span
              className="text-blue-500 cursor-pointer"
              onClick={() => handleDetailInvoice(item.invoice.invoiceNo)}
            >
              {item.invoice.invoiceNo}
            </span>
          ),
        },
        {
          label: 'Data',
          getValue: item => moment(item.committedAt).format('DD/MM/YYYY HH:mm'),
        },
        {
          label: 'Ação',
          getValue: item => item.description,
        },
      ]}
    />
  );
};

export default LogTable;
