import { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  getReasons,
  saveReason,
  removeReason,
  resetState,
} from '@redux/ducks/reversalReason';
import ReversalReasonForm from './components/ReversalReasonForm';
import { TableForm, Button, Confirm } from '@components';

const ReversalReason = ({ results, load_query, load_mutation }) => {
  const [itemInUpdate, setItemInUpdate] = useState(null);
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const listRef = useRef(null);
  const confirmRef = useRef(null);

  function initialize() {
    dispatch(resetState());
    window.scroll(0, 0);
    listRef.current.closeForm();
    dispatch(getReasons({}));
  }

  function onSave(data, edit) {
    return new Promise((resolve, reject) => {
      dispatch(
        saveReason({
          data,
          edit,
          success: () => {
            initialize();
            resolve();
          },
          error: error => {
            reject(error);
          },
        })
      );
    });
  }

  async function edit({ id, description }) {
    await listRef.current.openForm();

    setTimeout(() => {
      formRef?.current?.edit({
        id,
        description,
      });
    }, 200);
  }

  const handleSearch = search => {
    dispatch(getReasons({ search }));
  };

  const handleRemove = ({ id }) => {
    confirmRef.current.open(`Deseja realmente excluir esse motivo?`, () => {
      setItemInUpdate(id);
      dispatch(
        removeReason({
          data: {
            id,
          },
          success: () => {
            setItemInUpdate(null);
            initialize();
          },
          error: () => {
            setItemInUpdate(null);
          },
        })
      );
    });
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <>
      <TableForm
        formHeader={{
          title: 'Motivos de devolução',
          subtitle: 'Gerencie e visualize os motivos das devoluções',
        }}
        onSearch={handleSearch}
        titleModal="Cadastro de motivo de devolução"
        onNew={() => listRef.current.openForm()}
        source={results}
        load={load_query}
        ref={listRef}
        headers={[
          { label: 'Descrição', getValue: item => item.description },
          {
            label: 'Ações',
            props: {
              align: 'center',
              width: '170px',
            },
            getValue: item => (
              <div className="flex gap-2 justify-end">
                <Button compact onClick={() => edit(item)}>
                  Editar
                </Button>
                <Button
                  compact
                  variant="negative"
                  onClick={() => handleRemove(item)}
                  isLoading={load_mutation && item.id === itemInUpdate}
                >
                  Remover
                </Button>
              </div>
            ),
          },
        ]}
      >
        <ReversalReasonForm
          formRef={formRef}
          onSubmit={(values, edit) => onSave(values, edit)}
          loadMutation={load_mutation}
        />
      </TableForm>
      <Confirm ref={confirmRef} />
    </>
  );
};

function mapStateToProps({ reversalReason }) {
  const { results, load_query, load_mutation } = reversalReason;

  return {
    results,
    load_query,
    load_mutation,
  };
}

export default connect(mapStateToProps)(ReversalReason);
