import { connect, useDispatch } from 'react-redux';
import {
  useNavigate,
  useParams,
  useLocation,
  useOutletContext,
} from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

import InvoiceList from '../components/invoiceList';
import {
  fetchInvoices,
  getInvoice,
  removeReturn,
  resetState,
} from '@redux/ducks/invoice';
import { fetchOverview } from '@redux/ducks/overview';
import { Confirm } from '@components';
import { permissions } from '@config/permission';
import { RedeliveryForm } from '@pages';
import DevolutionOpenForm from '../../DevolutionInvoices/components/DevolutionOpenForm';

const InvoicesQuery = props => {
  const { results, pageInfo, load_query, contextCompany } = props;
  const [invoiceToReturn, setInvoiceToReturn] = useState(null);
  const [invoiceToDevolution, setInvoiceToDevolution] = useState(null);
  const confirmRef = useRef(null);

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const filterName = params['*'];

  let location = useLocation();
  const context = useOutletContext();
  const isReturnedSheet = location.pathname === '/invoices/returned';

  const createRedelivery = selectedInvoice => {
    setInvoiceToReturn(selectedInvoice);
  };

  const isOverdue = () => {
    return location.pathname.indexOf('overdue') !== -1;
  };

  const getActionBtns = () => {
    const buttons = [];

    if (location.pathname === '/invoices/returned') {
      if (permissions.invoice.createRedelivery()) {
        buttons.push({
          content: 'Reentregar',
          onClick: ({ node }) => {
            createRedelivery(node);
          },
        });
      }
      if (permissions.invoice.createReversal()) {
        buttons.push({
          content: 'Devolver ',
          onClick: ({ node }) => {
            handleReversalClick(node.key);
          },
        });
      }
      if (permissions.invoice.removeReturn()) {
        buttons.push({
          content: 'Remover',
          props: { variants: 'negative' },
          onClick: ({ node }) => {
            onRemoveReturn(node.key);
          },
        });
      }
    }

    return buttons;
  };

  const next = () => {
    const overviewFilter = {
      ...location.state,
      companyId: contextCompany?.id,
    };
    dispatch(fetchOverview(overviewFilter));
    dispatch(
      fetchInvoices({
        cursor: filterName === 'overdue' ? pageInfo.after : pageInfo.before,
        filter: filterName,
        params: context.state || {},
      })
    );
  };

  const handleReversalClick = invoiceKey => {
    dispatch(
      getInvoice({
        search: invoiceKey,
        success: invoice => {
          createDevolution(invoice);
        },
      })
    );
  };

  const createDevolution = selectedInvoice => {
    setInvoiceToDevolution(selectedInvoice);
  };

  const goToPage = location => {
    navigate(location);
  };

  const canSelectToSendMail = () => {
    const dispatcher = context?.state?.dispatcher;
    return isOverdue() && permissions.invoice.sendMail() && dispatcher;
  };

  const onRemoveReturn = invoiceKey => {
    confirmRef.current.open(
      'Deseja realmente remover a rejeição da nota selecionada',
      () => {
        dispatch(
          removeReturn({
            data: {
              invoiceKey,
            },
            success: () => {
              goToPage(location);
            },
          })
        );
      }
    );
  };

  async function handleRedeliveryCloseForm(){
    setInvoiceToReturn(null)
    goToPage(location);
  }

  async function handleDevolutionCloseForm(){
    setInvoiceToDevolution(null)
    goToPage(location);
  }

  useEffect(() => {
    const overviewFilter = {
      ...context.state,
      companyId: contextCompany?.id,
    };
    dispatch(resetState());
    dispatch(fetchOverview(overviewFilter));
    dispatch(
      fetchInvoices({
        filter: filterName,
        params: context.state || {},
      })
    );
  }, [location, contextCompany]);

  return (
    <>
      <InvoiceList
        filter={filterName}
        source={results}
        hasNext={pageInfo.hasNextPage}
        load={load_query}
        next={next}
        state={context.state}
        isAdmin={permissions.users.operator()}
        actionsBtn={getActionBtns()}
        allowToSendMail={canSelectToSendMail()}
        selectInvoiceToSendMail={item => context.selectInvoice(item)}
        selectInvoiceToShippingRelationship={item =>
          context.selectInvoiceToShippingRelationship(item)
        }
      />
      <Confirm ref={confirmRef} />
      {isReturnedSheet && (
        <RedeliveryForm
          open={!!invoiceToReturn}
          onClose={handleRedeliveryCloseForm}
          invoice={invoiceToReturn}
        />
      )}
      {isReturnedSheet && invoiceToDevolution && (
        <DevolutionOpenForm
          devolutionInvoice={invoiceToDevolution}
          openDevolutionFormModal={!!invoiceToDevolution}
          setDevolutionOpenForm={setInvoiceToDevolution}
          onClose={handleDevolutionCloseForm}
        />
      )}
    </>
  );
};

function mapStateToProps({ invoice, auth }) {
  const { results, pageInfo, load_query } = invoice;
  const { company } = auth;

  return {
    results,
    pageInfo,
    load_query,
    contextCompany: company,
  };
}

export default connect(mapStateToProps)(InvoicesQuery);
