import { forwardRef, useImperativeHandle } from 'react';
import { CheckCircleIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { toast } from 'react-toastify';

const Toast = forwardRef((_, ref) => {
  const showToast = ({ title, message, type }) => {
    return toast(
      <div>
        {title && (
          <p className="block text-md font-semibold leading-6 text-gray-900">
            {title}
          </p>
        )}
        <p className="block text-base font-medium leading-6 text-gray-700">
          {message}
        </p>
      </div>,
      {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        type: type,
        icon:
          type === 'success' ? (
            <CheckCircleIcon
              className="h-10 w-10 text-green-500"
              aria-hidden="true"
            />
          ) : (
            <XCircleIcon
              className="h-10 w-10 text-red-500"
              aria-hidden="true"
            />
          ),
        style: {
          borderRadius: 10,
        },
      }
    );
  };

  useImperativeHandle(ref, () => ({
    show: showToast,
  }));
});

Toast.displayName = 'Toast';

export { Toast };
