import { connect,useDispatch } from 'react-redux';
import { Load, Modal } from '@components';
import { fetchDriversByShipper } from '@redux/ducks/driver';
import { useEffect, useRef, useState } from 'react';
import ShipperDriverList from './components/ShipperDriverList'

const ShipperDriverModal = ({
  shipperCnpj,
  isShipperDriverModalOpen,
  setIsShipperDriverModalOpen,
  results,
  pageInfo,
  load_query,
  load_mutation
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const listRef = useRef(null)
  const dispatch = useDispatch();

  const next = () => {
    dispatch(
      fetchDriversByShipper({
        cursor: pageInfo.cursor,
        shipperCnpj: shipperCnpj,
      })
    );
  };

  function handleShipperDriver(shipperCnpj) {
    setIsLoading(true);

    dispatch(
      fetchDriversByShipper({
        shipperCnpj:  shipperCnpj,
        error: error => {
          alert(error.message);
        },
        finally: setIsLoading(false)
      }))
  }

  useEffect(() => {
    if (shipperCnpj) {
      handleShipperDriver(shipperCnpj);
    }
  }, [shipperCnpj]);

  return (
    <div>
      <Modal
        open={isShipperDriverModalOpen}
        onClose={() => setIsShipperDriverModalOpen(false)}
        title="Motoristas"
      >
        <ShipperDriverList
        source={results}
        hasNext={pageInfo.hasNextPage}
        load={load_query}
        next={next}
        ref={listRef}
      />
      </Modal>
      <Load active={isLoading || load_mutation} />
    </div>
  );
};

function mapStateToProps({ driver }) {
  const { results, pageInfo, load_query, load_mutation } = driver;

  return {
    results,
    pageInfo,
    load_query,
    load_mutation
  };
}

export default connect(mapStateToProps)(ShipperDriverModal);
