export const Types = {
  VALIDATE: 'auth/VALIDATE',
  SET_TOKEN: 'auth/SET_TOKEN',
  SET_DATA: 'auth/SET_DATA',
  REFRESH_USER: 'auth/REFRESH_USER',
  LOGOUT: 'auth/LOGOUT',
  FORCE_LOGOUT: 'auth/FORCE_LOGOUT',
  USER_LOGOUT: 'USER_LOGOUT',
  LOAD_MUTATION: 'auth/LOAD_MUTATION',
  LOAD_QUERY: 'auth/LOAD_QUERY',
  SET_COMPANY: 'auth/SET_COMPANY',
  SET_CONTRACTORS: 'auth/SET_CONTRACTORS',
};

const initialState = {
  token: undefined,
  user: {},
  load_mutation: false,
  load_query: false,
  company: {},
  contractors: [],
  shipper: undefined,
  licenses: undefined,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case Types.SET_DATA:
      return {
        ...state,
        token: action.token,
        user: action.user,
        company: action?.company,
        contractors: action?.contractors,
        shipper: action?.shipper || undefined,
        licenses: action?.licenses || undefined,
      };
    case Types.SET_TOKEN:
      return { ...state, token: action.token };
    case Types.USER_LOGOUT:
      return initialState;
    case Types.LOAD_MUTATION:
      return { ...state, load_mutation: action.load };
    case Types.LOAD_QUERY:
      return { ...state, load_query: action.load };
    case Types.SET_COMPANY:
      return { ...state, company: action.company };
    case Types.SET_CONTRACTORS:
      return { ...state, contractors: action.contractors };
    default:
      return state;
  }
}

export function signin({ data, success, error }) {
  return {
    type: Types.VALIDATE,
    data,
    success,
    error,
  };
}

export function refreshUser() {
  return {
    type: Types.REFRESH_USER,
  };
}

export function logout() {
  return {
    type: Types.LOGOUT,
  };
}

export function AddCompany(company) {
  return {
    type: Types.SET_COMPANY,
    company: company,
  };
}
