import { useState } from 'react';
import { DateRangePicker, Button, Input } from '@components';
import { useDispatch } from 'react-redux';
import { resetState } from '@redux/ducks/expense';

const Filter = ({ initialState, onSearch, showApproved }) => {
  const [initialStateFilter, setInitialStateFilter] = useState(initialState);
  const dispatch = useDispatch();

  const handleOptionsStatus = showApproved => {
    let options = [
      { key: 1, text: 'Pendente', value: 1 },
      { key: 2, text: 'Aprovado', value: 2 },
      { key: 3, text: 'Rejeitado', value: 3 },
    ];

    if (showApproved) {
      options = [
        { key: 2, text: 'Aprovado', value: 2 },
        { key: 3, text: 'Rejeitado', value: 3 },
      ];
    }
    return options;
  };

  const expenseOptions = initialStateFilter?.expense?.map(item => ({
    key: item.id,
    text: item.description,
    value: item.id,
  }))

  function handleSearch() {
    dispatch(resetState());
    if (onSearch) onSearch(initialStateFilter);
  }

  return (
    <form className="rounded-md px-3 py-2 text-sm font-medium">
      <div className="flex sm:items-end gap-2 flex-col sm:flex-row">
        <DateRangePicker
          placeholderFrom="Período de"
          placeholderTo="Até"
          from={initialStateFilter.from}
          to={initialStateFilter.to}
          onChangeFrom={from => {
            setInitialStateFilter(prevState => {
              return { ...prevState, from };
            });
          }}
          onChangeTo={to => {
            setInitialStateFilter(prevState => {
              return { ...prevState, to };
            });
          }}
        />

        <Input.Select
          caption="Situação"
          options={handleOptionsStatus(showApproved)}
          placeholder="Selecione ..."
          onChange={data => {
            setInitialStateFilter(prevState => ({
              ...prevState,
              status: parseInt(data),
            }));
          }}
        />
        <Input.Select
          caption="Despesa"
          defaultEmptyOption="Selecione ..."
          options={expenseOptions}
          placeholder="Selecione ..."
          onChange={data => {
            setInitialStateFilter(prevState => ({
              ...prevState,
              type: data,
            }));
          }}
        />
        <div className="">
          <Button
            onClick={() => {
              handleSearch();
            }}
          >
            Consultar
          </Button>
        </div>
      </div>
    </form>
  );
};

export default Filter;
