import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import auth from './auth';
import changePassword from './changePassword';
import invoice from './invoice';
import message from './message';
import incident from './incident';
import delivery from './delivery';
import overview from './overview';
import driver from './driver';
import incidentType from './incidentType';
import region from './region';
import regionCities from './regionCities';
import city from './city';
import logisticCenter from './logisticCenter';
import logisticCenterRegions from './logisticCenterRegions';
import logisticCenterShippers from './logisticCenterShippers';
import shipper from './shipper';
import operator from './operator';
import importalert from './alert';
import transportQueue from './transportQueue';
import shippingOrder from './shippingOrder';
import customer from './customer';
import reversal from './reversal';
import reversalReason from './reversalReason';
import redelivery from './redelivery';
import toast from './toast';
import reprieve from './reprieve';
import mail from './mail';
import loading from './loading';
import log from './log';
import expenseType from './expenseType';
import expense from './expense';
import companies from './companies';
import activateInvitation from './activateInvitation';
import config from './config';
import webhook from './webhook';

export default combineReducers({
  form: formReducer,
  auth,
  changePassword,
  invoice,
  message,
  incident,
  delivery,
  overview,
  driver,
  incidentType,
  region,
  regionCities,
  city,
  logisticCenter,
  logisticCenterRegions,
  logisticCenterShippers,
  shipper,
  operator,
  importalert,
  transportQueue,
  shippingOrder,
  customer,
  reversal,
  reversalReason,
  redelivery,
  toast,
  reprieve,
  mail,
  loading,
  log,
  expenseType,
  expense,
  companies,
  activateInvitation,
  config,
  webhook,
});
