export default function Toogle({ ...rest }) {
  return (
    <label className="inline-flex items-center space-x-4 my-1 cursor-pointer text-white">
      <span className="relative">
        <input type="checkbox" className="hidden peer" {...rest} />
        <div className="h-6 w-11 rounded-full shadow-inner bg-gray-200 peer-checked:bg-blue-600"></div>

        <div className="absolute inset-y-0 left-0 w-4 h-4 m-1 rounded-full shadow peer-checked:right-0 peer-checked:left-auto bg-white"></div>
      </span>
    </label>
  );
}
