export const Types = {
  GET_ACCESS_TOKEN: 'config/GET_ACCESS_TOKEN',
  SET_ACCESS_TOKEN: 'config/SET_ACCESS_TOKEN',
};

const initialState = {
  accessToken: undefined,
  load_mutation: false,
};

export default function config(state = initialState, action) {
  switch (action.type) {
    case Types.GET_ACCESS_TOKEN:
      return { ...state, accessToken: action.success };
    case Types.SET_ACCESS_TOKEN:
      return { ...state, accessToken: action.accessToken };
    default:
      return state;
  }
}

export function setAccessToken({ success, error }) {
  return {
    type: Types.SET_ACCESS_TOKEN,
    success,
    error,
  };
}

export function getAccessToken({ success, error }) {
  return {
    type: Types.GET_ACCESS_TOKEN,
    success,
    error,
  };
}
