import { Route, Routes } from 'react-router-dom';
import { NotFound, Operator } from '@pages';
import Protected from '@components/Protected';

function RoutesOperator() {
  return (
    <Protected>
      <Routes>
        <Route index element={<Operator />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Protected>
  );
}
export default RoutesOperator;
