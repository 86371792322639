import { connect } from 'react-redux';
import { FormCreate } from '@components/ReduxForm/Form';
import validate from './validate';

const Form = FormCreate('OperatorForm', validate, { active: true });

const OperatorForm = ({ states: statesList, ...rest }) => {
  return (
    <Form
      {...rest}
      fields={[
        {
          name: 'firstName',
          component: 'input',
          type: 'text',
          label: 'Nome:',
          inputProps: {
            placeholder: 'Informe o primeiro nome',
          },
        },
        {
          name: 'lastName',
          component: 'input',
          type: 'text',
          label: 'Sobrenome:',
          inputProps: {
            placeholder: 'Informe o sobrenome',
          },
        },
        {
          name: 'email',
          component: 'input',
          type: 'text',
          label: 'Email:',
          edit: false,
          inputProps: {
            placeholder: 'Informe o email',
          },
        },
        {
          name: 'states',
          component: 'multiselection',
          type: 'text',
          label: 'Estados:',
          inputProps: {
            placeholder: 'Selecione os estados que o usuário pode atender',
            options: statesList.map(state => {
              const [key, text] = Object.entries(state)[0];
              return {
                key,
                text,
                value: key,
              };
            }),
            onChange: (data, reduxFormChange) => {
              reduxFormChange('states', data.value);
            },
          },
        },
      ]}
    />
  );
};

function mapStateToProps(state) {
  const { states } = state.city;

  return {
    states,
  };
}

export default connect(mapStateToProps)(OperatorForm);
