import { useNavigate, useSearchParams } from 'react-router-dom';

import { Button, Toast } from '@components';
import Logo from '@assets/img/shipy.svg';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import { activateAccount } from '@redux/ducks/activateInvitation';
import { useRef } from 'react';

const ReceiveInvitation = ({ activateAccount, load_mutation }) => {
  const [searchParams] = useSearchParams();
  const invitation_key = searchParams.get('invitation_key');
  const navigate = useNavigate();
  const toastRef = useRef(null);

  function handlePasswordToken(resetPasswordToken) {
    if (resetPasswordToken) {
      navigate(`/activate-invitation`);
    } else {
      navigate(`/success-validation`);
    }
  }

  function handleActivateInvitation() {
    activateAccount({
      data: {
        invitation_key,
      },
      success: ({ resetPasswordToken }) =>
        handlePasswordToken(resetPasswordToken),
      error: ({ message }) => {
        const filteredMessage = message.replace('GraphQL error: ', '');
        toastRef.current.show({
          message: filteredMessage,
          type: 'error',
        });
      },
    });
  }

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-20 w-auto pl-5"
          src={Logo}
          alt="Logo do Shipy"
        />
        <h2 className="mt-10 text-center text-5xl font-bold leading-10 tracking-tight text-gray-900">
          Shipy
        </h2>
      </div>

      <div className="sm:mx-auto sm:w-full sm:max-w-sm mt-8">
        <p className="text-center font-normal">Bem vindo ao Shipy!</p>
        <p className="text-center font-normal">
          Clique no botão abaixo para ativar a sua conta.
        </p>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <Button
          isLoading={load_mutation}
          fullWidth
          variant="primary"
          onClick={handleActivateInvitation}
        >
          Ativar minha conta
        </Button>
      </div>
      <Toast ref={toastRef} />
    </div>
  );
};

function mapStateToProps(state) {
  const { load_mutation } = state.activateInvitation;

  return {
    load_mutation,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ activateAccount }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReceiveInvitation);
