import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Detail } from './Detail';
import { Load, Modal } from '@components';
import { fetchCustomer } from '@redux/ducks/customer';
import { useEffect, useState } from 'react';

const CustomerDetail = ({
  customerData,
  fetchCustomer,
  customerDetailModalData,
  isCustomerDetailModalOpen,
  setIsCustomerDetailModalOpen,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  function handleDetail(cnpj) {
    setIsLoading(true);
    fetchCustomer({
      cnpj,
      error: error => {
        alert(error.message);
      },
      finally: setIsLoading(false),
    });
  }

  useEffect(() => {
    if (isCustomerDetailModalOpen) {
      handleDetail(customerDetailModalData);
    }
  }, [customerDetailModalData]);

  return (
    <div>
      <Modal
        open={isCustomerDetailModalOpen}
        onClose={() => setIsCustomerDetailModalOpen(false)}
        title="Detalhamento de Cliente"
      >
        <Detail source={customerData} />
      </Modal>
      <Load active={isLoading} />
    </div>
  );
};

function mapStateToProps({ customer }) {
  let customerData = customer.customer;

  return {
    customerData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchCustomer }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetail);
