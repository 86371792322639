import { useNavigate } from 'react-router-dom';
import { Button } from '@components';
import { useDispatch } from 'react-redux';
import { logout } from '@redux/ducks/auth';
import Logo from '@assets/img/shipy.svg';
import { useEffect } from 'react';

const SuccessValidation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(logout());
  }, []);

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-20 w-auto pl-5"
          src={Logo}
          alt="Logo do Shipy"
        />
        <h2 className="mt-10 text-center text-5xl font-bold leading-10 tracking-tight text-gray-900">
          Shipy
        </h2>
      </div>

      <div className="sm:mx-auto sm:w-full sm:max-w-sm mt-8">
        <p className="text-center font-medium">
          Seu cadastro foi ativado com sucesso! Clique no botão abaixo para
          entrar na sua conta.
        </p>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <Button fullWidth variant="primary" onClick={() => navigate('/')}>
          Ir para o login
        </Button>
      </div>
    </div>
  );
};

export default SuccessValidation;
