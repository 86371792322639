import { connect, useDispatch } from 'react-redux';
import { getInvoice } from '@redux/ducks/invoice';
import { FormCreate, Modal } from '@components';
import validate from './validate';

const Form = FormCreate('InvoiceSearchForm', validate, {});

const InvoiceSearchForm = ({ open, onClose, load_mutation, ...rest }) => {
  const dispatch = useDispatch();
  const fetchInvoice = ({ search }) => {
    dispatch(
      getInvoice({
        search,
        success: invoice => {
          if (rest.onSubmit) {
            rest.onSubmit(invoice);
          }
        },
      })
    );
  };

  const formProps = {
    ...rest,
    onSubmit: fetchInvoice,
  };

  return (
    <Modal open={open} onClose={onClose} title="Busca de notas fiscais">
      <Form
        {...formProps}
        fields={[
          {
            name: 'search',
            component: 'input',
            type: 'text',
            label: 'Nota fiscal:',
            inputProps: {
              placeholder: 'Informe o numero da nota fiscal',
            },
          },
        ]}
        lbSubmit="Buscar"
        loadMutation={load_mutation}
      />
    </Modal>
  );
};

function mapStateToProps({ invoice }) {
  const { load_mutation } = invoice;
  return { load_mutation };
}

export default connect(mapStateToProps)(InvoiceSearchForm);
