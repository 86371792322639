import { Route, Routes } from 'react-router-dom';
import { NotFound, Alert } from '@pages';
import { Protected } from '@components';

function RoutesAlerts() {
  return (
    <Protected>
      <Routes>
        <Route element={<Alert />}>
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Protected>
  );
}

export default RoutesAlerts;
