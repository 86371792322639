import { client } from '@config/apollo';
import gql from 'graphql-tag';
import { call, put, select } from 'redux-saga/effects';
import { Types } from '../ducks/logisticCenterShippers';
import { Types as ToastTypes } from '../ducks/toast';

export function* createLogisticCenterShipper(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { idShipper, dispatch, redispatch, logisticCenterRegion } =
      action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($data: ShipperDeadlineInput) {
          createShipperDeadline(data: $data) {
            id
          }
        }
      `,
      variables: {
        data: {
          shipper: idShipper,
          logisticCenterRegion,
          eta: {
            dispatch: Number(dispatch),
            redispatch: Number(redispatch),
          },
        },
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Transportadora criada com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* updateLogisticCenterShipper(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { id, dispatch, redispatch } = action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($id: String!, $data: ShipperDeadlineUpdateInput) {
          updateShipperDeadline(id: $id, data: $data) {
            ok
          }
        }
      `,
      variables: {
        id,
        data: {
          eta: {
            dispatch,
            redispatch,
          },
        },
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Transportadora alterada com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* removeLogisticCenterShipper(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { id } = action;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($id: String!) {
          removeShipperDeadline(id: $id) {
            ok
          }
        }
      `,
      variables: {
        id,
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Transportadora removida com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* fetchLogisticCenterShippers(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    yield put({ type: Types.LOAD_QUERY, load: true });

    const { logisticCenterRegion, filter } = action;
    const response = yield call(cli.query, {
      query: gql`
        query (
          $logisticCenterRegion: String!
          $filter: ShipperDeadlineFilterInput
        ) {
          shipperDeadlines(
            logisticCenterRegion: $logisticCenterRegion
            filter: $filter
          ) {
            id
            logisticCenterRegion {
              id
            }
            shipper {
              id
              code
              company {
                cnpj
                corporateName
                commercialName
              }
            }
            eta {
              dispatch
              redispatch
            }
          }
        }
      `,
      variables: {
        logisticCenterRegion,
        filter,
      },
    });

    const results = response.data.shipperDeadlines;

    yield put({ type: Types.SET_RESULTS, results });
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
  } finally {
    yield put({ type: Types.LOAD_QUERY, load: false });
  }
}
