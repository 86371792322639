export const Types = {
  CREATE_REPRIEVE: 'reprieve/CREATE_REPRIEVE',
  REMOVE_REPRIEVE: 'reprieve/REMOVE_REPRIEVE',
  EFFECT_REPRIEVE: 'reprieve/EFFECT_REPRIEVE',
  SET_RESULTS: 'reprieve/SET_RESULTS',
  NEXT_RESULTS: 'reprieve/NEXT_RESULTS',
  SET_PAGE_INFO: 'reprieve/SET_PAGE_INFO',
  SET_ERROR_QUERY: 'reprieve/SET_ERROR_QUERY',
  SET_ERROR_MUTATION: 'reprieve/SET_ERROR_MUTATION',
  SET_SUCCESS_QUERY: 'reprieve/SET_SUCCESS_QUERY',
  SET_SUCCESS_MUTATION: 'reprieve/SET_SUCCESS_MUTATION',
  LOAD_QUERY: 'reprieve/LOAD_QUERY',
  LOAD_MUTATION: 'reprieve/LOAD_MUTATION',
  FETCH_REPRIEVE: 'reprieve/FETCH_REPRIEVE',
  GET_REPRIEVE: 'reprieve/GET_REPRIEVE',
  GET_REASONS: 'reprieve/GET_REASONS',
  RESET_STATE: 'reprieve/RESET_STATE',
  USER_LOGOUT: 'USER_LOGOUT',
};

const initialState = {
  results: [],
  pageInfo: {},
  load_query: false,
  load_mutation: false,
  error_query: {},
  error_mutation: {},
  success_query: {},
  success_mutation: {},
};

export default function reprieve(state = initialState, action) {
  switch (action.type) {
    case Types.SET_RESULTS:
      return { ...state, results: action.results };
    case Types.NEXT_RESULTS:
      return { ...state, results: [...state.results, ...action.results] };
    case Types.SET_PAGE_INFO:
      return { ...state, pageInfo: action.pageInfo };
    case Types.SET_ERROR_QUERY:
      return { ...state, error_query: action.error };
    case Types.SET_ERROR_MUTATION:
      return { ...state, error_mutation: action.error };
    case Types.SET_SUCCESS_QUERY:
      return { ...state, success_query: action.success };
    case Types.SET_SUCCESS_MUTATION:
      return { ...state, success_mutation: action.success };
    case Types.LOAD_QUERY:
      return { ...state, load_query: action.load };
    case Types.LOAD_MUTATION:
      return { ...state, load_mutation: action.load };
    case Types.RESET_STATE:
    case Types.USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export function createReprieve({ titles, data, success, error }) {
  return {
    type: Types.CREATE_REPRIEVE,
    titles,
    data,
    success,
    error,
  };
}

export function effectReprieve({ data, success, error }) {
  return {
    type: Types.EFFECT_REPRIEVE,
    data,
    success,
    error,
  };
}

export function removeReprieve({ data, success, error }) {
  return {
    type: Types.REMOVE_REPRIEVE,
    data,
    success,
    error,
  };
}

export function fetchReprieveEffect({ filter }) {
  return {
    type: Types.FETCH_REPRIEVE,
    filter: { ...filter, status: 2 },
  };
}

export function fetchReprievePending() {
  return {
    type: Types.FETCH_REPRIEVE,
    filter: { status: 0 },
  };
}

export function getReprieve({ invoiceKey, success, error }) {
  return {
    type: Types.GET_REPRIEVE,
    invoiceKey,
    success,
    error,
  };
}

export function getReprieveReasons({ search, success, error }) {
  return {
    type: Types.GET_REASONS,
    search,
    success,
    error,
  };
}

export function resetState() {
  return {
    type: Types.RESET_STATE,
  };
}
