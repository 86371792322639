import { useRef } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { signin } from '@redux/ducks/auth';
import Logo from '@assets/img/shipy.svg';
import LoginForm from './components/LoginForm';
import { Toast } from '@components';
import { loadUserMenuByRole } from '@config/permission';

const Login = ({ token, signin, user, load_mutation }) => {
  const toastRef = useRef(null);
  const { defaultRoute } = loadUserMenuByRole(user);

  if (token) {
    return <Navigate to={defaultRoute} />;
  }

  async function handleSignin({ login, password }) {
    await signin({
      data: { login, password },
      error: ({ message }) => {
        const filteredMessage = message.replace('GraphQL error: ', '');
        toastRef.current.show({
          message: filteredMessage,
          type: 'error',
        });
      },
    });
  }

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-20 w-auto pl-5"
          src={Logo}
          alt="Logo do Shipy"
        />
        <h2 className="mt-10 text-center text-5xl font-bold leading-10 tracking-tight text-gray-900">
          Shipy
        </h2>
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <LoginForm submitFunction={handleSignin} load_mutation={load_mutation}>
          <div className="text-sm flex justify-end mt-4">
            <Link to="/forgot-password">
              <span className="font-semibold text-blue-600 hover:text-blue-500">
                Esqueceu a senha?
              </span>
            </Link>
          </div>
        </LoginForm>

        {/* <p className="mt-10 text-center text-sm text-gray-500">
          Não conhece o Shipy?{' '}
          <a
            href="#"
            className="font-semibold leading-6 text-blue-600 hover:text-blue-500"
          >
            Solicite uma demonstração
          </a>
        </p> */}
      </div>
      <Toast ref={toastRef} />
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ signin }, dispatch);
}

function mapStateToProps(state) {
  let { token, load_mutation, user } = state.auth;

  return {
    token,
    load_mutation,
    user,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
