import { put } from 'redux-saga/effects';
import { Types } from '../ducks/reprieve';
import { Types as InvoiceTypes } from '../ducks/invoice';
import { Types as ToastTypes } from '../ducks/toast';
import { companyCustomConfiguration } from '../../config/api';

const customConfiguration = companyCustomConfiguration.find(company => company.cnpj === '76827344000130')

import moment from 'moment';

export function* fetchReprieve(action) {
  try {
    yield put({ type: Types.LOAD_QUERY, load: true });

    const { filter = {} } = action;
    const { dateFrom, dateTo, status } = filter;

    if (
      status > 0 &&
      (!dateFrom || dateFrom === '' || !dateTo || dateTo === '')
    ) {
      throw Error('Favor informar o periodo que deseja consultar');
    }

    const from = moment(dateFrom || new Date()).format('DD/MM/YYYY');
    const to = moment(dateTo || new Date()).format('DD/MM/YYYY');

    const url = `${customConfiguration.url}prorrogacao/?dataDe=${from}&dataAte=${to}&situacao=${status}`;
    let options = {
      method: 'GET',
      headers: {
        Authorization: customConfiguration.authorization,
      },
    };

    const results = yield fetch(url, options).then(response => {
      return response.json();
    });

    if (results.error) {
      throw results.error;
    }

    yield put({ type: Types.SET_RESULTS, results });
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
  } finally {
    yield put({ type: Types.LOAD_QUERY, load: false });
  }
}

export function* createReprieve(action) {
  try {
    const { titles, data } = action;

    const dateTitles = titles.map(title => {
      return {
        code: title.codigo,
        date: action.data[`title:${title.codigo}`] || null,
      };
    });

    for (let index in dateTitles) {
      if (!dateTitles[index].date) {
        throw {
          message: 'Favor informar data de vencimento para todos os títulos!',
        };
      }
    }

    const url = `${customConfiguration.url}prorrogacao/`;
    let options = {
      method: 'PUT',
      headers: {
        Authorization: customConfiguration.authorization,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...action.data,
        dateTitles,
      }),
    };

    const results = yield fetch(url, options).then(response => {
      return response.json();
    });

    if (results.error) {
      throw results.error;
    }

    //Adicionar log no Shipy
    yield put({
      type: InvoiceTypes.ADD_LOG_BACKGROUND,
      message: `
      Aberto processo de prorrogação.
      Motivo: ${data.reasonDescription}.
      OBS: ${data.note}
      `,
      key: data.invoiceKey,
    });
    //

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Prorrogação cadastrada com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* removeReprieve(action) {
  try {
    const { id, chaveNF, titulo, valorTitulo, prgData } = action.data;

    const url = `${customConfiguration.url}prorrogacao/${id}`;
    let options = {
      method: 'DELETE',
      headers: {
        Authorization: customConfiguration.authorization,
      },
    };

    const results = yield fetch(url, options).then(response => {
      return response.json();
    });

    if (results.error) {
      throw results.error;
    }

    //Adicionar log no Shipy
    yield put({
      type: InvoiceTypes.ADD_LOG_BACKGROUND,
      message: `
      Prorrogação cancelada.
      Título: ${titulo}; Valor: ${valorTitulo}; Data.Prg: ${prgData}
      `,
      key: chaveNF,
    });
    //

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: `Prorrogação do título ${titulo} cancelada!`,
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* effectReprieve(action) {
  try {
    const { id, chaveNF, titulo, valorTitulo, prgData } = action.data;

    const url = `${customConfiguration.url}prorrogacao/${id}`;
    let options = {
      method: 'POST',
      headers: {
        Authorization: customConfiguration.authorization,
      },
    };

    const results = yield fetch(url, options).then(response => {
      return response.json();
    });

    if (results.error) {
      throw results.error;
    }

    //Adicionar log no Shipy
    yield put({
      type: InvoiceTypes.ADD_LOG_BACKGROUND,
      message: `
      Prorrogação liberada.
      Título: ${titulo}; Valor: ${valorTitulo}; Data.Prg: ${prgData}
      `,
      key: chaveNF,
    });
    //
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: `Prorrogação do título ${titulo} liberada!`,
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* getReprieve(action) {
  try {
    yield put({ type: Types.LOAD_QUERY, load: true });

    const { invoiceKey } = action;
    const url = `${customConfiguration.url}prorrogacao/config?key=${invoiceKey}`;
    let options = {
      method: 'GET',
      headers: {
        Authorization: customConfiguration.authorization,
      },
    };

    const results = yield fetch(url, options).then(response => {
      return response.json();
    });

    if (results.error) {
      throw results.error;
    }

    if (action.success) action.success(results);
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_QUERY, load: false });
  }
}

export function* getReprieveReasons(action) {
  try {
    const { search } = action;
    const url = `${customConfiguration.url}prorrogacao/motivos?search=${search}`;
    let options = {
      method: 'GET',
      headers: {
        Authorization: customConfiguration.authorization,
      },
    };

    const results = yield fetch(url, options).then(response => {
      return response.json();
    });

    if (results.error) {
      throw results.error;
    }

    if (action.success) action.success(results.motivo);
  } catch (error) {
    if (action.error) action.error(error);
  }
}
