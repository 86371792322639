export default function ViewFile(props) {
  let { url } = props;
  url = `/${url}`;

  return (
    <div>
      {(url.indexOf('.jpg') !== -1 || url.indexOf('.png') !== -1) && (
        <a href={url} target="_blank" rel="noreferrer">
          {' '}
          <img src={url} alt="image" className="w-100 h-100"></img>
        </a>
      )}
      {url.indexOf('.pdf') !== -1 && (
        <a href={url} target="_blank" rel="noreferrer">
          {' '}
          Ver anexo{' '}
        </a>
      )}
    </div>
  );
}
