import { client } from '@config/apollo';
import gql from 'graphql-tag';
import { call, put, select } from 'redux-saga/effects';
import { Types } from '../ducks/companies';
import { Types as ToastTypes } from '../ducks/toast';

export function* fetchCompanies() {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    yield put({ type: Types.RESET_STATE, error: {} });
    yield put({ type: Types.LOAD_QUERY, load: true });

    const response = yield call(cli.query, {
      query: gql`
        query companies(
          $first: Int!
          $last: Int
          $after: String
          $before: String
          $filter: CompanyFilterInput
        ) {
          companies(
            first: $first
            last: $last
            after: $after
            before: $before
            filter: $filter
          ) {
            edges {
              node {
                id
                cnpj
                corporateName
                commercialName
                phone
                licenseSlots
                operatorsCount
                shippersCount
                logisticCentersCount
                status
                stateInscription
                neighborhood
                address
                city {
                  name
                }
                user {
                  id
                  firstName
                  lastName
                  email
                  active
                  role {
                    name
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        first: 50,
        filter: {
          user_WITH: {
            role_WITH: {
              name: 'COMPANY',
            },
          },
        },
      },
    });

    const results = response.data.companies.edges;
    yield put({ type: Types.SET_RESULTS, results: results });
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
  } finally {
    yield put({ type: Types.LOAD_QUERY, load: false });
  }
}

export function* createCompany(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const {
      cnpj,
      corporateName,
      commercialName,
      contactFirstName,
      contactLastName,
      email,
      phone,
      stateInscription,
      neighborhood,
      address,
      licenseSlots,
      cityId,
    } = action.data;

    const licenseSlotsInt = Number(licenseSlots);
    const phoneOnlyNumber = phone.replace(/\D/g, '');

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($company: CompanyInput!) {
          createCompany(company: $company) {
            ok
          }
        }
      `,
      variables: {
        company: {
          cnpj,
          corporateName,
          commercialName,
          contact: contactFirstName,
          email,
          phone: phoneOnlyNumber,
          licenseSlots: licenseSlotsInt,
          stateInscription,
          neighborhood,
          address,
          city: cityId,
          user: {
            email,
            firstName: contactFirstName,
            lastName: contactLastName,
            active: true,
          },
        },
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Convite enviado com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* handleUpdateCompany(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const {
      id,
      active,
      commercialName,
      phone,
      corporateName,
      contactFirstName,
      licenseSlots,
      neighborhood,
      address,
      cityId,
    } = action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($companyId: String!, $data: CompanyUpdateInput!) {
          updateCompany(companyId: $companyId, data: $data) {
            ok
          }
        }
      `,
      variables: {
        companyId: id,
        data: {
          commercialName,
          corporateName,
          contact: contactFirstName,
          licenseSlots: Number(licenseSlots),
          phone,
          active,
          neighborhood,
          address,
          city: cityId,
        },
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Empresa alterada com sucesso!',
      },
    });

    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* changeStatusCompany(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { id, active } = action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation updateCompany($companyId: String!, $active: Boolean!) {
          toggleCompany(companyId: $companyId, active: $active) {
            ok
          }
        }
      `,
      variables: {
        companyId: id,
        active,
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Alterada a situação da empresa com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* resendCompanyInvitation(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { userId } = action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($userId: String!) {
          resendCompanyInvitation(userId: $userId) {
            ok
          }
        }
      `,
      variables: {
        userId,
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Convite reenviado com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}
