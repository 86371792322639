import { connect, useDispatch } from 'react-redux';
import { editExpense } from '@redux/ducks/expense';

import { FormCreate, Modal } from '@components';
import moment from 'moment';
import { useEffect, useRef } from 'react';

const Form = FormCreate('RejectForm');

const ExpenseDetails = ({
  approved,
  expenseDetailData,
  open,
  onClose,
  modalTitle,
  ...rest
}) => {
  const { id, invoice, type, description, cost, reason } = expenseDetailData;
  const dispatch = useDispatch();
  const formRef = useRef(null)

  useEffect(() => {
    setTimeout(() => {
      formRef?.current?.initialize({
        id,
        invoice,
        type,
        description,
        _cost: cost,
        reason
      });
    }, 200);
  },[])

  useEffect(() => {
    setTimeout(() => {
      formRef?.current?.edit({
        cost: cost
      })
    }, 200)
  }, [open])

  if (!open) return <></>

  function handleSubmit(data, edit) {
    const {  _cost, reason } = data

    return new Promise((resolve, reject) => {
      dispatch(
        editExpense({
          id,
          data: { cost: _cost?.floatValue || _cost, reason, approved: !!approved },
          edit,
          success: () => {
            onClose();
            resolve();
          },
          error: error => {
            reject(error);
          },
        })
      );
    });
  }

  return (
    <Modal open={open} onClose={onClose} title={modalTitle}>
      <Form
      formRef={formRef}
        {...rest}
        edit
        fields={[
          {
            name: 'invoiceNo',
            component: 'input',
            type: 'text',
            edit: false,
            label: 'NF:',
            inputProps: {
              value: invoice?.invoiceNo,
            },
          },
          {
            name: 'emittedAt',
            component: 'input',
            edit: false,
            type: 'text',
            label: 'Data de emissão:',
            inputProps: {
              value: moment(invoice?.activities?.emittedAt).format(
                'DD/MM/YYYY'
              ),
            },
          },
          {
            name: 'type',
            component: 'input',
            type: 'text',
            edit: false,
            label: 'Tipo de despesa:',
            inputProps: {
              value: type?.description,
            },
          },
          {
            name: 'description',
            component: 'textarea',
            type: 'textarea',
            edit: false,
            label: 'Observação:',
            inputProps: {
              value: description,
              rows: 3,
            },
          },
          {
            type: 'text',
            name: 'cost',
            component: 'currency',
            label: 'Valor: ',
            inputProps: {
              placeholder: 'Informe o valor dessa despesa',
            }
          },
          {
            name: 'reason',
            component: 'textarea',
            type: 'textarea',
            label: 'Motivo:',
            inputProps: {
              placeholder:
                'Informe o motivo da aprovação, rejeição ou alteração',
              value: reason ? reason : undefined,
            },
          },
        ]}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

function mapStateToProps(state) {
  const { RejectForm = {} } = state.form;

  return {
    values: RejectForm.values,
  };
}

export default connect(mapStateToProps)(ExpenseDetails);
