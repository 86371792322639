import { client } from '@config/apollo';
import gql from 'graphql-tag';
import { call, put, select } from 'redux-saga/effects';
import { Types } from '../ducks/driver';
import { Types as ToastTypes } from '../ducks/toast';

export function* createDriver(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { cpf, plate, firstName, lastName, phone, active } = action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($driver: DriverInput) {
          createDriver(driver: $driver) {
            id
          }
        }
      `,
      variables: {
        driver: {
          cpf,
          plate,
          active,
          user: {
            firstName,
            lastName,
            phone,
            password: phone,
          },
        },
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Motorista criado com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* removeDriver(action) {
  const id = action.id;

  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    yield call(cli.mutate, {
      mutation: gql`
        mutation ($id: String!) {
          removeDriver(id: $id) {
            ok
          }
        }
      `,
      variables: {
        id,
      },
    });

    yield put({
      type: Types.REMOVE_DRIVER_HANDLE,
      id,
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Motorista deletado com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
  }
}

export function* updateDriver(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { id, plate, firstName, lastName, phone, active } = action.data;
    const phoneOnlyNumber = phone.replace(/\D/g, '');
    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($id: String!, $driver: DriverUpdateInput) {
          updateDriver(id: $id, driver: $driver) {
            ok
          }
        }
      `,
      variables: {
        id,
        driver: {
          plate,
          active,
          user: {
            firstName,
            lastName: lastName || '',
            phone: phoneOnlyNumber,
          },
        },
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Motorista alterado com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* fetchDrivers(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    yield put({ type: Types.LOAD_QUERY, load: true });

    const { filter } = action;

    const response = yield call(cli.query, {
      query: gql`
        query ($first: Int, $after: String, $filter: DriverFilterInput) {
          drivers(first: $first, after: $after, filter: $filter) {
            edges {
              node {
                id
                firstName
                lastName
                user {
                  phone
                }
                cpf
                shipperDriver {
                  plate
                  phone
                  active
                }
                active
              }
            }
            pageInfo {
              hasNextPage
              after
              before
            }
          }
        }
      `,
      variables: {
        first: 50,
        after: action.cursor,
        filter,
      },
    });

    const pageInfo = response.data.drivers.pageInfo;
    const results = response.data.drivers.edges;

    yield put({
      type: Types.SET_PAGE_INFO,
      pageInfo: { ...pageInfo, cursor: pageInfo.after },
    });

    if (action.cursor) {
      yield put({ type: Types.NEXT_RESULTS, results });
    } else {
      yield put({ type: Types.SET_RESULTS, results });
    }
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
  } finally {
    yield put({ type: Types.LOAD_QUERY, load: false });
  }
}

export function* getDrivers(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { filter } = action;

    const response = yield call(cli.query, {
      query: gql`
        query (
          $first: Int
          $after: String
          $orderBy: String
          $filter: DriverFilterInput
        ) {
          drivers(
            first: $first
            after: $after
            orderBy: $orderBy
            filter: $filter
          ) {
            edges {
              node {
                id
                firstName
                lastName
                user {
                  phone
                }
                cpf
                shipperDriver {
                  plate
                  phone
                  active
                }
                active
              }
            }
            pageInfo {
              hasNextPage
              after
              before
            }
          }
        }
      `,
      variables: {
        first: 100,
        after: action.cursor,
        filter,
      },
    });

    const results = response.data.drivers.edges;

    if (action.success) action.success(results);
  } catch (error) {
    if (action.error) action.error(error);
  }
}



export function* fetchDriversByShipper(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    yield put({ type: Types.LOAD_QUERY, load: true });

    const { shipperCnpj } = action;

    const response = yield call(cli.query, {
      query: gql`
        query ($shipperCnpj: String!, $first: Int, $after: String) {
          shipperDriver(shipperCnpj: $shipperCnpj, first: $first, after: $after) {
            edges {
              node {
                driver {
                  firstName
                  lastName
                  cpf
                  id
                }
                phone
                plate
              }
            }
            pageInfo {
              hasNextPage
              after
              before
            }
          }
        }
      `,
      variables: {
        shipperCnpj: shipperCnpj,
        first: 50,
        after: action.cursor,
      },
    });

    const pageInfo = response.data.shipperDriver.pageInfo;
    const results = response.data.shipperDriver.edges;

    yield put({
      type: Types.SET_PAGE_INFO,
      pageInfo: { ...pageInfo, cursor: pageInfo.after },
    });

    if (action.cursor) {
      yield put({ type: Types.NEXT_RESULTS, results });
    } else {
      yield put({ type: Types.SET_RESULTS, results });
    }
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
  } finally {
    yield put({ type: Types.LOAD_QUERY, load: false });
  }
}
