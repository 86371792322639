import { useState, useImperativeHandle } from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import { Button, TableTail } from '@components';
import {
  Input,
  TextArea,
  Toggle,
  Select,
  MultiSelection,
  Search,
  DatePicker,
  Time,
  Text,
  UploadFile,
  Currency,
  Btn,
} from '.';

const getComponent = component => {
  switch (component) {
    case 'input':
      return Input;
    case 'textarea':
      return TextArea;
    case 'toggle':
      return Toggle;
    case 'select':
      return Select;
    case 'multiselection':
      return MultiSelection;
    case 'search':
      return Search;
    case 'date':
      return DatePicker;
    case 'time':
      return Time;
    case 'text':
      return Text;
    case 'uploadfile':
      return UploadFile;
    case 'currency':
      return Currency;
    case 'button':
      return Btn;
    default:
      return Input;
  }
};

const renderField = ({
  field,
  indexField,
  handleChange,
  hide,
  disabledFields,
  editing,
}) => {
  if (typeof field === 'function') return field();

  let { name, inputProps, edit, display } = field;
  if (display && display === 'none') return null;

  inputProps = inputProps || {};
  if (edit === undefined) {
    edit = true;
  }

  let disabled = false;

  if (disabledFields?.length > 0) {
    disabled = disabledFields.includes(name || indexField);
  } else {
    disabled = inputProps.disabled || (edit ? false : editing);
  }

  if (
    field.component === 'search' ||
    field.component === 'multiselection' ||
    field.component === 'uploadfile' ||
    field.component === 'currency'
  ) {
    inputProps = { ...inputProps, disabled, forceChange: handleChange };
  }

  const fieldProps = {
    ...field,
    name: field.name || indexField,
    component: getComponent(field.component),
    inputProps: { ...inputProps, disabled },
  };

  return (hide || []).includes(name || indexField) ? null : (
    <Field key={name || indexField} {...fieldProps} />
  );
};

const renderFieldGroup = (
  fields,
  iGroup,
  handleChange,
  hide,
  disabledFields,
  editing
) => {
  const group = fields.map(field => {
    return renderField({
      field,
      indexField: iGroup,
      handleChange,
      hide,
      disabledFields,
      editing,
    });
  });

  return <div key={iGroup}>{group}</div>;
};

const renderTable = ({ headers, body, name }, index) => {
  return (
    <TableTail key={name || index}>
      <TableTail.Header>
        <TableTail.Row>
          {headers.map((header, indexHeader) => (
            <TableTail.HeaderCell key={`header-${indexHeader}-${name}`}>
              {header.label}
            </TableTail.HeaderCell>
          ))}
        </TableTail.Row>
      </TableTail.Header>

      <TableTail.Body>
        {body.map((cells, indexRow) => (
          <TableTail.Row key={`row-${indexRow}-${name}`}>
            {cells.map((cell, indexCell) => (
              <TableTail.Cell key={`cell-${indexCell}-${indexRow}-${name}`}>
                {renderField({
                  field: cell,
                  indexField: `${name}-${indexRow}-${indexCell}`,
                })}
              </TableTail.Cell>
            ))}
          </TableTail.Row>
        ))}
      </TableTail.Body>
    </TableTail>
  );
};

const renderFields = ({
  fields,
  handleChange,
  hide,
  disabledFields,
  editing,
}) => {
  return fields.map((field, index) => {
    if (Array.isArray(field)) {
      return renderFieldGroup(
        field,
        index,
        handleChange,
        disabledFields,
        editing
      );
    } else {
      const { component } = field;
      if ('table' === component) {
        return renderTable(field, index);
      }
      return renderField({
        field,
        indexField: field,
        handleChange,
        hide,
        disabledFields,
        editing,
      });
    }
  });
};

const CForm = ({
  handleSubmit,
  pristine,
  submitting,
  onCancel,
  fieldErrors,
  ribbon = true,
  loadMutation = false,
  lbSubmit = 'Gravar',
  lbReset = 'Limpar',
  lbCancel = 'Cancelar',
  cancel,
  edit = false,
  reset,
  onReset,
  change,
  onSubmit,
  initialize,
  fields,
  disabled = [],
  hide = [],
  // initialValues,
  formRef,
}) => {
  const [editing, setEditing] = useState(edit);
  const handleSave = async data => {
    if (onSubmit) await onSubmit(data, editing);
  };

  const handleEdit = data => {
    setEditing(true);

    if (data) {
      for (let prop in data) {
        handleChange([prop], data[prop]);
      }
    } else {
      handleReset();
    }
  };

  const populate = data => {
    if (data) {
      for (let prop in data) {
        handleChange([prop], data[prop]);
      }
    } else {
      handleReset();
    }
  };

  const handleChange = (prop, value) => {
    change(prop, value);
  };

  const handleInitialize = data => {
    setEditing(false);
    initialize(data);
  };

  const handleReset = () => {
    setEditing(false);
    if (reset) reset();
    if (onReset) onReset();
  };

  useImperativeHandle(formRef, () => ({
    edit: handleEdit,
    initialize: handleInitialize,
    change: populate,
  }));

  return (
    <form
      onSubmit={handleSubmit(handleSave)}
      className="flex flex-col gap-2"
      autoComplete="off"
    >
      {renderFields({
        fields,
        handleChange,
        hide,
        disabledFields: disabled,
        editing,
      })}
      <div className="flex mt-2 justify-between items-center">
        <div>
          {ribbon && (
            <span
              className={`${
                editing
                  ? 'text-yellow-700 bg-yellow-100'
                  : 'text-blue-700 bg-blue-100 align-middle'
              } block items-center rounded-md px-2 py-1 text-xs font-medium`}
            >
              {editing ? 'Editando' : 'Adicionando'}
            </span>
          )}
        </div>
        <div className="flex gap-2 justify-end">
          <Button
            type="submit"
            variant="primary"
            isLoading={submitting || loadMutation}
            disabled={pristine || loadMutation}
          >
            {lbSubmit}
          </Button>
          {!editing && (
            <Button type="button" disabled={submitting} onClick={handleReset}>
              {lbReset}
            </Button>
          )}
          {cancel && (
            <Button type="button" disabled={submitting} onClick={onCancel}>
              {lbCancel}
            </Button>
          )}
        </div>
      </div>
      {fieldErrors && (
        <div style={{ paddingTop: 5, marginBottom: 13 }}>
          <span className="text-red-500">
            Um ou mais campos obrigatórios não foram preenchidos!
          </span>
        </div>
      )}
    </form>
  );
};

export const FormCreate = (formName, validate, initialValues = {}) => {
  const mapStateToProps = state => {
    const form = state.form[formName] || {};
    return {
      initialValues,
      fieldErrors: form.syncErrors && form.submitFailed,
    };
  };

  const CFormRedux = reduxForm({
    form: formName,
    validate,
  })(CForm);

  return connect(mapStateToProps)(CFormRedux);
};
