import { useRef, useState } from 'react';
import { Maps, Confirm, Button, CardImages } from '@components';
import moment from 'moment';
import { HOST, PROTOCOL_HTTP } from '@config/apollo';
import { permissions } from '@config/permission';
import DeliveredAtForm from '../DeliveredAtForm';

import { saveDelivery, reversalDelivery } from '@redux/ducks/delivery';
import { fetchInvoice } from '@redux/ducks/invoice';
import { useDispatch } from 'react-redux';

const url = `${PROTOCOL_HTTP}://${HOST}`;

const DetailDelivery = ({ source, invoiceKey }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const confirmRef = useRef(null);
  const imagesRef = useRef(null);
  const formRef = useRef(null);

  const onSave = async (data, edit) => {
    return new Promise((resolve, reject) => {
      dispatch(
        saveDelivery({
          data,
          edit,
          success: () => {
            resolve();
            setIsModalOpen(false);
          },
          error: error => {
            reject(error);
          },
        })
      );
    }).then(() => {
      dispatch(fetchInvoice(invoiceKey));
    });
  };

  const onReversal = id => {
    confirmRef.current.open('Deseja realmente estornar a entrega?', () => {
      dispatch(
        reversalDelivery({
          id,
          success: () => {
            dispatch(fetchInvoice(invoiceKey));
          },
        })
      );
    });
  };

  return source.map((item, index) => {
    const stub = item.stub && `${url}/${item.stub}`;
    const evidences =
      item.evidences &&
      item.evidences.map(evidence => {
        return `${url}/${evidence}`;
      });

    let imgs = [];
    if (stub) imgs = [...imgs, stub];
    if (evidences) imgs = [...imgs, ...evidences];

    function handleOpenForm() {
      setIsModalOpen(true);

      setTimeout(() => {
        formRef?.current?.edit({
          id: item.id,
          dateHour: item.date && moment(item.date).format('DD/MM/YYYY-HH:mm'),
        });
      }, 200);
    }

    return (
      <div className="grid grid-cols-5 gap-3 p-3" key={index}>
        <div className="col-span-3">
          {item.driver ? (
            <div className="grid grid-cols-2 gap-2">
              <div className="mt-1 text-sm leading-6 text-gray-900 sm:mt-0">
                <b>Transportadora: </b>
                <span>{item.shipper?.company?.corporateName}</span>
              </div>

              <div className="mt-1 text-sm leading-6 text-gray-900 sm:mt-0">
                <b>Motorista: </b>
                <span>
                  {item.driver?.firstName} {item.driver?.lastName}
                </span>
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-2">
              <div className="mt-1 text-sm leading-6 text-gray-900 sm:mt-0">
                <b>Entrega efetuada por um interno: </b>
                <span>
                  {item.createdBy?.firstName} {item.createdBy?.lastName}
                </span>
              </div>
            </div>
          )}

          <div className="flex pt-3 pb-8 text-sm leading-6 text-gray-900 sm:mt-0 items-center gap-2">
            <b>Data da Entrega: </b>
            {permissions.invoice.editDeliveredAt() ? (
              <>
                <div className="flex gap-3 pl-2 items-center rounded-md border border-gray-200  text-gray-900 sm:text-sm sm:leading-6">
                  {item.date && moment(item.date).format('DD/MM/YYYY-HH:mm')}
                  <Button onClick={handleOpenForm}>Editar</Button>
                </div>

                <DeliveredAtForm
                  ref={formRef}
                  open={isModalOpen}
                  onClose={() => setIsModalOpen(false)}
                  edit={true}
                  onSubmit={onSave}
                />

                <Button
                  variant="negative"
                  onClick={() => {
                    onReversal(item.id);
                  }}
                >
                  Estornar Entrega
                </Button>
                <Confirm ref={confirmRef} />
              </>
            ) : (
              item.date && moment(item.date).format('DD/MM/YYYY-HH:mm')
            )}
          </div>

          <CardImages
            imgs={imgs}
            ref={imagesRef}
            source={'/uploads/deliveries/'}
            close={() => dispatch(fetchInvoice(invoiceKey))}
          />
        </div>

        <div className="w-full col-span-2">
          <Maps position={item.location} />
        </div>
      </div>
    );
  });
};

export { DetailDelivery };
