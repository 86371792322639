import { client } from '@config/apollo';
import gql from 'graphql-tag';
import { call, put, select } from 'redux-saga/effects';
import { Types } from '../ducks/logisticCenter';
import { Types as ToastTypes } from '../ducks/toast';

export function* createLogisticCenter(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { cnpj, name, cityCode } = action.data;
    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($logisticCenter: LogisticCenterInput!) {
          createLogisticCenter(logisticCenter: $logisticCenter) {
            id
          }
        }
      `,
      variables: {
        logisticCenter: {
          cnpj,
          name,
          cityCode,
        },
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Centro logístico criado com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* updateLogisticCenter(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { id, name, cityCode } = action.data;
    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($id: String!, $logisticCenter: LogisticCenterUpdateInput!) {
          updateLogisticCenter(id: $id, logisticCenter: $logisticCenter) {
            ok
          }
        }
      `,
      variables: {
        id,
        logisticCenter: {
          name,
          cityCode,
        },
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Centro logístico alterado com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* fetchLogisticCenters() {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    yield put({ type: Types.LOAD_QUERY, load: true });

    const response = yield call(cli.query, {
      query: gql`
        query {
          logisticCenters {
            id
            cnpj
            name
            city {
              id
              code
              name
              state
            }
          }
        }
      `,
    });

    const results = response.data.logisticCenters;

    yield put({ type: Types.SET_RESULTS, results });
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
  } finally {
    yield put({ type: Types.LOAD_QUERY, load: false });
  }
}
