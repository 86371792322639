import {
  useImperativeHandle,
  useState,
  forwardRef,
  useRef,
  useCallback,
} from 'react';
import { Button, Input, TableTail, Modal } from '@components';

const Loader = ({ loading }) =>
  loading && (
    <div className="fixed flex items-center gap-2 right-4 bottom-4 bg-slate-100 rounded-lg p-2">
      <span
        className="animate-spin inline-block w-4 h-4 border-[2px] border-current border-t-transparent text-gray-800 rounded-full"
        role="status"
        aria-label="loading"
      />
      Carregando...
    </div>
  );

const TableForm = forwardRef(
  (
    {
      formHeader,
      actionsBtn,
      children,
      onSearch,
      btnNew = true,
      onNew = () => {},
      titleModal = '',
      labelModalOpen = 'Novo',
      load = false,
      back,
      onBack,
      lbBack = 'Voltar',
      headers,
      headersBtn,
      source,
      keyRow = (_, index) => index,
      propsRow,
      hasNext = false,
      next,
      filters,
      modalOverflow = false,
    },
    ref
  ) => {
    const [search, setSearch] = useState('');
    const [isOpenForm, setIsOpenForm] = useState(false);

    const renderFilter = () => {
      let components = [];

      if (onSearch) {
        components.push(
          <div key="filter" className="flex gap-2">
            <Input.Action
              onFireAction={() => {
                onSearch(search);
              }}
              value={search}
              placeholder="Pesquisar..."
              onChange={({ target }) => {
                setSearch(target.value);
              }}
            />
          </div>
        );
      }

      if (children && btnNew) {
        components.push(
          <Button
            key="add-btn"
            variant="primary"
            onClick={() => {
              setIsOpenForm(true);
              onNew();
            }}
          >
            {labelModalOpen}
          </Button>
        );
      }

      if (back) {
        components.push(
          <Button type="button" key={generateId()} onClick={onBack}>
            {lbBack}
          </Button>
        );
      }

      if (headersBtn) {
        components = [
          ...components,
          headersBtn.map((btn, index) => {
            return (
              <Button
                {...btn.props}
                key={index}
                onClick={() => {
                  btn.onClick();
                }}
              >
                {btn.content}
              </Button>
            );
          }),
        ];
      }

      if (components?.length > 0) {
        return (
          <div className="sm:flex gap-2 justify-end sm:items-center">
            {components}
          </div>
        );
      }
    };

    const generateId = () => {
      return Math.floor((1 + Math.random()) * Date.now())
        .toString(16)
        .substring(1);
    };

    const renderHeaders = () => {
      return headers
        .filter(h => h.visible !== false)
        .map((header, index) => {
          return (
            <TableTail.HeaderCell key={index} {...header.props}>
              {header.label}
            </TableTail.HeaderCell>
          );
        });
    };

    const renderBody = () => {
      const nCols = headers.filter(h => h.visible !== false).length;
      const colSpan = nCols + 1;

      return source?.length ? (
        <TableTail.Body key={generateId()}>{renderItems()}</TableTail.Body>
      ) : (
        <TableTail.Footer>
          <TableTail.Row>
            <TableTail.HeaderCell colSpan={colSpan}>
              <div className="flex gap-2 justify-center items-center bg-orange-100 py-2 text-gray-700 rounded-md">
                Nenhum registro encontrado!
              </div>
            </TableTail.HeaderCell>
          </TableTail.Row>
        </TableTail.Footer>
      );
    };

    const renderItems = () => {
      return source.map((item, index) => {
        const cell = headers
          .filter(h => h.visible !== false)
          .map((header, i) => {
            return (
              <TableTail.Cell key={i} {...header.props}>
                {header.getValue(item, index)}
              </TableTail.Cell>
            );
          });

        const props = (propsRow && propsRow(item, index)) || {};
        return (
          <TableTail.Row
            key={keyRow(item, index)}
            ref={index === source.length - 1 ? lastRow : null}
            {...props}
          >
            {cell}
            {actionsBtn?.length > 0 && (
              <TableTail.Cell key={generateId()}>
                <div className="inline-flex gap-2">
                  {actionsBtn.map((btn, i) => {
                    return (
                      <Button
                        {...btn.props}
                        key={i}
                        compact
                        onClick={() => {
                          btn.onClick(item);
                        }}
                      >
                        {btn.content}
                      </Button>
                    );
                  })}
                </div>
              </TableTail.Cell>
            )}
          </TableTail.Row>
        );
      });
    };

    const handleNextPage = () => {
      if (hasNext) {
        next();
      }
    };

    const clearSearch = () => {
      setSearch('');
    };

    const getSearch = () => {
      return search;
    };

    const openForm = () => {
      setIsOpenForm(true);
    };

    const closeForm = () => {
      setIsOpenForm(false);
    };

    useImperativeHandle(ref, () => ({
      openForm: openForm,
      closeForm: closeForm,
      clearSearch: clearSearch,
      getSearch: getSearch,
    }));

    const observer = useRef(null);

    const lastRow = useCallback(
      node => {
        if (!node) return;
        if (load) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
          const [entry] = entries;
          if (entry.isIntersecting) {
            handleNextPage();
          }
        });
        observer.current.observe(node);
      },
      [load]
    );

    return (
      <>
        <div className="sm:flex sm:items-center mb-2">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-4 text-gray-800">
              {formHeader?.title}
            </h1>
            <p className="mt-1 text-sm text-gray-700">{formHeader?.subtitle}</p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            {renderFilter()}
          </div>
        </div>
        {filters && (
          <div className="sm:flex flex-auto flex-col my-4 gap-2">
            {filters()}
          </div>
        )}
        <TableTail>
          <TableTail.Header>
            <TableTail.Row>
              {renderHeaders()}
              {actionsBtn?.length > 0 && (
                <TableTail.HeaderCell>Ações</TableTail.HeaderCell>
              )}
            </TableTail.Row>
          </TableTail.Header>
          {renderBody()}
        </TableTail>
        <Loader loading={load} />
        {children && (
          <Modal
            title={titleModal}
            open={isOpenForm}
            onClose={() => setIsOpenForm(false)}
            modalOverflow={modalOverflow}
          >
            {children}
          </Modal>
        )}
      </>
    );
  }
);

TableForm.displayName = 'TableForm';

export default TableForm;
