import { connect } from 'react-redux';

import LogTable from './LogTable';
import LogFilter from './LogFilter';

const Logs = ({ logs, loader }) => {
  const RenderFilter = () => <LogFilter />;

  return (
    <LogTable
      formHeader={{
        title: 'Eventos por operador',
        subtitle:
          'Consulte o que foi realizado dentro do sistema por cada operador',
      }}
      filters={RenderFilter}
      load={loader}
      source={logs}
    />
  );
};

function mapStateToProps({ log }) {
  return {
    loader: log.loader,
    logs: log.logs
      ? log.logs.sort(
          (a, b) =>
            new Date(a.commmittedAt).getTime() -
            new Date(b.commmittedAt).getTime()
        )
      : [],
  };
}

export default connect(mapStateToProps)(Logs);
