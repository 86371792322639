export const Types = {
  SET_RESULTS: 'logisticCenterRegions/SET_RESULTS',
  NEXT_RESULTS: 'logisticCenterRegions/NEXT_RESULTS',
  SET_PAGE_INFO: 'logisticCenterRegions/SET_PAGE_INFO',
  SET_ERROR_QUERY: 'logisticCenterRegions/SET_ERROR_QUERY',
  SET_ERROR_MUTATION: 'logisticCenterRegions/SET_ERROR_MUTATION',
  SET_SUCCESS_QUERY: 'logisticCenterRegions/SET_SUCCESS_QUERY',
  SET_SUCCESS_MUTATION: 'logisticCenterRegions/SET_SUCCESS_MUTATION',
  LOAD_QUERY: 'logisticCenterRegions/LOAD_QUERY',
  LOAD_MUTATION: 'logisticCenterRegions/LOAD_MUTATION',
  FETCH_LOGISTIC_CENTER_REGIONS:
    'logisticCenterRegions/FETCH_LOGISTIC_CENTER_REGIONS',
  UPDATE_LOGISTIC_CENTER_REGION:
    'logisticCenterRegions/UPDATE_LOGISTIC_CENTER_REGION',
  CREATE_LOGISTIC_CENTER_REGION:
    'logisticCenterRegions/CREATE_LOGISTIC_CENTER_REGION',
  REMOVE_LOGISTIC_CENTER_REGION:
    'logisticCenterRegions/REMOVE_LOGISTIC_CENTER_REGION',
  RESET_STATE: 'logisticCenterRegions/RESET_STATE',
  USER_LOGOUT: 'USER_LOGOUT',
};

const initialState = {
  results: [],
  resultInfo: {},
  pageInfo: {},
  load_query: false,
  load_mutation: false,
  error_query: {},
  error_mutation: {},
  success_query: {},
  success_mutation: {},
};

export default function logisticCenterRegions(state = initialState, action) {
  switch (action.type) {
    case Types.SET_RESULTS:
      return {
        ...state,
        results: action.results,
        resultInfo: action.resultInfo,
      };
    case Types.NEXT_RESULTS:
      return { ...state, results: [...state.results, ...action.results] };
    case Types.SET_PAGE_INFO:
      return { ...state, pageInfo: action.pageInfo };
    case Types.SET_ERROR_QUERY:
      return { ...state, error_query: action.error };
    case Types.SET_ERROR_MUTATION:
      return { ...state, error_mutation: action.error };
    case Types.SET_SUCCESS_QUERY:
      return { ...state, success_query: action.success };
    case Types.SET_SUCCESS_MUTATION:
      return { ...state, success_mutation: action.success };
    case Types.LOAD_QUERY:
      return { ...state, load_query: action.load };
    case Types.LOAD_MUTATION:
      return { ...state, load_mutation: action.load };
    case Types.RESET_STATE:
    case Types.USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export function fetchLogisticCenterRegions({ cnpj, search, cursor }) {
  let filter;
  if (search && search.length > 0) {
    filter = {
      OR: [
         {
           region_WITH: {
             description_LIKE: search
           }
         },
      ],
    };
  }

  return {
    type: Types.FETCH_LOGISTIC_CENTER_REGIONS,
    cnpj,
    cursor,
    filter
  };
}

export function resetState() {
  return {
    type: Types.RESET_STATE,
  };
}

export function saveLogisticCenterRegion({ data, edit, success, error }) {
  return {
    type:
      edit === true
        ? Types.UPDATE_LOGISTIC_CENTER_REGION
        : Types.CREATE_LOGISTIC_CENTER_REGION,
    data,
    success,
    error,
  };
}

export function removeLogisticCenterRegion({ id, success, error }) {
  return {
    type: Types.REMOVE_LOGISTIC_CENTER_REGION,
    id,
    success,
    error,
  };
}
