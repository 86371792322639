import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Detail from './Detail';
import { Load, CardImages, Modal, Tabs, Toggle, Button } from '@components';

import { fetchShippingOrder } from '@redux/ducks/shippingOrder';
import './styles.css';
import { HOST, PROTOCOL_HTTP } from '@config/apollo';

import { useReactToPrint } from 'react-to-print';

const url = `${PROTOCOL_HTTP}://${HOST}`;

const customStyles = `
@page {
  margin: 1.0cm;
}
@media print {
  .ui.celled.grid > .column:not(.row), .ui.celled.grid > .row > .column {
    padding: 0em !important;
    padding-left: 5px !important;
  }
  .ui.table tr, .ui.table td{
    background: #FFFFFF !important;
    color: #000 !important;
  }
  .ui.table thead th {
    background: #FFFFFF !important;
  }
  a {
    color: #000 !important;
  }
  canvas {
    height: 70px !important;
    width: 70px !important;
  }
}
`;

const ShippingOrderDetail = ({
  open,
  onClose,
  shippingOrderDetailModalData,
  shippingOrder,
  load_modal,
  shipper,
}) => {
  const _print = useRef(null);

  const dispatch = useDispatch();
  const [printItem, setPrintItem] = useState(false);
  const [isGeneralData, setGeneralData] = useState(true);

  const reactToPrintContent = useCallback(() => {
    return _print.current;
  }, [_print.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    pageStyle: customStyles,
  });

  const detail = key => {
    dispatch(fetchShippingOrder({ key }));
  };

  let imgs = null;
  if (shippingOrder && shippingOrder.evidence) {
    imgs = [`${url}/${shippingOrder.evidence}`];
  }

  useEffect(() => {
    if (shippingOrderDetailModalData) {
      detail(shippingOrderDetailModalData);
    }
  }, [shippingOrderDetailModalData]);

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={onClose}
        title="Detalhamento de Ordem de Transporte"
        containerClassSize="max-w-full"
      >
        <div className="grid grid-flow-col gap-2">
          <div className="col-span-2">
            <Tabs>
              <Tabs.Item
                name="generalData"
                active={isGeneralData}
                onClick={() => setGeneralData(true)}
              >
                Dados gerais
              </Tabs.Item>
              <Tabs.Item
                name="evidence"
                active={!isGeneralData}
                onClick={() => setGeneralData(false)}
              >
                Evidências de entrega
              </Tabs.Item>
            </Tabs>
          </div>
          {isGeneralData && (
            <>
              <div className="flex justify-self-end">
                <div className="mr-4 text-sm font-medium leading-6 text-gray-900">
                  Impressão Completa
                </div>
                <Toggle onChange={() => setPrintItem(!printItem)} />
              </div>
              <div className="justify-self-end">
                <Button onClick={handlePrint}>Imprimir</Button>
              </div>
            </>
          )}
        </div>
        {isGeneralData && (
          <Detail
            printItem={printItem}
            source={shippingOrder}
            ref={_print}
            shipper={shipper}
          />
        )}
        {!isGeneralData &&
          (imgs ? (
            <div className="m-4">
              <CardImages
                imgs={imgs}
                size="small"
                source="/uploads/shipping-orders/"
                close={() => detail(shippingOrder.key)}
              />
            </div>
          ) : (
            <div className="mt-6 flex justify-center items-center bg-orange-100 py-2 text-gray-700 text-sm font-semibold rounded-md">
              Sem evidências de entrega!
            </div>
          ))}
      </Modal>
      <Load active={load_modal} />
    </React.Fragment>
  );
};
function mapStateToProps(state) {
  let { shippingOrder, load_query, load_modal } = state.shippingOrder;
  const { shipper } = state.auth;

  return {
    shippingOrder,
    load_query,
    load_modal,
    shipper,
  };
}

export default connect(mapStateToProps)(ShippingOrderDetail);
