import { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import ShipperForm from './components/ShipperForm';
import ShipperDriver from './components/ShipperDriver'

import {
  fetchShippers,
  saveShipper,
  fetchUnassignedShippers,
  changeStatusShipper,
  inviteShipper,
  resetState,
} from '@redux/ducks/shipper';
import { getCities } from '@redux/ducks/city';
import { refreshUser } from '@redux/ducks/auth';


import { CnpjCpf } from '@utils';
import {
  Tabs,
  TableForm,
  Label,
  Button,
  Confirm,
  ResendEmailForm,
} from '@components';

const Shipper = ({
  results,
  pageInfo,
  load_query,
  load_mutation,
  licenseSlots,
  operatorsCount,
  shippersCount,
  inviteesCount,
  userRoleName
}) => {
  const [option, setOption] = useState('selected');
  const [itemInUpdate, setItemInUpdate] = useState(null);
  const [isResendEmailModalOpen, setIsResendEmailModalOpen] = useState(false);
  const [resendEmailData, setResendEmailData] = useState({});
  const [isShipperDriverModalOpen, setIsShipperDriverModalOpen] = useState(false)
  const [shipperCnpj, setShipperCnpj] = useState(null)
  const totalCount = operatorsCount + shippersCount + inviteesCount + 1;
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const listRef = useRef(null);
  const confirmRef = useRef(null);

  const initialize = () => {
    dispatch(resetState());
    const search = listRef.current.getSearch();
    window.scroll(0, 0);
    listRef.current.closeForm();

    if (option === 'selected') {
      dispatch(fetchShippers({ search }));
    }
    if (option === 'shipy') {
      dispatch(fetchUnassignedShippers({ search }));
    }
  };

  const onSearch = search => {
    if (option === 'selected') {
      dispatch(
        fetchShippers({
          search,
        })
      );
    }
    if (option === 'shipy') {
      dispatch(
        fetchUnassignedShippers({
          search,
        })
      );
    }
  };

  const next = () => {
    const search = listRef.current.getSearch();
    if (option === 'selected') {
      dispatch(
        fetchShippers({
          cursor: pageInfo.cursor,
          search,
        })
      );
    }
    if (option === 'shipy') {
      dispatch(
        fetchUnassignedShippers({
          cursor: pageInfo.cursor,
          search,
        })
      );
    }
  };

  const onSave = (data, edit) => {
    dispatch(
      saveShipper({
        data,
        edit,
        success: () => initialize(),
      })
    );
  };
  const edit = async shipper => {
    const { id, code, active, company } = shipper;

    listRef?.current.openForm();
    setTimeout(() => {
      formRef?.current?.edit({
        shipperId: id,
        code,
        active,
        cnpj: company.cnpj,
        corporateName: company.corporateName,
        commercialName: company.commercialName,
        stateInscription: company.stateInscription,
        contactFirstName: company.user.firstName,
        contactLastName: company.user.lastName,
        neighborhood: company.neighborhood,
        address: company.address,
        city: company.city ? company.city.name : '',
        cityCode: company.city ? company.city.code : '',
        cityId: company.city ? company.city.id : '',
        email: company.user.email,
        phone: company.phone,
      });
    }, 200);
  };

  async function handleOpenRegister() {
    if (totalCount >= licenseSlots) {
      confirmRef.current.open(
        `Você não possui licenças disponíveis.
        Seu contrato prevê ${licenseSlots} licenças.
        Entre contato com a Qualix para aumentar as licenças ou inative operadores e/ou transportadoras para liberar licenças que estejam em desuso`,
        () => {},
        () => {},
        true
      );
      return listRef.current.closeForm();
    }

    listRef.current.openForm();
  }

  function handleShipperInvitation(item) {
    if (totalCount >= licenseSlots) {
      confirmRef.current.open(
        `Você não possui licenças disponíveis. Seu contrato prevê ${licenseSlots} licenças.
        Entre contato com a Qualix para aumentar as licenças ou inative operadores e/ou transportadoras para liberar licenças que estejam em desuso`,
        () => {},
        () => {},
        true
      );
      return listRef.current.closeForm();
    }

    dispatch(
      inviteShipper({
        data: {
          userId: item.company.user.id,
        },
        success: () => {
          setOption('selected');
        },
      })
    );
  }

  async function handleResendEmail(data) {
    setResendEmailData(data);
    setIsResendEmailModalOpen(true);
  }

  function handleOpenDrivers(item) {
    setShipperCnpj(item.company.cnpj)
    setIsShipperDriverModalOpen(true)
  }

  function renderButtonDriver(item) {
    if (userRoleName !== 'COMPANY') return

    return (
      <Button
        compact
        onClick={() => handleOpenDrivers(item.node)}
        disabled={item.node.status === 'INVITED'}
      >
        Motoristas
      </Button>
    )
  }

  const handleShipperStatus = item => {
    const active = item?.active;
    const action = active ? 'inativar' : 'ativar';
    confirmRef.current.open(
      `Deseja realmente ${action} a transportadora ${item?.company?.corporateName}?`,
      () => {
        setItemInUpdate(item.id);
        dispatch(
          changeStatusShipper({
            data: {
              shipperId: item.id,
              active: !active,
            },
            edit: true,
            error: () => {
              setItemInUpdate(null);
            },
            success: () => {
              setItemInUpdate(null);
              initialize();
            },
          })
        );
      }
    );
  };

  const Filters = () => (
    <Tabs>
      <Tabs.Item
        name="selected"
        active={option === 'selected'}
        onClick={() => setOption('selected')}
      >
        Em uso por sua empresa
      </Tabs.Item>
      <Tabs.Item
        name="shipy"
        active={option === 'shipy'}
        onClick={() => setOption('shipy')}
      >
        Cadastradas no Shipy
      </Tabs.Item>
    </Tabs>
  );
  useEffect(() => {
    dispatch(refreshUser({}));
    initialize();
  }, [option]);

  return (
    <>
      <TableForm
        formHeader={{
          title: 'Transportadoras',
          subtitle: 'Gerencie e cadastre as transportadoras',
        }}
        labelModalOpen="Convidar Transportadora"
        titleModal="Cadastro de transportadora"
        onNew={handleOpenRegister}
        filters={Filters}
        source={results}
        hasNext={pageInfo.hasNextPage}
        load={load_query}
        onSearch={onSearch}
        ref={listRef}
        next={next}
        headers={[
          {
            label: 'Razão social',
            getValue: item => item.node.company.corporateName,
          },
          {
            label: 'CNPJ',
            getValue: item => CnpjCpf.mask(item.node.company.cnpj),
          },
          {
            label: 'Contato',
            getValue: item => item.node.company.contact,
          },
          {
            label: 'E-mail',
            getValue: item => item.node?.company?.user?.email,
          },
          {
            label: 'Situação',
            getValue: item => {
              return (
                <>
                  {item.node.status !== 'INVITED' &&
                    item.node.status !== undefined && (
                      <Label
                        type={item.node.active ? 'success' : 'alert'}
                        text={item.node.active ? 'ativo' : 'inativo'}
                      />
                    )}

                  {item.node.status === undefined && (
                    <Label type={'warning'} text={'Não convidado'} />
                  )}
                  {item.node.status === 'INVITED' && (
                    <Label type="warning" text="convidado" />
                  )}
                  {item.node.status === 'EXPIRED' && (
                    <Label type="alert" text="expirado" />
                  )}

                  {(item.node.status === 'INVITED' ||
                    item.node.status === 'EXPIRED') && (
                    <button
                      type="button"
                      className="text-blue-500 text-xs ml-2"
                      onClick={() => handleResendEmail(item?.node)}
                    >
                      Reenviar
                    </button>
                  )}
                </>
              );
            },
          },
          {
            label: 'Ações',
            props: {
              align: 'center',
              width: option === 'selected' ? '340px' : '110px',
            },
            getValue: item =>
              option === 'selected' ? (
                <div className="flex gap-2 justify-center">
                  <Button
                    compact
                    onClick={() => edit(item.node)}
                    disabled={item.node.status === 'INVITED'}
                  >
                    Editar
                  </Button>
                  {renderButtonDriver(item)}
                  <Button
                    compact
                    isLoading={load_mutation && item.id === itemInUpdate}
                    onClick={() => handleShipperStatus(item.node)}
                    disabled={item.node.status === 'INVITED'}
                  >
                    Alterar situação
                  </Button>
                </div>
              ) : (
                <div className="flex gap-2 justify-center">
                  <Button
                    compact
                    key={item.node.id}
                    onClick={() => handleShipperInvitation(item.node)}
                  >
                    Convidar
                  </Button>
                </div>
              ),
          },
        ]}
        keyRow={item => item.node.id}
      >
        <ShipperForm
          formRef={formRef}
          onSubmit={onSave}
          getCities={getCities}
          refreshFormData={data => formRef.current.change(data)}
          loadMutation={load_mutation}
        />
      </TableForm>
      <ResendEmailForm
        isResendEmailModalOpen={isResendEmailModalOpen}
        setIsResendEmailModalOpen={setIsResendEmailModalOpen}
        resendEmailData={resendEmailData}
        invitationRole="SHIPPER"
      />
      <ShipperDriver
        isShipperDriverModalOpen={isShipperDriverModalOpen}
        setIsShipperDriverModalOpen={setIsShipperDriverModalOpen}
        shipperCnpj={shipperCnpj}
      />

      <Confirm ref={confirmRef} />
    </>
  );
};

function mapStateToProps({ auth, shipper }) {
  const { results, pageInfo, load_query, load_mutation } = shipper;
  const { licenseSlots, operatorsCount, shippersCount, inviteesCount } =
    auth.licenses || {};

  const user = auth?.user;
  const userRoleName = user?.role?.name;

  return {
    results,
    pageInfo,
    load_query,
    load_mutation,
    licenseSlots,
    operatorsCount,
    shippersCount,
    inviteesCount,
    userRoleName
  };
}

export default connect(mapStateToProps)(Shipper);
