import { TableForm } from '@components';
import moment from 'moment';
import { forwardRef } from 'react';

const ShippingOrderList = forwardRef((props, ref) => {
  return (
    <TableForm
      {...props}
      ref={ref}
      headers={[
        { label: 'OT', getValue: item => item.node.key },
        {
          label: 'Saída',
          getValue: item =>
            item.node.dispatchedAt &&
            moment(item.node.dispatchedAt).format('DD/MM/YYYY-HH:mm'),
        },
        {
          label: 'Entrega',
          getValue: item =>
            item.node.deliveredAt &&
            moment(item.node.deliveredAt).format('DD/MM/YYYY-HH:mm'),
        },
        { label: 'Placa', getValue: item => item.node.plate },
        {
          label: 'Destino',
          getValue: item => item.node.destination,
        },
        {
          label: 'Motorista',
          getValue: item => item?.node?.driverName,
        },
        {
          label: 'Transportadoras',
          getValue: item => {
            const { dispatcher, redispatcher } = item.node.invoiceExcerpt;
            return (
              <>
                <div className="truncate">
                  {`${dispatcher?.company?.corporateName}(${dispatcher?.company?.cnpj})`}
                </div>
                {redispatcher && (
                  <div className="truncate text-xs text-orange-500">
                    {`${redispatcher?.company?.corporateName}(${redispatcher?.company?.cnpj})`}
                  </div>
                )}
              </>
            );
          },
        },
      ]}
      keyRow={item => item.node.id}
    />
  );
});

ShippingOrderList.displayName = 'ShippingOrderList';

export default ShippingOrderList;
