export const Types = {
  ACTIVATE_ACCOUNT: 'activateInvitation/ACTIVATE_ACCOUNT',
  SET_RESET_TOKEN: 'activateInvitation/SET_RESET_TOKEN',
  SET_NEW_PASSWORD: 'activateInvitation/SET_NEW_PASSWORD',
  LOAD_MUTATION: 'activateInvitation/LOAD_MUTATION',
};

const initialState = {
  resetPasswordToken: undefined,
  load_mutation: false,
};

export default function activateInvitation(state = initialState, action) {
  switch (action.type) {
    case Types.SET_RESET_TOKEN:
      return {
        ...state,
        resetPasswordToken: action.resetPasswordToken,
      };
    case Types.LOAD_MUTATION:
      return { ...state, load_mutation: action.load };
    default:
      return state;
  }
}

export function activateAccount({ data, success, error }) {
  return {
    type: Types.ACTIVATE_ACCOUNT,
    data,
    success,
    error,
  };
}

export function validatePassword({ data, success, error }) {
  return {
    type: Types.SET_NEW_PASSWORD,
    data,
    success,
    error,
  };
}
