import { all, takeLatest } from 'redux-saga/effects';
import { Types as AuthTypes } from '../ducks/auth';
import { validateUser, refreshUser, logout } from './auth';

import { Types as ChangePasswordTypes } from '../ducks/changePassword';
import {
  handleCheckToken,
  handleSendEmail,
  handleSendNewPassword,
} from './changePassword';

import { Types as InvoiceTypes } from '../ducks/invoice';
import {
  fetchInvoices,
  fetchOverdueInvoices,
  fetchInvoice,
  getInvoice,
  addLog,
  addLogBackground,
  removeReturn,
  saveAgreedTo,
  editInvoiceExpenseDeadlineDate,
} from './invoice';

import { Types as IncidentTypes } from '../ducks/incident';
import {
  fetchIncidents,
  terminateIncident,
  subscribeToNewIncident,
  createIncident,
} from './incident';

import { Types as DeliveryTypes } from '../ducks/delivery';
import { updateDelivery, reversalDelivery } from './delivery';

import { Types as MessageTypes } from '../ducks/message';
import { fetchMessages, createMessage } from './message';

import { Types as OverviewTypes } from '../ducks/overview';
import { fetchOverview } from './overview';

import { Types as DriverTypes } from '../ducks/driver';
import {
  createDriver,
  updateDriver,
  fetchDrivers,
  fetchDriversByShipper,
  getDrivers,
  removeDriver,
} from './driver';

import { Types as IncidentTypeTypes } from '../ducks/incidentType';
import { createType, updateType, fetchTypes, getTypes } from './incidentType';

import { Types as RegionTypes } from '../ducks/region';
import {
  createRegion,
  removeRegion,
  updateRegion,
  fetchRegions,
  getRegions,
} from './region';

import { Types as RegionCitiesTypes } from '../ducks/regionCities';
import { addCity, removeCity, fetchRegionCities } from './regionCities';

import { Types as CitiesTypes } from '../ducks/city';
import { fetchCities, getCities } from './city';

import { Types as LogisticCenterTypes } from '../ducks/logisticCenter';
import {
  createLogisticCenter,
  updateLogisticCenter,
  fetchLogisticCenters,
} from './logisticCenter';

import { Types as LogisticCenterRegionsTypes } from '../ducks/logisticCenterRegions';
import {
  fetchLogisticCenterRegions,
  createLogisticCenterRegion,
  updateLogisticCenterRegion,
  removeLogisticCenterRegion,
} from './logisticCenterRegions';

import { Types as LogisticCenterShippersTypes } from '../ducks/logisticCenterShippers';
import {
  fetchLogisticCenterShippers,
  createLogisticCenterShipper,
  updateLogisticCenterShipper,
  removeLogisticCenterShipper,
} from './logisticCenterShippers';

import { Types as ShipperTypes } from '../ducks/shipper';
import {
  createShipper,
  updateShipper,
  fetchShippers,
  fetchUnassignedShippers,
  getShippers,
  getContractors,
  handleShipperInvite,
  handleResendShipperInvitation,
  changeStatusShipper,
  changeEmailShipper,
  getExternalShippers,
} from './shipper';

import { Types as OperatorTypes } from '../ducks/operator';
import {
  createOperator,
  updateOperator,
  fetchOperators,
  handleResendInvitation,
  changeStatusOperator,
  changeEmailOperator,
} from './operator';

import { Types as AlertTypes } from '../ducks/alert';
import {
  fetchAlerts,
  createAlert,
  updateAlert,
  subscribeToNewAlert,
} from './alert';

import { Types as TransportQueueTypes } from '../ducks/transportQueue';
import {
  fetchTransportQueue,
  addDriverQueue,
  removeDriverQueue,
} from './transportQueue';

import { Types as ShippingOrderTypes } from '../ducks/shippingOrder';
import { fetchShippingOrders, fetchShippingOrder } from './shippingOrder';

import { Types as CustomerTypes } from '../ducks/customer';
import { fetchCustomer } from './customer';

import { Types as ReversalTypes } from '../ducks/reversal';
import {
  fetchReversals,
  createReversal,
  effectReversal,
  removeReversal,
  getReversalDetails,
} from './reversal';

import { Types as ReversalReasonTypes } from '../ducks/reversalReason';
import {
  createReason,
  getReasons,
  removeReason,
  updateReason,
} from './reversalReason';

import { Types as redeliveryTypes } from '../ducks/redelivery';
import { saveRedelivery, getRedeliveries, getPrediction } from './redelivery';

import { Types as ReprieveTypes } from '../ducks/reprieve';
import {
  fetchReprieve,
  createReprieve,
  removeReprieve,
  effectReprieve,
  getReprieve,
  getReprieveReasons,
} from './reprieve';

import { Types as mailTypes } from '../ducks/mail';
import { sendMail } from './mail';

import { Types as logTypes } from '../ducks/log';
import { fetchLogs } from './log';

import { Types as ExpenseTypeTypes } from '../ducks/expenseType';
import {
  createExpenseType,
  editExpenseType,
  fetchExpenseTypes,
} from './expenseType';

import { Types as ExpenseTypes } from '../ducks/expense';
import {
  createExpense,
  editExpense,
  fetchExpense,
  getExpense,
} from './expense';

import { Types as CompaniesTypes } from '../ducks/companies';
import {
  changeStatusCompany,
  createCompany,
  fetchCompanies,
  handleUpdateCompany,
  resendCompanyInvitation,
} from './companies';

import { Types as ActivateInvitationTypes } from '../ducks/activateInvitation';
import {
  handleActivateAccount,
  handleSetNewPassword,
} from './activateInvitation';

import { Types as ConfigTypes } from '../ducks/config';
import { generateAccessToken, getAccessToken } from './config';

import { Types as WebhookTypes } from '../ducks/webhook';
import {
  createSecret,
  updateSecret,
  createWebhook,
  updateWebhook,
  removeWebhook,
  fetchWebhooks,
} from './webhook';

export default function* root(dispatch) {
  yield all([
    takeLatest(AuthTypes.VALIDATE, validateUser),
    takeLatest(AuthTypes.REFRESH_USER, refreshUser),
    takeLatest(AuthTypes.LOGOUT, logout),

    takeLatest(ConfigTypes.SET_ACCESS_TOKEN, generateAccessToken),
    takeLatest(ConfigTypes.GET_ACCESS_TOKEN, getAccessToken),

    takeLatest(ChangePasswordTypes.SEND_EMAIL, handleSendEmail),
    takeLatest(ChangePasswordTypes.CHECK_TOKEN, handleCheckToken),
    takeLatest(ChangePasswordTypes.CHANGE_PASSWORD, handleSendNewPassword),

    takeLatest(InvoiceTypes.FETCH_INVOICES, fetchInvoices),
    takeLatest(InvoiceTypes.FETCH_OVERDUE_INVOICES, fetchOverdueInvoices),
    takeLatest(InvoiceTypes.FETCH_INVOICE, fetchInvoice),
    takeLatest(InvoiceTypes.GET_INVOICE, getInvoice),
    takeLatest(InvoiceTypes.ADD_LOG, addLog),
    takeLatest(InvoiceTypes.ADD_LOG_BACKGROUND, addLogBackground),
    takeLatest(InvoiceTypes.REMOVE_RETURN, removeReturn),
    takeLatest(InvoiceTypes.UPDATE_INVOICE, saveAgreedTo),
    takeLatest(
      InvoiceTypes.EDIT_EXPENSE_DEADLINE,
      editInvoiceExpenseDeadlineDate
    ),

    takeLatest(IncidentTypes.FETCH_INCIDENTS, fetchIncidents),
    takeLatest(IncidentTypes.TERMINATE_INCIDENT, terminateIncident),
    takeLatest(
      IncidentTypes.SUBSCRIBE_TO_NEW_INCIDENT,
      subscribeToNewIncident,
      dispatch
    ),
    takeLatest(IncidentTypes.CREATE_INCIDENT, createIncident),

    takeLatest(MessageTypes.FETCH_MESSAGES, fetchMessages),
    takeLatest(MessageTypes.CREATE_MESSAGE, createMessage),

    takeLatest(DeliveryTypes.UPDATE_DELIVERY, updateDelivery),
    takeLatest(DeliveryTypes.REVERSAL_DELIVERY, reversalDelivery),

    takeLatest(OverviewTypes.FETCH_OVERVIEW, fetchOverview),

    takeLatest(DriverTypes.CREATE_DRIVER, createDriver),
    takeLatest(DriverTypes.UPDATE_DRIVER, updateDriver),
    takeLatest(DriverTypes.FETCH_DRIVERS, fetchDrivers),
    takeLatest(DriverTypes.FETCH_DRIVERS_BY_SHIPPER, fetchDriversByShipper),
    takeLatest(DriverTypes.GET_DRIVERS, getDrivers),
    takeLatest(DriverTypes.REMOVE_DRIVER, removeDriver),

    takeLatest(IncidentTypeTypes.CREATE_TYPE, createType),
    takeLatest(IncidentTypeTypes.UPDATE_TYPE, updateType),
    takeLatest(IncidentTypeTypes.FETCH_TYPES, fetchTypes),
    takeLatest(IncidentTypeTypes.GET_TYPES, getTypes),

    takeLatest(RegionTypes.CREATE_REGION, createRegion),
    takeLatest(RegionTypes.REMOVE_REGION, removeRegion),
    takeLatest(RegionTypes.UPDATE_REGION, updateRegion),
    takeLatest(RegionTypes.FETCH_REGIONS, fetchRegions),
    takeLatest(RegionTypes.GET_REGIONS, getRegions),
    takeLatest(RegionCitiesTypes.ADD_CITY, addCity),
    takeLatest(RegionCitiesTypes.REMOVE_CITY, removeCity),
    takeLatest(RegionCitiesTypes.FETCH_REGION_CITIES, fetchRegionCities),

    takeLatest(CitiesTypes.FETCH_CITIES, fetchCities),
    takeLatest(CitiesTypes.GET_CITIES, getCities),

    takeLatest(
      LogisticCenterTypes.CREATE_LOGISTIC_CENTER,
      createLogisticCenter
    ),
    takeLatest(
      LogisticCenterTypes.UPDATE_LOGISTIC_CENTER,
      updateLogisticCenter
    ),
    takeLatest(
      LogisticCenterTypes.FETCH_LOGISTIC_CENTERS,
      fetchLogisticCenters
    ),
    takeLatest(
      LogisticCenterRegionsTypes.CREATE_LOGISTIC_CENTER_REGION,
      createLogisticCenterRegion
    ),
    takeLatest(
      LogisticCenterRegionsTypes.UPDATE_LOGISTIC_CENTER_REGION,
      updateLogisticCenterRegion
    ),
    takeLatest(
      LogisticCenterRegionsTypes.REMOVE_LOGISTIC_CENTER_REGION,
      removeLogisticCenterRegion
    ),
    takeLatest(
      LogisticCenterRegionsTypes.FETCH_LOGISTIC_CENTER_REGIONS,
      fetchLogisticCenterRegions
    ),
    takeLatest(
      LogisticCenterShippersTypes.CREATE_LOGISTIC_CENTER_SHIPPER,
      createLogisticCenterShipper
    ),
    takeLatest(
      LogisticCenterShippersTypes.UPDATE_LOGISTIC_CENTER_SHIPPER,
      updateLogisticCenterShipper
    ),
    takeLatest(
      LogisticCenterShippersTypes.REMOVE_LOGISTIC_CENTER_SHIPPER,
      removeLogisticCenterShipper
    ),
    takeLatest(
      LogisticCenterShippersTypes.FETCH_LOGISTIC_CENTER_SHIPPERS,
      fetchLogisticCenterShippers
    ),

    takeLatest(ShipperTypes.CREATE_SHIPPER, createShipper),
    takeLatest(ShipperTypes.UPDATE_SHIPPER, updateShipper),
    takeLatest(ShipperTypes.FETCH_SHIPPERS, fetchShippers),
    takeLatest(ShipperTypes.FETCH_UNASSIGNEDSHIPPERS, fetchUnassignedShippers),
    takeLatest(ShipperTypes.GET_SHIPPERS, getShippers),
    takeLatest(ShipperTypes.GET_EXTERNAL_SHIPPERS, getExternalShippers),
    takeLatest(ShipperTypes.GET_CONTRACTORS, getContractors),
    takeLatest(ShipperTypes.INVITE_SHIPPER, handleShipperInvite),
    takeLatest(ShipperTypes.UPDATE_STATUS_SHIPPER, changeStatusShipper),
    takeLatest(ShipperTypes.CHANGE_EMAIL_SHIPPER, changeEmailShipper),
    takeLatest(
      ShipperTypes.RESEND_INVITE_SHIPPER,
      handleResendShipperInvitation
    ),

    takeLatest(OperatorTypes.CREATE_OPERATOR, createOperator),
    takeLatest(OperatorTypes.UPDATE_OPERATOR, updateOperator),
    takeLatest(OperatorTypes.FETCH_OPERATORS, fetchOperators),
    takeLatest(OperatorTypes.RESEND_INVITATION, handleResendInvitation),
    takeLatest(OperatorTypes.UPDATE_STATUS_OPERATOR, changeStatusOperator),
    takeLatest(OperatorTypes.CHANGE_EMAIL_OPERATOR, changeEmailOperator),

    takeLatest(AlertTypes.FETCH_ALERTS, fetchAlerts),
    takeLatest(AlertTypes.CREATE_ALERT, createAlert),
    takeLatest(AlertTypes.UPDATE_ALERT, updateAlert),
    takeLatest(
      AlertTypes.SUBSCRIBE_TO_NEW_ALERT,
      subscribeToNewAlert,
      dispatch
    ),

    takeLatest(TransportQueueTypes.FETCH_TRANSPORT_QUEUE, fetchTransportQueue),
    takeLatest(TransportQueueTypes.ADD_DRIVER_QUEUE, addDriverQueue),
    takeLatest(TransportQueueTypes.REMOVE_DRIVER_QUEUE, removeDriverQueue),

    takeLatest(ShippingOrderTypes.FETCH_SHIPPING_ORDERS, fetchShippingOrders),
    takeLatest(ShippingOrderTypes.FETCH_SHIPPING_ORDER, fetchShippingOrder),
    takeLatest(CustomerTypes.FETCH_CUSTOMER, fetchCustomer),

    takeLatest(ReversalTypes.FETCH_REVERSALS, fetchReversals),
    takeLatest(ReversalTypes.CREATE_REVERSAL, createReversal),
    takeLatest(ReversalTypes.EFFECT_REVERSAL, effectReversal),
    takeLatest(ReversalTypes.REMOVE_REVERSAL, removeReversal),

    takeLatest(ReversalTypes.FETCH_DETAIL, getReversalDetails),
    takeLatest(ReversalReasonTypes.CREATE_REVERSAL_REASON, createReason),
    takeLatest(ReversalReasonTypes.UPDATE_REVERSAL_REASON, updateReason),
    takeLatest(ReversalReasonTypes.REMOVE_REVERSAL_REASON, removeReason),
    takeLatest(ReversalReasonTypes.GET_REASONS, getReasons),

    takeLatest(redeliveryTypes.SAVE_REDELIVERY, saveRedelivery),
    takeLatest(redeliveryTypes.GET_REDELIVERIES_HANDLE, getRedeliveries),
    takeLatest(redeliveryTypes.GET_PREDICTION_HANDLE, getPrediction),

    takeLatest(ReprieveTypes.FETCH_REPRIEVE, fetchReprieve),
    takeLatest(ReprieveTypes.CREATE_REPRIEVE, createReprieve),
    takeLatest(ReprieveTypes.EFFECT_REPRIEVE, effectReprieve),
    takeLatest(ReprieveTypes.REMOVE_REPRIEVE, removeReprieve),
    takeLatest(ReprieveTypes.GET_REPRIEVE, getReprieve),
    takeLatest(ReprieveTypes.GET_REASONS, getReprieveReasons),

    takeLatest(mailTypes.SEND_MAIL_HANDLER, sendMail),

    takeLatest(logTypes.FETCH_LOGS, fetchLogs),

    takeLatest(ExpenseTypeTypes.CREATE_EXPENSE_TYPE, createExpenseType),
    takeLatest(ExpenseTypeTypes.EDIT_EXPENSE_TYPE, editExpenseType),
    takeLatest(ExpenseTypeTypes.FETCH_EXPENSE_TYPES, fetchExpenseTypes),

    takeLatest(ExpenseTypes.CREATE_EXPENSE, createExpense),
    takeLatest(ExpenseTypes.EDIT_EXPENSE, editExpense),
    takeLatest(ExpenseTypes.FETCH_EXPENSE, fetchExpense),
    takeLatest(ExpenseTypes.GET_EXPENSE, getExpense),

    takeLatest(CompaniesTypes.FETCH_COMPANIES, fetchCompanies),
    takeLatest(CompaniesTypes.CREATE_COMPANY, createCompany),
    takeLatest(CompaniesTypes.UPDATE_COMPANY, handleUpdateCompany),
    takeLatest(CompaniesTypes.UPDATE_STATUS_COMPANY, changeStatusCompany),
    takeLatest(
      CompaniesTypes.RESEND_COMPANY_INVITATION,
      resendCompanyInvitation
    ),

    takeLatest(ActivateInvitationTypes.ACTIVATE_ACCOUNT, handleActivateAccount),
    takeLatest(ActivateInvitationTypes.SET_NEW_PASSWORD, handleSetNewPassword),

    takeLatest(WebhookTypes.CREATE_SECRET, createSecret),
    takeLatest(WebhookTypes.UPDATE_SECRET, updateSecret),
    takeLatest(WebhookTypes.CREATE_WEBHOOK, createWebhook),
    takeLatest(WebhookTypes.UPDATE_WEBHOOK, updateWebhook),
    takeLatest(WebhookTypes.REMOVE_WEBHOOK, removeWebhook),
    takeLatest(WebhookTypes.FETCH_WEBHOOKS, fetchWebhooks),
  ]);
}
