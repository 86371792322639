import { Field, reduxForm } from 'redux-form';
import { Button, Input } from '@components';
import { validate } from './validate';

const renderField = ({
  input,
  label,
  type,
  meta: { touched, error, warning },
}) => (
  <>
    <Input {...input} caption={label} type={type} />
    {touched &&
      ((error && <span className="text-sm text-red-500">{error}</span>) ||
        (warning && <span className="text-sm text-red-500">{warning}</span>))}
  </>
);

const ResetValidation = ({
  handleSubmit,
  submitFunction,
  submitting,
  invalid,
}) => {
  return (
    <form className="space-y-8" onSubmit={handleSubmit(submitFunction)}>
      <Field
        name="newPassword"
        type="password"
        component={renderField}
        label="Nova senha"
      />
      <Field
        name="passwordConfirmation"
        type="password"
        component={renderField}
        label="Confirme a nova senha"
      />
      <Button
        type="submit"
        fullWidth
        variant="primary"
        disabled={invalid}
        isLoading={submitting}
      >
        Definir senha
      </Button>
    </form>
  );
};

export default reduxForm({
  form: 'resetValidation',
  validate,
})(ResetValidation);
