import { client } from '@config/apollo';
import gql from 'graphql-tag';
import { call, put, select } from 'redux-saga/effects';
import { Types } from '../ducks/shippingOrder';
import { Types as ToastTypes } from '../ducks/toast';

export function* fetchShippingOrders(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  if (!action.cursor) {
    yield put({ type: Types.SET_RESULTS, results: {} });
  }

  try {
    yield put({ type: Types.LOAD_QUERY, load: true });
    const response = yield call(cli.query, {
      query: gql`
        query (
          $first: Int
          $last: Int
          $before: String
          $filter: ShippingOrderFilterInput
        ) {
          shippingOrders(
            first: $first
            last: $last
            before: $before
            filter: $filter
          ) {
            edges {
              node {
                id
                key
                dispatchedAt
                deliveredAt
                plate
                destination
                driverName
                driverCpf
                invoiceExcerpt {
                  redispatcher {
                    company {
                      id
                      cnpj
                      commercialName
                      corporateName
                    }
                  }
                  dispatcher {
                    company {
                      id
                      cnpj
                      commercialName
                      corporateName
                    }
                  }
                  externalRedispatcher {
                    cnpj
                    commercialName
                    corporateName
                  }
                  externalRedispatcher {
                    cnpj
                    commercialName
                    corporateName
                  }
                }
              }
            }
            pageInfo {
              hasNextPage
              after
              before
            }
          }
        }
      `,
      variables: {
        last: 50,
        before: action.cursor,
        filter: {
          ...action.filter,
          companyId: auth?.company?.id,
        },
      },
    });

    const pageInfo = response.data.shippingOrders.pageInfo;
    const results = response.data.shippingOrders.edges;

    yield put({
      type: Types.SET_PAGE_INFO,
      pageInfo: { ...pageInfo, cursor: pageInfo.before },
    });

    if (action.cursor) {
      yield put({ type: Types.NEXT_RESULTS, results });
    } else {
      yield put({ type: Types.SET_RESULTS, results });
    }
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
  } finally {
    yield put({ type: Types.LOAD_QUERY, load: false });
  }
}

export function* fetchShippingOrder(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    yield put({ type: Types.LOAD_MODAL, load: true });

    const response = yield call(cli.query, {
      query: gql`
        query ($key: String, $companyId: String) {
          shippingOrder(key: $key, companyId: $companyId) {
            id
            key
            dispatchedAt
            deliveredAt
            plate
            destination
            driverName
            driverCpf
            note
            evidence
            invoiceExcerpt {
              redispatcher {
                company {
                  id
                  cnpj
                  corporateName
                  phone
                  stateInscription
                  contact
                  neighborhood
                  address
                  city {
                    id
                    code
                    name
                    state
                  }
                }
              }
              dispatcher {
                company {
                  id
                  cnpj
                  commercialName
                  corporateName
                }
              }
              externalRedispatcher {
                cnpj
                commercialName
                corporateName
              }
              externalRedispatcher {
                cnpj
                commercialName
                corporateName
              }
            }
            dispatcherFreightCost
            dispatcherFreightCost
            dispatcherInsurancePercentage
            dispatcherInsuranceCost
            redispatcherFreightCost
            redispatcherInsurancePercentage
            redispatcherInsuranceCost
            invoices {
              id
              key
              invoiceNo
              dispatcher {
                company {
                  cnpj
                }
              }
              redispatcher {
                company {
                  cnpj
                }
              }
              dispatcherFreightCost
              redispatcherFreightCost
              productInfo {
                aggregated {
                  quantity
                  grossWeight
                  totalValue
                }
                items {
                  sku
                  name
                  quantity
                }
              }
              activities {
                emittedAt
              }
              customer {
                id
                code
                cnpj
                corporateName
                city {
                  id
                  name
                  state
                }
                stateInscription
              }
            }
          }
        }
      `,
      variables: {
        key: action.key,
        companyId: auth?.company?.id,
      },
    });

    const shippingOrder = { ...response.data.shippingOrder };

    yield put({ type: Types.SET_SHIPPING_ORDER, shippingOrder });
    if (action.success) action.success(response);
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
  } finally {
    yield put({ type: Types.LOAD_MODAL, load: false });
  }
}
