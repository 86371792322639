import { useEffect, useRef, useState } from 'react';

import { connect, useDispatch } from 'react-redux';
import { fetchExpense, resetState } from '@redux/ducks/expense';

import { ShipperSelector } from '@components';
import { Confirm } from '@components';
import ExpenseList from '@pages/Expenses/components/ExpenseList';
import ExpenseDetail from '@pages/Expenses/Accompaniments/Detail';

const ExpensePending = ({ results, load_query, pageInfo }) => {
  const confirmRef = useRef(null);

  const [shipper, setShipper] = useState(undefined);
  const [isExpenseRejectModalOpen, setIsExpenseRejectModalOpen] =
    useState(false);
  const [expenseRejectData, setExpenseRejectData] = useState({});
  const [isExpenseAproveModalOpen, setIsExpenseAproveModalOpen] =
    useState(false);
  const [expenseAproveData, setExpenseAproveData] = useState({});
  const dispatch = useDispatch();

  function handleLoadData() {
    dispatch(fetchExpense({ status: 4, shipper }));
  }

  async function handleAprove(data) {
    setExpenseAproveData(data.node);
    setIsExpenseAproveModalOpen(true);
  }

  function handleReject(data) {
    setExpenseRejectData(data.node);
    setIsExpenseRejectModalOpen(true);
  }

  const next = () => {
    dispatch(
      fetchExpense({
        status: 4,
        shipper: shipper || undefined,
        cursor: pageInfo.before
      })
    )
  }

  useEffect(() => {
    dispatch(resetState());
    dispatch(fetchExpense({ status: 4, shipper }));
  }, [shipper, !isExpenseRejectModalOpen, !isExpenseAproveModalOpen]);

  return (
    <>
      <ShipperSelector
        onSelect={selection => setShipper(selection.id)}
        onClear={() => setShipper(undefined)}
        onSearch={() => handleLoadData()}
      />

      <ExpenseList
        source={results}
        showReason={false}
        onApprove={data => handleAprove(data)}
        onReject={data => handleReject(data)}
        load={load_query}
        next={next}
        hasNext={pageInfo.hasNextPage}
      />

      {/* REJEITAR */}
      <ExpenseDetail
        modalTitle="Não aprovado ou indeferido"
        open={isExpenseRejectModalOpen}
        onClose={() => setIsExpenseRejectModalOpen(false)}
        expenseDetailData={expenseRejectData}
        hide={['cost', 'description', 'type']}
      />

      {/* APROVAR */}
      <ExpenseDetail
        modalTitle="Detalhes da despesa"
        open={isExpenseAproveModalOpen}
        onClose={() => setIsExpenseAproveModalOpen(false)}
        expenseDetailData={expenseAproveData}
        approved={true}
      />

      <Confirm ref={confirmRef} />
    </>
  );
};

function mapStateToProps({ expense }) {
  const { results, load_query, pageInfo } = expense;

  return {
    results,
    load_query,
    pageInfo
  };
}

export default connect(mapStateToProps)(ExpensePending);
