export const Detail = ({ source }) => {
  return (
    <div className="px-3">
      <dl className="grid grid-cols-1 sm:grid-cols-2">
        <div className="flex px-2 py-2 sm:col-span-1 sm:px-0">
          <dt className="text-sm font-bold leading-6 text-gray-900">Código:</dt>
          <dd className="ml-1 text-sm leading-6 text-gray-700">
            {source?.code}
          </dd>
        </div>
        <div className="flex px-2 py-2 sm:col-span-1 sm:px-0">
          <dt className="text-sm font-bold leading-6 text-gray-900">CNPJ:</dt>
          <dd className="ml-1 text-sm leading-6 text-gray-700">
            {source?.cnpj}
          </dd>
        </div>
      </dl>

      <dl className="grid grid-cols-1 sm:grid-cols-1">
        <div className="flex px-2 py-2 sm:col-span-1 sm:px-0">
          <dt className="text-sm font-bold leading-6 text-gray-900">
            Cliente:
          </dt>
          <dd className="ml-1 text-sm leading-6 text-gray-700">
            {source?.corporateName}
          </dd>
        </div>
      </dl>

      <dl className="grid grid-cols-1 sm:grid-cols-2">
        <div className="flex px-2 py-2 sm:col-span-1 sm:px-0">
          <dt className="text-sm font-bold leading-6 text-gray-900">
            Endereço:
          </dt>
          <dd className="ml-1 text-sm leading-6 text-gray-700">
            {source?.address}
          </dd>
        </div>
      </dl>

      <dl className="grid grid-cols-1 sm:grid-cols-2">
        <div className="flex px-2 py-2 sm:col-span-1 sm:px-0">
          <dt className="text-sm font-bold leading-6 text-gray-900">Cidade:</dt>
          <dd className="ml-1 text-sm leading-6 text-gray-700">
            {source?.city?.name} - {source?.city?.state}
          </dd>
        </div>
        <div className="flex px-2 py-2 sm:col-span-1 sm:px-0">
          <dt className="text-sm font-bold leading-6 text-gray-900">CEP:</dt>
          <dd className="ml-1 text-sm leading-6 text-gray-700">
            {source?.postalCode}
          </dd>
        </div>
      </dl>

      <dl className="grid grid-cols-1 sm:grid-cols-1">
        <div className="flex px-2 py-2 sm:col-span-1 sm:px-0">
          <dt className="text-sm font-bold leading-6 text-gray-900">Bairro:</dt>
          <dd className="ml-1 text-sm leading-6 text-gray-700">
            {source?.district}
          </dd>
        </div>
      </dl>

      <dl className="grid grid-cols-1 sm:grid-cols-2">
        <div className="flex px-2 py-2 sm:col-span-1 sm:px-0">
          <dt className="text-sm font-bold leading-6 text-gray-900">Fone:</dt>
          <dd className="ml-1 text-sm leading-6 text-gray-700">
            {source?.phone}
          </dd>
        </div>
      </dl>

      <dl className="grid grid-cols-1 sm:grid-cols-2">
        <div className="flex px-2 py-2 sm:col-span-1 sm:px-0">
          <dt className="text-sm font-bold leading-6 text-gray-900">
            Fantasia:
          </dt>
          <dd className="ml-1 text-sm leading-6 text-gray-700">
            {source?.commercialName}
          </dd>
        </div>
      </dl>

      <dl className="grid grid-cols-1 sm:grid-cols-2">
        <div className="flex px-2 py-2 sm:col-span-1 sm:px-0">
          <dt className="text-sm font-bold leading-6 text-gray-900">Email:</dt>
          <dd className="ml-1 text-sm leading-6 text-gray-700">
            {source?.email}
          </dd>
        </div>
      </dl>
    </div>
  );
};
