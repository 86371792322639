import { client } from '@config/apollo';
import gql from 'graphql-tag';
import { call, put, select } from 'redux-saga/effects';
import { Types } from '../ducks/logisticCenterRegions';
import { Types as ToastTypes } from '../ducks/toast';

export function* createLogisticCenterRegion(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { regionId, logisticCenterCnpj, dispatch, redispatch } = action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($logisticCenterRegion: LogisticCenterRegionInput!) {
          createLogisticCenterRegion(
            logisticCenterRegion: $logisticCenterRegion
          ) {
            id
          }
        }
      `,
      variables: {
        logisticCenterRegion: {
          regionId,
          logisticCenterCnpj,
          eta: {
            dispatch: Number(dispatch),
            redispatch: Number(redispatch),
          },
        },
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Região do centro logístico criada com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* updateLogisticCenterRegion(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { id, dispatch, redispatch } = action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation (
          $id: String!
          $logisticCenterRegion: LogisticCenterRegionUpdateInput!
        ) {
          updateLogisticCenterRegion(
            id: $id
            logisticCenterRegion: $logisticCenterRegion
          ) {
            ok
          }
        }
      `,
      variables: {
        id,
        logisticCenterRegion: {
          eta: {
            dispatch,
            redispatch,
          },
        },
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Região do centro logístico alterada com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* removeLogisticCenterRegion(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { id } = action;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($id: String!) {
          removeLogisticCenterRegion(id: $id) {
            ok
          }
        }
      `,
      variables: {
        id,
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Região do centro logístico removida com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* fetchLogisticCenterRegions(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    yield put({ type: Types.LOAD_QUERY, load: true });

    const { cnpj, cursor, filter } = action;

    const response = yield call(cli.query, {
      query: gql`
        query ($first: Int, $after: String, $cnpj: String!, $filter: LogisticCenterRegionFilterInput) {
          logisticCenterRegions(first: $first, after: $after, cnpj: $cnpj, filter: $filter) {
            edges {
              node {
                id
                region {
                  id
                  key
                  description
                }
                eta {
                  dispatch
                  redispatch
                }
                logisticCenter {
                  cnpj
                  name
                }
              }
            }
            pageInfo {
              hasNextPage
              after
              before
            }
          }
        }
      `,
      variables: {
        cnpj,
        first: 1000,
        after: cursor,
        filter
      },
    });

    const results = response.data.logisticCenterRegions.edges;

    yield put({ type: Types.SET_RESULTS, results });
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
  } finally {
    yield put({ type: Types.LOAD_QUERY, load: false });
  }
}
