export const Types = {
  ADD_DRIVER_QUEUE: 'transportQueue/ADD_DRIVER_QUEUE',
  REMOVE_DRIVER_QUEUE: 'transportQueue/REMOVE_DRIVER_QUEUE',
  SET_RESULTS: 'transportQueue/SET_RESULTS',
  NEXT_RESULTS: 'transportQueue/NEXT_RESULTS',
  SET_PAGE_INFO: 'transportQueue/SET_PAGE_INFO',
  SET_ERROR_QUERY: 'transportQueue/SET_ERROR_QUERY',
  SET_ERROR_MUTATION: 'transportQueue/SET_ERROR_MUTATION',
  SET_SUCCESS_QUERY: 'transportQueue/SET_SUCCESS_QUERY',
  SET_SUCCESS_MUTATION: 'transportQueue/SET_SUCCESS_MUTATION',
  LOAD_QUERY: 'transportQueue/LOAD_QUERY',
  LOAD_MUTATION: 'transportQueue/LOAD_MUTATION',
  FETCH_TRANSPORT_QUEUE: 'transportQueue/FETCH_TRANSPORT_QUEUE',
  RESET_STATE: 'transportQueue/RESET_STATE',
  USER_LOGOUT: 'USER_LOGOUT',
};

const initialState = {
  results: [],
  pageInfo: {},
  load_query: false,
  load_mutation: false,
  error_query: {},
  error_mutation: {},
  success_query: {},
  success_mutation: {},
};

export default function transportQueue(state = initialState, action) {
  switch (action.type) {
    case Types.SET_RESULTS:
      return { ...state, results: action.results };
    case Types.NEXT_RESULTS:
      return { ...state, results: [...state.results, ...action.results] };
    case Types.SET_PAGE_INFO:
      return { ...state, pageInfo: action.pageInfo };
    case Types.SET_ERROR_QUERY:
      return { ...state, error_query: action.error };
    case Types.SET_ERROR_MUTATION:
      return { ...state, error_mutation: action.error };
    case Types.SET_SUCCESS_QUERY:
      return { ...state, success_query: action.success };
    case Types.SET_SUCCESS_MUTATION:
      return { ...state, success_mutation: action.success };
    case Types.LOAD_QUERY:
      return { ...state, load_query: action.load };
    case Types.LOAD_MUTATION:
      return { ...state, load_mutation: action.load };
    case Types.RESET_STATE:
    case Types.USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export function addDriverQueue({ data, success, error }) {
  return {
    type: Types.ADD_DRIVER_QUEUE,
    data,
    success,
    error,
  };
}

export function removeDriverQueue({ id, success, error }) {
  return {
    type: Types.REMOVE_DRIVER_QUEUE,
    id,
    success,
    error,
  };
}

export function fetchTransportQueue() {
  return {
    type: Types.FETCH_TRANSPORT_QUEUE,
  };
}

export function resetState() {
  return {
    type: Types.RESET_STATE,
  };
}
