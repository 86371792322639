import { TableForm } from '@components';
import { CnpjCpf } from '@utils';
import { forwardRef } from 'react';

const ShipperDriverList = forwardRef((props, ref) => {

  return (
    <TableForm
      ref={ref}
      {...props}
      headers={[
        { label: 'CPF', getValue: item => CnpjCpf.maskAnonymizedCPF(item.node.driver.cpf) },
        {
          label: 'Nome',
          getValue: item => `${item.node.driver.firstName} ${item.node.driver.lastName}`,
        },
        { label: 'Placa', getValue: item => item.node.plate },
        { label: 'Telefone', getValue: item => item.node.phone }
      ]}
    />
  );
});

ShipperDriverList.displayName = 'ShipperDriverList';

export default ShipperDriverList;
