import React, { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { FormCreate, TableForm, Button } from '@components';
import { fetchAvailableCities } from '@redux/ducks/city';
import { useNavigate } from 'react-router-dom';

const Form = FormCreate('RegionCitiesForm');

const CityForm = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let params = { state: '' };

  const { region, states, results, pageInfo, load_query } = props;

  const onSubmit = data => {
    params = data;
    onSearch();
  };

  const onReset = () => {
    onSubmit({
      state: '',
      name: '',
    });
  };

  const onSearch = cursor => {
    if (params.state && params.state.length > 0) {
      dispatch(
        fetchAvailableCities({
          cursor,
          params: params,
        })
      );
    }
  };

  const next = () => {
    const { pageInfo } = props;
    onSearch(pageInfo.cursor);
  };

  const onAddCity = item => {
    props.onAddCity(item.node);
  };

  const onCancel = () => {
    navigate('/regions');
  };

  useEffect(() => {
    onSearch();
  }, []);

  return (
    <React.Fragment>
      <Form
        {...props}
        header={{
          icon: 'point',
          title: region.description,
        }}
        fields={[
          {
            name: 'state',
            component: 'select',
            type: 'select',
            label: 'Estado:',
            inputProps: {
              options: (() => {
                const options = states.map(state => {
                  const [key, text] = Object.entries(state)[0];
                  return {
                    key,
                    text,
                    value: key,
                  };
                });
                return [{ key: '', text: 'Selecione', value: '' }, ...options];
              })(),
            },
          },
          {
            name: 'name',
            component: 'input',
            type: 'text',
            label: 'Cidade:',
            inputProps: {
              placeholder: 'Buscar ...',
            },
          },
        ]}
        lbSubmit="Listar"
        lbCancel="Voltar"
        ribbon={false}
        cancel={true}
        onSubmit={onSubmit}
        onReset={onReset}
        onCancel={onCancel}
      />
      <TableForm
        headers={[
          {
            label: 'UF',
            getValue: item => {
              const city = item.node || item;
              return city.state;
            },
          },
          {
            label: 'Cidade',
            getValue: item => {
              const city = item.node || item;
              return city.name;
            },
          },
          {
            label: 'Ações',
            props: {
              align: 'center',
              width: '90px',
            },
            getValue: item => (
              <div className="flex gap-2 justify-end">
                <Button compact onClick={() => onAddCity(item)}>
                  Adicionar
                </Button>
              </div>
            ),
          },
        ]}
        source={results}
        hasNext={pageInfo.hasNextPage}
        load={load_query}
        next={next}
        empty={'Nenhuma cidade disponível!'}
      />
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  const { states, results, pageInfo, load_query } = state.city;

  return {
    states,
    results,
    pageInfo,
    load_query,
  };
}

export default connect(mapStateToProps)(CityForm);
