import { useRef } from 'react';
import { Link } from 'react-router-dom';

import bgLogin from '@assets/img/bg-login.jpg';
import logo from '@assets/img/shipy.svg';

import ForgotForm from './components/ForgotForm';
import { Toast } from '@components';

import { sendEmail } from '@redux/ducks/changePassword';
import { useDispatch } from 'react-redux';
import { useState } from 'react';

export default function ForgotPassword() {
  const dispatch = useDispatch();
  const toastRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false)

  async function handleSubmit({ email }) {
    setIsLoading(true)
    dispatch(
      sendEmail({
        data: { email },
        success: message => {
          toastRef.current.show({
            message,
            type: 'success',
          });
          setIsLoading(false);
        },
        error: ({ message }) => {
          const filteredMessage = message.replace('GraphQL error: ', '');
          toastRef.current.show({
            message: filteredMessage,
            type: 'error',
          });
          setIsLoading(false);
        },
      })
    );
  }

  return (
    <div className="flex h-screen">
      <div className="flex flex-1 flex-col justify-center py-12 px-4">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img className="mx-auto lg:ml-0 h-20" src={logo} alt="Logo" />
            <h2 className="mt-8 text-2xl font-semibold text-center lg:text-left text-[#5C5C5B ]">
              Esqueceu sua senha?
            </h2>
            <p className="mt-8 px-6 lg:px-0 text-base text-center lg:text-left text-gray-600">
              Informe seu e-mail ou login e enviaremos instruções para você
              criar sua senha.
            </p>
          </div>

          <div className="mt-8">
            <ForgotForm submitFunction={handleSubmit} load_mutation={isLoading} />
          </div>

          <div className="flex items-center flex-col mt-8">
            <Link to="/">
              <span className="text-sm font-medium text-blue-500 hover:text-blue-400">
                &larr; Voltar para o login
              </span>
            </Link>
          </div>
        </div>
      </div>

      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={bgLogin}
          alt=""
        />
      </div>
      <Toast ref={toastRef} />
    </div>
  );
}
