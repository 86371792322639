import moment from 'moment';

export const Types = {
  SET_RESULTS: 'invoice/SET_RESULTS',
  NEXT_RESULTS: 'invoice/NEXT_RESULTS',
  SET_DATA_OBJECT: 'invoice/SET_DATA_OBJECT',
  SET_PROP_DATA_OBJECT: 'invoice/SET_PROP_DATA_OBJECT',
  SET_PAGE_INFO: 'invoice/SET_PAGE_INFO',
  SET_ERROR_QUERY: 'invoice/SET_ERROR_QUERY',
  SET_ERROR_MUTATION: 'invoice/SET_ERROR_MUTATION',
  SET_SUCCESS_QUERY: 'invoice/SET_SUCCESS_QUERY',
  SET_SUCCESS_MUTATION: 'invoice/SET_SUCCESS_MUTATION',
  LOAD_QUERY: 'invoice/LOAD_QUERY',
  LOAD_MUTATION: 'invoice/LOAD_MUTATION',
  FETCH_INVOICES: 'invoice/FETCH_INVOICES',
  FETCH_OVERDUE_INVOICES: 'invoice/FETCH_OVERDUE_INVOICES',
  IS_FETCH_INVOICE: 'invoice/IS_FETCH_INVOICE',
  FETCH_INVOICE: 'invoice/FETCH_INVOICE',
  EDIT_EXPENSE_DEADLINE: 'invoice/EDIT_EXPENSE_DEADLINE',
  GET_INVOICE: 'invoice/GET_INVOICE',
  ADD_LOG: 'invoice/ADD_LOG',
  ADD_LOG_BACKGROUND: 'invoice/ADD_LOG_BACKGROUND',
  REMOVE_RETURN: 'invoice/REMOVE_RETURN',
  REMOVE_DELIVERY: 'invoice/REMOVE_DELIVERY',
  UPDATE_INVOICE: 'invoice/UPDATE_INVOICE',
  RESET_STATE: 'invoice/RESET_STATE',
  USER_LOGOUT: 'USER_LOGOUT',
};

const initialState = {
  results: [],
  dataObject: {},
  pageInfo: {},
  isFetchInvoice: false,
  load_query: false,
  load_mutation: false,
  error_query: {},
  error_mutation: {},
  success_query: {},
  success_mutation: {},
};

export default function invoice(state = initialState, action) {
  switch (action.type) {
    case Types.SET_RESULTS:
      return { ...state, results: action.results };
    case Types.NEXT_RESULTS:
      return { ...state, results: [...state.results, ...action.results] };
    case Types.SET_DATA_OBJECT:
      return { ...state, dataObject: action.dataObject };
    case Types.SET_PROP_DATA_OBJECT:
      return {
        ...state,
        dataObject: { ...state.dataObject, ...action.data },
        error_mutation: {},
        success_mutation: {},
      };
    case Types.REMOVE_DELIVERY: {
      return {
        ...state,
        dataObject: {
          ...state.dataObject,
          invoice: {
            ...state.dataObject.invoice,
            deliveries: state.dataObject.invoice.deliveries.filter(
              delivery => delivery.id !== action.id
            ),
          },
        },
        error_mutation: {},
        success_mutation: {},
      };
    }
    case Types.SET_PAGE_INFO:
      return { ...state, pageInfo: action.pageInfo };
    case Types.SET_ERROR_QUERY:
      return { ...state, error_query: action.error };
    case Types.SET_ERROR_MUTATION:
      return { ...state, error_mutation: action.error };
    case Types.SET_SUCCESS_QUERY:
      return { ...state, success_query: action.success };
    case Types.SET_SUCCESS_MUTATION:
      return { ...state, success_mutation: action.success };
    case Types.IS_FETCH_INVOICE:
      return { ...state, isFetchInvoice: action.load };
    case Types.LOAD_QUERY:
      return { ...state, load_query: action.load };
    case Types.LOAD_MUTATION:
      return { ...state, load_mutation: action.load };
    case Types.RESET_STATE:
      return initialState;
    case Types.USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export function setDataObject(dataObject) {
  return {
    type: Types.SET_DATA_OBJECT,
    dataObject,
  };
}

export function setPropDataObject(data) {
  return {
    type: Types.SET_PROP_DATA_OBJECT,
    data,
  };
}

export function fetchInvoice(value) {
  value = String(value);
  let filter = {
    key: value,
  };
  if (value && value.length < 44) {
    filter = {
      invoiceNo: value,
    };
  }

  return {
    type: Types.FETCH_INVOICE,
    filter,
  };
}

export function editInvoiceExpenseDeadlineDate({ data, success, error }) {
  return {
    type: Types.EDIT_EXPENSE_DEADLINE,
    data,
    success,
    error,
  };
}

export function getInvoice({ search, success, error }) {
  search = String(search);
  let filter = {
    key: search,
  };
  if (search && search.length < 44) {
    filter = {
      invoiceNo: search,
    };
  }

  return {
    type: Types.GET_INVOICE,
    filter,
    success,
    error,
  };
}

export const addInvoiceLog = (key, message) => {
  return {
    type: Types.ADD_LOG_BACKGROUND,
    message,
    key,
  };
};

export function addLog(message, success, error) {
  return {
    type: Types.ADD_LOG,
    message,
    success,
    error,
  };
}

function _dateFrom(value) {
  return moment(value).format('YYYY-MM-DD') + 'T00:00:00.000Z';
}

function _dateTo(value) {
  return moment(value).format('YYYY-MM-DD') + 'T23:59:59.999Z';
}

function _getDefaultFilter(filter = {}, params) {
  if (params) {
    if (params.dispatcher) {
      filter = {
        ...filter,
        OR: [
          {
            redispatcherId: params.dispatcher,
          },
          {
            dispatcherId: params.dispatcher,
            redispatcherId_NOT_EQUALS: null,
            OR: [
              { shippingOrderId: null },
              { shippingOrder_WITH: { deliveredAt: null } },
            ],
          },
          {
            dispatcherId: params.dispatcher,
            redispatcherId: null,
          },
        ],
      };
    }

    if (params.plate) {
      filter = {
        ...filter,
        driverPlate: params.plate,
      };
    }

    if (params.customer) {
      filter = {
        ...filter,
        customer_WITH: {
          OR: [
            {
              code: params.customer,
            },
            {
              commercialName_LIKE: params.customer,
            },
            {
              corporateName_LIKE: params.customer,
            },
          ],
        },
      };
    }
  }

  if (params.emittedFrom || params.emittedTo) {
    if (params.emittedFrom) {
      const emittedFrom = _dateFrom(params.emittedFrom);
      filter = {
        ...filter,
        emittedAt_GREATER_EQUALS_THAN: emittedFrom,
      };
    }

    if (params.emittedTo) {
      const emittedTo = _dateTo(params.emittedTo);
      filter = {
        ...filter,
        emittedAt_LOWER_EQUALS_THAN: emittedTo,
      };
    }
  }

  return filter;
}

export function fetchInvoices(args = {}) {
  const { filter } = args;

  switch (filter) {
    case 'pending':
      return fetchPending(args);
    case 'expiring':
      return fetchExpiring(args);
    case 'overdue':
      return fetchOverdue(args);
    case 'occurrence':
      return fetchOccurrence(args);
    case 'delivered':
      return fetchDelivered(args);
    case 'returned':
      return fetchReturned(args);
    default:
      return fetchEmitted(args);
  }
}

export function fetchEmitted({ cursor, params }) {
  let filter = _getDefaultFilter(
    {
      reversalId: null,
      activities: {
        emittedAt_NOT_EQUALS: null,
      },
    },
    params
  );

  if (params.dispatcherName) {
    const fobCompany = params.dispatcherName.toUpperCase();

    filter = {
      ...filter,
      OR: [
        ...filter.OR,
        {
          externalDispatcher_WITH: {
            corporateName_IN_JSON_CONTAINING: fobCompany,
          },
        },
        {
          externalDispatcher_WITH: {
            commercialName_IN_JSON_CONTAINING: fobCompany,
          },
        },
        {
          externalRedispatcher_WITH: {
            corporateName_IN_JSON_CONTAINING: fobCompany,
          },
        },
        {
          externalRedispatcher_WITH: {
            commercialName_IN_JSON_CONTAINING: fobCompany,
          },
        },
      ],
    };
  }

  return {
    type: Types.FETCH_INVOICES,
    cursor,
    filter,
  };
}

export function fetchPending({ cursor, params }) {
  let filter = _getDefaultFilter(
    {
      reversalId: null,
      returnId: null,
      isDelivered: false,
    },
    params
  );

  if (params.dispatcherName) {
    const fobCompany = params.dispatcherName.toUpperCase();

    filter = {
      ...filter,
      OR: [
        ...filter.OR,
        {
          externalDispatcher_WITH: {
            corporateName_IN_JSON_CONTAINING: fobCompany,
          },
        },
        {
          externalDispatcher_WITH: {
            commercialName_IN_JSON_CONTAINING: fobCompany,
          },
        },
        {
          externalRedispatcher_WITH: {
            corporateName_IN_JSON_CONTAINING: fobCompany,
          },
        },
        {
          externalRedispatcher_WITH: {
            commercialName_IN_JSON_CONTAINING: fobCompany,
          },
        },
      ],
    };
  }

  return {
    type: Types.FETCH_INVOICES,
    cursor,
    filter,
    queryType: 'PENDING',
  };
}

export function fetchExpiring({ cursor, params }) {
  let filter = _getDefaultFilter(
    {
      predictedTo_GREATER_EQUALS_THAN: new Date().toISOString(),
      isDelivered: false,
    },
    params
  );

  if (params.dispatcherName) {
    const fobCompany = params.dispatcherName.toUpperCase();

    filter = {
      ...filter,
      OR: [
        ...filter.OR,
        {
          externalDispatcher_WITH: {
            corporateName_IN_JSON_CONTAINING: fobCompany,
          },
        },
        {
          externalDispatcher_WITH: {
            commercialName_IN_JSON_CONTAINING: fobCompany,
          },
        },
        {
          externalRedispatcher_WITH: {
            corporateName_IN_JSON_CONTAINING: fobCompany,
          },
        },
        {
          externalRedispatcher_WITH: {
            commercialName_IN_JSON_CONTAINING: fobCompany,
          },
        },
      ],
    };
  }

  return {
    type: Types.FETCH_INVOICES,
    cursor,
    filter,
    queryType: 'EXPIRING',
  };
}

export function fetchOverdue({ cursor, params }) {
  let filter = _getDefaultFilter(
    {
      reversalId: null,
      predictedTo_LOWER_THAN: new Date().toISOString(),
      returnId: null,
      isDelivered: false,
    },
    params
  );

  if (params.dispatcherName) {
    const fobCompany = params.dispatcherName.toUpperCase();

    filter = {
      ...filter,
      OR: [
        ...filter.OR,
        {
          externalDispatcher_WITH: {
            corporateName_IN_JSON_CONTAINING: fobCompany,
          },
        },
        {
          externalDispatcher_WITH: {
            commercialName_IN_JSON_CONTAINING: fobCompany,
          },
        },
        {
          externalRedispatcher_WITH: {
            corporateName_IN_JSON_CONTAINING: fobCompany,
          },
        },
        {
          externalRedispatcher_WITH: {
            commercialName_IN_JSON_CONTAINING: fobCompany,
          },
        },
      ],
    };
  }

  return {
    type: Types.FETCH_OVERDUE_INVOICES,
    cursor,
    filter,
    descending: false,
    queryType: 'OVERDUE',
  };
}

export function fetchOccurrence({ cursor, params }) {
  let incidents_WITH = {
    end: null,
  };

  if (params && params.startIncidentFrom && params.startIncidentTo) {
    const startIncidentFrom = _dateFrom(params.startIncidentFrom);
    const startIncidentTo = _dateTo(params.startIncidentTo);

    incidents_WITH = {
      ...incidents_WITH,
      start_GREATER_EQUALS_THAN: startIncidentFrom,
      start_LOWER_EQUALS_THAN: startIncidentTo,
    };
  }

  if (params && params.purposeIncident) {
    incidents_WITH = {
      ...incidents_WITH,
      type_WITH: {
        purpose: params.purposeIncident,
      },
    };
  }

  let filter = _getDefaultFilter(
    {
      incidents_WITH,
    },
    params
  );

  if (params.dispatcherName) {
    const fobCompany = params.dispatcherName.toUpperCase();

    filter = {
      ...filter,
      OR: [
        ...filter.OR,
        {
          externalDispatcher_WITH: {
            corporateName_IN_JSON_CONTAINING: fobCompany,
          },
        },
        {
          externalDispatcher_WITH: {
            commercialName_IN_JSON_CONTAINING: fobCompany,
          },
        },
        {
          externalRedispatcher_WITH: {
            corporateName_IN_JSON_CONTAINING: fobCompany,
          },
        },
        {
          externalRedispatcher_WITH: {
            commercialName_IN_JSON_CONTAINING: fobCompany,
          },
        },
      ],
    };
  }

  return {
    type: Types.FETCH_INVOICES,
    cursor,
    filter,
    queryType: 'INCIDENTS',
  };
}

export function fetchReturned({ cursor, params }) {
  let return_WITH = {};
  if (params && params.dateReturnFrom && params.dateReturnTo) {
    const dateReturnFrom = _dateFrom(params.dateReturnFrom);
    const dateReturnTo = _dateTo(params.dateReturnTo);

    return_WITH = {
      ...return_WITH,
      date_GREATER_EQUALS_THAN: dateReturnFrom,
      date_LOWER_EQUALS_THAN: dateReturnTo,
    };
  }

  let filter = _getDefaultFilter(
    {
      reversalId: null,
      return_WITH,
      returnId_NOT_EQUALS: null,
    },
    params
  );

  if (params.dispatcherName) {
    const fobCompany = params.dispatcherName.toUpperCase();

    filter = {
      ...filter,
      OR: [
        ...filter.OR,
        {
          externalDispatcher_WITH: {
            corporateName_IN_JSON_CONTAINING: fobCompany,
          },
        },
        {
          externalDispatcher_WITH: {
            commercialName_IN_JSON_CONTAINING: fobCompany,
          },
        },
        {
          externalRedispatcher_WITH: {
            corporateName_IN_JSON_CONTAINING: fobCompany,
          },
        },
        {
          externalRedispatcher_WITH: {
            commercialName_IN_JSON_CONTAINING: fobCompany,
          },
        },
      ],
    };
  }

  return {
    type: Types.FETCH_INVOICES,
    cursor,
    filter,
    queryType: 'RETURNS',
  };
}

export function fetchDelivered({ cursor, params }) {
  let deliveries_WITH = {};
  if (params && params.dateDeliveryFrom && params.dateDeliveryTo) {
    const dateDeliveryFrom = _dateFrom(params.dateDeliveryFrom);
    const dateDeliveryTo = _dateTo(params.dateDeliveryTo);

    deliveries_WITH = {
      ...deliveries_WITH,
      date_GREATER_EQUALS_THAN: dateDeliveryFrom,
      date_LOWER_EQUALS_THAN: dateDeliveryTo,
    };
  }

  let filter = _getDefaultFilter(
    {
      reversalId: null,
      deliveries_WITH,
    },
    params
  );

  if (params.dispatcherName) {
    const fobCompany = params.dispatcherName.toUpperCase();

    filter = {
      ...filter,
      OR: [
        ...filter.OR,
        {
          externalDispatcher_WITH: {
            corporateName_IN_JSON_CONTAINING: fobCompany,
          },
        },
        {
          externalDispatcher_WITH: {
            commercialName_IN_JSON_CONTAINING: fobCompany,
          },
        },
        {
          externalRedispatcher_WITH: {
            corporateName_IN_JSON_CONTAINING: fobCompany,
          },
        },
        {
          externalRedispatcher_WITH: {
            commercialName_IN_JSON_CONTAINING: fobCompany,
          },
        },
      ],
    };
  }

  return {
    type: Types.FETCH_INVOICES,
    cursor,
    filter,
    queryType: 'DELIVERIES',
  };
}

export function removeReturn({ data, success, error }) {
  return {
    type: Types.REMOVE_RETURN,
    data,
    success,
    error,
  };
}

export function saveAgreedTo({ data, success, error }) {
  return {
    type: Types.UPDATE_INVOICE,
    data,
    success,
    error,
  };
}

export function resetState() {
  return {
    type: Types.RESET_STATE,
  };
}
