export const Types = {
  CREATE_REDELIVERY: 'redelivery/CREATE_REDELIVERY',
  SAVE_REDELIVERY: 'redelivery/SAVE_REDELIVERY',
  LOAD_QUERY: 'redelivery/LOAD_QUERY',
  LOAD_MUTATION: 'redelivery/LOAD_MUTATION',
  SET_ERROR_MUTATION: 'redelivery/SET_ERROR_MUTATION',
  SET_SUCCESS_MUTATION: 'redelivery/SET_SUCCESS_MUTATION',
  SET_RESULTS: 'redelivery/SET_RESULTS',
  NEXT_RESULTS: 'redelivery/NEXT_RESULTS',
  SET_PAGE_INFO: 'redeliverySET_PAGE_INFO',
  GET_REDELIVERIES_HANDLE: 'redelivery/GET_REDELIVERIES_HANDLE',
  SET_PREDICTION: 'redelivery/SET_PREDICTION',
  GET_PREDICTION_HANDLE: 'redelivery/GET_PREDICTION_HANDLE',
  RESET_STATE: 'redelivery/RESET_STATE',
};

const initialState = {
  redeliveries: [],
  load_query: false,
  prediction: new Date(),
  pageInfo: {
    first: 50,
    after: null,
    before: null,
  },
  args: {
    dispatcher: '',
    customer: '',
    invoice: '',
  },
  error_mutation: {},
  success_mutation: {},
};

export default function redelivery(state = initialState, action) {
  switch (action.type) {
    case Types.SET_RESULTS:
      return { ...state, redeliveries: action.results };
    case Types.NEXT_RESULTS:
      return {
        ...state,
        redeliveries: [...state.redeliveries, ...action.results],
      };
    case Types.SET_PAGE_INFO:
      return { ...state, pageInfo: action.pageInfo };
    case Types.SET_PREDICTION:
      return { ...state, prediction: action.prediction };
    case Types.LOAD_QUERY:
      return { ...state, load_query: action.load };
    case Types.SET_SUCCESS_MUTATION:
      return { ...state, success_mutation: action.success };
    case Types.SET_ERROR_MUTATION:
      return { ...state, error_mutation: action.error };
    case Types.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export const saveRedelivery = (redelivery, id, key) => {
  return {
    type: Types.SAVE_REDELIVERY,
    redelivery,
    id,
    key,
  };
};

export const handleGetInitialDeliveries = (
  pageInfo = initialState.pageInfo
) => {
  const filter = {};

  return {
    type: Types.GET_REDELIVERIES_HANDLE,
    filter,
    pageInfo,
  };
};

export const getDeliveryPrediction = key => {
  return {
    type: Types.GET_PREDICTION_HANDLE,
    key,
  };
};

export const getRedeliveries = (args, pageInfo = initialState.pageInfo) => {
  let filter = {
    invoice_WITH: {
      AND: [],
    },
  };

  if (args.dispatcher) {
    filter = {
      ...filter,
      shipperId: args.dispatcher,
    };
  }

  if (args.customer) {
    filter.invoice_WITH.AND.push({
      customer_WITH: {
        OR: [
          {
            corporateName_LIKE: args.customer,
          },
          {
            commercialName_LIKE: args.customer,
          },
        ],
      },
    });
  }

  if (args.invoice) {
    filter.invoice_WITH.AND.push({ invoiceNo: args.invoice });
  }

  if (filter.invoice_WITH.AND.length === 0) {
    delete filter.invoice_WITH.AND;
  }

  if (!args.invoice && !args.customer && !args.dispatcher) {
    filter = {};
  }

  return {
    type: Types.GET_REDELIVERIES_HANDLE,
    filter,
    pageInfo,
  };
};

export function resetState() {
  return {
    type: Types.RESET_STATE,
  };
}
