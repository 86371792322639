const validate = values => {
  const errors = {};
  if (!values.key) {
    errors.description = 'Favor informar a região! ';
  }
  if (!values.description) {
    errors.description = 'Favor informar a descrição da região! ';
  }
  return errors;
};

export default validate;
