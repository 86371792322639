import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  fetchLogisticCenterShippers,
  saveLogisticCenterShipper,
  removeLogisticCenterShipper,
} from '@redux/ducks/logisticCenterShippers';
import { getShippers } from '@redux/ducks/shipper';
import LogisticCenterShippersForm from './components/LogisticCenterShippersForm';
import { Confirm, TableForm, Button } from '@components';
import { useNavigate, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';

const LogisticCenterShippers = ({
  results,
  pageInfo,
  load_query,
  load_mutation,
  fetchLogisticCenterShippers,
  getShippers,
  saveLogisticCenterShipper,
  removeLogisticCenterShipper,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const listRef = useRef(null);
  const formRef = useRef(null);
  const confirmRef = useRef(null);

  function initialize() {
    const search = listRef.current.getSearch();
    window.scroll(0, 0);
    listRef.current.closeForm();
    fetchLogisticCenterShippers({ logisticCenterRegion: params.id, search });
  }

  function handleSearch(search) {
    fetchLogisticCenterShippers({
      logisticCenterRegion: params.id,
      search,
    });
  }

  function handleSave(data, edit) {
    data.dispatch = 0;
    data.redispatch = +data.redispatch;

    return new Promise((resolve, reject) => {
      saveLogisticCenterShipper({
        data: { ...data, logisticCenterRegion: params.id },
        edit,
        success: () => {
          initialize();
          resolve();
        },
        error: error => {
          reject(error);
        },
      });
    });
  }

  async function handleEdit(logisticCenterShipper) {
    await listRef.current.openForm();
    const { id, shipper, eta } = logisticCenterShipper;

    setTimeout(() => {
      formRef?.current?.edit({
        id,
        idShipper: shipper.id,
        shipper: `${shipper.code ? '(' + shipper.code + ') ' : ''}${
          shipper.company.corporateName
        } - ${shipper.company.commercialName || ''}`,
        redispatch: eta.redispatch,
      });
    }, 200);
  }

  function handleRemove(logisticCenterShipper) {
    const { id } = logisticCenterShipper;
    confirmRef.current.open(
      'Deseja realmente remover o prazo por transportadora selecionado?',
      () => {
        removeLogisticCenterShipper({
          id,
          success: () => {
            initialize();
          },
        });
      }
    );
  }

  function handleBack() {
    navigate(`/logistic-centers/${params.cnpj}/regions`);
  }

  useEffect(() => {
    initialize();
  }, []);

  return (
    <>
      <TableForm
        formHeader={{
          title: 'Transportadoras da região',
          subtitle:
            'Gerencie e visualize os prazos de entrega por transportadoras',
        }}
        titleModal="Cadastro de transportadoras da região"
        source={results}
        hasNext={pageInfo.hasNextPage}
        load={load_query}
        onSearch={handleSearch}
        ref={listRef}
        onNew={() => listRef.current.openForm()}
        headers={[
          {
            label: 'Transportadora',
            getValue: item =>
              `${item.shipper.code ? '(' + item.shipper.code + ') ' : ''}${
                item.shipper.company.corporateName
              } - ${item.shipper.company.commercialName || ''}`,
          },
          { label: 'Redespacho', getValue: item => item.eta.redispatch },
          {
            label: 'Ações',
            props: {
              align: 'center',
              width: '170px',
            },
            getValue: item => (
              <div className="flex gap-2 justify-end">
                <Button compact key={item.id} onClick={() => handleEdit(item)}>
                  Editar
                </Button>
                <Button
                  compact
                  variant="negative"
                  onClick={() => handleRemove(item)}
                >
                  Remover
                </Button>
              </div>
            ),
          },
        ]}
        keyRow={item => item.id}
        back={true}
        lbBack="Regiões"
        onBack={handleBack}
      >
        <LogisticCenterShippersForm
          getShippers={getShippers}
          formRef={formRef}
          onSubmit={handleSave}
          loadMutation={load_mutation}
        />
      </TableForm>
      <Confirm ref={confirmRef} />
    </>
  );
};

function mapStateToProps(state) {
  const { results, pageInfo, load_query, load_mutation } =
    state.logisticCenterShippers;
  return {
    results,
    pageInfo,
    load_query,
    load_mutation,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchLogisticCenterShippers,
      getShippers,
      saveLogisticCenterShipper,
      removeLogisticCenterShipper,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LogisticCenterShippers);
