import { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import _ from 'lodash';
import { SearchModal, DateRangePicker, Tabs, Input, Button } from '@components';
import InvoiceSearchForm from '@pages/invoices/components/invoiceSearch';
import { MailSender } from '@pages';
import ShipperIncidentForm from '../ShipperIncident';
import { setDataObject as setInvoice } from '@redux/ducks/invoice';
import { getShippers } from '@redux/ducks/shipper';
import { permissions } from '@config/permission';
import ShippingRelationship from '../ShippingReleationship';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { getExternalShippers } from '../../redux/ducks/shipper';
import { toast } from 'react-toastify';
import { XCircleIcon } from '@heroicons/react/24/outline';

const Invoices = ({ dataObject, children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let location = useLocation();

  const [isMailSenderModalOpen, setIsMailSenderModalOpen] = useState(false);
  const [mailSenderModalData, setMailSenderModalData] = useState({});
  const [shipperIncidentModalOpen, setShipperIncidentModalOpen] =
    useState(false);
  const [shipperIncidentData, setShipperIncidentData] = useState();
  const [shipperToggleQuery, setShipperToggleQuery] = useState(false);
  const [state, setState] = useState({
    invoiceNo: '',
    selectedInvoices: [],
    shippingRelationshipInvoices: [],
    printing: false,
    showInvoiceSearch: false,
    startIncidentFrom: '',
    startIncidentTo: '',
    purposeIncident: '',
    dateDeliveryFrom: '',
    dateDeliveryTo: '',
    dateReturnFrom: '',
    dateReturnTo: '',
    company: '',
  });
  const { pathname } = location;
  const overview = dataObject || {};
  const { selectedInvoices } = state;

  const sendMail = () => {
    const { dispatcherMail } = state;
    if (!dispatcherMail) {
      alert('É preciso cadastrar um transportadora com e-mail cadastrado');
    } else {
      setIsMailSenderModalOpen(true);
      setMailSenderModalData({
        selectedInvoices: state.selectedInvoices,
        mail: dispatcherMail,
      });
    }
  };

  const isOverdue = () => {
    const { pathname } = location;
    return pathname.indexOf('overdue') !== -1;
  };

  const invoiceSelected = ({ id }) => {
    return state.selectedInvoices.find(item => item.id === id);
  };

  const invoiceSelectedToShippingRelationship = ({ id }) => {
    return state.shippingRelationshipInvoices.find(item => item.node.id === id);
  };

  function selectInvoice({ node }) {
    if (invoiceSelected(node)) {
      setState(prev => ({
        ...prev,
        selectedInvoices: prev.selectedInvoices.filter(
          item => item.id !== node.id
        ),
      }));
    } else {
      setState(prev => ({
        ...prev,
        selectedInvoices: [...prev.selectedInvoices, node],
      }));
    }
  }

  function selectInvoiceToShippingRelationship(invoice) {
    if (invoiceSelectedToShippingRelationship(invoice.node)) {
      setState(prev => ({
        ...prev,
        shippingRelationshipInvoices: prev.shippingRelationshipInvoices.filter(
          item => item.node.id !== invoice.node.id
        ),
      }));
    } else {
      setState(prev => ({
        ...prev,
        shippingRelationshipInvoices: [
          ...prev.shippingRelationshipInvoices,
          invoice,
        ],
      }));
    }
  }

  const aggreg = results => {
    const cities = groupBy(results, 'node.customer.city.name');
    const invoices = results.map(item => item.node.invoiceNo);
    const cityItems = Object.keys(cities)
      .map(city => {
        return {
          [city]: cities[city].map(item => item.node.productInfo.items),
        };
      })
      .map(city => {
        let items = Object.values(city).flat(Infinity);
        return {
          [Object.keys(city)[0]]: groupBy(items, 'sku'),
        };
      });

    const cityItemsSummary = cityItems.map(item => {
      const city = Object.keys(item)[0];
      const skus = Object.values(item);
      let obj;
      Object.values(skus).map(sku => {
        Object.keys(sku).map(item => {
          obj = _.merge(obj, {
            [city]: {
              items: [
                {
                  [item]: {
                    sku: sku[item][0].sku,
                    description: sku[item][0].name,
                    quantity: sum(sku[item], 'quantity'),
                    weight: sum(sku[item], 'weight'),
                    cubage: sum(sku[item], 'cubage'),
                    unity: sku[item][0].unity,
                    barCode: sku[item][0].barCode,
                  },
                },
              ],
            },
          });
        });
      });
      return obj;
    });

    const summaryByCity = cityItemsSummary
      .map(f => {
        return Object.values(f)[0].items.flat(Infinity);
      })
      .flat(Infinity);

    let totalAggregation = {};
    summaryByCity.map(item => {
      Object.keys(item).forEach(sku => {
        if (totalAggregation[sku]) {
          totalAggregation = {
            ...totalAggregation,
            [sku]: {
              ...totalAggregation[sku],
              ...item[sku],
              quantity: totalAggregation[sku].quantity + item[sku].quantity,
              cubage: totalAggregation[sku].cubage + item[sku].cubage,
              weight: totalAggregation[sku].weight + item[sku].weight,
            },
          };
        } else {
          totalAggregation = {
            ...totalAggregation,
            [sku]: item[sku],
          };
        }
      });
    });

    const unitGroup = groupBy(Object.values(totalAggregation), 'unity');

    let totalizer = {};

    for (let unit of Object.keys(unitGroup)) {
      totalizer[unit] = sum(unitGroup[unit], 'quantity');
    }

    return {
      totalizer,
      invoices,
      cityItemsSummary,
      totalAggregation: Object.values(totalAggregation),
    };
  };

  const sum = (arr, field) => {
    return arr.reduce((prev, next) => {
      return prev + next[field];
    }, 0);
  };

  const groupBy = (array, prop) => {
    let acumulator = {};

    array.map(item => {
      let internalAcumulator = _.get(item, prop);

      acumulator = {
        ...acumulator,
        [internalAcumulator]: acumulator[internalAcumulator]
          ? [...acumulator[internalAcumulator], item]
          : [item],
      };
      return null;
    });

    return acumulator;
  };

  const generateRelationshipShipping = () =>
    setState(oldState => ({
      ...oldState,
      printing: true,
    }));

  const renderShippingRelationShip = () => {
    const { cityItemsSummary, totalAggregation, invoices, totalizer } = aggreg(
      state.shippingRelationshipInvoices
    );

    return (
      <ShippingRelationship
        citySummary={cityItemsSummary}
        invoices={invoices}
        itemsAggregation={totalAggregation}
        totalizer={totalizer}
        open={state.printing}
        close={() =>
          setState(oldState => ({
            ...oldState,
            printing: false,
            shippingRelationshipInvoices: [],
          }))
        }
      />
    );
  };

  const renderDefaultFilter = pathname => {
    const { emittedFrom, emittedTo, dispatcherName, customer, plate } = state;

    return (
      <>
        <div className="flex sm:items-end gap-2 flex-col sm:flex-row">
          <div className="">
            <DateRangePicker
              placeholderFrom="Emissão de"
              placeholderTo="até"
              from={emittedFrom}
              to={emittedTo}
              onChangeFrom={emittedFrom => {
                setState(oldState => ({ ...oldState, emittedFrom }));
              }}
              onChangeTo={emittedTo => {
                setState(oldState => ({ ...oldState, emittedTo }));
              }}
            />
          </div>

          {permissions.invoice.dispatcherFilter() && (
            <div className="flex flex-col">
              <SearchModal
                isShipperQuery
                setShipperToggleQuery={setShipperToggleQuery}
                shipperToggleQuery={shipperToggleQuery}
                caption="Transportadora"
                value={dispatcherName || ''}
                keyExtractor={item => item.node?.id || item.cnpj}
                textExtractor={item =>
                  `${item.node?.company?.corporateName || item.corporateName
                  } - ${item.node?.company?.commercialName || item.commercialName
                  }`
                }
                onSearch={search => {
                  return new Promise((resolve, reject) => {
                    shipperToggleQuery
                      ? dispatch(
                          getExternalShippers({
                            search,
                            success: results => {
                              resolve(results);
                            },
                            error: error => {
                              reject(error);
                            },
                          })
                      )
                      : dispatch(
                          getShippers({
                            search,
                            success: results => {
                              resolve(results);
                            },
                            error: error => {
                              reject(error);
                            },
                          })
                      );
                  });
                }}
                onSelect={item => {
                  setState(oldState => ({
                    ...oldState,
                    dispatcher: item?.node?.id || item.cnpj,
                    dispatcherName:
                      item?.node?.company?.corporateName || item.corporateName,
                    dispatcherMail: item?.node?.company?.user?.email || '',
                    selectedInvoices: [],
                  }));
                }}
              />
            </div>
          )}

          <div className="flex flex-col">
            <Input
              type="text"
              caption="Clientes"
              value={customer || ''}
              placeholder="Nome ou código do Cliente"
              onChange={({ target }) =>
                setState(oldState => ({
                  ...oldState,
                  customer: target.value,
                }))
              }
            />
          </div>
          <div className="flex flex-col">
            <Input
              type="text"
              caption="Placa"
              value={plate || ''}
              name="plate"
              placeholder="Placa"
              onChange={({ target }) => {
                setState(oldState => ({
                  ...oldState,
                  [target.name]: target.value,
                }));
              }}
            />
          </div>
        </div>
        <div className="flex sm:items-end gap-2 flex-col sm:flex-row">
          <div className="flex flex-col">
            <label>&nbsp;</label>
            <Button
              onClick={() => {
                onSearch();
              }}
            >
              Consultar
            </Button>
          </div>

          <div className="flex flex-col">
            <label>&nbsp;</label>
            <Button
              disabled={!state.shippingRelationshipInvoices.length}
              onClick={() => {
                generateRelationshipShipping();
              }}
            >
              Gerar romaneio
            </Button>
          </div>
          <div className="flex flex-col">
            <label>&nbsp;</label>
            <Button
              onClick={() => {
                clearFilter();
              }}
            >
              Limpar
            </Button>
          </div>
          <div className="flex flex-col">
            <label>&nbsp;</label>
            <Button
              onClick={() =>
                setState(oldState => ({
                  ...oldState,
                  showInvoiceSearch: true,
                }))
              }
            >
              Gerar Ocorrência
            </Button>
          </div>
        </div>

        {renderAdditionalFilter(pathname)}

        {state.showInvoiceSearch && (
          <InvoiceSearchForm
            modal={true}
            open={state.showInvoiceSearch}
            ribbon={false}
            onSubmit={shipperIncident}
            onClose={() =>
              setState(oldState => ({
                ...oldState,
                showInvoiceSearch: false,
              }))
            }
          />
        )}
      </>
    );
  };

  const renderAdditionalFilter = pathname => {
    const {
      startIncidentFrom,
      startIncidentTo,
      purposeIncident,
      dateDeliveryFrom,
      dateDeliveryTo,
      dateReturnFrom,
      dateReturnTo,
    } = state;

    if (pathname === '/invoices/occurrence') {
      return (
        <div className="mt-2 flex sm:items-end gap-2 flex-col sm:flex-row">
          <div className="flex flex-col">
            <DateRangePicker
              placeholderFrom="Ocorrência de"
              placeholderTo="Até"
              from={startIncidentFrom}
              to={startIncidentTo}
              onChangeFrom={startIncidentFrom => {
                setState(oldState => ({ ...oldState, startIncidentFrom }));
              }}
              onChangeTo={startIncidentTo => {
                setState(oldState => ({ ...oldState, startIncidentTo }));
              }}
            />
          </div>
          <div className="flex flex-col">
            <Input.Select
              caption="Tipo Ocorrência"
              options={[
                { key: 1, text: 'Entrega', value: 'ENTREGA' },
                { key: 2, text: 'Transporte', value: 'TRANSPORTE' },
              ]}
              placeholder="Selecione ..."
              value={purposeIncident || ''}
              onChange={value => {
                setState(oldState => ({
                  ...oldState,
                  purposeIncident: value,
                }));
              }}
            />
          </div>
        </div>
      );
    }

    if (pathname === '/invoices/delivered') {
      return (
        <div className="mt-2 flex sm:items-end gap-2 flex-col sm:flex-row">
          <div className="flex flex-col">
            <DateRangePicker
              placeholderFrom="Entrega de"
              placeholderTo="Até"
              from={dateDeliveryFrom}
              to={dateDeliveryTo}
              onChangeFrom={dateDeliveryFrom => {
                setState(oldState => ({ ...oldState, dateDeliveryFrom }));
              }}
              onChangeTo={dateDeliveryTo => {
                setState(oldState => ({ ...oldState, dateDeliveryTo }));
              }}
            />
          </div>
        </div>
      );
    }

    if (pathname === '/invoices/returned') {
      return (
        <div className="mt-2 flex sm:items-end gap-2 flex-col sm:flex-row">
          <div className="flex flex-col">
            <DateRangePicker
              placeholderFrom="Rejeição de"
              placeholderTo="Até"
              from={dateReturnFrom}
              to={dateReturnTo}
              onChangeFrom={dateReturnFrom => {
                setState(oldState => ({ ...oldState, dateReturnFrom }));
              }}
              onChangeTo={dateReturnTo => {
                setState(oldState => ({ ...oldState, dateReturnTo }));
              }}
            />
          </div>
        </div>
      );
    }
  };

  const getChildContext = () => {
    return {
      state: state,
      searchInvoice: searchInvoice,
      selectInvoice: selectInvoice,
      selectInvoiceToShippingRelationship: selectInvoiceToShippingRelationship,
    };
  };

  const onSearch = () => {
    const { pathname, search } = location;
    goToPage({ pathname, search, state: state });
  };

  const clearFilter = () => {
    setState({
      invoiceNo: '',
      selectedInvoices: [],
      shippingRelationshipInvoices: [],
      printing: false,
      showInvoiceSearch: false,
      startIncidentFrom: '',
      startIncidentTo: '',
      purposeIncident: '',
      dateDeliveryFrom: '',
      dateDeliveryTo: '',
      dateReturnFrom: '',
      dateReturnTo: '',
      company: '',
    });
    const { pathname, search } = location;

    goToPage({ pathname, search, state: {} });
  };

  const handleItemClick = name => {
    const location = {
      pathname: `/invoices/${name}`,
      search: '',
      state: state,
    };
    goToPage(location);
  };

  const shipperIncident = data => {
    setState(oldState => ({
      ...oldState,
      showInvoiceSearch: false,
    }));
    if (data?.externalDispatcher) {
      return toast.error(
        'Não é possível registrar uma ocorrência para uma transportadora FOB!',
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          icon: (
            <XCircleIcon
              className="h-10 w-10 text-red-500"
              aria-hidden="true"
            />
          ),
          style: {
            borderRadius: 10,
          },
        }
      );
    }
    setShipperIncidentData(data);
    setShipperIncidentModalOpen(true);
  };

  const goToPage = location => {
    navigate(location);
  };

  const searchInvoice = () => {
    const { invoiceNo } = state;
    dispatch(
      setInvoice({
        key: invoiceNo,
      })
    );
  };

  if (state.printing) {
    return renderShippingRelationShip();
  }

  return (
    <>
      <div className="sm:flex sm:items-center mb-2">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-4 text-gray-800">
            Notas Fiscais
          </h1>
          <p className="mt-1 text-sm text-gray-700">
            Consulta de notas fiscais
          </p>
        </div>
      </div>
      <div className="flex sm:items-end gap-2 flex-col sm:flex-row">
        <div className="flex flex-col">
          <Tabs>
            <Tabs.Item
              name="pending"
              active={pathname === '/invoices/pending'}
              onClick={() => handleItemClick('pending')}
            >
              Pendentes
              <span className="inline-flex ml-4 items-center rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-600">
                {overview.pending || 0}
              </span>
            </Tabs.Item>
            <Tabs.Item
              name="expiring"
              active={pathname === '/invoices/expiring'}
              onClick={() => handleItemClick('expiring')}
            >
              No prazo
              <span className="inline-flex ml-4 items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-600">
                {overview.expiring || 0}
              </span>
            </Tabs.Item>
            <Tabs.Item
              name="overdue"
              active={pathname === '/invoices/overdue'}
              onClick={() => handleItemClick('overdue')}
            >
              Atrasadas
              <span className="inline-flex ml-4 items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-600">
                {overview.overdue || 0}
              </span>
            </Tabs.Item>
            <Tabs.Item
              name="occurrence"
              active={pathname === '/invoices/occurrence'}
              onClick={() => handleItemClick('occurrence')}
            >
              Em ocorrência
              <span className="inline-flex ml-4 items-center rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-600">
                {overview.withIncidents || 0}
              </span>
            </Tabs.Item>
            <Tabs.Item
              name="returned"
              active={pathname === '/invoices/returned'}
              onClick={() => handleItemClick('returned')}
            >
              Entregas rejeitadas
              <span className="inline-flex ml-4 items-center rounded-md bg-purple-100 px-2 py-1 text-xs font-medium text-purple-600">
                {overview.withReturns || 0}
              </span>
            </Tabs.Item>
            <Tabs.Item
              name="delivered"
              active={pathname === '/invoices/delivered'}
              onClick={() => handleItemClick('delivered')}
            >
              Entregues
            </Tabs.Item>
          </Tabs>
        </div>

        {isOverdue() &&
          permissions.invoice.sendMail() &&
          selectedInvoices.length > 0 && (
            <div>
              <Button variant="primary" onClick={() => sendMail()}>
                Enviar e-mail
              </Button>
            </div>
          )}
      </div>

      <ShipperIncidentForm
        open={shipperIncidentModalOpen}
        onClose={() => setShipperIncidentModalOpen(false)}
        clearData={() => setShipperIncidentData()}
        data={shipperIncidentData}
      />

      {renderDefaultFilter(pathname)}
      {children}
      <Outlet context={getChildContext()} />
      <MailSender
        open={isMailSenderModalOpen}
        setIsMailSenderModalOpen={setIsMailSenderModalOpen}
        mailSenderModalData={mailSenderModalData}
      />
    </>
  );
};

function mapStateToProps({ overview }) {
  const { dataObject } = overview;
  return {
    dataObject,
  };
}

export default connect(mapStateToProps)(Invoices);
