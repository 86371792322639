import { useEffect, useRef, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getReprieve,
  fetchReprievePending,
  fetchReprieveEffect,
  effectReprieve,
  removeReprieve,
  resetState,
} from '@redux/ducks/reprieve';

import InvoiceSearchForm from '@pages/invoices/components/invoiceSearch';
import { TableForm, Tabs, Button, Confirm } from '@components';

import moment from 'moment';
import Filter from './components/Filter';
import ReprieveForm from './components/ReprieveForm';
import { Navigate } from 'react-router-dom';
import { companyCustomConfiguration } from '../../config/api';

const Reprieve = ({
  results,
  load_query,
  load_mutation,
  getReprieve,
  fetchReprievePending,
  fetchReprieveEffect,
  effectReprieve,
  removeReprieve,
  resetState,
  contextCompany,
  user,
}) => {
  const [queryType, setQueryType] = useState('pending');
  const [isInvoiceSearchFormOpen, setIsInvoiceSearchFormOpen] = useState(false);
  const [reprieveFormModalOpen, setReprieveFormModalOpen] = useState(false);
  const [reprieveFormData, setReprieveFormData] = useState();

  const confirmRef = useRef(null);

  const customConfiguration = companyCustomConfiguration.find(
    company => company.cnpj === '76827344000130'
  );

  const initialFilter = {
    from: new Date(moment().subtract(30, 'd').format('YYYY-MM-DD')),
    to: new Date(moment(Date.now()).format('YYYY-MM-DD')),
  };

  const onSearch = data => {
    resetState();
    fetchReprieveEffect({
      filter: {
        dateFrom: data.from,
        dateTo: data.to,
      },
    });
  };

  const onEffect = data => {
    confirmRef.current.open(
      `Deseja realmente liberar a prorrogação do título ${data.titulo}?`,
      () => {
        effectReprieve({
          data,
          success: () => {
            fetchReprievePending();
          },
        });
      }
    );
  };

  const onRemove = data => {
    confirmRef.current.open(
      `Deseja realmente cancelar a prorrogação do título ${data.titulo}?`,
      () => {
        removeReprieve({
          data,
          success: () => {
            fetchReprievePending();
          },
        });
      }
    );
  };

  function handleCreateReprieve(invoice) {
    getReprieve({
      invoiceKey: invoice.key,
      success: result => {
        setIsInvoiceSearchFormOpen(false);
        setReprieveFormData({
          invoice,
          reprieve: result,
        });
        setReprieveFormModalOpen(true);
      },
    });
  }

  const Filters = () => {
    return (
      <div className="flex justify-between">
        <div>
          <Tabs>
            <Tabs.Item
              active={queryType === 'pending'}
              onClick={() => setQueryType('pending')}
            >
              Pendentes
            </Tabs.Item>
            <Tabs.Item
              active={queryType === 'approved'}
              onClick={() => setQueryType('approved')}
            >
              Encerradas
            </Tabs.Item>
          </Tabs>

          <div className="mt-4">
            {queryType === 'approved' && (
              <Filter initialState={initialFilter} onSearch={onSearch} />
            )}
          </div>
        </div>

        <div>
          <Button
            variant="primary"
            onClick={() => setIsInvoiceSearchFormOpen(true)}
          >
            Prorrogar
          </Button>
        </div>
      </div>
    );
  };

  const renderHeaders = () => {
    const headers = [
      {
        label: 'Cliente',
        getValue: item => `(${item.codCliente}) ${item.nomeCliente}`,
      },
      {
        label: 'Motivo/Observação',
        getValue: item => {
          return (
            <>
              <b>Motivo:</b> {item.motivoNome}
              <br />
              <b>Obs:</b> {item.observacao}
            </>
          );
        },
      },
      { label: 'Geração', getValue: item => item.geracaoData },
      { label: 'Titulo', getValue: item => item.titulo },
      {
        label: 'Valor',
        getValue: item => `R$ ${item.valorTitulo}`,
      },
      { label: 'Emissão', getValue: item => item.dataEmissao },
      {
        label: 'Cond.Venda',
        getValue: item => `${item.NFvencimentos} dias`,
      },
      { label: 'Prorrogar', getValue: item => item.prgData },
    ];

    if (queryType === 'pending') {
      headers.push({
        label: 'Ações',
        props: {
          align: 'center',
        },
        getValue: item => {
          if (item.situacao == 0 || item.situacao == 5) {
            return (
              <div className="flex gap-2 justify-center">
                <Button compact onClick={() => onEffect(item)}>
                  Liberar
                </Button>
                <Button compact onClick={() => onRemove(item)}>
                  Cancelar
                </Button>
              </div>
            );
          }
          return null;
        },
      });
    }
    return headers;
  };

  useEffect(() => {
    resetState();
    if (queryType === 'pending') {
      fetchReprievePending();
    } else {
      fetchReprieveEffect({
        filter: {
          dateFrom: initialFilter.from,
          dateTo: initialFilter.to,
        },
      });
    }
  }, [queryType]);

  if (
    contextCompany.cnpj !== customConfiguration.cnpj ||
    (user?.role?.name !== 'COMPANY' && user?.role?.name !== 'OPERATOR')
  ) {
    return <Navigate to="/invoices/pending" />;
  }

  return (
    <>
      <TableForm
        formHeader={{
          title: 'Consulta de prorrogações',
          subtitle: 'Gerencie e visualize as prorrogações',
        }}
        filters={Filters}
        btnNew={false}
        load={load_query}
        source={results.prorrogacao}
        headers={renderHeaders()}
      />

      <ReprieveForm
        loadMutation={load_mutation}
        open={reprieveFormModalOpen}
        onClose={() => setReprieveFormModalOpen(false)}
        clearData={() => setReprieveFormData()}
        data={reprieveFormData}
        onSuccess={() => {
          setReprieveFormModalOpen(false);
          fetchReprievePending();
        }}
      />

      <InvoiceSearchForm
        onSubmit={handleCreateReprieve}
        ribbon={false}
        open={isInvoiceSearchFormOpen}
        onClose={() => setIsInvoiceSearchFormOpen(false)}
      />
      <Confirm ref={confirmRef} />
    </>
  );
};

function mapStateToProps({ auth, reprieve }) {
  const { company, user } = auth;
  const { results, load_query, load_mutation } = reprieve;

  return {
    results,
    load_query,
    load_mutation,
    contextCompany: company,
    user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getReprieve,
      resetState,
      fetchReprievePending,
      fetchReprieveEffect,
      effectReprieve,
      removeReprieve,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Reprieve);
