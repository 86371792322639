import { useEffect, useRef, useState } from 'react';
import RedeliveryListComponent from './component/RedeliveryListComponent';
import { Button, Input } from '@components';
import { connect, useDispatch } from 'react-redux';
import { getShippers } from '@redux/ducks/shipper';
import {
  getRedeliveries,
  handleGetInitialDeliveries,
  resetState,
} from '@redux/ducks/redelivery';
import { SearchModal } from '@components';
import { permissions } from '@config/permission';

const RedeliveryList = props => {
  const { load_query, redeliveries, pageInfo, contextCompany } = props;
  const [state, setState] = useState({
    dispatcher: '',
    invoice: '',
    customer: '',
    dispatcherName: '',
  });
  const dispatch = useDispatch();
  const { dispatcherName, invoice, customer } = state;

  const inputInvoiceRef = useRef();
  const inputCustomerRef = useRef();

  const search = () => {
    dispatch(resetState());
    dispatch(getRedeliveries(state));
  };

  const next = () => {
    dispatch(getRedeliveries(state, pageInfo));
  };

  function getShippersHandle(search) {
    return new Promise((resolve, reject) => {
      dispatch(
        getShippers({
          search,
          success: results => {
            resolve(results);
          },
          error: error => {
            reject(error);
          },
        })
      );
    });
  }

  const setFilter = (value, prop) => {
    setState(props => ({ ...props, [prop]: value }));
  };

  const handleClear = () => {
    setState({
      dispatcher: '',
      invoice: '',
      customer: '',
      dispatcherName: '',
    });
    dispatch(resetState());
    dispatch(
      getRedeliveries({
        dispatcher: '',
        invoice: '',
        customer: '',
        dispatcherName: '',
      })
    );
  };

  const Filters = () => (
    <form className="flex flex-1 flex-row space-x-2 py-4 sm:items-end rounded-md">
      <div>
        <Input
          caption="Nota Fiscal"
          icon="file text"
          ref={inputInvoiceRef}
          placeholder="Nota Fiscal"
          onChange={e => setFilter(e.target.value, 'invoice')}
          value={invoice}
        />
      </div>
      <div>
        {permissions.redelivery.shipperFilter() && (
          <SearchModal
            caption="Transportadora"
            value={dispatcherName || ''}
            keyExtractor={item => item.node.id}
            textExtractor={item =>
              `${item.node.company.corporateName} - ${
                item.node.company.commercialName || ''
              }`
            }
            onSearch={getShippersHandle}
            onSelect={item => {
              setFilter(item.node.id, 'dispatcher');
              setFilter(item.node.company.corporateName, 'dispatcherName');
            }}
          />
        )}
      </div>
      <div>
        <Input
          caption="Clientes"
          icon="user"
          placeholder="Cliente"
          ref={inputCustomerRef}
          onChange={e => setFilter(e.target.value, 'customer')}
          value={customer}
        />
      </div>
      <div>
        <Button onClick={() => search()}>Consultar</Button>
      </div>
      <div>
        <Button onClick={() => handleClear()}>Limpar</Button>
      </div>
    </form>
  );

  useEffect(() => {
    dispatch(handleGetInitialDeliveries());
  }, [contextCompany]);

  return (
    <>
      <RedeliveryListComponent
        formHeader={{
          title: 'Reentregas Cadastradas',
          subtitle: 'Consulta de notas reentregues',
        }}
        filters={Filters}
        hasNext={pageInfo.hasNextPage}
        load={load_query}
        next={next}
        source={redeliveries || []}
      />
    </>
  );
};

function mapStateToProps({ redelivery, auth }) {
  const { load_query, pageInfo, redeliveries } = redelivery;
  const { company } = auth;
  return {
    load_query,
    pageInfo,
    redeliveries,
    contextCompany: company,
  };
}

export default connect(mapStateToProps)(RedeliveryList);
