import { useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import DriverList from './components/DriverList';
import DriverForm from './components/DriverForm';

import { fetchDrivers, saveDriver, removeDriver } from '@redux/ducks/driver';
import { Confirm } from '@components';
import { permissions } from '@config/permission';
import { useNavigate, useParams } from 'react-router-dom';

const Driver = props => {
  const confirmRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();
  const _shipper = params.id;
  const formRef = useRef(null);
  const listRef = useRef(null);

  const { results, pageInfo, load_query, load_mutation } = props;

  const initialize = () => {
    const search = listRef.current.getSearch();

    window.scroll(0, 0);

    listRef.current.closeForm();

    dispatch(fetchDrivers({ id: _shipper, search }));
  };

  const onSearch = search => {
    dispatch(
      fetchDrivers({
        id: _shipper,
        search,
      })
    );
  };

  const next = () => {
    const { pageInfo } = props;
    const search = listRef.current.getSearch();
    dispatch(
      fetchDrivers({
        cursor: pageInfo.cursor,
        id: _shipper,
        search,
      })
    );
  };

  const fnRemoveDriver = ({ node }) => {
    confirmRef.current.open(
      `Deseja realmente apagar o motorista? Após a confirmação não será possível recuperar o motorista ${node.firstName}  ${node.lastName}`,
      () => {
        dispatch(removeDriver(node.id));
      }
    );
  };

  const onSave = (data, edit) => {
    return new Promise((resolve, reject) => {
      dispatch(
        saveDriver({
          data: { ...data, shipper: _shipper },
          edit,
          success: () => {
            initialize();
            resolve();
          },
          error: error => {
            reject(error);
          },
        })
      );
    });
  };

  const edit = async driver => {
    await listRef.current.openForm();

    const { id, cpf, firstName, lastName, shipperDriver } = driver;
    setTimeout(() => {
      formRef?.current?.edit({
        id,
        cpf,
        plate: shipperDriver.plate,
        active: shipperDriver.active,
        firstName,
        lastName,
        phone: shipperDriver.phone,
      });
    }, 200);
  };

  const onBack = () => {
    if (permissions.users.operator()) {
      navigate(`/users/shippers`);
    } else {
      navigate(`/shippers`);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <>
      <DriverList
        formHeader={{
          title: 'Motoristas',
          subtitle: 'Gerencie e visualize seus motoristas',
        }}
        titleModal="Cadastro de motorista"
        labelModalOpen="Adicionar motorista"
        onNew={() => listRef.current.openForm()}
        source={results}
        hasNext={pageInfo.hasNextPage}
        load={load_query}
        next={next}
        onSearch={onSearch}
        ref={listRef}
        edit={item => edit(item)}
        removeDriver={item => fnRemoveDriver(item)}
        back={!!_shipper}
        onBack={onBack}
        lbBack="Transportadoras"
      >
        <DriverForm
          formRef={formRef}
          onSubmit={onSave}
          loadMutation={load_mutation}
        />
      </DriverList>
      <Confirm ref={confirmRef} />
    </>
  );
};

function mapStateToProps({ driver }) {
  const { results, pageInfo, load_query, load_mutation } = driver;

  return {
    results,
    pageInfo,
    load_query,
    load_mutation,
  };
}

export default connect(mapStateToProps)(Driver);
