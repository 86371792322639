import { FormCreate } from '@components';
import validate from './validate';

const Form = FormCreate('DriverForm', validate, {
  active: true,
});

const number = value => value && value.replace(/[^0-9]/g, '');

const DriverForm = props => {
  return (
    <Form
      {...props}
      fields={[
        {
          name: 'cpf',
          component: 'input',
          type: 'text',
          label: 'CPF:',
          edit: false,
          inputProps: {
            mask: '999.999.999-99',
            maskChar: '_',
            placeholder: 'Informe o CPF',
          },
          normalize: number,
        },
        {
          name: 'firstName',
          component: 'input',
          type: 'text',
          label: 'Nome:',
          inputProps: {
            placeholder: 'Informe o seu primeiro nome',
          },
        },
        {
          name: 'lastName',
          component: 'input',
          type: 'text',
          label: 'Sobrenome:',
          inputProps: {
            placeholder: 'Informe o seu sobrenome',
          },
        },
        {
          name: 'plate',
          component: 'input',
          type: 'text',
          label: 'Placa:',
          inputProps: {
            placeholder: 'Informe a placa',
          },
        },
        {
          name: 'phone',
          component: 'input',
          type: 'text',
          label: 'Telefone:',
          inputProps: {
            mask: '55 (99) 99999-9999',
            maskChar: '_',
            placeholder: 'Informe o telefone',
          },
          normalize: number,
        },
        {
          name: 'active',
          component: 'toggle',
          type: 'checkbox',
          label: 'Situação:',
        },
      ]}
    />
  );
};

export default DriverForm;
