import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/es/integration/react';
import { store, persistor } from './redux/store';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { InvoiceDetail } from '@pages';
import Routes from './routes';
import { ReloadApplication } from '@components/ReloadApplication';

const App = () => (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
      <InvoiceDetail />
    </PersistGate>
    <ToastContainer closeButton={false} />
    <ReloadApplication />
  </Provider>
);

export default App;
