import { store } from '@redux/store';

const roles = {
  REGISTERED: 'REGISTERED',
  ADMIN: 'ADMIN',
  COMPANY: 'COMPANY',
  SHIPPER: 'SHIPPER',
  DRIVER: 'DRIVER',
  OPERATOR: 'OPERATOR',
};

const hasPermission = permissions => {
  const { user } = store.getState().auth;
  if (user && user.role) {
    return permissions.some(permission => {
      return permission === roles[user.role.name];
    });
  }
  return false;
};

const menuOptions = {
  ADMIN: {
    defaultRoute: '/companies',
    shownInvoiceTools: false,
    navBarOptions: [
      {
        route: '/companies',
        name: 'Empresas',
      },
    ],
    menuOptions: [],
  },
  OPERATOR: {
    defaultRoute: '/invoices/pending',
    shownInvoiceTools: true,
    navBarOptions: [
      {
        route: '/invoices/pending',
        name: 'Notas fiscais',
      },
    ],
    menuOptions: [
      {
        route: '/expenses/accompaniment',
        name: 'Acompanhamento despesas',
      },
      {
        route: '/logistic-centers',
        name: 'Centros logísticos',
      },
      {
        route: '/alerts',
        name: 'Alertas',
      },
      {
        route: '/messages',
        name: 'Mensagens',
      },
       {
        route: '/reprieves',
        name: 'Consulta prorrogações',
      },
      {
        route: '/redeliveries',
        name: 'Consulta reentregas',
      },
      {
        route: '/devolution-invoices',
        name: 'Notas devolvidas',
      },
      {
        route: '/invoices/delivered',
        name: 'Notas entregues',
      },
      {
        route: '/incidents',
        name: 'Ocorrências',
      },
      {
        route: '/shipping-orders',
        name: 'Ordens de transporte',
      },
      {
        route: '/regions',
        name: 'Regiões',
      },
      {
        route: '/expense-types',
        name: 'Tipos de despesa',
      },
      {
        route: '/incident-types',
        name: 'Tipos de ocorrência',
      },
      {
        route: '/reversal-reason',
        name: 'Motivos de devolução',
      },
      {
        route: '/shippers',
        name: 'Transportadoras',
      },
    ],
  },
  COMPANY: {
    defaultRoute: '/invoices/pending',
    shownInvoiceTools: true,
    navBarOptions: [
      {
        route: '/invoices/pending',
        name: 'Notas fiscais',
      },
      {
        route: '/users/operators',
        name: 'Usuários',
      },
      {
        route: '/shippers',
        name: 'Transportadoras',
      },
    ],
    menuOptions: [
      {
        route: '/expenses/accompaniment',
        name: 'Acompanhamento despesas',
      },
      {
        route: '/messages',
        name: 'Mensagens',
      },
      {
        route: '/alerts',
        name: 'Alertas',
      },
      {
        route: '/logistic-centers',
        name: 'Centros logísticos',
      },
      {
        route: '/reprieves',
        name: 'Consulta prorrogações',
      },
      {
        route: '/redeliveries',
        name: 'Consulta reentregas',
      },
      {
        route: '/logs',
        name: 'Logs de acesso',
      },
      {
        route: '/devolution-invoices',
        name: 'Notas devolvidas',
      },
      {
        route: '/invoices/delivered',
        name: 'Notas entregues',
      },
      {
        route: '/incidents',
        name: 'Ocorrências',
      },
      {
        route: '/shipping-orders',
        name: 'Ordens de transporte',
      },
      {
        route: '/regions',
        name: 'Regiões',
      },
      {
        route: '/expense-types',
        name: 'Tipos de despesa',
      },
      {
        route: '/incident-types',
        name: 'Tipos de ocorrência',
      },
      {
        route: '/reversal-reason',
        name: 'Motivos de devolução',
      },
      {
        route: '/token',
        name: 'Configuração',
      },
    ],
  },
  SHIPPER: {
    defaultRoute: '/invoices/pending',
    shownInvoiceTools: true,
    navBarOptions: [
      {
        route: '/invoices/pending',
        name: 'Notas fiscais',
      },
    ],
    menuOptions: [
      {
        route: '/redeliveries',
        name: 'Consulta reentregas',
      },
      {
        route: '/expenses',
        name: 'Despesas',
      },
      {
        route: '/queues',
        name: 'Fila de carga',
      },
      {
        route: '/drivers',
        name: 'Motoristas',
      },
      {
        route: '/devolution-invoices',
        name: 'Notas devolvidas',
      },
      {
        route: '/invoices/delivered',
        name: 'Notas entregues',
      },
      {
        route: '/shipping-orders',
        name: 'Ordens de transporte',
      },
    ],
  },
};

export const loadUserMenuByRole = user => {
  if (user && user.role) {
    return menuOptions[user.role.name];
  }
  return [];
};

export const permissions = {
  invoice: {
    dispatcherFilter: () =>
      hasPermission([roles.ADMIN, roles.COMPANY, roles.OPERATOR]),
    createLog: () =>
      hasPermission([roles.ADMIN, roles.COMPANY, roles.OPERATOR]),
    terminateIncident: () =>
      hasPermission([roles.ADMIN, roles.COMPANY, roles.OPERATOR]),
    editDeliveredAt: () =>
      hasPermission([roles.ADMIN, roles.COMPANY, roles.OPERATOR]),
    createRedelivery: () =>
      hasPermission([roles.ADMIN, roles.COMPANY, roles.OPERATOR]),
    createReversal: () =>
      hasPermission([roles.ADMIN, roles.COMPANY, roles.OPERATOR]),
    removeReturn: () =>
      hasPermission([roles.ADMIN, roles.COMPANY, roles.OPERATOR]),
    sendMail: () => hasPermission([roles.ADMIN, roles.COMPANY, roles.OPERATOR]),
  },
  menu: {
    users: () => hasPermission([roles.ADMIN, roles.COMPANY]),
    importalert: () => hasPermission([roles.ADMIN, roles.COMPANY]),
    messages: () => hasPermission([roles.ADMIN, roles.COMPANY, roles.OPERATOR]),
    incidents: () =>
      hasPermission([roles.ADMIN, roles.COMPANY, roles.OPERATOR]),
    incidentType: () =>
      hasPermission([roles.ADMIN, roles.COMPANY, roles.OPERATOR]),
    region: () => hasPermission([roles.ADMIN, roles.COMPANY, roles.OPERATOR]),
    logisticCenter: () =>
      hasPermission([roles.ADMIN, roles.COMPANY, roles.OPERATOR]),
    driver: () => hasPermission([roles.SHIPPER]),
    shipper: () => hasPermission([roles.OPERATOR]),
    transportQueue: () => hasPermission([roles.SHIPPER]),
    shippingOrder: () =>
      hasPermission([
        roles.SHIPPER,
        roles.ADMIN,
        roles.COMPANY,
        roles.OPERATOR,
      ]),
    invoiceDelivered: () =>
      hasPermission([
        roles.OPERATOR,
        roles.ADMIN,
        roles.COMPANY,
        roles.SHIPPER,
      ]),
    redeliveryList: () =>
      hasPermission([
        roles.OPERATOR,
        roles.ADMIN,
        roles.COMPANY,
        roles.SHIPPER,
      ]),
    devolutionInvoices: () =>
      hasPermission([
        roles.OPERATOR,
        roles.ADMIN,
        roles.COMPANY,
        roles.SHIPPER,
      ]),
    reprieve: () => hasPermission([roles.ADMIN, roles.COMPANY, roles.OPERATOR]),
    log: () => hasPermission([roles.ADMIN, roles.COMPANY]),
    expenseType: () =>
      hasPermission([roles.ADMIN, roles.COMPANY, roles.OPERATOR]),
    expense: () => hasPermission([roles.SHIPPER]),
    expenseAccompaniment: () => hasPermission([roles.OPERATOR, roles.COMPANY]),
  },
  users: {
    operator: () => hasPermission([roles.ADMIN, roles.COMPANY]),
  },
  driver: {
    editPlate: () =>
      hasPermission([roles.ADMIN, roles.COMPANY, roles.OPERATOR]),
  },
  reversal: {
    effect: () => hasPermission([roles.ADMIN, roles.COMPANY, roles.OPERATOR]),
    create: () => hasPermission([roles.ADMIN, roles.COMPANY, roles.OPERATOR]),
    cancel: () => hasPermission([roles.ADMIN, roles.COMPANY, roles.OPERATOR]),
  },
  shippingOrder: {
    viewFreightValue: () => hasPermission([roles.COMPANY, roles.OPERATOR]),
  },
  redelivery: {
    shipperFilter: () => hasPermission([roles.COMPANY]),
  },
};
