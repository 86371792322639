import { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Button, Toggle } from '@components';

import moment from 'moment';
import NumberFormat from 'react-number-format';
import DetailItems from './DetailItems';
import DetailLogistic from './DetailLogistic';
import DispatcherDetail from './DispatcherDetail';
import AgreedToForm from '../AgreedToForm';

import { CalendarIcon } from '@heroicons/react/20/solid';

import {
  saveAgreedTo,
  fetchInvoice,
  editInvoiceExpenseDeadlineDate,
} from '@redux/ducks/invoice';

import { ShippingOrderDetail } from '@pages';

const Format = ({ value }) => {
  value = value || 0;
  return (
    <NumberFormat
      value={value}
      displayType={'text'}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      fixedDecimalScale
    />
  );
};

const getStatusBR = status => {
  switch (status) {
    case 'EMITTED':
      return 'Emitida';
    case 'CANCELLED':
      return 'Cancelada';
    case 'RETURNED':
      return 'Rejeitada';
    case 'DELIVERED':
      return 'Entregue';
    case 'REVERSED':
      return 'Devolvida';
    case 'DELIVERED_AND_REVERSED':
      return 'Entregue/Devolvida parcial';
    default:
      return status;
  }
};

const Detail = ({
  shippingOrderDetail,
  userRoleName,
  companyCity,
  source: invoice,
}) => {
  const dispatch = useDispatch();
  const [openModalDispatcher, setOpenModalDispatcher] = useState(false);
  const [openModalRedispatcher, setOpenModalRedispatcher] = useState(false);
  const [openModalForecast, setOpenModalForecast] = useState(false);
  const [modalForecastData, setModalForecastData] = useState({});
  const [shippingOrderDetailModalData, setShippingOrderDetailModalData] =
    useState(false);
  const [isShippingOrderDetailModalOpen, setIsShippingOrderDetailModalOpen] =
    useState(false);
  const [shipperToggleQuery, setShipperToggleQuery] = useState(
    invoice.canExtendExpense
  );

  const {
    customer,
    activities,
    productInfo,
    dispatcher,
    redispatcher,
    shippingOrder,
    deliveries,
    externalDispatcher,
    externalRedispatcher,
    logisticInfo: logistic,
  } = invoice || {};
  const { city } = customer || {};
  const { aggregated, items } = productInfo || {};

  let deliveryDate;
  if (invoice.deliveries && invoice.deliveries.length > 0) {
    let lastDelivery = invoice.deliveries.length - 1;
    deliveryDate = invoice.deliveries[lastDelivery].date;
  }

  const enderecoCliente = `Cidade: ${city?.name} - ${city?.state} (${
    customer?.address
  }${customer?.number && `, ${customer?.number}`}${
    customer?.complement && `, ${customer?.complement}`
  } - ${customer?.patio})`;

  function handleShippingOrderDetail(shippingOrder) {
    setShippingOrderDetailModalData(shippingOrder.key);
    setIsShippingOrderDetailModalOpen(true);
  }

  async function onSave(data) {
    return new Promise((resolve, reject) => {
      dispatch(
        saveAgreedTo({
          data: {
            invoiceId: modalForecastData.invoiceId,
            invoiceKey: modalForecastData.invoiceKey,
            dateHour: modalForecastData.dateHour,
            date: data.date,
          },
          success: () => {
            resolve();
          },
          error: error => {
            reject(error);
          },
        })
      );
    }).then(() => {
      setOpenModalForecast(false);
      dispatch(fetchInvoice(invoice.key));
    });
  }

  function handleAgreedForm() {
    setModalForecastData({
      invoiceId: invoice.id,
      invoiceKey: invoice.key,
      dateHour: moment(activities?.predictedTo).format('DD/MM/YYYY'),
      date: '',
    });

    setOpenModalForecast(true);
  }

  function handleAllowExpenses() {
    const newToggleState = !shipperToggleQuery;
    dispatch(
      editInvoiceExpenseDeadlineDate({
        data: {
          invoiceId: invoice.id,
          canExtendExpense: newToggleState,
        },
        success: () => setShipperToggleQuery(newToggleState),
        error: () => setShipperToggleQuery(invoice.canExtendExpense),
      })
    );
  }

  return (
    <>
      <div className="p-3">
        <div>
          <dl className="grid grid-cols-1 sm:grid-cols-4">
            <div className="px-2 py-2 sm:col-span-2 sm:px-0">
              <dt className="text-sm font-bold leading-6 text-gray-900">
                Cliente
              </dt>
              <dd className="text-sm leading-6 text-gray-700">
                {`${customer.code ? '(' + customer.code + ') ' : ''}${
                  customer?.corporateName
                }`}
              </dd>
            </div>
            <div className="px-2 py-2 sm:col-span-2 sm:px-0">
              <dt className="text-sm font-bold leading-6 text-gray-900">
                Cidade
              </dt>
              <dd className="text-sm leading-6 text-gray-700">
                {city?.name} - {city?.state} ({customer?.address}
                {customer?.number && `, ${customer?.number}`}
                {customer?.complement && `, ${customer?.complement}`} -{' '}
                {customer?.patio}) &nbsp;
                <a
                  href={`https://www.google.com.br/maps/dir/${companyCity}/${enderecoCliente}`}
                  target="_blank"
                  rel="noreferrer"
                  className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                >
                  Mapa
                </a>
              </dd>
            </div>
          </dl>
          <dl className="grid grid-cols-1 sm:grid-cols-4">
            <div className="border-t border-gray-100 px-2 py-2 sm:col-span-2 sm:px-0">
              <dt className="text-sm font-bold leading-6 text-gray-900">OT</dt>
              {shippingOrderDetail ? (
                <dd className="text-sm leading-6 text-gray-700">
                  <span className="text-gray-700 whitespace-nowrap">
                    {shippingOrder?.key}
                  </span>
                </dd>
              ) : (
                <dd className="text-sm leading-6 text-gray-700">
                  <span
                    className="text-blue-500 cursor-pointer whitespace-nowrap"
                    onClick={() => handleShippingOrderDetail(shippingOrder)}
                  >
                    {shippingOrder?.key}
                  </span>
                </dd>
              )}
            </div>
            <div className="border-t border-gray-100 px-2 py-2 sm:col-span-2 sm:px-0">
              <dt className="text-sm font-bold leading-6 text-gray-900">
                Entrega OT
              </dt>
              <dd className="text-sm leading-6 text-gray-700">
                {shippingOrder?.deliveredAt &&
                  moment(shippingOrder?.deliveredAt).format('DD/MM/YYYY-HH:mm')}
              </dd>
            </div>
          </dl>
          <dl className="grid grid-cols-1 sm:grid-cols-4">
            <div className="border-t border-gray-100 px-2 py-2 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-bold leading-6 text-gray-900">
                Nota Fiscal
              </dt>
              <dd className="text-sm leading-6 text-gray-700">
                {invoice?.invoiceNo}
              </dd>
            </div>
            <div className="border-t border-gray-100 px-2 py-2 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-bold leading-6 text-gray-900">
                Pedido
              </dt>
              <dd className="text-sm leading-6 text-gray-700">
                {invoice?.order}
              </dd>
            </div>
            <div className="border-t border-gray-100 px-2 py-2 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-bold leading-6 text-gray-900">
                Vl.Mercadoria
              </dt>
              <dd className="text-sm leading-6 text-gray-700">
                <Format value={aggregated?.goodsValue} />
              </dd>
            </div>
          </dl>
          <dl className="grid grid-cols-1 sm:grid-cols-4">
            <div className="border-t border-gray-100 px-2 py-2 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-bold leading-6 text-gray-900">
                Data Emissão
              </dt>
              <dd className="text-sm leading-6 text-gray-700">
                {activities?.emittedAt &&
                  moment(activities?.emittedAt).format('DD/MM/YYYY-HH:mm')}
              </dd>
            </div>
            <div className="border-t border-gray-100 px-2 py-2 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-bold leading-6 text-gray-900">
                Entrega
              </dt>
              <dd className="text-sm leading-6 text-gray-700">
                {deliveryDate &&
                  moment(deliveryDate).format('DD/MM/YYYY-HH:mm')}
              </dd>
            </div>
            <div className="border-t border-gray-100 px-2 py-2 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-bold leading-6 text-gray-900">
                Total da Nota
              </dt>
              <dd className="text-sm leading-6 text-gray-700">
                <Format value={aggregated?.totalValue} />
              </dd>
            </div>
          </dl>

          <dl className="grid grid-cols-1 sm:grid-cols-4">
            <div className="border-t border-gray-100 px-4 py-2 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-bold leading-6 text-gray-900">
                Data Saída
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700">
                {activities?.dispatchedAt &&
                  moment(activities?.dispatchedAt).format('DD/MM/YYYY-HH:mm')}
              </dd>
            </div>
            <div className="flex items-center border-t border-gray-100 px-2 py-2 sm:col-span-1 sm:px-0">
              <div>
                {activities?.predictedTo && activities?.agreedTo ? (
                  <dt className="flex text-sm font-bold leading-6 text-orange-600">
                    Previsão Agendada
                    <CalendarIcon
                      className="ml-2 h-5 w-5 text-red"
                      aria-hidden="true"
                    />
                  </dt>
                ) : (
                  <dt className="text-sm font-bold leading-6 text-gray-900">
                    Previsão
                  </dt>
                )}

                <dd
                  className={`${
                    activities.agreedTo ? 'text-orange-600' : 'text-gray-900'
                  } text-sm leading-6 `}
                >
                  {activities?.predictedTo &&
                    moment(activities?.predictedTo).format('DD/MM/YYYY')}
                </dd>
              </div>
              <div className="ml-4">
                <Button
                  disabled={userRoleName === 'SHIPPER'}
                  onClick={handleAgreedForm}
                >
                  Editar
                </Button>
              </div>
              <AgreedToForm
                data={modalForecastData}
                onSubmit={onSave}
                open={openModalForecast}
                onClose={() => setOpenModalForecast(false)}
              />
            </div>

            {userRoleName !== 'SHIPPER' && (
              <div className="flex items-center border-t border-gray-100 px-2 py-2 sm:col-span-1 sm:px-0">
                <div className="flex items-center justify-center">
                  <Toggle
                    checked={shipperToggleQuery}
                    onChange={handleAllowExpenses}
                  />
                  <dt className="text-sm ml-3 font-bold leading-6 text-gray-900 mr-12">
                    Permitir despesas sem limite de data
                  </dt>
                </div>
              </div>
            )}

            <div className="border-t border-gray-100 px-2 py-2 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-bold leading-6 text-gray-900">
                Descontos
              </dt>
              <dd className="text-sm leading-6 text-gray-700">
                <Format value={invoice?.discount} />
              </dd>
            </div>
          </dl>

          <dl className="grid grid-cols-1 sm:grid-cols-4">
            <div className="border-t border-gray-100 px-4 py-2 sm:col-span-2 sm:px-0">
              <dt className="text-sm font-bold leading-6 text-gray-900">
                Tipo de Nota
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700">
                {invoice?.type}
              </dd>
            </div>
            <div className="border-t border-gray-100 px-2 py-2 sm:col-span-2 sm:px-0">
              <dt className="text-sm font-bold leading-6 text-gray-900">
                Situação
              </dt>
              <dd className="text-sm leading-6 text-gray-700">
                {getStatusBR(invoice?.status)}
              </dd>
            </div>
          </dl>

          <dl className="grid grid-cols-1 sm:grid-cols-1">
            <div className="border-t border-gray-100 px-2 py-2 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-bold leading-6 text-gray-900">
                Chave da nota
              </dt>
              <dd className="text-sm leading-6 text-gray-700">
                {invoice?.key}
              </dd>
            </div>
          </dl>

          <dl className="grid grid-cols-1 sm:grid-cols-1">
            <div className="border-t border-gray-100 px-2 py-2 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-bold leading-6 text-gray-900">
                Observação
              </dt>
              <dd className="text-sm leading-6 text-gray-700">
                {invoice?.note}
              </dd>
            </div>
          </dl>

          <dl className="grid grid-cols-1 sm:grid-cols-1">
            <div className="border-t border-gray-100 px-2 py-2 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-bold leading-6 text-gray-900">
                Transportadora
              </dt>
              {externalDispatcher ? (
                <dd className="flex items-center text-sm leading-6 text-gray-700 gap-5">
                  <span className="mr-3">
                    FOB - {externalDispatcher?.corporateName}
                  </span>
                  <Button onClick={() => setOpenModalDispatcher(true)}>
                    Detalhar
                  </Button>
                  <DispatcherDetail
                    open={openModalDispatcher}
                    onClose={() => setOpenModalDispatcher(false)}
                    dispatcher={externalDispatcher}
                  />
                </dd>
              ) : (
                dispatcher && (
                  <dd className="flex items-center text-sm leading-6 text-gray-700 gap-5">
                    {invoice?.dispatchShippingType} - {dispatcher?.company?.corporateName}
                    <Button onClick={() => setOpenModalDispatcher(true)}>
                      Detalhar
                    </Button>
                    <DispatcherDetail
                      open={openModalDispatcher}
                      onClose={() => setOpenModalDispatcher(false)}
                      dispatcher={dispatcher}
                    />
                  </dd>
                )
              )}
            </div>
          </dl>

          {externalRedispatcher ? (
            <dl className="grid grid-cols-1 sm:grid-cols-1">
              <div className="border-t border-gray-100 px-2 py-2 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-bold leading-6 text-gray-900">
                  Redespacho
                </dt>
                <dd className="flex items-center text-sm leading-6 text-gray-700 gap-5">
                  <span className="mr-3">
                  FOB - {externalRedispatcher?.corporateName}
                  </span>
                  <Button onClick={() => setOpenModalRedispatcher(true)}>
                    Detalhar
                  </Button>
                  <DispatcherDetail
                    open={openModalRedispatcher}
                    onClose={() => setOpenModalRedispatcher(false)}
                    dispatcher={externalRedispatcher}
                  />
                </dd>
              </div>
            </dl>
          ) : (
            redispatcher && (
              <dl className="grid grid-cols-1 sm:grid-cols-1">
                <div className="border-t border-gray-100 px-2 py-2 sm:col-span-1 sm:px-0">
                  <dt className="text-sm font-bold leading-6 text-gray-900">
                    Redespacho
                  </dt>
                  <dd className="flex items-center text-sm leading-6 text-gray-700 gap-5">
                  {invoice?.redispatchShippingType} - {redispatcher?.company?.corporateName}
                    <Button onClick={() => setOpenModalRedispatcher(true)}>
                      Detalhar
                    </Button>
                    <DispatcherDetail
                      open={openModalRedispatcher}
                      onClose={() => setOpenModalRedispatcher(false)}
                      dispatcher={redispatcher}
                    />
                  </dd>
                </div>
              </dl>
            )
          )}

          <dl className="grid grid-cols-4 sm:grid-cols-4">
            <div className="border-t border-gray-100 px-4 py-2  sm:px-0">
              <dt className="text-sm font-bold leading-6 text-gray-900">
                Peso Bruto (KG)
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700">
                <Format value={aggregated?.grossWeight} />
              </dd>
            </div>
            <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
              <dt className="text-sm font-bold leading-6 text-gray-900">
                Peso Líquido (KG)
              </dt>
              <dd className="text-sm leading-6 text-gray-700">
                <Format value={aggregated?.netWeight} />
              </dd>
            </div>
            <div className="border-t border-gray-100 px-2 py-2 sm:px-0">
              <dt className="text-sm font-bold leading-6 text-gray-900">
                Volumes
              </dt>
              <dd className="text-sm leading-6 text-gray-700">
                {aggregated?.volume}
              </dd>
            </div>
            <div className="border-t border-gray-100 px-2 py-2  sm:px-0">
              <dt className="text-sm font-bold leading-6 text-gray-900">
                Cubagem:
              </dt>
              <dd className="text-sm leading-6 text-gray-700">
                <Format value={aggregated?.cubage} />
              </dd>
            </div>
          </dl>

          <dl className="grid grid-cols-4 sm:grid-cols-4">
            <div className="border-t border-gray-100 px-4 py-2 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-bold leading-6 text-gray-900">
                Motorista
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700">
                {deliveries[0]?.driver?.firstName}{' '}
                {deliveries[0]?.driver?.lastName}
              </dd>
            </div>
            <div className="border-t border-gray-100 px-2 py-2 sm:col-span-1 sm:px-0">
              <dt className="text-sm font-bold leading-6 text-gray-900">
                Placa
              </dt>
              <dd className="text-sm leading-6 text-gray-700">
                {invoice?.driverPlate}
              </dd>
            </div>

            {invoice.xmlUrl && (
              <div className="border-t border-gray-100 px-2 sm:col-span-1 py-2 sm:px-0">
                <dd className="text-sm leading-6 text-gray-700">
                  <div className="flex-shrink-0">
                    <a
                      href={invoice?.xmlUrl}
                      download
                      className="font-medium text-blue-600 hover:text-blue-500"
                    >
                      Download XML
                    </a>
                  </div>

                  <div className="flex-shrink-0">
                    <a
                      href={invoice?.pdfUrl}
                      download
                      className="font-medium text-blue-600 hover:text-blue-500"
                    >
                      Download DANFE
                    </a>
                  </div>
                </dd>
              </div>
            )}
          </dl>
        </div>
        <DetailLogistic source={logistic} />
        <DetailItems source={items} />
      </div>
      <ShippingOrderDetail
        open={isShippingOrderDetailModalOpen}
        setIsShippingOrderDetailModalOpen={setIsShippingOrderDetailModalOpen}
        onClose={() => setIsShippingOrderDetailModalOpen(false)}
        shippingOrderDetailModalData={shippingOrderDetailModalData}
      />
    </>
  );
};

function mapStateToProps({ auth }) {
  const user = auth?.user;
  const companyCity = auth?.company?.city || auth?.shipper?.city?.name;
  const userRoleName = user?.role?.name;

  return {
    userRoleName,
    companyCity,
  };
}

export default connect(mapStateToProps)(Detail);

