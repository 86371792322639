import bgLogin from '@assets/img/bg-login.jpg';
import logo from '@assets/img/shipy.svg';

import { useNavigate, useSearchParams } from 'react-router-dom';

import ResetForm from './components/ResetForm';
import { connect } from 'react-redux';
import { checkToken, sendNewPassword } from '@redux/ducks/changePassword';
import { Toast, Button } from '@components';
import { useEffect, useRef, useState } from 'react';
import { bindActionCreators } from 'redux';

const ResetPassword = ({ sendNewPassword, checkToken, validatedToken }) => {
  const [tokenValid, setTokenValid] = useState(true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const toastRef = useRef(null);
  const token = searchParams.get('token');

  async function handleSubmit({ newPassword, passwordConfirmation }) {
    sendNewPassword({
      data: { newPassword, passwordConfirmation, validatedToken },
      success: message => {
        toastRef.current.show({
          message: message,
          type: 'success',
        });
        setTimeout(() => {
          navigate('/');
        }, 5000);
      },
      error: ({ message }) => {
        const filteredMessage = message.replace('GraphQL error: ', '');
        toastRef.current.show({
          message: filteredMessage,
          type: 'error',
        });
      },
    });
  }

  useEffect(() => {
    checkToken({
      data: { token },
      success: ({ isTokenValid }) => {
        if (!isTokenValid) {
          setTokenValid(false);
          toastRef.current.show({
            message:
              'Token expirado, solicite um novo token para redefinição de senha',
            type: 'error',
          });
        }
      },
      error: ({ message }) => {
        const filteredMessage = message.replace('GraphQL error: ', '');
        toastRef.current.show({
          message: filteredMessage,
          type: 'error',
        });
      },
    });
  }, []);

  return (
    <div className="flex h-screen">
      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={bgLogin}
          alt=""
        />
      </div>

      <div className="flex flex-1 flex-col justify-center py-12 px-4">
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <img className="mx-auto lg:ml-0 h-20" src={logo} alt="Logo" />
            <h2 className="mt-8 text-2xl font-bold text-center lg:text-left text-[#5C5C5B ]">
              {tokenValid ? 'Definir nova senha' : 'Token expirado'}
            </h2>
            <p className="mt-8 px-6 lg:px-0 text-base text-center lg:text-left text-gray-600">
              {tokenValid
                ? 'Sua nova senha deve ser diferente da senha usada anteriormente'
                : 'Por favor solicite um novo token para redefinição de senha'}
            </p>
          </div>

          <div className="mt-8">
            {tokenValid ? (
              <ResetForm submitFunction={handleSubmit} />
            ) : (
              <Button
                fullWidth
                variant="primary"
                onClick={() => navigate('/forgot-password')}
              >
                Solicitar novo token
              </Button>
            )}
          </div>
        </div>
      </div>
      <Toast ref={toastRef} />
    </div>
  );
};

function mapStateToProps(state) {
  const { validatedToken } = state.changePassword;
  return {
    validatedToken,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ sendNewPassword, checkToken }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
