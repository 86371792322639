import { client } from '@config/apollo';
import gql from 'graphql-tag';
import { call, put, select } from 'redux-saga/effects';
import { Types } from '../ducks/message';
import { Types as ToastTypes } from '../ducks/toast';

export function* fetchMessages(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    yield put({ type: Types.RESET_STATE, error: {} });
    yield put({ type: Types.LOAD_QUERY, load: true });

    const response = yield call(cli.query, {
      query: gql`
        query (
          $first: Int
          $last: Int
          $after: String
          $before: String
          $filter: MessageFilterInput
        ) {
          messages(
            first: $first
            last: $last
            after: $after
            before: $before
            filter: $filter
          ) {
            edges {
              node {
                id
                sender {
                  id
                  email
                  firstName
                  lastName
                }
                recipient {
                  id
                  firstName
                  lastName
                }
                content
                subject
                activities {
                  sentAt
                  deletedAt
                  readAt
                  receivedAt
                }
              }
            }
          }
        }
      `,
      variables: {
        last: 50,
        before: action.cursor,
        filter: {
          type: 'SENT',
        },
      },
    });

    const results = response.data.messages.edges;
    yield put({ type: Types.SET_RESULTS, results });
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
  } finally {
    yield put({ type: Types.LOAD_QUERY, load: false });
  }
}

export function* createMessage(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { cpf, subject, content } = action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($message: MessageInput) {
          sendMessage(message: $message) {
            ok
          }
        }
      `,
      variables: {
        message: {
          cpf,
          subject,
          content,
        },
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Mensagem enviada com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}
