import { Route, Routes } from 'react-router-dom';
import { NotFound, Messages } from '@pages';
import Protected from '@components/Protected';

function RoutesMessages() {
  return (
    <Protected>
      <Routes>
        <Route index element={<Messages />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Protected>
  );
}
export default RoutesMessages;
