import { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { fetchIncidents } from '@redux/ducks/incident';
import IncidentList from '../components/IncidentList';
import { DateRangePicker, Button, Tabs } from '@components';

const IncidentsQuery = props => {
  const [queryType, setQueryType] = useState('all');
  const [startFrom, setStartFrom] = useState();
  const [startTo, setStartTo] = useState();
  const dispatch = useDispatch();

  const {
    results,
    pageInfo: { cursor, hasNextPage },
    load_query,
  } = props;

  async function handleFetchIncidents() {
    dispatch(
      fetchIncidents({ filter: queryType, params: { startFrom, startTo } })
    );
  }
  useEffect(() => {
    handleFetchIncidents();
  }, [queryType]);

  useEffect(() => {
    handleFetchIncidents();
  }, []);

  const handleNextPage = async () => {
    dispatch(
      fetchIncidents({
        filter: queryType,
        cursor,
        params: { startFrom, startTo },
      })
    );
  };

  const handleReset = () => {
    setStartFrom(undefined);
    setStartTo(undefined);
    dispatch(
      fetchIncidents({
        filter: queryType,
        params: { startFrom: undefined, startTo: undefined },
      })
    );
  };

  const Filters = () => (
    <>
      <Tabs>
        <Tabs.Item
          active={queryType === 'all'}
          onClick={() => setQueryType('all')}
        >
          Todas
        </Tabs.Item>
        <Tabs.Item
          active={queryType === 'pending'}
          onClick={() => setQueryType('pending')}
        >
          Pendentes
        </Tabs.Item>
        <Tabs.Item
          active={queryType === 'terminated'}
          onClick={() => setQueryType('terminated')}
        >
          Encerradas
        </Tabs.Item>
      </Tabs>

      <div className="flex sm:items-end gap-2 flex-col sm:flex-row">
        <div className="">
          <DateRangePicker
            placeholderFrom="Ocorrência de"
            placeholderTo="Até"
            from={startFrom}
            to={startTo}
            onChangeFrom={setStartFrom}
            onChangeTo={setStartTo}
          />
        </div>
        <div className="">
          <div className="flex gap-2">
            <Button variant="primary" onClick={() => handleFetchIncidents()}>
              Consultar
            </Button>
            <Button onClick={handleReset}>Limpar</Button>
          </div>
        </div>
      </div>
    </>
  );

  return (
    <IncidentList
      formHeader={{
        title: 'Ocorrências',
        subtitle: 'Consulta de ocorrências',
      }}
      queryType={queryType}
      source={results}
      hasNext={hasNextPage}
      load={load_query}
      next={handleNextPage}
      filters={Filters}
    />
  );
};

function mapStateToProps(state) {
  let { results, pageInfo, load_query } = state.incident;
  return {
    results,
    pageInfo,
    load_query,
  };
}

export default connect(mapStateToProps)(IncidentsQuery);
