import { ApolloClient } from '@apollo/client/core';
import { split, concat } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { XCircleIcon } from '@heroicons/react/24/outline';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { InMemoryCache } from '@apollo/client/cache';
import { getMainDefinition } from '@apollo/client/utilities';
import { onError } from 'apollo-link-error';
import { logout } from '@redux/ducks/auth';
import { store } from '@redux/store';
import { toast } from 'react-toastify';

import introspectionQueryResultData from './fragmentTypes.json';

// eslint-disable-next-line no-undef
const __DEV__ = import.meta.NODE_ENV !== 'production';

const location = window.location;

export const HOST = import.meta.env.VITE_HOST || location.host;
const SSL = import.meta.env.VITE_SSL || String(location.protocol === 'https:');

export const PROTOCOL_HTTP = SSL === 'true' ? 'https' : 'http';
export const PROTOCOL_WS = SSL === 'true' ? 'wss' : 'ws';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cache = new InMemoryCache({ fragmentMatcher });

const _forceLogout = () => {
  store.dispatch(logout());
};

const monitorError = onError(args => {
  let { graphQLErrors, networkError } = args;
  if (graphQLErrors) {
    graphQLErrors.map(({ message, extensions }) => {
      if (extensions.code === 'ACCESS_ERROR') {
        _forceLogout();
      }
      return message;
    });
  }

  if (networkError) {
    let result = networkError.result || {};
    let error = result.error;

    if (error) {
      switch (error.name) {
        case 'InvalidAccessTokenError':
        case 'HttpForbiddenError':
        case 'InvalidUserProfileError':
          _forceLogout();
          break;
        default:
      }
      networkError.message = error.message;
    }
  }
});

let wsLink;
let clientAuth;
export function client(token) {
  if (clientAuth) return clientAuth;

  const httpLink = concat(
    monitorError,
    createHttpLink({
      credentials: __DEV__ ? 'include' : 'same-origin',
      uri: `${PROTOCOL_HTTP}://${HOST}/graphql`,
      headers: {
        Authorization: token ? 'Bearer ' + token : '',
      },
    })
  );

  if (!token) {
    return new ApolloClient({
      link: httpLink,
      cache,
    });
  }

  wsLink = new GraphQLWsLink(
    createClient({
      url: `${PROTOCOL_WS}://${HOST}/subscriptions`,
      options: {
        retryAttempts: 7,
        connectionParams: {
          Authorization: token ? 'Bearer ' + token : '',
        },
        connectionCallback: error => {
          if (error) {
            if (error.message === 'jwt expired') {
              toast.error(
                'Sua sessão expirou, por favor faça o login novamente',
                {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  icon: (
                    <XCircleIcon
                      className="h-10 w-10 text-red-500"
                      aria-hidden="true"
                    />
                  ),
                  style: {
                    borderRadius: 10,
                  },
                }
              );
              _forceLogout();
            }
          }
        },
      },
    })
  );

  const link = split(
    ({ query }) => {
      const { kind, operation } = getMainDefinition(query);
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpLink
  );

  return (clientAuth = new ApolloClient({
    link,
    cache,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      query: {
        fetchPolicy: 'no-cache',
      },
      mutate: {
        fetchPolicy: 'no-cache',
      },
    },
  }));
}

export function closeConnection() {
  clientAuth = null;
  if (wsLink && wsLink.subscriptionClient) {
    wsLink.subscriptionClient.close();
    wsLink = null;
  }
}

export async function upload(token, pathname, formData) {
  let options = {
    method: 'POST',
    headers: {
      Authorization: token ? 'Bearer ' + token : '',
    },
    body: formData,
  };

  return fetch(`${PROTOCOL_HTTP}://${HOST}${pathname}`, options).then(
    response => {
      if (response.status >= 200 && response.status < 300) {
        return response;
      } else if (response.status === 413) {
        throw {
          message: `Falha ao fazer upload. A imagem anexada tem tamanho superior ao limite permitido (50mb)`,
        };
      } else {
        throw {
          message: `Falha ao fazer upload. Status code: ${response.status}`,
        };
      }
    }
  );
}
