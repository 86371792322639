import { CnpjCpf } from '@utils';

const validate = values => {
  const errors = {};
  if (!values.cnpj) {
    errors.cnpj = 'Favor informar o CNPJ do centro logístico!';
  } else if (!CnpjCpf.validate(values.cnpj)) {
    errors.cnpj = 'CNPJ inválido. Favor preencha com um CNPJ válido!';
  }
  if (!values.name) {
    errors.name = 'Favor informar o nome do centro logístico! ';
  }
  return errors;
};

export default validate;
