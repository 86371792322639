import { Route, Routes } from 'react-router-dom';

import { NotFound, Expense, ExpenseAccompaniment } from '@pages';

import { Protected } from '@components';

function RoutesExpenses() {
  return (
    <Protected>
      <Routes>
        <Route index element={<Expense />} />
        <Route path="/accompaniment" element={<ExpenseAccompaniment />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Protected>
  );
}

export default RoutesExpenses;
