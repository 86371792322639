import { Types } from '../ducks/log';
import { Types as ToastTypes } from '../ducks/toast';

import gql from 'graphql-tag';
import { call, put, select } from 'redux-saga/effects';
import { client } from '@config/apollo';

export function* fetchLogs(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  yield put({ type: Types.SET_LOADER });
  try {
    const { data } = yield call(cli.query, {
      query: gql`
        query ($filter: InvoiceLogFilterInput!) {
          invoiceLogs(filter: $filter) {
            id
            createdBy {
              firstName
              lastName
            }
            description
            committedAt
            invoice {
              invoiceNo
            }
          }
        }
      `,
      variables: { filter: action.filters },
    });

    yield put({
      type: Types.SET_LOGS,
      logs: data.invoiceLogs,
    });
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: 'Ocorreu um erro ao buscar os eventos',
      },
    });
  } finally {
    yield put({ type: Types.UNSET_LOADER });
  }
}
