const Label = ({ text, type }) => {
  const colors = {
    alert: 'bg-red-100 text-red-700 ring-red-700/20 ',
    info: 'bg-blue-100 text-blue-700 ring-blue-700/20 ',
    warning: 'bg-amber-100 text-amber-700 ring-amber-700/20 ',
    success: 'bg-green-100 text-green-700 ring-green-700/20 ',
  };
  return (
    <span
      className={`${colors[type]}first:ml-0 ml-2 px-2 text-xs text-center p-1 rounded-md ring-1 ring-inset whitespace-nowrap`}
    >
      {text}
    </span>
  );
};

export default Label;
