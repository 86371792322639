import { Route, Routes } from 'react-router-dom';
import { NotFound, Region, RegionCities } from '@pages';
import Protected from '@components/Protected';

function RoutesRegion() {
  return (
    <Protected>
      <Routes>
        <Route index element={<Region />} />
        <Route path="/:regionKey/cities" element={<RegionCities />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Protected>
  );
}
export default RoutesRegion;
