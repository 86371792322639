export const Types = {
  ADD_CITY: 'regionCities/ADD_CITY',
  REMOVE_CITY: 'regionCities/REMOVE_CITY',
  SET_RESULTS: 'regionCities/SET_RESULTS',
  NEXT_RESULTS: 'regionCities/NEXT_RESULTS',
  SET_PAGE_INFO: 'regionCities/SET_PAGE_INFO',
  SET_ERROR_QUERY: 'regionCities/SET_ERROR_QUERY',
  SET_ERROR_MUTATION: 'regionCities/SET_ERROR_MUTATION',
  SET_SUCCESS_QUERY: 'regionCities/SET_SUCCESS_QUERY',
  SET_SUCCESS_MUTATION: 'regionCities/SET_SUCCESS_MUTATION',
  LOAD_QUERY: 'regionCities/LOAD_QUERY',
  LOAD_MUTATION: 'regionCities/LOAD_MUTATION',
  FETCH_REGION_CITIES: 'regionCities/FETCH_REGION_CITIES',
  FETCH_AVAILABLE_CITIES: 'regionCities/FETCH_AVAILABLE_CITIES',
  RESET_STATE: 'regionCities/RESET_STATE',
  USER_LOGOUT: 'USER_LOGOUT',
};

const initialState = {
  results: [],
  resultInfo: {},
  pageInfo: {},
  load_query: false,
  load_mutation: false,
  error_query: {},
  error_mutation: {},
  success_query: {},
  success_mutation: {},
};

export default function regionCities(state = initialState, action) {
  switch (action.type) {
    case Types.SET_RESULTS:
      return {
        ...state,
        results: action.results,
        resultInfo: action.resultInfo,
      };
    case Types.NEXT_RESULTS:
      return { ...state, results: [...state.results, ...action.results] };
    case Types.SET_PAGE_INFO:
      return { ...state, pageInfo: action.pageInfo };
    case Types.SET_ERROR_QUERY:
      return { ...state, error_query: action.error };
    case Types.SET_ERROR_MUTATION:
      return { ...state, error_mutation: action.error };
    case Types.SET_SUCCESS_QUERY:
      return { ...state, success_query: action.success };
    case Types.SET_SUCCESS_MUTATION:
      return { ...state, success_mutation: action.success };
    case Types.LOAD_QUERY:
      return { ...state, load_query: action.load };
    case Types.LOAD_MUTATION:
      return { ...state, load_mutation: action.load };
    case Types.RESET_STATE:
    case Types.USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export function addCity({ data, success, error }) {
  return {
    type: Types.ADD_CITY,
    data,
    success,
    error,
  };
}

export function removeCity({ data, success, error }) {
  return {
    type: Types.REMOVE_CITY,
    data,
    success,
    error,
  };
}

export function fetchRegionCities({ regionKey }) {
  return {
    type: Types.FETCH_REGION_CITIES,
    regionKey,
  };
}

export function resetState() {
  return {
    type: Types.RESET_STATE,
  };
}
