import { Modal } from '@components';
import { CnpjCpf } from '@utils';

const DispatcherDetail = ({ open, onClose, dispatcher }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Detalhamento da transportadora"
      containerClassSize="max-w-5xl"
    >
      <div>
        <dl className="grid grid-cols-1 sm:grid-cols-1">
          <div className="flex items-center border-gray-100 px-4 py-2 sm:col-span-2 sm:px-0">
            <dt className="text-sm font-bold leading-6 text-gray-900">
              Razão social:
            </dt>
            <dd className="ml-1 text-sm leading-6 text-gray-700">
              {dispatcher?.corporateName || dispatcher?.company?.corporateName}
            </dd>
          </div>
        </dl>

        <dl className="grid grid-cols-1 sm:grid-cols-3">
          <div className="flex sm:col-span-1 items-center border-gray-100 px-4 py-2 sm:px-0">
            <dt className="text-sm font-bold leading-6 text-gray-900">
              Fantasia:
            </dt>
            <dd className="ml-1 text-sm leading-6 text-gray-700">
              {dispatcher?.commercialName ||
                dispatcher?.company?.commercialName}
            </dd>
          </div>
          <div className="flex sm:col-span-2 items-center border-gray-100 px-2 py-2 sm:px-0">
            <dt className="text-sm font-bold leading-6 text-gray-900">
              Email:
            </dt>
            <dd className="ml-1 text-sm leading-6 text-gray-700">
              {dispatcher?.email || dispatcher?.company?.email}
            </dd>
          </div>
        </dl>

        <dl className="grid grid-cols-1 sm:grid-cols-3">
          <div className="flex sm:col-span-1 items-center border-gray-100 px-4 py-2 sm:px-0">
            <dt className="text-sm font-bold leading-6 text-gray-900">CNPJ:</dt>
            <dd className="ml-1 text-sm leading-6 text-gray-700">
              {dispatcher &&
                CnpjCpf.mask(dispatcher?.cnpj || dispatcher?.company?.cnpj)}
            </dd>
          </div>
          <div className="flex items-center border-gray-100 px-2 py-2 sm:col-span-2 sm:px-0">
            <dt className="text-sm font-bold leading-6 text-gray-900">I.E.:</dt>
            <dd className="ml-1 text-sm leading-6 text-gray-700">
              {dispatcher?.stateInscription ||
                dispatcher?.company?.stateInscription}
            </dd>
          </div>
        </dl>

        <dl className="grid grid-cols-1 sm:grid-cols-3">
          <div className="flex sm:col-span-1 items-center border-gray-100 px-4 py-2 sm:px-0">
            <dt className="text-sm font-bold leading-6 text-gray-900">
              Telefone:
            </dt>
            <dd className="ml-1 text-sm leading-6 text-gray-700">
              {dispatcher?.phone || dispatcher?.company?.phone}
            </dd>
          </div>
          <div className="flex sm:col-span-1 items-center border-gray-100 px-2 py-2 sm:px-0">
            <dt className="text-sm font-bold leading-6 text-gray-900">
              Contato:
            </dt>
            <dd className="ml-1 text-sm leading-6 text-gray-700">
              {dispatcher?.contact || dispatcher?.company?.contact}
            </dd>
          </div>
        </dl>

        <dl className="grid grid-cols-1 sm:grid-cols-3">
          <div className="flex sm:col-span-1 items-center border-gray-100 px-4 py-2 sm:px-0">
            <dt className="text-sm font-bold leading-6 text-gray-900">
              Cidade:
            </dt>
            <dd className="ml-1 text-sm leading-6 text-gray-700">
              {dispatcher?.city?.name || dispatcher?.company?.city?.name}
            </dd>
          </div>
          <div className="flex items-center border-gray-100 px-2 py-2 sm:col-span-2 sm:px-0">
            <dt className="text-sm font-bold leading-6 text-gray-900">Rua:</dt>
            <dd className="ml-1 text-sm leading-6 text-gray-700">
              {dispatcher?.address || dispatcher?.company?.address}
            </dd>
          </div>
        </dl>

        <dl className="grid grid-cols-1 sm:grid-cols-3">
          <div className="flex sm:col-span-1 items-center border-gray-100 px-4 py-2 sm:px-0">
            <dt className="text-sm font-bold leading-6 text-gray-900">
              Bairro:
            </dt>
            <dd className="ml-1 text-sm leading-6 text-gray-700">
              {dispatcher?.neighborhood || dispatcher?.company?.neighborhood}
            </dd>
          </div>
          <div className="flex sm:col-span-1 items-center border-gray-100 px-2 py-2 sm:px-0">
            <dt className="text-sm font-bold leading-6 text-gray-900">UF:</dt>
            <dd className="ml-1 text-sm leading-6 text-gray-700">
              {dispatcher?.city?.state || dispatcher?.company?.city?.state}
            </dd>
          </div>
          <div className="flex sm:col-span-1 items-center border-gray-100 px-2 py-2 sm:px-0">
            <dt className="text-sm font-bold leading-6 text-gray-900">CEP:</dt>
            <dd className="ml-1 text-sm leading-6 text-gray-700">
              {dispatcher?.city?.code || dispatcher?.company?.city?.code}
            </dd>
          </div>
        </dl>
      </div>
    </Modal>
  );
};

export default DispatcherDetail;
