export const companyCustomConfiguration = [
  {
		cnpj: "76827344000130",
		url: "https://rconnect.canoinhas.com.br/qualix/api/",
    authorization: "Basic c2hpcHk6U2hpcHlAMjAyMw==",
		routes: {
			SHIPPER_ROLE: [{
        route: '/queues',
        name: 'Fila de carga',
      },],
			OPERATOR_ROLE: [],
			COMPANY_ROLE: []
		}
	}
]