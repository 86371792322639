import { client } from '@config/apollo';
import gql from 'graphql-tag';
import { call, put } from 'redux-saga/effects';
import { Types } from '../ducks/activateInvitation';

export function* handleActivateAccount(action) {
  const cli = yield call(client);
  const { invitation_key } = action.data;

  yield put({ type: Types.LOAD_MUTATION, load: true });

  try {
    const response = yield call(cli.mutate, {
      mutation: gql`
        mutation acceptInvitation($key: String!) {
          acceptInvitation(key: $key) {
            ok
            resetPasswordToken
          }
        }
      `,
      variables: {
        key: invitation_key,
      },
    });

    const { resetPasswordToken } = response.data.acceptInvitation;

    if (resetPasswordToken) {
      yield put({
        type: Types.SET_RESET_TOKEN,
        resetPasswordToken,
      });
    }

    if (action.success) action.success({ resetPasswordToken });
  } catch (error) {
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* handleSetNewPassword(action) {
  const cli = yield call(client);
  const { password, passwordConfirmation, resetPasswordToken } = action.data;

  yield put({ type: Types.LOAD_MUTATION, load: true });

  try {
    yield call(cli.mutate, {
      mutation: gql`
        mutation confirmPasswordChange(
          $token: String!
          $newPassword: String!
          $passwordConfirmation: String!
        ) {
          confirmPasswordChange(
            token: $token
            newPassword: $newPassword
            passwordConfirmation: $passwordConfirmation
          ) {
            ok
          }
        }
      `,
      variables: {
        token: resetPasswordToken,
        newPassword: password,
        passwordConfirmation,
      },
    });

    if (action.success) action.success();
  } catch (error) {
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}
