import { TableForm, ImageCarousel, Button } from '@components';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import ExpenseSatus from '@pages/Expenses/components/status';
import { useState } from 'react';
import { HOST, PROTOCOL_HTTP } from '@config/apollo';

const url = `${PROTOCOL_HTTP}://${HOST}`;

const DetailExpense = ({ source, load_mutation }) => {
  const [openImageCarousel, setOpenImageCarousel] = useState(false)
  const [carouselImages, setCarouselImages] = useState(null);

  function handleCloseImageCarousel() {
    setOpenImageCarousel(false);
  }


  function handleOpenImageCarousel(images) {
    const imagesWithFullUrl = images.map(img => url + '/' + img);
    setCarouselImages(imagesWithFullUrl);
    setOpenImageCarousel(true);
  }

  return (
    <div>
      <TableForm
        headers={[
          {
            label: 'Data de criação',
            getValue: item =>
              item?.createdAt && moment(item.createdAt).format('DD/MM/YYYY'),
          },
          {
            label: 'Tipo da despesa',
            getValue: item => item.type?.description,
          },
          {
            label: 'Descrição',
            getValue: item => item.description,
          },
          { label: 'Anexo', getValue: item => (
            <>
              {item.attachments && item.attachments.length > 0 &&
                <Button compact onClick={() => handleOpenImageCarousel(item.attachments)}>Ver anexos</Button>
              }
            </>
          )
          },
          {
            label: 'Situação',
            getValue: item => ExpenseSatus(item),
          },
          {
            label: 'Valor',
            getValue: item => (
              <NumberFormat
                value={item?.cost && item.cost}
                displayType={'text'}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale
              />
            ),
          },
          {
            label: 'Auditado por',
            getValue: item =>
              item?.auditedBy &&
              item.auditedBy.firstName + ' ' + item.auditedBy.lastName,
          },
          { label: 'Motivo', getValue: item => item.reason },
        ]}
        keyRow={item => item.id}
        source={source}
        load={load_mutation}
      />
      <ImageCarousel
              open={openImageCarousel}
              onClose={handleCloseImageCarousel}
              images={carouselImages}
              modalTitle="Visualizar anexos"
            />
    </div>
  );
};

export default DetailExpense;
