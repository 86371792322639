export const objectSize = obj => {
  let size = 0;
  Object.keys(obj).map(() => {
    size++;
  });

  return size;
};

export const group = (items, key) => {
  return items.reduce((item, obj) => {
    (item[obj[key]['id']] = item[obj[key]] || []).push(obj);
    return item;
  }, {});
};
