import { client } from '@config/apollo';
import gql from 'graphql-tag';
import { call, put, select } from 'redux-saga/effects';
import { Types } from '../ducks/incidentType';
import { Types as ToastTypes } from '../ducks/toast';

export function* createType(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { description, purpose } = action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($incidentType: IncidentTypeInput) {
          createIncidentType(incidentType: $incidentType) {
            id
          }
        }
      `,
      variables: {
        incidentType: {
          description,
          purpose,
        },
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Tipo de ocorrência criado com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* updateType(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { id, description, purpose } = action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($id: ID!, $incidentType: IncidentTypeInput!) {
          updateIncidentType(id: $id, incidentType: $incidentType) {
            ok
          }
        }
      `,
      variables: {
        id,
        incidentType: {
          description,
          purpose,
        },
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Tipo de ocorrência alterado com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* fetchTypes() {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    yield put({ type: Types.LOAD_QUERY, load: true });

    const response = yield call(cli.query, {
      query: gql`
        query {
          incidentTypes {
            id
            description
            purpose
          }
        }
      `,
    });

    const results = response.data.incidentTypes;

    yield put({ type: Types.SET_RESULTS, results });
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
  } finally {
    yield put({ type: Types.LOAD_QUERY, load: false });
  }
}

export function* getTypes(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);
  const { cnpj } = action;
  try {
    const response = yield call(cli.query, {
      query: gql`
        query IncidentTypes($companyCnpj: String) {
          incidentTypes(companyCnpj: $companyCnpj) {
            id
            description
          }
        }
      `,
      variables: {
        companyCnpj: cnpj,
      },
    });

    const results = response.data.incidentTypes;

    if (action.success) action.success(results);
  } catch (error) {
    if (action.error) action.error(error);
  }
}
