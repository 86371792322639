import { client, closeConnection } from '@config/apollo';
import gql from 'graphql-tag';
import { call, select, put } from 'redux-saga/effects';
import { Types as ToastTypes } from '../ducks/toast';

export function* generateAccessToken(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const response = yield call(cli.mutate, {
      mutation: gql`
        mutation {
          generateIntegrationKey {
            key
          }
        }
      `,
    });

    const results = response.data.generateIntegrationKey.key;

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Token gerado com sucesso',
      },
    });
    if (action.success) action.success(results);
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) {
      action.error(error);
      yield call(closeConnection);
    }
  }
}

export function* getAccessToken(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const response = yield call(cli.query, {
      query: gql`
        query {
          me {
            ... on Company {
              commercialName
              corporateName
              integrationKey
              user {
                role {
                  name
                }
              }
            }
          }
        }
      `,
    });

    const results = response.data.me.integrationKey;
    if (action.success) action.success(results);
  } catch (error) {
    if (action.error) {
      action.error(error);
    }
  }
}
