// Types
export const Types = {
  REMOVE_REGION: 'region/REMOVE_REGION',
  CREATE_REGION: 'region/CREATE_REGION',
  UPDATE_REGION: 'region/UPDATE_REGION',
  SET_RESULTS: 'region/SET_RESULTS',
  NEXT_RESULTS: 'region/NEXT_RESULTS',
  SET_PAGE_INFO: 'region/SET_PAGE_INFO',
  SET_ERROR_QUERY: 'region/SET_ERROR_QUERY',
  SET_ERROR_MUTATION: 'region/SET_ERROR_MUTATION',
  SET_SUCCESS_QUERY: 'region/SET_SUCCESS_QUERY',
  SET_SUCCESS_MUTATION: 'region/SET_SUCCESS_MUTATION',
  LOAD_QUERY: 'region/LOAD_QUERY',
  LOAD_MUTATION: 'region/LOAD_MUTATION',
  FETCH_REGIONS: 'region/FETCH_REGIONS',
  GET_REGIONS: 'region/GET_REGIONS',
  RESET_STATE: 'region/RESET_STATE',
  USER_LOGOUT: 'USER_LOGOUT',
};

const initialState = {
  results: [],
  pageInfo: {},
  load_query: false,
  load_mutation: false,
  error_query: {},
  error_mutation: {},
  success_query: {},
  success_mutation: {},
};

export default function region(state = initialState, action) {
  switch (action.type) {
    case Types.SET_RESULTS:
      return { ...state, results: action.results };
    case Types.NEXT_RESULTS:
      return { ...state, results: [...state.results, ...action.results] };
    case Types.SET_PAGE_INFO:
      return { ...state, pageInfo: action.pageInfo };
    case Types.SET_ERROR_QUERY:
      return { ...state, error_query: action.error };
    case Types.SET_ERROR_MUTATION:
      return { ...state, error_mutation: action.error };
    case Types.SET_SUCCESS_QUERY:
      return { ...state, success_query: action.success };
    case Types.SET_SUCCESS_MUTATION:
      return { ...state, success_mutation: action.success };
    case Types.LOAD_QUERY:
      return { ...state, load_query: action.load };
    case Types.LOAD_MUTATION:
      return { ...state, load_mutation: action.load };
    case Types.RESET_STATE:
    case Types.USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export function saveRegion({ data, edit, success, error }) {
  let newData = data;
  if (newData.key.includes('_')) {
    newData.key = newData.key.replaceAll('_', '');
  }

  return {
    type: edit === true ? Types.UPDATE_REGION : Types.CREATE_REGION,
    data: newData,
    success,
    error,
  };
}

export function removeRegion({ id, success, error }) {
  return {
    type: Types.REMOVE_REGION,
    id,
    success,
    error,
  };
}

export function fetchRegions({ cursor, success, error }) {
  return {
    type: Types.FETCH_REGIONS,
    cursor,
    success,
    error,
  };
}

export function resetState() {
  return {
    type: Types.RESET_STATE,
  };
}

export function getRegions({ search, first, success, error }) {
  let filter;
  if (search && search.length > 0) {
    filter = {
      OR: [{ key: search }, { description_LIKE: search }],
    };
  }

  return {
    type: Types.GET_REGIONS,
    first,
    filter,
    success,
    error,
  };
}
