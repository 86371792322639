import moment from 'moment';

export const Types = {
  SET_RESULTS: 'incident/SET_RESULTS',
  NEXT_RESULTS: 'incident/NEXT_RESULTS',
  SET_PAGE_INFO: 'incident/SET_PAGE_INFO',
  LOAD_QUERY: 'incident/LOAD_QUERY',
  LOAD_MUTATION: 'incident/LOAD_MUTATION',
  SUBSCRIBE_TO_NEW_INCIDENT: 'incident/SUBSCRIBE_TO_NEW_INCIDENT',
  SET_NEW_INCIDENT: 'incident/SET_NEW_INCIDENT',
  FETCH_INCIDENTS: 'incident/FETCH_INCIDENTS',
  TERMINATE_INCIDENT: 'incident/TERMINATE_INCIDENT',
  CREATE_INCIDENT: 'incident/CREATE_INCIDENT',
  RESET_STATE: 'incident/RESET_STATE',
  USER_LOGOUT: 'USER_LOGOUT',
};

const initialState = {
  results: [],
  pageInfo: {},
  load_query: false,
  load_mutation: false,
  newIncident: false,
};

export default function incident(state = initialState, action) {
  switch (action.type) {
    case Types.SET_RESULTS:
      return { ...state, results: action.results };
    case Types.NEXT_RESULTS:
      return { ...state, results: [...state.results, ...action.results] };
    case Types.SET_PAGE_INFO:
      return { ...state, pageInfo: action.pageInfo };
    case Types.LOAD_QUERY:
      return { ...state, load_query: action.load };
    case Types.LOAD_MUTATION:
      return { ...state, load_mutation: action.load };
    case Types.SET_NEW_INCIDENT:
      return { ...state, newIncident: action.newIncident };
    case Types.RESET_STATE:
    case Types.USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
}

function _dateFrom(value) {
  return moment(value).format('YYYY-MM-DD') + 'T00:00:00.000Z';
}

function _dateTo(value) {
  return moment(value).format('YYYY-MM-DD') + 'T23:59:59.999Z';
}

function _getDefaultFilter(filter = {}, params) {
  if (params && params.startFrom && params.startTo) {
    const startFrom = _dateFrom(params.startFrom);
    const startTo = _dateTo(params.startTo);

    filter = {
      ...filter,
      start_GREATER_EQUALS_THAN: startFrom,
      start_LOWER_EQUALS_THAN: startTo,
    };
  }
  return filter;
}

export function fetchIncidents(args = {}) {
  const { filter } = args;

  switch (filter) {
    case 'pending':
      return fetchIncidentsPending(args);
    case 'terminated':
      return fetchIncidentsTerminated(args);
    default:
      return fetchIncidentsAll(args);
  }
}

export function fetchIncidentsAll({ cursor, params }) {
  const filter = _getDefaultFilter({}, params);

  return {
    type: Types.FETCH_INCIDENTS,
    cursor,
    filter,
  };
}

export function fetchIncidentsPending({ cursor, params }) {
  const filter = _getDefaultFilter(
    {
      end: null,
    },
    params
  );

  return {
    type: Types.FETCH_INCIDENTS,
    cursor,
    filter,
  };
}

export function fetchIncidentsTerminated({ cursor, params }) {
  const filter = _getDefaultFilter(
    {
      end_NOT_EQUALS: null,
    },
    params
  );

  return {
    type: Types.FETCH_INCIDENTS,
    cursor,
    filter,
  };
}

export function terminateIncident({ incident, message, success, error }) {
  return {
    type: Types.TERMINATE_INCIDENT,
    incident,
    message,
    success,
    error,
  };
}

export function subscribeToNewIncident() {
  return {
    type: Types.SUBSCRIBE_TO_NEW_INCIDENT,
  };
}

export function setNewIncident(newIncident) {
  return {
    type: Types.SET_NEW_INCIDENT,
    newIncident,
  };
}

export function resetState() {
  return {
    type: Types.RESET_STATE,
  };
}

export function saveIncident({ data, success, error }) {
  return {
    type: Types.CREATE_INCIDENT,
    data,
    success,
    error,
  };
}
