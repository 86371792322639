import { toast } from 'react-toastify';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { useRegisterSW } from 'virtual:pwa-register/react';

export function ReloadApplication() {
  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(swUrl, r) {
      r &&
        setInterval(() => {
          r.update();
        }, 60000);
    },
    onRegisterError(error) {
      console.log('SW registration error', error);
    },
  });

  return (
    needRefresh &&
    toast(
      <div>
        <p className="block text-md font-semibold leading-6 text-gray-900">
          Atualização disponível
        </p>
        <p className="block text-base font-medium leading-6 text-gray-700">
          Uma nova versão do portal está disponível. Clique aqui para atualizar
          agora!
        </p>
      </div>,
      {
        position: 'top-right',
        hideProgressBar: false,
        onClick: () => updateServiceWorker(true),
        pauseOnHover: true,
        autoClose: false,
        draggable: true,
        type: 'warning',
        icon: (
          <InformationCircleIcon
            className="h-10 w-10 text-blue-500"
            aria-hidden="true"
          />
        ),
        style: {
          borderRadius: 10,
        },
      }
    )
  );
}
