import { put, select } from 'redux-saga/effects';
import { Types } from '../ducks/transportQueue';
import { Types as ToastTypes } from '../ducks/toast';
import { companyCustomConfiguration } from '../../config/api';

const customConfiguration = companyCustomConfiguration.find(
  company => company.cnpj === '76827344000130'
);

export function* fetchTransportQueue() {
  const auth = yield select(state => state.auth);

  try {
    yield put({ type: Types.LOAD_QUERY, load: true });
    yield put({ type: Types.SET_RESULTS, results: [] });
    const { cnpj } = auth.shipper;

    const url = `${customConfiguration.url}filacarga/${cnpj}`;
    let options = {
      method: 'GET',
      headers: {
        Authorization: customConfiguration.authorization,
      },
    };

    const results = yield fetch(url, options).then(response => {
      return response.json();
    });

    if (results.error) {
      throw results.error;
    }

    yield put({ type: Types.SET_RESULTS, results });
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
  } finally {
    yield put({ type: Types.LOAD_QUERY, load: false });
  }
}

export function* addDriverQueue(action) {
  const auth = yield select(state => state.auth);

  try {
    const { plate, phone, data, hora, aparas } = action.data;
    const { cnpj } = auth.shipper;
    const phoneOnlyNumber = phone?.replace(/\D/g, '');

    const url = `${customConfiguration.url}filacarga/`;
    let options = {
      method: 'POST',
      headers: {
        Authorization: customConfiguration.authorization,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        transportadora: cnpj,
        placa: plate,
        telefone: phoneOnlyNumber,
        data,
        hora,
        aparas,
      }),
    };

    const results = yield fetch(url, options).then(response => {
      return response.json();
    });

    if (results.error) {
      throw results.error;
    }

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Marcação de placa efetuada com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* removeDriverQueue(action) {
  try {
    const url = `${customConfiguration.url}filacarga/${action.id}`;
    let options = {
      method: 'DELETE',
      headers: {
        Authorization: customConfiguration.authorization,
      },
    };

    const results = yield fetch(url, options).then(response => {
      return response.json();
    });

    if (results.error) {
      throw results.error;
    }

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Marcação de placa removida com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}
