import { FormCreate } from '@components';
import validate from './validate';

const Form = FormCreate('LogisticCenterShippersForm', validate);
const number = value => value && value.replace(/[^0-9]/g, '');

function LogisticCenterShippersForm({ getShippers, ...rest }) {
  return (
    <Form
      {...rest}
      fields={[
        {
          name: 'shipper',
          component: 'search',
          type: 'search',
          label: 'Transportadora:',
          edit: false,
          inputProps: {
            keyExtractor: item => item.node.id,
            textExtractor: item =>
              `${item.node.code ? '(' + item.node.code + ') ' : ''}${
                item.node.company.corporateName
              } - ${item.node.company.commercialName || ''}`,
            onSearch: search => {
              return new Promise((resolve, reject) => {
                getShippers({
                  search,
                  success: results => {
                    resolve(results);
                  },
                  error: error => {
                    reject(error);
                  },
                });
              });
            },
            onSelect: (item, reduxFormChange) => {
              reduxFormChange('idShipper', item.node.id);
              reduxFormChange(
                'shipper',
                `${item.node.code ? '(' + item.node.code + ') ' : ''}${
                  item.node.company.corporateName
                } - ${item.node.company.commercialName || ''}`
              );
            },
          },
        },
        {
          name: 'redispatch',
          component: 'input',
          type: 'text',
          label: 'Numero de dias entrega com redespacho:',
          inputProps: {
            placeholder: 'Informe o numero de dias entrega com redespacho',
          },
          normalize: number,
        },
      ]}
    />
  );
}

export default LogisticCenterShippersForm;
