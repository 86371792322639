import { client } from '@config/apollo';
import gql from 'graphql-tag';
import { call, put, select } from 'redux-saga/effects';
import { Types } from '../ducks/customer';
import { Types as ToastTypes } from '../ducks/toast';

export function* fetchCustomer(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  const { cnpj } = action;

  try {
    const response = yield call(cli.query, {
      query: gql`
        query ($cnpj: String) {
          customer(cnpj: $cnpj) {
            id
            cnpj
            commercialName
            corporateName
            patio
            complement
            postalCode
            number
            phone
            district
            email
            address
            invoiceEmail
            code
            stateInscription
            city {
              name
              state
            }
          }
        }
      `,
      variables: {
        cnpj,
      },
    });

    const customer = response?.data?.customer;

    yield put({ type: Types.SET_CUSTOMER, customer });
    if (action.success) action.success(customer);
  } catch (error) {
    yield put({ type: Types.SET_CUSTOMER, customer: undefined });
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    if (action.finish) action.finish();
  }
}
