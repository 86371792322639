import { Button } from '@components';
import Background from '@assets/img/unrelatedShipper.svg';

import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { logout } from '@redux/ducks/auth';
import { connect, useDispatch } from 'react-redux';

const UnrelatedShipper = ({ company }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  if (company?.key) {
    return (
      <Navigate to="/invoices/pending" state={{ from: location }} replace />
    );
  }

  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  };

  return (
    <div className="flex h-full">
      <div className="flex flex-1 flex-col justify-center px-4">
        <img
          className="mx-auto h-4/6"
          src={Background}
          alt="Nenhum vículo encontrado com a transportadora"
        />

        <div className="flex flex-col justify-center items-center">
          <h2 className="mt-8 text-2xl font-semibold text-center lg:text-left text-[#5C5C5B ]">
            Atenção
          </h2>
          <p className="mt-3 mb-6 px-6 lg:px-0 text-base text-center lg:text-left text-gray-600">
            No momento você não possui vínculo ativo com nenhuma empresa
          </p>
          <Button variant="primary" onClick={handleLogout}>
            Sair do Shipy
          </Button>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps({ auth }) {
  const { company } = auth;
  return { company };
}

export default connect(mapStateToProps)(UnrelatedShipper);
