import { Fragment, useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

const CompanySelector = ({ options, setOption, selectedOption = {} }) => {
  const [selected, setSelected] = useState(selectedOption);

  useEffect(() => {
    setOption(selected);
  }, [selected]);

  return (
    <div className="flex items-center justify-center z-10">
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative">
          <Listbox.Button className="relative w-64 cursor-pointer rounded-md bg-white py-1.5 pl-3 pr-10 text-left ring-1 ring-inset ring-gray-300 focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm">
            <span className="block truncate text-sm items-center font-normal leading-6 text-gray-900">
              {selected.id ? selected.corporateName : 'Selecione uma empresa'}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {options.map(item => (
                <Listbox.Option
                  key={item.id}
                  value={item}
                  className={() => `relative cursor-pointer select-none py-2 pl-3 pr-9
                    ${
                      selected.id === item.id ? 'bg-gray-100' : 'text-gray-900'
                    }`}
                >
                  {() => (
                    <>
                      <span
                        className={`block truncate ${
                          selected.id === item.id
                            ? 'font-medium'
                            : 'font-regular'
                        }`}
                      >
                        {item.corporateName}
                      </span>

                      {selected.id === item.id && (
                        <span
                          className={`absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600`}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      )}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};
export default CompanySelector;
