import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

const Modal = ({
  open,
  onClose,
  title = '',
  containerClassSize = 'max-w-2xl',
  modalOverflow = false,
  children,
}) => {
  const fullScreen = containerClassSize === 'max-w-full';
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <div className="fixed inset-0 overflow-hidden bg-slate-500 bg-opacity-75 transition-opacity">
          <div className="absolute inset-0 overflow-hidden flex items-center justify-center">
            <div
              className={`pointer-events-none fixed inset-y-0 flex items-center justify-center`}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  data-fullscreen={fullScreen}
                  className={`w-screen pointer-events-auto rounded-lg
                    data-[fullscreen=true]:h-full
                    data-[fullscreen=true]:p-5
                    ${containerClassSize}
                    `}
                >
                  <div
                    data-fullscreen={fullScreen}
                    className="flex flex-col bg-white shadow-xl rounded-lg data-[fullscreen=true]:h-full max-h-screen"
                  >
                    <div className="p-5">
                      <div className="flex items-start justify-between">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900">
                          {title}
                        </Dialog.Title>
                        <div className="flex h-6 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                            onClick={onClose}
                          >
                            <span className="sr-only">Fechar modal</span>
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`max-h-[750px] p-5 pt-0 flex-1 ${
                        modalOverflow ? ' overflow-visible' : ' overflow-y-auto'
                      }`}
                    >
                      {children}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
