import { useEffect, useRef } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createReversal } from '@redux/ducks/reversal';
import { getReasons } from '@redux/ducks/reversalReason';

import { FormCreate, Modal } from '@components';

import validate from './validate';
import moment from 'moment';

const Form = FormCreate('DevolutionOpenForm', validate, {
  representativeFreight: false,
  representativeTaxSubstitution: false,
});

const number = value => value && value.replace(/[^0-9]/g, '');

const DevolutionOpenForm = ({
  devolutionInvoice,
  load_mutation,
  createReversal,
  getReasons,
  onClose,
  setDevolutionOpenForm,
  openDevolutionFormModal,
  ...rest
}) => {
  const { id, key, invoiceNo, customer, activities, productInfo } =
    devolutionInvoice;
  const formRef = useRef(null);

  const getNameItem = (code, index) => {
    return `item:${index}`;
  };

  function handleSubmit(data, edit) {
    return new Promise((resolve, reject) => {
      createReversal({
        data: {
          ...data,
          invoiceKey: key,
          invoiceId: id,
          items: productInfo.items,
        },
        edit,
        success: () => {
          setDevolutionOpenForm(false);
          onClose()
        },
        error: error => {
          reject(error);
        },
      });
    });
  }

  const populateItemsField = () => {
    productInfo?.items?.map((item, index) => {
      const rowName = getNameItem(item?.sku, index);
      formRef?.current?.change({
        [rowName]: String(item.quantity),
      });
    });
  };

  useEffect(() => {
    setTimeout(() => {
      populateItemsField();
    }, 200);
  }, [openDevolutionFormModal]);

  return (
    <>
      <Modal
        open={openDevolutionFormModal}
        onClose={() => setDevolutionOpenForm(false)}
        title="Devolução de nota fiscal"
      >
        <Form
          {...rest}
          formRef={formRef}
          ribbon={false}
          edit={true}
          onSubmit={handleSubmit}
          loadMutation={load_mutation}
          onClose={() => setDevolutionOpenForm(false)}
          fields={[
            {
              name: 'invoiceNo',
              component: 'input',
              type: 'text',
              label: 'NF:',
              edit: false,
              inputProps: {
                value: invoiceNo,
                disabled: true,
              },
            },
            {
              name: 'emittedAt',
              component: 'input',
              type: 'text',
              label: 'Data de emissão:',
              edit: false,
              inputProps: {
                value: moment(activities?.emittedAt).format('DD/MM/YYYY-HH:mm'),
                disabled: true,
              },
            },
            {
              name: 'customer',
              component: 'input',
              type: 'text',
              label: 'Cliente:',
              edit: false,
              inputProps: {
                value: customer?.corporateName,
                disabled: true,
              },
            },
            {
              name: 'reasonDescription',
              component: 'search',
              type: 'search',
              label: 'Motivo de devolução:',
              inputProps: {
                keyExtractor: item => item.id,
                textExtractor: item => item.description,
                onSearch: search => {
                  return new Promise((resolve, reject) => {
                    getReasons({
                      search,
                      success: results => {
                        resolve(results);
                      },
                      error: error => {
                        reject(error);
                      },
                    });
                  });
                },
                onSelect: (item, reduxFormChange) => {
                  reduxFormChange('reasonDescription', item.description);
                  reduxFormChange('reason', item.id);
                },
              },
            },
            {
              name: 'note',
              component: 'textarea',
              type: 'textarea',
              label: 'Observação:',
              inputProps: {
                placeholder: 'Informe uma observação',
              },
            },
            {
              name: 'files',
              component: 'uploadfile',
              label: 'Anexo verso/nota cliente:',
              inputProps: {
                onDropAccepted: (files, reduxFormChange) => {
                  reduxFormChange('files', files);
                },
                removeFile: reduxFormChange => {
                  reduxFormChange('files', null);
                },
              },
            },
            {
              name: 'representativeFreight',
              component: 'toggle',
              type: 'checkbox',
              label: 'Cobrar frete do representante:',
            },
            {
              name: 'representativeTaxSubstitution',
              component: 'toggle',
              type: 'checkbox',
              label: 'Cobrar ST do representante:',
            },
            {
              component: 'table',
              headers: [
                {
                  component: 'text',
                  label: 'Itens da nota',
                },
                {
                  component: 'text',
                  label: 'Qtd.Solicitada',
                },
                {
                  component: 'text',
                  label: 'Qtd.Cancelada',
                },
              ],
              body: (() => {
                if (productInfo?.items) {
                  return productInfo?.items?.map((item, index) => {
                    return [
                      {
                        component: 'text',
                        label: `${item.sku} - ${item.name}`,
                      },
                      { component: 'text', label: item.quantity },
                      {
                        name: getNameItem(item.sku, index),
                        component: 'input',
                        type: 'text',
                        normalize: number,
                      },
                    ];
                  });
                }
              })(),
            },
          ]}
        />
      </Modal>
    </>
  );
};

function mapStateToProps({ reversal }) {
  const { load_mutation } = reversal;

  return {
    load_mutation,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ createReversal, getReasons }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(DevolutionOpenForm);
