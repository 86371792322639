import { forwardRef } from "react";

const Table = ({ children, ...rest }) => (
  <table {...rest} className="min-w-full divide-y divide-gray-300">
    {children}
  </table>
);

Table.Header = ({ children, ...rest }) => <thead {...rest}>{children}</thead>;
Table.Header.displayName = 'Table.Header';

Table.Row = forwardRef(({ children, ...rest }, ref) => <tr ref={ref }{...rest}>{children}</tr>);
Table.Row.displayName = 'Table.Row';

Table.HeaderCell = ({ children, align = 'left', colSpan, ...rest }) => {
  const aligningClasses = {
    left: 'place-items-start text-left',
    center: 'place-items-center text-center',
    right: 'place-items-end text-right',
  };
  return (
    <th
      scope="col"
      colSpan={colSpan}
      {...rest}
      className={`${aligningClasses[align]} px-2 py-3.5 text-sm font-semibold text-gray-900`}
    >
      {children}
    </th>
  );
};
Table.HeaderCell.displayName = 'Table.HeaderCell';

Table.Body = ({ children, ...rest }) => (
  <tbody {...rest} className="divide-y divide-gray-200 bg-white">
    {children}
  </tbody>
);
Table.Body.displayName = 'Table.Body';

Table.Cell = ({ children, align = 'left', ...rest }) => {
  const aligningClasses = {
    left: 'place-items-start text-left',
    center: 'place-items-center text-center',
    right: 'place-items-end text-right',
  };
  return (
    <td
      scope="col"
      {...rest}
      className={`${aligningClasses[align]} px-2 py-2 text-sm text-gray-800`}
    >
      {children}
    </td>
  );
};
Table.Cell.displayName = 'Table.HeaderCell';

Table.Footer = ({ children, ...rest }) => <tfoot {...rest}>{children}</tfoot>;
Table.Footer.displayName = 'Table.Footer';

export default Table;
