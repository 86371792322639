export const validate = values => {
  const errors = {};
  const minLength = 8;
  const specialCharacters = '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~';

  if (!values.newPassword) {
    errors.newPassword = 'Campo obrigatório! ';
  } else if (values.newPassword.length < minLength) {
    errors.newPassword = `A nova senha precisa ter no mínimo ${minLength} caracteres.`;
  } else if (values.newPassword.toLowerCase() === values.newPassword) {
    errors.newPassword =
      'A nova senha precisa ter pelo menos um caractere em maiúsculo.';
  } else if (values.newPassword.toUpperCase() === values.newPassword) {
    errors.newPassword =
      'A nova senha precisa ter pelo menos um caractere em minúsculo.';
  } else if (
    !values.newPassword.split('').some(char => specialCharacters.includes(char))
  ) {
    errors.newPassword =
      'A nova senha precisa ter pelo menos um caractere especial.';
  }

  if (!values.passwordConfirmation) {
    errors.passwordConfirmation = 'Campo obrigatório! ';
  } else if (values.passwordConfirmation !== values.newPassword) {
    errors.passwordConfirmation = 'As senhas devem ser iguais';
  }

  return errors;
};
