const validate = values => {
  const errors = {};
  if (!values.bdNumber) {
    errors.bdNumber = 'Favor informar o numero do BD ou nota fisca do cliente!';
  }
  if (!values.bdValue) {
    errors.bdValue =
      'Favor informar o valor do BD ou da nota fisca do cliente!';
  }
  if (!values.freightValue) {
    errors.freightValue = 'Favor informar o valor do frete!';
  }
  return errors;
};

export default validate;
