import { forwardRef, useState } from 'react';
import { TableForm, Button, ImageCarousel } from '@components';
import moment from 'moment';
import { Status } from '../index';
import NumberFormat from 'react-number-format';
import { HOST, PROTOCOL_HTTP } from '@config/apollo';
import { setDataObject as setInvoice } from '@redux/ducks/invoice';
import { useDispatch } from 'react-redux';

const url = `${PROTOCOL_HTTP}://${HOST}`;

const ExpenseList = forwardRef(
  ({ onReject, onApprove, onDetailReject, showReason, ...rest }, ref) => {
    const [openImageCarousel, setOpenImageCarousel] = useState(false);
    const [carouselImages, setCarouselImages] = useState(null);
    const dispatch = useDispatch();

    function handleDetailInvoice(invoiceNumber) {
      dispatch(
        setInvoice({
          key: invoiceNumber,
        })
      );
    }

    function handleOpenImageCarousel(images) {
      const imagesWithFullUrl = images.map(img => url + '/' + img);
      setCarouselImages(imagesWithFullUrl);
      setOpenImageCarousel(true);
    }

    function handleCloseImageCarousel() {
      setOpenImageCarousel(false);
      setCarouselImages(null);
    }

    return (
      <>
        <TableForm
          {...rest}
          ref={ref}
          headers={[
            {
              label: 'Nota',
              getValue: item => (
                <span
                  className="text-blue-500 cursor-pointer"
                  onClick={() =>
                    handleDetailInvoice(item.node.invoice.invoiceNo)
                  }
                >
                  {item.node.invoice.invoiceNo}
                </span>
              ),
            },
            {
              label: 'Transportadora',
              getValue: item =>
                item.node.shipper.company.commercialName ||
                item.node.shipper.company.corporateName,
            },
            {
              label: 'Valor',
              getValue: item => (
                <NumberFormat
                  value={item.node.cost}
                  displayType={'text'}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale
                />
              ),
            },
            {
              label: 'Criado em',
              getValue: item =>
                moment(item.node.createdAt).format('DD/MM/YYYY'),
            },
            { label: 'Situação', getValue: item => Status(item.node) },
            {
              label: 'Descrição',
              getValue: item => item.node.description,
            },
            {
              label: 'Tipo',
              getValue: item => item.node.type.description,
            },
            {
              label: 'Motivo',
              visible: showReason,
              getValue: item => item.node.reason,
            },
            {
              label: 'Ações',
              props: {
                align: 'center',
                width: '170px',
              },
              getValue: item => {
                if (onApprove && onReject) {
                  return (
                    <div className="flex space-x-2" key={item.node.id}>
                      <Button
                        variant="positive"
                        compact
                        onClick={() => {
                          if (onApprove) {
                            onApprove(item);
                          }
                        }}
                      >
                        Aprovar
                      </Button>
                      <Button
                        variant="negative"
                        compact
                        onClick={() => {
                          if (onReject) onReject(item);
                        }}
                      >
                        Rejeitar
                      </Button>

                      {item.node.attachments && item.node.attachments.length > 0 && (
                        <Button
                          compact
                          onClick={() =>
                            handleOpenImageCarousel(item.node.attachments)
                          }
                        >
                          Anexos
                        </Button>
                      )}
                    </div>
                  );
                }

                // Não permite edição, é o detalhamento pra transportadora.
                if (onDetailReject && Status(item.node) === 'Rejeitado') {
                  return (
                    <Button
                      compact
                      onClick={() => {
                        if (onDetailReject) onDetailReject(item);
                      }}
                    >
                      Detalhar Rejeição
                    </Button>
                  );
                }

                return (
                  <>
                  {item.node.attachments && item.node.attachments.length > 0 && (
                    <Button
                      compact
                      onClick={() =>
                        handleOpenImageCarousel(item.node.attachments)
                      }
                    >
                      Anexos
                    </Button>
                  )}
                  </>
                )
              },
            },
          ]}
          keyRow={(_, index) => index}
        />
        <ImageCarousel
          open={openImageCarousel}
          onClose={handleCloseImageCarousel}
          images={carouselImages}
          modalTitle="Visualizar anexos"
        />
      </>
    );
  }
);

ExpenseList.displayName = 'ExpenseList';

export default ExpenseList;
