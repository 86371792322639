import { useState } from 'react';

import { DateRangePicker, Button } from '@components';
import moment from 'moment';

const Filter = ({ initialState, onSearch }) => {
  const [filter, setFilter] = useState({
    from: initialState.from,
    to: initialState.to,
  });

  const handleSearch = () => {
    if (onSearch) onSearch(filter);
  };

  return (
    <div className="flex sm:items-end gap-2 flex-col sm:flex-row">
      <div>
        <DateRangePicker
          placeholderFrom="Período de"
          placeholderTo="Até"
          from={filter.from}
          to={filter.to}
          onChangeFrom={from => {
            setFilter(prev => ({
              ...prev,
              error: {
                ...prev.error,
                date: prev.to ? false : true,
              },
              from: new Date(moment(from).startOf('day')),
            }));
          }}
          onChangeTo={to => {
            setFilter(prev => ({
              ...prev,
              error: {
                ...prev.error,
                date: prev.from ? false : true,
              },
              to: new Date(moment(to).endOf('day')),
            }));
          }}
        />
      </div>

      <div className="flex gap-2">
        <Button variant="primary" onClick={handleSearch}>
          Consultar
        </Button>
      </div>
    </div>
  );
};

export default Filter;
