import NumberFormat from 'react-number-format';
import InputMask from 'react-input-mask';
import {
  DatePickerInput,
  SearchModal,
  Uploader,
  Input as InputXP,
  Button,
  Toggle as ToggleXP,
  DropdownMulti,
} from '@components';

const TouchedError = ({ children }) => (
  <span className="block text-sm font-normal leading-6 text-red-500">
    {children}
  </span>
);

const Input = ({
  label,
  type,
  input,
  inputProps,
  meta: { touched, error },
}) => {
  return (
    <div>
      <InputMask type={type} {...input} {...inputProps}>
        <InputXP caption={label} />
      </InputMask>
      {touched && error && <TouchedError>{error}</TouchedError>}
    </div>
  );
};

const TextArea = ({ label, input, inputProps, meta: { touched, error } }) => {
  return (
    <div>
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <textarea
        {...input}
        {...inputProps}
        rows="4"
        className="block w-full disabled:bg-zinc-50 disabled:text-gray-500 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
      />
      {touched && error && <TouchedError>{error}</TouchedError>}
    </div>
  );
};

const Toggle = ({ label, input, inputProps, meta: { touched, error } }) => {
  return (
    <div>
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <ToggleXP {...input} {...inputProps} />
      {touched && error && <TouchedError>{error}</TouchedError>}
    </div>
  );
};

const Select = ({ label, input, inputProps, meta: { touched, error } }) => {
  const { options, disabled } = inputProps || {};
  return (
    <div>
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <select
        {...input}
        className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6"
        disabled={disabled}
      >
        {options.map(item => {
          return (
            <option key={item.key} value={item.value}>
              {item.text}
            </option>
          );
        })}
      </select>
      {touched && error && <TouchedError>{error}</TouchedError>}
    </div>
  );
};

const Search = ({ label, input, inputProps, meta: { touched, error } }) => {
  return (
    <div>
      <SearchModal caption={label} {...input} {...inputProps} />
      {touched && error && <TouchedError>{error}</TouchedError>}
    </div>
  );
};

const MultiSelection = ({
  label,
  input,
  inputProps,
  meta: { touched, error },
}) => {
  return (
    <div>
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <DropdownMulti {...input} {...inputProps} />
      {touched && error && <TouchedError>{error}</TouchedError>}
    </div>
  );
};

const DatePicker = ({ label, input, inputProps, meta: { touched, error } }) => {
  return (
    <div>
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <DatePickerInput value={input.value} inputProps={input} {...inputProps} />
      {touched && error && <TouchedError>{error}</TouchedError>}
    </div>
  );
};

const Time = ({ label, type, input, inputProps, meta: { touched, error } }) => {
  inputProps = {
    ...inputProps,
    mask: '99:99',
    maskChar: '',
  };
  return (
    <div>
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <div>
        <InputMask
          type={type}
          {...input}
          {...inputProps}
          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
        />
      </div>
      {touched && error && <TouchedError>{error}</TouchedError>}
    </div>
  );
};

const Text = ({ label }) => {
  return (
    <label className="block text-sm font-medium leading-6 text-gray-900">
      {label}
    </label>
  );
};

const UploadFile = ({ label, inputProps }) => {
  const { onDropAccepted, removeFile, forceChange } = inputProps;
  const reduxFormChange = forceChange || (() => {});

  const props = {
    onDropAccepted: file => {
      if (onDropAccepted) onDropAccepted(file, reduxFormChange);
    },
    removeFile: () => {
      if (removeFile) removeFile(reduxFormChange);
    },
  };

  return (
    <div>
      <Uploader label={label} {...props} />
    </div>
  );
};

const Currency = ({ label, input, inputProps, meta: { touched, error } }) => {
  const {
    placeholder = '',
    thousandSeparator = '.',
    decimalSeparator = ',',
    decimalScale = 2,
    fixedDecimalScale = false,
  } = inputProps;
  const { forceChange } = inputProps;
  const reduxFormChange = forceChange || (() => {});

  return (
    <div>
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {label}
      </label>
      <NumberFormat
        {...input}
        placeholder={placeholder}
        thousandSeparator={thousandSeparator}
        decimalSeparator={decimalSeparator}
        decimalScale={decimalScale}
        fixedDecimalScale={fixedDecimalScale}
        onValueChange={values => {
          const { name } = input;
          reduxFormChange(`_${name}`, values);
        }}
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
      />
      {touched && error && <TouchedError>{error}</TouchedError>}
    </div>
  );
};

const Btn = ({ label, type, input, btnProps, meta: { touched, error } }) => {
  return (
    <>
      <Button type={type} {...input} {...btnProps}>
        {label}
      </Button>
      {touched && error && <TouchedError>{error}</TouchedError>}
    </>
  );
};

export {
  Input,
  TextArea,
  Toggle,
  Select,
  MultiSelection,
  Search,
  DatePicker,
  Time,
  Text,
  UploadFile,
  Currency,
  Btn,
};
