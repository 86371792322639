export const Types = {
  CREATE_SHIPPER: 'shipper/CREATE_SHIPPER',
  UPDATE_SHIPPER: 'shipper/UPDATE_SHIPPER',
  INVITE_SHIPPER: 'shipper/INVITE_SHIPPER',
  RESEND_INVITE_SHIPPER: 'shipper/RESEND_INVITE_SHIPPER',
  CHANGE_EMAIL_SHIPPER: 'operator/CHANGE_EMAIL_SHIPPER',
  SET_RESULTS: 'shipper/SET_RESULTS',
  NEXT_RESULTS: 'shipper/NEXT_RESULTS',
  SET_PAGE_INFO: 'shipper/SET_PAGE_INFO',
  LOAD_QUERY: 'shipper/LOAD_QUERY',
  LOAD_MUTATION: 'shipper/LOAD_MUTATION',
  FETCH_SHIPPERS: 'shipper/FETCH_SHIPPERS',
  UPDATE_STATUS_SHIPPER: 'shipper/UPDATE_STATUS_SHIPPER',
  FETCH_UNASSIGNEDSHIPPERS: 'shipper/FETCH_UNASSIGNEDSHIPPERS',
  GET_SHIPPERS: 'shipper/GET_SHIPPERS',
  GET_EXTERNAL_SHIPPERS: 'shipper/GET_EXTERNAL_SHIPPERS',
  GET_CONTRACTORS: 'shipper/Types.GET_CONTRACTORS',
  RESET_STATE: 'shipper/RESET_STATE',
  USER_LOGOUT: 'USER_LOGOUT',
};

const initialState = {
  results: [],
  pageInfo: {},
  load_query: false,
  load_mutation: false,
};

export default function shipper(state = initialState, action) {
  switch (action.type) {
    case Types.SET_RESULTS:
      return { ...state, results: action.results };
    case Types.NEXT_RESULTS:
      return { ...state, results: [...state.results, ...action.results] };
    case Types.SET_PAGE_INFO:
      return { ...state, pageInfo: action.pageInfo };
    case Types.LOAD_QUERY:
      return { ...state, load_query: action.load };
    case Types.LOAD_MUTATION:
      return { ...state, load_mutation: action.load };
    case Types.RESET_STATE:
    case Types.USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export function saveShipper({ data, edit, success, error }) {
  let newData = data;

  newData.cnpj = newData.cnpj
    .replaceAll('.', '')
    .replaceAll('-', '')
    .replaceAll('/', '');

  return {
    type: edit === true ? Types.UPDATE_SHIPPER : Types.CREATE_SHIPPER,
    data: newData,
    success,
    error,
  };
}

export function changeStatusShipper({ data, success, error }) {
  return {
    type: Types.UPDATE_STATUS_SHIPPER,
    data,
    success,
    error,
  };
}

export function changeEmailShipper({ data, success, error }) {
  return {
    type: Types.CHANGE_EMAIL_SHIPPER,
    data,
    success,
    error,
  };
}

export function fetchShippers({ search, cursor }) {
  let filter;
  if (search && search.length > 0) {
    filter = {
      OR: [
        { corporateName_LIKE: search },
        { commercialName_LIKE: search },
        { cnpj: search },
      ],
    };
  }

  return {
    type: Types.FETCH_SHIPPERS,
    cursor,
    filter,
  };
}

export function fetchUnassignedShippers({ search, cursor }) {
  let filter;
  if (search && search.length > 0) {
    filter = {
      AND: [
        { corporateName_LIKE: search },
        { commercialName_LIKE: search },
      ],
    };
  }

  return {
    type: Types.FETCH_UNASSIGNEDSHIPPERS,
    cursor,
    filter,
  };
}

export function inviteShipper({ data, success, error }) {
  return {
    type: Types.INVITE_SHIPPER,
    data,
    success,
    error,
  };
}

export function resendInviteShipper({ data, success, error }) {
  return {
    type: Types.RESEND_INVITE_SHIPPER,
    data,
    success,
    error,
  };
}

export function getShippers({ search, first, success, error }) {
  let filter;
  if (search && search.length > 0) {
    filter = {
      OR: [{ corporateName_LIKE: search }, { commercialName_LIKE: search }],
    };
  }

  return {
    type: Types.GET_SHIPPERS,
    first,
    filter,
    success,
    error,
  };
}

export function getExternalShippers({ search, first, success, error }) {
  let filter;
  if (search && search.length > 0) {
    const upperCaseSearch = search.toUpperCase();
    filter = {
      OR: [
        {
          corporateName_IN_JSON_CONTAINING: upperCaseSearch,
        },
        {
          commercialName_IN_JSON_CONTAINING: upperCaseSearch,
        },
        {
          cnpj_IN_JSON_CONTAINING: upperCaseSearch,
        },
      ],
    };
  }

  return {
    type: Types.GET_EXTERNAL_SHIPPERS,
    first,
    filter,
    success,
    error,
  };
}

export function getContractors() {
  return {
    type: Types.GET_CONTRACTORS,
  };
}

export function resetState() {
  return {
    type: Types.RESET_STATE,
  };
}
