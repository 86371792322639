import { client, upload } from '@config/apollo';
import gql from 'graphql-tag';
import { call, put, select } from 'redux-saga/effects';
import { Types } from '../ducks/expense';
import { Types as ToastTypes } from '../ducks/toast';


export function* createExpense(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);


  function isImageValid(image) {
    const imageSize = image.size / 1024 ** 2;

    if (!!imageSize && imageSize > 30) {
      return false
    }

    return true
  }

  try {
    const cost =
      (action.data._cost &&
        !isNaN(action.data._cost.floatValue) &&
        action.data._cost.floatValue) ||
      0;
    const { invoiceKey, description, type, files, note } = action.data;

    if (files && files.length > 0) {
      let imageInvalid = false
      let imageName = ""

      files.forEach(file => {

        if (!isImageValid(file)) {
          imageInvalid = true
          imageName = file.name
        }

      })

      if (imageInvalid) {
        return yield put({
          type: ToastTypes.SET_MESSAGE,
          message: {
            type: 'error',
            message:
              `A imagem anexada ${imageName} tem tamanho superior ao limite permitido (30mb).`,
          },
        });
     }

    }


    yield put({ type: Types.LOAD_MUTATION, load: true });
    const response = yield call(cli.mutate, {
      mutation: gql`
        mutation ($data: ExpenseInput!) {
          createExpense(data: $data) {
            id
          }
        }
      `,
      variables: {
        data: {
          invoiceKey,
          description,
          type,
          note,
          cost,
        },
      },
    });

    if (files && files.length > 0) {
        const formData = new FormData();
        formData.append('id', response.data.createExpense.id);
        formData.append('attachments', files[0]);
        formData.append('attachments', files[1]);
        formData.append('attachments', files[2]);
        formData.append('attachments', files[3]);
        yield upload(auth.token, `/uploads/expenses/${invoiceKey}`, formData);

    }

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Despesa criada com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* editExpense(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    yield put({ type: Types.LOAD_MUTATION, load: true });

    const { id, data } = action;
    delete action.data._cost;

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($id: String!, $data: ExpenseUpdateInput!) {
          editExpense(id: $id, data: $data) {
            ok
          }
        }
      `,
      variables: {
        id,
        data: { ...data },
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Despesa editada com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* fetchExpense(args = {}) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  const { cursor, filter } = args;
  const companyId = auth.company ? auth.company.id : undefined;

  try {
    yield put({ type: Types.LOAD_QUERY, load: true });

    const response = yield call(cli.query, {
      query: gql`
        query ($last: Int, $before: String, $filter: ExpenseFilterInput) {
          expenses(last: $last, before: $before, filter: $filter) {
            pageInfo {
              hasNextPage
              after
              before
            }
            edges {
              cursor
              node {
                id
                cost
                description
                createdAt
                approved
                rejectedAt
                approvedAt
                attachments
                reason
                shipper {
                  company {
                    corporateName
                    commercialName
                  }
                }
                type {
                  id
                  description
                }
                auditedBy {
                  id
                  firstName
                  lastName
                }
                invoice {
                  id
                  invoiceNo
                  key
                  activities {
                    emittedAt
                  }
                  customer {
                    id
                    commercialName
                  }
                }
              }
            }
          }
        }
      `,
      variables: {
        last: 50,
        before: cursor,
        filter: {
          ...filter,
          companyId,
        },
      },
    });

    const pageInfo = response.data.expenses.pageInfo;
    const results = response.data.expenses.edges;

    yield put({
      type: Types.SET_PAGE_INFO,
      pageInfo: { ...pageInfo, cursor: pageInfo.before },
    });

    if (cursor) {
      yield put({ type: Types.NEXT_RESULTS, results });
    } else {
      yield put({ type: Types.SET_RESULTS, results });
    }
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
  } finally {
    yield put({ type: Types.LOAD_QUERY, load: false });
  }
}

export function* getExpense(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);
  const { id } = action;
  try {
    const response = yield call(cli.query, {
      query: gql`
        query Expense($id: String) {
          expense(id: $id) {
            id
            description
          }
        }
      `,
      variables: {
        id: id,
      },
    });

    const results = response.data.expense;

    if (action.success) action.success(results);
  } catch (error) {
    if (action.error) action.error(error);
  }
}
