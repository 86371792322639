import { FormCreate, Modal } from '@components';
import validate from './validate';

const Form = FormCreate('AgreedToForm', validate);

const AgreedToForm = ({ open, onClose, data, ...rest }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Edite a data de previsão combinada com o cliente"
      modalOverflow
    >
      <Form
        {...rest}
        edit={true}
        fields={[
          {
            name: 'dateHour',
            component: 'input',
            type: 'text',
            label: 'Data de previsão:',
            inputProps: {
              value: data.dateHour || undefined,
              disabled: true,
            },
          },
          [
            {
              name: 'date',
              component: 'date',
              type: 'text',
              label: 'Nova data:',
              inputProps: {
                placeholder: 'Selecione a nova data',
              },
            },
          ],
        ]}
      />
    </Modal>
  );
};

export default AgreedToForm;
