const NotFound = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-white">
      <div className="flex flex-col">
        <div className="flex flex-col items-center">
          <div className="text-blue-600 font-bold text-7xl">404</div>

          <div className="font-bold text-3xl xl:text-7xl lg:text-6xl md:text-5xl mt-10">
            Esta página não existe
          </div>

          <div className="text-gray-400 font-medium text-sm md:text-xl lg:text-2xl mt-8">
            A página que você está procurando não pôde ser encontrada.
          </div>
        </div>

        <div className="flex flex-col mt-12">
          <p className="mt-4 text-center text-lg text-gray-500">
            <a
              href="/"
              className="font-semibold leading-6 text-blue-600 hover:text-blue-500"
            >
              Clique aqui{' '}
            </a>
            para prosseguir
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
