import { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { permissions } from '@config/permission';
import { SearchModal, DateRangePicker, Button, Input } from '@components';
import { getShippers } from '@redux/ducks/shipper';

const Filter = props => {
  const dispatch = useDispatch();
  const initialState = props.initialState || {};
  const [state, setState] = useState({
    from: initialState.from,
    to: initialState.to,
    plate: initialState.plate,
    dispatcher: initialState.dispatcher,
    dispatcherName: initialState.dispatcherName,
    dispatcherMail: initialState.dispatcherMail,
  });
  /*
  constructor(props) {
    super(props);

    const initialState = props.initialState || {};

    state = {
      from: initialState.from,
      to: initialState.to,
      plate: initialState.plate,
      dispatcher: initialState.dispatcher,
      dispatcherName: initialState.dispatcherName,
      dispatcherMail: initialState.dispatcherMail,
    };
  }*/

  const onSearch = () => {
    const { onSearch } = props;
    if (onSearch) onSearch(state);
  };

  const clearFilter = () => {
    setState(
      {
        from: '',
        to: '',
        plate: '',
        dispatcher: '',
        dispatcherName: '',
        dispatcherMail: '',
      },
      () => onSearch()
    );
  };
  const { from, to, plate, dispatcherName } = state;
  return (
    <>
      <div className="flex sm:items-end gap-2 flex-col sm:flex-row">
        <div className="">
          <DateRangePicker
            placeholderFrom="Período de"
            placeholderTo="Até"
            from={from}
            to={to}
            onChangeFrom={from => {
              setState(oldState => ({ ...oldState, from }));
            }}
            onChangeTo={to => {
              setState(oldState => ({ ...oldState, to }));
            }}
          />
        </div>
        <div className="flex flex-col">
          <div className="text-sm font-medium leading-6 text-gray-900">
            Placa
          </div>
          <Input
            type="text"
            value={plate}
            placeholder="Informe a Placa"
            onChange={({ target }) => {
              setState(oldState => ({ ...oldState, plate: target.value }));
            }}
          />
        </div>
        {permissions.invoice.dispatcherFilter() && (
          <div className="flex flex-col">
            <SearchModal
              caption="Transportadora"
              value={dispatcherName || ''}
              keyExtractor={item => item.node.id}
              textExtractor={item =>
                `${item.node.code ? '(' + item.node.code + ') ' : ''}${
                  item.node.company.corporateName
                } - ${item.node.company.commercialName || ''}`
              }
              onSearch={search => {
                return new Promise((resolve, reject) => {
                  dispatch(
                    getShippers({
                      search,
                      success: results => {
                        resolve(results);
                      },
                      error: error => {
                        reject(error);
                      },
                    })
                  );
                });
              }}
              onSelect={item => {
                setState(oldState => ({
                  ...oldState,
                  dispatcher: item.node.id,
                  dispatcherName: item.node.company.corporateName,
                  dispatcherMail: item.node.company.user.email,
                  selectedInvoices: [],
                }));
              }}
            />
          </div>
        )}
        <div className="">
          <div className="flex gap-2">
            <Button
              variant="primary"
              onClick={() => {
                onSearch();
              }}
            >
              Consultar
            </Button>
            <Button
              onClick={() => {
                clearFilter();
              }}
            >
              Limpar
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    state,
  };
};

export default connect(mapStateToProps)(Filter);
