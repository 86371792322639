import { Route, Routes } from 'react-router-dom';
import { NotFound, Operator, Shipper } from '@pages';
import { Protected } from '@components';

function RoutesUsers() {
  return (
    <Protected>
      <Routes>
        <Route index path="operators" element={<Operator />} />
        <Route path="shippers" element={<Shipper />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Protected>
  );
}

export default RoutesUsers;
