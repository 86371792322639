import { useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import TransportQueueList from './components/TransportQueueList';
import TransportQueueForm from './components/TransportQueueForm';
import {
  fetchTransportQueue,
  addDriverQueue,
  removeDriverQueue,
} from '@redux/ducks/transportQueue';

import NumberFormat from 'react-number-format';
import moment from 'moment';
import { Confirm, TableForm } from '@components';
import { Navigate } from 'react-router-dom';
import { companyCustomConfiguration } from '../../config/api';

const TransportQueue = ({ results, load_query, contextCompany }) => {
  const formRef = useRef(null);
  const listRef = useRef(null);
  const confirm = useRef();
  const dispatch = useDispatch();

  const customConfiguration = companyCustomConfiguration.find(
    company => company.cnpj === '76827344000130'
  );

  function initialize() {
    window.scroll(0, 0);
    listRef.current.closeForm();
    dispatch(fetchTransportQueue());
  }

  function handleSave(data, edit) {
    return new Promise((resolve, reject) => {
      dispatch(
        addDriverQueue({
          data,
          edit,
          success: () => {
            initialize();
            resolve();
          },
          error: error => {
            reject(error);
          },
        })
      );
    });
  }

  function handleRemove(item) {
    const { id } = item;
    confirm.current.open(
      'Deseja realmente excluir a marcação selecionada?',
      () => {
        dispatch(
          removeDriverQueue({
            id,
            success: initialize,
          })
        );
      }
    );
  }

  const Format = ({ value }) => {
    value = value || 0;
    return (
      <NumberFormat
        value={value}
        displayType={'text'}
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={2}
        fixedDecimalScale
      />
    );
  };

  useEffect(() => {
    initialize();
  }, []);

  if (contextCompany.cnpj !== customConfiguration.cnpj) {
    return <Navigate to="/invoices/pending" />;
  }

  return (
    <>
      <TableForm
        source={results.marcacao || []}
        load={load_query}
        ref={listRef}
        formHeader={{
          title: 'Fila de carga',
          subtitle: 'Gerencie e visualize a fila de carga',
        }}
        labelModalOpen="Informar previsão de chegada"
        onNew={() => listRef.current.openForm()}
        titleModal="Informar previsão de chegada"
        modalOverflow
        headers={[
          {
            label: 'Cadastro',

            getValue: item =>
              moment(`${item.c1}-${item.c2}`).format('DD/MM/YYYY-HH:mm:ss'),
          },
          { label: 'Motorista', getValue: item => item.c5 },
          { label: 'Placa', getValue: item => item.c6 },
          { label: 'OBS', getValue: item => item.c11 },
          { label: 'Carga', getValue: item => item.c7 },
          {
            label: 'Cubagem',
            getValue: item => <Format value={item.c8} />,
          },
          {
            label: 'Chegada',
            getValue: item =>
              moment(`${item.c9}-${item.c10}`).format('DD/MM/YYYY-HH:mm'),
          },
        ]}
        keyRow={item => item.id}
        headersBtn={[
          {
            content: 'Atualizar',
            onClick: () => {
              initialize();
            },
          },
        ]}
        actionsBtn={[
          {
            content: 'Excluir',
            onClick: item => {
              handleRemove(item);
            },
          },
        ]}
      >
        <TransportQueueForm ref={formRef} onSubmit={handleSave} />
      </TableForm>
      <TransportQueueList source={results.fila || []} load={load_query} />
      <Confirm ref={confirm} />
    </>
  );
};

function mapStateToProps({ auth, transportQueue }) {
  const { company } = auth;
  const { results, load_query } = transportQueue;

  return {
    results,
    load_query,
    contextCompany: company,
  };
}

export default connect(mapStateToProps)(TransportQueue);
