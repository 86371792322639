import { client } from '../../config/apollo';
import gql from 'graphql-tag';
import { call, put, select } from 'redux-saga/effects';
import { Types } from '../ducks/overview';

export function* fetchOverview(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    yield put({ type: Types.SET_ERROR_QUERY, error: {} });
    yield put({ type: Types.LOAD_QUERY, load: true });

    const response = yield call(cli.query, {
      query: gql`
        query ($filter: InvoiceFilterInput) {
          invoicesOverview(filter: $filter) {
            pending
            expiring
            overdue
            withIncidents
            withReturns
          }
        }
      `,
      variables: {
        filter: action.filter,
      },
    });

    yield put({
      type: Types.SET_DATA_OBJECT,
      dataObject: response.data.invoicesOverview,
    });
  } catch (error) {
    yield put({ type: Types.SET_ERROR_QUERY, error });
  } finally {
    yield put({ type: Types.LOAD_QUERY, isFetching: false });
  }
}
