import { useEffect } from 'react';
import { Modal } from '@components';

import DevolutionDetailComponent from './component/DevolutionDetailComponent';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchDetail } from '@redux/ducks/reversal';

const DevolutionInvoiceDetail = ({
  reversalDetail,
  fetchDetail,
  openDetailsModal,
  id,
  ...rest
}) => {
  useEffect(() => {
    if (openDetailsModal) {
      fetchDetail(id);
    }
  }, [id]);

  return (
    <Modal {...rest}>
      {reversalDetail && <DevolutionDetailComponent item={reversalDetail} />}
    </Modal>
  );
};

function mapStateToProps({ reversal }) {
  return {
    reversalDetail: reversal.reversalDetail,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchDetail }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DevolutionInvoiceDetail);
