import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { getInvoice } from '@redux/ducks/invoice';

import { Modal, Tabs } from '@components';
import { Load } from '@components';

import Detail from '@pages/invoices/components/invoiceDetail/Detail';
import Historic from '@pages/invoices/components/invoiceDetail/Historic';
import { QRCode } from '@pages/invoices/components/invoiceDetail/QRCode';
import DetailExpense from '@pages/invoices/components/invoiceDetail/DetailExpense';
import { DetailReturn } from '@pages/invoices/components/invoiceDetail/DetailReturn';
import DetailIncident from '@pages/invoices/components/invoiceDetail/DetailIncident';
import DetailReversal from '@pages/invoices/components/invoiceDetail/DetailReversal';
import { DetailDelivery } from '@pages/invoices/components/invoiceDetail/DetailDelivery';

const InvoiceDetail = ({
  invoiceNo,
  setInvoiceNo,
  load_query,
  onTerminate,
}) => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState('Detalhamento');
  const [invoice, setInvoice] = useState({});

  const close = () => {
    setInvoiceNo('');
    setInvoice({});
    setSelectedTab('Detalhamento');
  };

  const noContentFound = message => {
    return (
      <div className="mt-6 flex justify-center items-center bg-orange-100 py-2 text-gray-700 text-sm font-semibold rounded-md">
        {message}
      </div>
    );
  };

  const renderPanel = panel => {
    switch (panel) {
      case 'Detalhamento':
        if (invoiceNo) {
          return <Detail source={invoice} shippingOrderDetail />;
        }
        return null;

      case 'Histórico':
        return <Historic source={invoice} invoiceKey={invoice.key} />;

      case 'Entregas':
        if (invoice.deliveries) {
          return (
            <DetailDelivery
              source={invoice.deliveries}
              invoiceKey={invoice.key}
              invoice={invoice}
            />
          );
        }
        return null;

      case 'Devolução':
        if (invoice.reversal) {
          return <DetailReversal source={invoice.reversal} />;
        }
        return null;

      case 'QR Code da nota':
        return (
          <div className="p-3">
            <QRCode value={invoice.key} />
          </div>
        );

      case 'Despesas':
        if (invoice.expenses) {
          return (
            <DetailExpense
              source={invoice.expenses}
              invoiceKey={invoice.key}
              invoiceId={invoice.id}
            />
          );
        }
        return noContentFound('Nenhuma despesa cadastrada');

      case 'Rejeição':
        if (invoice.return) {
          return <DetailReturn source={invoice} />;
        }
        return null;

      case 'Incidentes':
        if (invoice.incidents) {
          return (
            <DetailIncident
              source={invoice.incidents}
              invoiceKey={invoice.key}
              onTerminate={invoiceKey => {
                dispatch(
                  getInvoice({
                    search: invoiceKey,
                    success: invoice => {
                      setInvoice(invoice);
                    },
                  })
                );
                if (onTerminate) onTerminate();
              }}
            />
          );
        }
        return null;

      default:
        return null;
    }
  };

  useEffect(() => {
    if (invoiceNo) {
      dispatch(
        getInvoice({
          search: invoiceNo,
          success: invoice => {
            setInvoice(invoice);
          },
        })
      );
    }
  }, [invoiceNo]);

  if (!invoiceNo) {
    return null;
  }

  return (
    <>
      <Modal
        onClose={() => close()}
        open={!!invoice}
        title="Detalhamento da nota"
        containerClassSize="max-w-full"
      >
        <div className="col-span-2">
          <Tabs>
            <Tabs.Item
              name="detail"
              active={selectedTab === 'Detalhamento'}
              onClick={() => setSelectedTab('Detalhamento')}
            >
              Detalhamento
            </Tabs.Item>
            <Tabs.Item
              name="history"
              active={selectedTab === 'Histórico'}
              onClick={() => setSelectedTab('Histórico')}
            >
              Histórico
            </Tabs.Item>
            {invoice.deliveries && invoice.deliveries.length > 0 && (
              <Tabs.Item
                name="deliveries"
                active={selectedTab === 'Entregas'}
                onClick={() => setSelectedTab('Entregas')}
              >
                Entregas
              </Tabs.Item>
            )}
            {invoice.reversal && (
              <Tabs.Item
                name="devolution"
                active={selectedTab === 'Devolução'}
                onClick={() => setSelectedTab('Devolução')}
              >
                Devolução
              </Tabs.Item>
            )}
            <Tabs.Item
              name="invoiceQRcode"
              active={selectedTab === 'QR Code da nota'}
              onClick={() => setSelectedTab('QR Code da nota')}
            >
              QR Code da Nota
            </Tabs.Item>
            <Tabs.Item
              name="expenses"
              active={selectedTab === 'Despesas'}
              onClick={() => setSelectedTab('Despesas')}
            >
              Despesas
            </Tabs.Item>
            {invoice.return && (
              <Tabs.Item
                name="return"
                active={selectedTab === 'Rejeição'}
                onClick={() => setSelectedTab('Rejeição')}
              >
                Rejeição
              </Tabs.Item>
            )}
            {invoice.incidents && invoice.incidents.length > 0 && (
              <Tabs.Item
                name="incidents"
                active={selectedTab === 'Incidentes'}
                onClick={() => setSelectedTab('Incidentes')}
              >
                Incidentes
              </Tabs.Item>
            )}
          </Tabs>
        </div>
        {Object.keys(invoice).length > 0 ? (
          renderPanel(selectedTab)
        ) : (
          <Load active={load_query} />
        )}
      </Modal>
    </>
  );
};

function mapStateToProps({ invoice }) {
  const { load_query } = invoice;

  return {
    load_query,
  };
}

export default connect(mapStateToProps)(InvoiceDetail);
