const validate = values => {
  const errors = {};
  if (!values.type || values.type === '-1') {
    errors.type = 'Selecione o tipo de despesa!';
  }
  if (!values.cost) {
    errors.cost = 'Informe um valor para a despesa!';
  }
  if (!values.description) {
    errors.description = 'Informe uma descrição para a despesa!';
  }
  return errors;
};

export default validate;
