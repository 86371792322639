import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import Logo from '@assets/img/shipy.svg';
import ActivateInvitationForm from './components/ActivateInvitationForm';
import { Toast } from '@components';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { validatePassword } from '@redux/ducks/activateInvitation';

const ActivateInvitation = ({
  validatePassword,
  resetPasswordToken,
  load_mutation,
}) => {
  const navigate = useNavigate();
  const toastRef = useRef(null);

  async function handleConfirmInvitation({ password, passwordConfirmation }) {
    validatePassword({
      data: {
        password,
        passwordConfirmation,
        resetPasswordToken,
      },
      success: () => navigate('/success-validation'),
      error: ({ message }) => {
        const filteredMessage = message.replace('GraphQL error: ', '');
        toastRef.current.show({
          message: filteredMessage,
          type: 'error',
        });
      },
    });
  }

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <img
          className="mx-auto h-20 w-auto pl-5"
          src={Logo}
          alt="Logo do Shipy"
        />
        <h2 className="mt-10 text-center text-5xl font-bold leading-10 tracking-tight text-gray-900">
          Shipy
        </h2>
      </div>

      <div className="sm:mx-auto sm:w-full sm:max-w-sm mt-8">
        <p className="text-center">
          Informe os dados a seguir para concluir seu cadastro e ativar sua
          conta no Shipy
        </p>
      </div>

      <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-sm">
        <ActivateInvitationForm
          submitFunction={handleConfirmInvitation}
          load_mutation={load_mutation}
        />
      </div>
      <Toast ref={toastRef} />
    </div>
  );
};

function mapStateToProps({ activateInvitation }) {
  const { resetPasswordToken, load_mutation } = activateInvitation;

  return {
    resetPasswordToken,
    load_mutation,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ validatePassword }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivateInvitation);
