import { connect } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

const Protected = function ({ token, user, company, children }) {
  const location = useLocation();

  const userIsEmpty = Object.keys(user).length === 0;

  if (!token || userIsEmpty) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (user.role.name === 'SHIPPER' && !company?.id) {
    return (
      <Navigate to="/unrelated-shipper" state={{ from: location }} replace />
    );
  }

  return children;
};

function mapStateToProps({ auth }) {
  const { token, user, company } = auth;
  return { token, user, company };
}

export default connect(mapStateToProps)(Protected);
