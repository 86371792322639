import { Route, Routes } from 'react-router-dom';
import { NotFound, Companies } from '@pages';
import { Protected } from '@components';

function RoutesCompanies() {
  return (
    <Protected>
      <Routes>
        <Route index element={<Companies />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Protected>
  );
}

export default RoutesCompanies;
