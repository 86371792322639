import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { TableForm } from '@components';
import MessageForm from './components/MessageForm';
import { fetchMessages, saveMessage } from '@redux/ducks/message';

import moment from 'moment';

const Messages = ({
  results,
  fetchMessages,
  saveMessage,
  pageInfo,
  load_query,
}) => {
  const listRef = useRef(null);
  const formRef = useRef(null);

  const initialize = () => {
    window.scroll(0, 0);
    listRef?.current?.closeForm();
    fetchMessages({});
  };

  const next = () => {
    fetchMessages({
      cursor: pageInfo.cursor,
    });
  };

  const onSave = (data, edit) => {
    return new Promise((resolve, reject) => {
      saveMessage({
        data,
        edit,
        success: () => {
          initialize();
          resolve();
        },
        error: error => {
          reject(error);
        },
      });
    });
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <TableForm
      formHeader={{
        title: 'Mensagens',
        subtitle: 'Gerencie e visualize as mensagens',
      }}
      titleModal="Criar nova mensagem"
      source={results}
      hasNext={pageInfo.hasNextPage}
      load={load_query}
      next={next}
      ref={listRef}
      headers={[
        {
          label: 'Usuário',
          getValue: item =>
            `${item?.node?.sender?.firstName} ${item?.node?.sender?.lastName}`,
        },
        { label: 'Assunto', getValue: item => item?.node?.subject },
        { label: 'Descrição', getValue: item => item?.node?.content },
        {
          label: 'Enviado',
          props: { singleLine: true },
          getValue: item =>
            item?.node?.activities?.sentAt &&
            moment(item.node.activities.sentAt).format('DD/MM/YYYY-HH:mm'),
        },
        {
          label: 'Recebido',
          props: { singleLine: true },
          getValue: item =>
            item?.node?.activities?.receivedAt &&
            moment(item.node.activities.receivedAt).format('DD/MM/YYYY-HH:mm'),
        },
        {
          label: 'Lido',
          props: { singleLine: true },
          getValue: item =>
            item?.node?.activities?.readAt &&
            moment(item.node.activities.readAt).format('DD/MM/YYYY-HH:mm'),
        },
      ]}
      keyRow={item => item.node.id}
    >
      <MessageForm onSubmit={onSave} ref={formRef} />
    </TableForm>
  );
};

function mapStateToProps(state) {
  const { results, pageInfo, load_query } = state.message;

  return {
    results,
    pageInfo,
    load_query,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchMessages, saveMessage }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
