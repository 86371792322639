class Hora {
  validate(value) {
    if (!value || value.length < 5) {
      return false;
    }

    const hrs = value.substring(0, 2);
    const min = value.substring(3, 5);

    if (hrs < 0 || hrs > 23 || min < 0 || min > 59) {
      return false;
    }

    return true;
  }
}

export default new Hora();
