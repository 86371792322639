import { client } from '@config/apollo';
import gql from 'graphql-tag';
import { call, put, select } from 'redux-saga/effects';
import { Types } from '../ducks/shipper';
import { Types as AuthTypes } from '../ducks/auth';
import { Types as ToastTypes } from '../ducks/toast';

export function* createShipper(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const {
      cnpj,
      corporateName,
      commercialName,
      email,
      phone,
      stateInscription,
      contactFirstName,
      contactLastName,
      neighborhood,
      address,
      cityId,
    } = action.data;
    const phoneOnlyNumber = phone.replace(/\D/g, '');
    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($shipper: ShipperInput!) {
          createShipper(shipper: $shipper) {
            id
          }
        }
      `,
      variables: {
        shipper: {
          company: {
            cnpj,
            corporateName,
            commercialName,
            email,
            phone: phoneOnlyNumber,
            stateInscription,
            contact: contactFirstName,
            neighborhood,
            address,
            city: cityId,
            user: {
              email,
              firstName: contactFirstName,
              lastName: contactLastName,
              active: true,
            },
          },
        },
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Transportadora convidada com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* updateShipper(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);
  try {
    const {
      shipperId,
      cnpj,
      code,
      active,
      corporateName,
      commercialName,
      email,
      phone,
      stateInscription,
      contactFirstName,
      contactLastName,
      neighborhood,
      address,
      cityId,
    } = action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($shipperId: String!, $shipper: ShipperInput!) {
          updateShipper(shipperId: $shipperId, shipper: $shipper) {
            ok
          }
        }
      `,
      variables: {
        shipperId,
        shipper: {
          code,
          active,
          company: {
            cnpj,
            corporateName,
            commercialName,
            email,
            phone,
            user: {
              email,
              firstName: contactFirstName,
              lastName: contactLastName,
            },
            stateInscription,
            contact: contactFirstName,
            neighborhood,
            address,
            city: cityId,
          },
        },
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Transportadora alterada com sucesso',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* changeEmailShipper(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { shipperId, newEmail } = action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($shipperId: String!, $newEmail: String!) {
          changeShipperUserEmail(shipperId: $shipperId, newEmail: $newEmail) {
            ok
          }
        }
      `,
      variables: {
        shipperId,
        newEmail,
      },
    });

    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* changeStatusShipper(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { active, shipperId } = action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($shipperId: String!, $active: Boolean!) {
          toggleShipper(shipperId: $shipperId, active: $active) {
            ok
          }
        }
      `,
      variables: {
        shipperId,
        active,
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Transportadora alterada com sucesso',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* fetchShippers(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    yield put({ type: Types.LOAD_QUERY, load: true });

    const { cursor, filter } = action;

    const response = yield call(cli.query, {
      query: gql`
        query ($first: Int!, $after: String, $filter: ShipperFilterInput) {
          shippers(first: $first, after: $after, filter: $filter) {
            edges {
              node {
                id
                code
                active
                status
                company {
                  cnpj
                  corporateName
                  commercialName
                  phone
                  email
                  user {
                    id
                    email
                    firstName
                    lastName
                  }
                  stateInscription
                  contact
                  neighborhood
                  address
                  city {
                    id
                    code
                    name
                  }
                }
                since
              }
            }
            pageInfo {
              hasNextPage
              after
              before
            }
          }
        }
      `,
      variables: {
        first: 50,
        after: cursor,
        filter,
      },
    });

    const pageInfo = response.data.shippers.pageInfo;
    const results = response.data.shippers.edges;

    yield put({
      type: Types.SET_PAGE_INFO,
      pageInfo: { ...pageInfo, cursor: pageInfo.after },
    });

    if (action.cursor) {
      yield put({ type: Types.NEXT_RESULTS, results });
    } else {
      yield put({ type: Types.SET_RESULTS, results });
    }
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
  } finally {
    yield put({ type: Types.LOAD_QUERY, load: false });
  }
}

export function* fetchUnassignedShippers(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    yield put({ type: Types.LOAD_QUERY, load: true });

    const { cursor, filter } = action;
    const response = yield call(cli.query, {
      query: gql`
        query (
          $first: Int!
          $after: String
          $filter: UnassignedShipperFilterInput
        ) {
          unassignedShippers(first: $first, after: $after, filter: $filter) {
            edges {
              node {
                id
                company {
                  cnpj
                  corporateName
                  commercialName
                  phone
                  user {
                    id
                    email
                  }
                  stateInscription
                  contact
                  neighborhood
                  address
                  city {
                    id
                    code
                    name
                  }
                }
              }
            }
            pageInfo {
              hasNextPage
              after
              before
            }
          }
        }
      `,
      variables: {
        first: 50,
        after: cursor,
        filter,
      },
    });

    const pageInfo = response.data.unassignedShippers.pageInfo;
    const results = response.data.unassignedShippers.edges;

    yield put({
      type: Types.SET_PAGE_INFO,
      pageInfo: { ...pageInfo, cursor: pageInfo.after },
    });

    if (action.cursor) {
      yield put({ type: Types.NEXT_RESULTS, results });
    } else {
      yield put({ type: Types.SET_RESULTS, results });
    }
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
  } finally {
    yield put({ type: Types.LOAD_QUERY, load: false });
  }
}

export function* handleShipperInvite(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { userId } = action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation sendInvitation($userId: String!) {
          sendInvitation(userId: $userId) {
            ok
          }
        }
      `,
      variables: {
        userId,
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Transportadora convidada com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* handleResendShipperInvitation(action) {
  const cli = yield call(client);
  const { userId } = action.data;

  yield put({ type: Types.LOAD_MUTATION, load: true });

  try {
    yield call(cli.mutate, {
      mutation: gql`
        mutation resendInvitation($userId: String!) {
          resendInvitation(userId: $userId) {
            ok
          }
        }
      `,
      variables: {
        userId,
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Convite enviado com sucesso',
      },
    });

    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* getShippers(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { filter } = action;

    const response = yield call(cli.query, {
      query: gql`
        query ($first: Int!, $after: String, $filter: ShipperFilterInput) {
          shippers(first: $first, after: $after, filter: $filter) {
            edges {
              node {
                id
                code
                company {
                  cnpj
                  corporateName
                  commercialName
                  phone
                  user {
                    email
                  }
                }
              }
            }
            pageInfo {
              hasNextPage
              after
              before
            }
          }
        }
      `,
      variables: {
        first: action.first || 50,
        after: action.cursor,
        filter,
      },
    });

    const results = response.data.shippers.edges;

    if (action.success) action.success(results);
  } catch (error) {
    if (action.error) action.error(error);
  }
}

export function* getExternalShippers(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  yield put({ type: Types.SET_RESULTS, results: [] });

  try {
    const { filter } = action;

    const response = yield call(cli.query, {
      query: gql`
        query externalShippers($filter: ExternalShipperFilterInput) {
          externalShippers(filter: $filter) {
            cnpj
            corporateName
            commercialName
          }
        }
      `,
      variables: {
        filter,
      },
    });

    const results = response.data.externalShippers;

    if (action.success) action.success(results);
  } catch (error) {
    if (action.error) action.error(error);
  }
}

export function* getContractors() {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  const isShipper = auth?.user?.role?.name === 'SHIPPER';
  if (!isShipper) return;

  const companyId = auth.company?.id;

  const response = yield call(cli.query, {
    query: gql`
      query {
        me {
          ... on Shipper {
            contractors {
              id
              corporateName
              cnpj
            }
            user {
              role {
                name
              }
            }
          }
        }
      }
    `,
  });
  const contractors = response.data?.me?.contractors;
  yield put({ type: AuthTypes.SET_CONTRACTORS, contractors });

  if (!companyId) {
    const firstContractor = contractors[0] || {};
    yield put({ type: AuthTypes.SET_COMPANY, company: firstContractor });
    return;
  }

  const companyAssociated = contractors.find(
    contractor => contractor.id === companyId
  );
  if (!companyAssociated) {
    yield put({ type: AuthTypes.SET_COMPANY, company: {} });
  }
}
