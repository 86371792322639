import { useEffect, useRef } from 'react';

import { Modal } from '@components';
import validate from './validate';

import { FormCreate } from '@components/ReduxForm/Form';
import {
  resendInvitation,
  changeEmailOperator,
  fetchOperators,
} from '@redux/ducks/operator';
import {
  resendInviteShipper,
  changeEmailShipper,
  fetchShippers,
} from '@redux/ducks/shipper';
import { useDispatch } from 'react-redux';

const Form = FormCreate('ResendEmailForm', validate);

const ResendEmailForm = ({
  isResendEmailModalOpen,
  setIsResendEmailModalOpen,
  resendEmailData,
  invitationRole,
  ...rest
}) => {
  const dispatch = useDispatch();
  const formRef = useRef(null);

  async function handleResendEmail({ userId, email }) {
    if (invitationRole === 'SHIPPER') {
      if (email === resendEmailData.company.user.email) {
        return dispatch(
          resendInviteShipper({
            data: {
              userId,
            },
            success: () => setIsResendEmailModalOpen(false),
          })
        );
      } else if (email !== resendEmailData.company.user.email) {
        return dispatch(
          changeEmailShipper({
            data: {
              shipperId: resendEmailData.id,
              newEmail: email,
            },
            edit: true,
            success: () => {
              dispatch(
                resendInvitation({
                  data: {
                    userId,
                  },
                })
              );
              dispatch(fetchShippers({}));
              setIsResendEmailModalOpen(false);
            },
          })
        );
      }
    }

    if (invitationRole === 'OPERATOR') {
      if (email === resendEmailData.user.email) {
        return dispatch(
          resendInvitation({
            data: {
              userId,
            },
            success: () => setIsResendEmailModalOpen(false),
          })
        );
      } else if (email !== resendEmailData.user.email) {
        return dispatch(
          changeEmailOperator({
            data: {
              operatorId: resendEmailData.id,
              newEmail: email,
            },
            edit: true,
            success: () => {
              dispatch(
                resendInvitation({
                  data: {
                    userId,
                  },
                })
              );
              dispatch(fetchOperators({}));
              setIsResendEmailModalOpen(false);
            },
          })
        );
      }
    }
  }

  useEffect(() => {
    setTimeout(() => {
      formRef?.current?.edit({
        userId: resendEmailData?.user?.id || resendEmailData?.company?.user?.id,
        email:
          resendEmailData?.user?.email || resendEmailData?.company?.user?.email,
      });
    }, 200);
  }, [isResendEmailModalOpen]);

  return (
    <Modal
      open={isResendEmailModalOpen}
      onClose={() => setIsResendEmailModalOpen(false)}
      title="Reenvio de convite"
    >
      <Form
        formRef={formRef}
        lbSubmit="Reenviar"
        onSubmit={handleResendEmail}
        edit
        {...rest}
        fields={[
          {
            name: 'email',
            component: 'input',
            type: 'text',
            label: 'Email:',
            inputProps: {
              placeholder: 'Informe o email',
            },
          },
          {
            name: 'userId',
            component: 'input',
            type: 'text',
            label: 'ID:',
            display: 'none',
          },
        ]}
      />
    </Modal>
  );
};

export default ResendEmailForm;
