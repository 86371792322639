import { useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import RegionForm from './components/RegionForm';
import {
  fetchRegions,
  saveRegion,
  removeRegion,
  resetState,
} from '@redux/ducks/region';
import { Confirm, TableForm, Button } from '@components';
import { useNavigate } from 'react-router-dom';

const Region = props => {
  const formRef = useRef(null);
  const confirmRef = useRef(null);
  const listRef = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { results, pageInfo, load_query, load_mutation } = props;

  async function initialize() {
    dispatch(resetState());
    window.scroll(0, 0);
    dispatch(fetchRegions({}));
  }

  async function onNew() {
    await listRef.current.openForm();
    dispatch(
      fetchRegions({
        success: key => {
          setTimeout(() => {
            formRef?.current?.initialize({
              key: key.toString(),
              active: true,
            });
          }, 200);
        },
      })
    );
  }

  function next() {
    const { pageInfo } = props;
    dispatch(
      fetchRegions({
        cursor: pageInfo.cursor,
      })
    );
  }

  const onSave = (data, edit) => {
    return new Promise((resolve, reject) => {
      dispatch(
        saveRegion({
          data,
          edit,
          success: () => {
            initialize();
            resolve();
            listRef.current.closeForm();
          },
          error: error => {
            reject(error);
          },
        })
      );
    });
  };

  async function edit(region) {
    await listRef.current.openForm();
    const { id, key, description, active } = region;

    setTimeout(() => {
      formRef?.current?.edit({
        id,
        key,
        description,
        active,
      });
    }, 200);
  }

  const onRemove = region => {
    const { id } = region;
    confirmRef.current.open(
      'Deseja realmente excluir a região selecionada?',
      () => {
        dispatch(
          removeRegion({
            id,
            success: () => {
              initialize();
            },
          })
        );
      }
    );
  };

  const goToCities = regionKey => {
    navigate(`/regions/${regionKey}/cities`);
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <>
      <TableForm
        formHeader={{
          title: 'Regiões',
          subtitle: 'Defina as regiões',
        }}
        source={results}
        hasNext={pageInfo.hasNextPage}
        load={load_query}
        next={next}
        ref={listRef}
        keyRow={item => item.node.id}
        onNew={() => onNew()}
        titleModal="Cadastro de região"
        headers={[
          { label: 'Região', getValue: item => item.node.key },
          { label: 'Descrição', getValue: item => item.node.description },
          {
            label: 'Situação',
            getValue: item => (item.node.active ? 'Ativo' : 'Inativo'),
          },
          {
            label: 'Ações',
            props: {
              align: 'center',
              width: '250px',
            },
            getValue: item => (
              <div className="flex gap-2 justify-end">
                <Button compact onClick={() => goToCities(item?.node?.key)}>
                  Cidades
                </Button>
                <Button
                  compact
                  //isLoading={load_mutation && item.node.id === itemInUpdate}
                  onClick={() => edit(item.node)}
                >
                  Editar
                </Button>
                <Button
                  compact
                  variant="negative"
                  onClick={() => onRemove(item.node)}
                >
                  Excluir
                </Button>
              </div>
            ),
          },
        ]}
      >
        <RegionForm
          formRef={formRef}
          onSubmit={onSave}
          loadMutation={load_mutation}
        />
      </TableForm>
      <Confirm ref={confirmRef} />
    </>
  );
};

function mapStateToProps(state) {
  const { results, pageInfo, load_query, load_mutation } = state.region;

  return {
    results,
    pageInfo,
    load_query,
    load_mutation,
  };
}

export default connect(mapStateToProps)(Region);
