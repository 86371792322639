import { useEffect, useRef, useState } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { CnpjCpf } from '@utils';
import { Confirm, TableForm, Button, Label } from '@components';
import {
  fetchCompanies,
  saveCompany,
  changeStatusCompany,
  resendCompanyInvitation,
} from '@redux/ducks/companies';
import CompanyForm from './components/CompaniesForm';

const COUNT_ADMIN_LICENSE = 1;

const Companies = ({
  fetchCompanies,
  saveCompany,
  changeStatusCompany,
  resendCompanyInvitation,
  results,
  load_query,
  load_mutation,
}) => {
  const [itemInUpdate, setItemInUpdate] = useState(null);
  const formRef = useRef(null);
  const listRef = useRef(null);
  const confirmRef = useRef(null);

  function handleSubmit(data, edit) {
    saveCompany({
      data: {
        ...data,
      },
      edit,
      success: () => {
        listRef.current.closeForm();
        fetchCompanies();
      },
    });
  }

  const handleCompanyStatus = item => {
    const active = item?.node?.user.active;
    const action = active ? 'inativar' : 'ativar';

    confirmRef.current.open(`Deseja realmente ${action} esta empresa?`, () => {
      setItemInUpdate(item.node.id);
      changeStatusCompany({
        data: {
          id: item.node.id,
          active: !active,
        },
        error: () => {
          setItemInUpdate(null);
        },
        success: () => {
          setItemInUpdate(null);
          fetchCompanies();
        },
      });
    });
  };

  const handleResendInvitation = userId => {
    resendCompanyInvitation({
      data: {
        userId,
      },
    });
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  const handleOpenForm = item => {
    listRef?.current.openForm();
    setTimeout(() => {
      formRef?.current?.edit({
        active: item.node.user.active,
        cnpj: item.node.cnpj,
        commercialName: item.node.commercialName,
        corporateName: item.node.corporateName,
        id: item.node.id,
        city: item.node.city ? item.node.city.name : '',
        phone: item.node.phone,
        contactFirstName: item.node.user.firstName,
        contactLastName: item.node.user.lastName,
        email: item.node.user.email,
        licenseSlots: item.node.licenseSlots,
        stateInscription: item.node.stateInscription,
        neighborhood: item.node.neighborhood,
        address: item.node.address,
      });
    }, 200);
  };

  const headers = [
    {
      label: 'Razão social',
      getValue: item => item.node.corporateName,
    },
    {
      label: 'CNPJ',
      getValue: item => CnpjCpf.mask(item.node.cnpj),
    },
    {
      label: 'Licenças',
      props: {
        align: 'center',
      },
      getValue: item => item.node.licenseSlots,
    },
    {
      label: 'Operadores',
      props: {
        align: 'center',
      },
      getValue: item => item.node.operatorsCount + COUNT_ADMIN_LICENSE,
    },
    {
      label: 'Transportadoras',
      props: {
        align: 'center',
      },
      getValue: item => item.node.shippersCount,
    },
    {
      label: 'Centros logísticos',
      props: {
        align: 'center',
      },
      getValue: item => item.node.logisticCentersCount,
    },
    {
      label: 'Situação',
      getValue: item => {
        return (
          <>
            {item.node.status === 'CONFIRMED' ? (
              <Label
                type={item.node.user.active ? 'success' : 'alert'}
                text={item.node.user.active ? 'Ativo' : 'Inativo'}
              />
            ) : (
              <Label
                type={item.node.status === 'INVITED' ? 'warning' : 'alert'}
                text={item.node.status === 'INVITED' ? 'Convidado' : 'Expirado'}
              />
            )}

            {item.node.status === 'INVITED' && (
              <button
                type="button"
                className="text-blue-500 text-xs ml-2"
                onClick={() => handleResendInvitation(item.node.user.id)}
              >
                Reenviar
              </button>
            )}
          </>
        );
      },
    },
    {
      label: 'Ações',
      props: {
        align: 'center',
        width: '230px',
      },
      getValue: item => (
        <div className="flex gap-2 justify-end">
          <Button
            compact
            onClick={() => handleOpenForm(item)}
            disabled={item.node.status === 'INVITED'}
          >
            Editar
          </Button>
          <Button
            compact
            isLoading={load_mutation && item.node.id === itemInUpdate}
            key={item.node.id}
            onClick={() => handleCompanyStatus(item)}
            disabled={item.node.status === 'INVITED'}
          >
            Alterar&nbsp;situação
          </Button>
        </div>
      ),
    },
  ];
  return (
    <>
      <TableForm
        headers={headers}
        formHeader={{
          title: 'Empresas configuradas',
          subtitle: 'Empresas que utilizam o Shipy como embarcador',
        }}
        labelModalOpen="Adicionar empresa"
        onNew={() => listRef.current.openForm()}
        titleModal="Cadastro de empresa"
        ref={listRef}
        load={load_query}
        source={results}
      >
        <CompanyForm
          formRef={formRef}
          refreshFormData={data => formRef.current.change(data)}
          onSubmit={handleSubmit}
          loadMutation={load_mutation}
        />
      </TableForm>
      <Confirm ref={confirmRef} />
    </>
  );
};

function mapStateToProps({ companies }) {
  const { results, load_query, load_mutation } = companies;

  return {
    results,
    load_query,
    load_mutation,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchCompanies,
      saveCompany,
      changeStatusCompany,
      resendCompanyInvitation,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
