import { Field, reduxForm } from 'redux-form';
import { Button, Input } from '@components';

const validate = values => {
  const errors = {};
  if (!values.login) {
    errors.login = 'Campo obrigatório! ';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.login)) {
    errors.login = 'E-mail inválido! ';
  }
  if (!values.password) {
    errors.password = 'Campo obrigatório!';
  }
  return errors;
};

const renderField = ({
  input,
  label,
  type,
  meta: { touched, error, warning },
}) => (
  <>
    <Input {...input} caption={label} type={type} />
    {touched &&
      ((error && <span className="text-sm text-red-500">{error}</span>) ||
        (warning && <span className="text-sm text-red-500">{warning}</span>))}
  </>
);

const FieldLevelValidationForm = ({
  handleSubmit,
  submitFunction,
  submitting,
  children,
  invalid,
  load_mutation,
}) => {
  return (
    <form className="space-y-4" onSubmit={handleSubmit(submitFunction)}>
      <Field
        name="login"
        type="email"
        autoComplete="email"
        component={renderField}
        label="Email de usuário"
      />
      <Field
        name="password"
        type="password"
        autoComplete="current-password"
        component={renderField}
        label="Senha de acesso"
      />
      {children}
      <Button
        type="submit"
        fullWidth
        variant="primary"
        disabled={invalid}
        isLoading={submitting || load_mutation}
      >
        Entrar
      </Button>
    </form>
  );
};

export default reduxForm({
  form: 'loginValidation',
  validate,
})(FieldLevelValidationForm);
