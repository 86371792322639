import { Route, Routes } from 'react-router-dom';
import { Protected } from '@components';
import { Reprieve, NotFound } from '@pages';

function RoutesReprieve() {
  return (
    <Protected>
      <Routes>
        <Route index element={<Reprieve />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Protected>
  );
}

export default RoutesReprieve;
