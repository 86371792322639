import { client } from '@config/apollo';
import gql from 'graphql-tag';
import { call, put, select } from 'redux-saga/effects';
import { Types } from '../ducks/regionCities';
import { Types as ToastTypes } from '../ducks/toast';

export function* addCity(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { id, cityCodes } = action.data;
    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($id: String!, $cityCodes: [String]!) {
          addCitiesToRegion(id: $id, cityCodes: $cityCodes) {
            ok
          }
        }
      `,
      variables: {
        id,
        cityCodes,
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Cidade adicionada com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* removeCity(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { id, cityCodes } = action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($id: String!, $cityCodes: [String]!) {
          removeCitiesFromRegion(id: $id, cityCodes: $cityCodes) {
            ok
          }
        }
      `,
      variables: {
        id,
        cityCodes,
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Cidade removida com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* fetchRegionCities(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    yield put({ type: Types.LOAD_QUERY, load: true });

    const { regionKey } = action;

    const response = yield call(cli.query, {
      query: gql`
        query ($key: String) {
          region(key: $key) {
            id
            key
            description
            cities {
              id
              code
              name
              state
            }
          }
        }
      `,
      variables: {
        key: regionKey,
      },
    });

    const resultInfo = {
      id: response.data.region && response.data.region.id,
      key: response.data.region && response.data.region.key,
      description: response.data.region && response.data.region.description,
    };
    const results = response.data.region.cities;

    if (action.cursor) {
      yield put({ type: Types.NEXT_RESULTS, results });
    } else {
      yield put({ type: Types.SET_RESULTS, results, resultInfo });
    }
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
  } finally {
    yield put({ type: Types.LOAD_QUERY, load: false });
  }
}
