const validate = values => {
  const errors = {};
  if (!values.phone) {
    errors.phone = 'Favor informar o numero de celular do motorista!';
  }
  if (!values.subject) {
    errors.subject = 'Favor informar o título da mensagem!';
  }
  if (!values.content) {
    errors.content = 'Favor informar o conteúdo da mensagem!';
  }

  return errors;
};

export default validate;
