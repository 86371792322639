import { Input, Button, Confirm } from '@components';
import {
  BarsArrowUpIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import {
  fetchWebhooks,
  saveWebhook,
  removeWebhook,
} from '@redux/ducks/webhook';
import { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const WebhookItem = ({
  webhook,
  webhooksList,
  saveWebhook,
  fetchWebhooks,
  removeWebhook,
}) => {
  const confirmRef = useRef(null);
  const webhookData = webhooksList?.find(item => item.subject === webhook);
  const [currentWebhookUrl, setCurrentWebhookUrl] = useState(
    webhookData?.url || ''
  );

  const webhooksInfo = {
    incident_created: {
      caption: 'Url a ser chamada ao criar um incidente',
      placeholder: 'Ex.: https://dnsdodestino.com.br/novo-incidente',
    },
    shipper_updated: {
      caption: 'Url a ser chamada ao editar uma transportadora',
      placeholder: 'Ex.: https://dnsdodestino.com.br/situacao-transportadora',
    },
    invoice_log_updated: {
      caption: 'Url a ser chamada ao gerar um log para a nota fiscal',
      placeholder: 'Ex.: https://dnsdodestino.com.br/ocorrencia-nota',
    },
    delivery_created: {
      caption: 'Url a ser chamada ao criar uma entrega',
      placeholder: 'Ex.: https://dnsdodestino.com.br/entrega-nota',
    },
    delivery_updated: {
      caption: 'Url a ser chamada ao atualizar uma entrega',
      placeholder: 'Ex.: https://dnsdodestino.com.br/atualizacao-nota',
    },
    delivery_removed: {
      caption: 'Url a ser chamada ao desfazer uma entrega',
      placeholder: 'Ex.: https://dnsdodestino.com.br/desfazer-entrega',
    },
    reversal_updated: {
      caption: 'Url a ser chamada ao efetuar uma devolução',
      placeholder: 'Ex.: https://dnsdodestino.com.br/devolucao-nota',
    },
    redelivery_created: {
      caption: 'Url a ser chamada ao solicitar uma reentrega',
      placeholder: 'Ex.: https://dnsdodestino.com.br/reentregar-nota',
    },
    shipping_order_delivery_created: {
      caption: 'Url a ser chamada ao entregar uma ordem de transporte',
      placeholder: 'Ex.: https://dnsdodestino.com.br/ordem-transporte',
    },
  };

  async function handleSaveWebhook() {
    if (webhookData) {
      return saveWebhook({
        data: {
          id: webhookData.id,
          subject: webhookData.subject,
          url: currentWebhookUrl,
        },
        edit: true,
        success: () => fetchWebhooks(),
      });
    }

    return saveWebhook({
      data: {
        subject: webhook,
        url: currentWebhookUrl,
      },
      edit: false,
      success: () => fetchWebhooks(),
    });
  }

  async function handleDeleteWebhook() {
    confirmRef.current.open(
      `Deseja realmente apagar o webhook? Após a confirmação não será possível recuperar esta URL: \n (${webhookData.url})`,
      () => {
        removeWebhook({
          data: {
            subject: webhookData.subject,
          },
          success: () => fetchWebhooks(),
        });
        setCurrentWebhookUrl('');
      }
    );
  }

  const onChange = event => {
    setCurrentWebhookUrl(event.target.value.replace(/\s/g, ''));
  };

  return (
    <div className="flex flex-1 space-x-2 mb-3" key={webhook}>
      <div className="w-2/3">
        <Input
          caption={webhooksInfo[webhook]?.caption}
          placeholder={webhooksInfo[webhook]?.placeholder}
          value={currentWebhookUrl}
          onChange={onChange}
        />
      </div>
      <div className="w-36">
        <label>&nbsp;</label>
        {webhookData?.url ? (
          <div className="flex flex-row">
            <Button
              variant={'default'}
              fullWidth
              onClick={handleSaveWebhook}
              disabled={!currentWebhookUrl}
            >
              <PencilSquareIcon className="h-5 w-5" />
              Editar
            </Button>
            <label>&nbsp;</label>
            <Button
              variant={'negative'}
              onClick={handleDeleteWebhook}
              disabled={!currentWebhookUrl}
            >
              <TrashIcon className="h-5 w-5" />
            </Button>
          </div>
        ) : (
          <Button
            variant={'primary'}
            fullWidth
            onClick={handleSaveWebhook}
            disabled={!currentWebhookUrl}
          >
            <BarsArrowUpIcon className="h-5 w-5" />
            Adicionar
          </Button>
        )}
      </div>
      <Confirm ref={confirmRef} />
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { saveWebhook, fetchWebhooks, removeWebhook },
    dispatch
  );
}

export default connect(null, mapDispatchToProps)(WebhookItem);
