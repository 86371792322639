import { useRef, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import CityForm from './components/CityForm';
import {
  fetchRegionCities,
  addCity,
  removeCity,
  resetState,
} from '@redux/ducks/regionCities';
import { TableForm, Button } from '@components';
import { useNavigate, useParams } from 'react-router-dom';

const City = props => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formRef = useRef(null);
  const _contextRef = useRef(null);

  const params = useParams();

  const regionKey = params.regionKey;

  const { results, resultInfo, load_query } = props;

  const regionName = resultInfo?.description || '';

  const initialize = () => {
    dispatch(resetState());
    window.scroll(0, 0);
    dispatch(fetchRegionCities({ regionKey: regionKey }));
  };

  const onRemoveCity = data => {
    dispatch(
      removeCity({
        data: {
          id: resultInfo.id,
          cityCodes: [data.id],
        },
        success: refresh,
      })
    );
  };

  const onAddCity = data => {
    dispatch(
      addCity({
        data: {
          id: resultInfo.id,
          cityCodes: [data.code],
        },
        success: refresh,
      })
    );
  };

  function handleBack() {
    navigate('/regions');
  }

  const refresh = () => {
    initialize();
  };

  useEffect(() => {
    initialize();
  }, []);

  return (
    <div ref={ref => (_contextRef.current = ref)}>
      <TableForm
        formHeader={{
          title: `Cidades da região ${regionName} `,
          subtitle: 'Gerencie e visualize as cidades da região',
        }}
        titleModal="Cadastre as cidades dessa região"
        source={results}
        load={load_query}
        back={true}
        lbBack="Regiões"
        onBack={handleBack}
        headers={[
          {
            label: 'UF',
            getValue: item => {
              const city = item.node || item;
              return city.state;
            },
          },
          {
            label: 'Cidade',
            getValue: item => {
              const city = item.node || item;
              return city.name;
            },
          },
          {
            label: 'Ações',
            props: {
              align: 'center',
              width: '90px',
            },
            getValue: item => (
              <div className="flex gap-2 justify-end">
                <Button compact onClick={() => onRemoveCity(item)}>
                  Remover
                </Button>
              </div>
            ),
          },
        ]}
        keyRow={item => {
          const city = item.node || item;
          return city.id;
        }}
      >
        <CityForm formRef={formRef} region={resultInfo} onAddCity={onAddCity} />
      </TableForm>
    </div>
  );
};

function mapStateToProps(state) {
  const { results, resultInfo, load_query } = state.regionCities;

  return {
    results,
    resultInfo,
    load_query,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { fetchRegionCities, addCity, removeCity, resetState },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(City);
