import { Hora } from '@utils';

const validate = values => {
  const errors = {};
  if (!values.date) {
    errors.date = 'Favor informar a data de entrega!';
  }
  if (!values.hour) {
    errors.hour = 'Favor informar a hora da entrega!';
  }
  if (values.hour && !Hora.validate(values.hour)) {
    errors.hour = 'Hora inválida!';
  }
  return errors;
};

export default validate;
