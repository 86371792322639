import { Fragment, useEffect, useState } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, CheckIcon } from '@heroicons/react/20/solid';

const DropdownMulti = ({ options, value, placeholder, ...rest }) => {
  const [selection, setSelection] = useState([]);

  function handleSelection(event, field) {
    event.preventDefault();
    const hasDuplicate = selection.find(item => item.key === field.key);

    if (hasDuplicate) {
      const selectionFiltered = selection.filter(
        item => item.key !== hasDuplicate.key
      );
      return setSelection(selectionFiltered);
    }
    setSelection(prev => [...prev, field]);
  }

  useEffect(() => {
    const sortedSelection = [...selection].sort((a, b) =>
      a.text.localeCompare(b.text)
    );
    if (JSON.stringify(sortedSelection) !== JSON.stringify(selection)) {
      setSelection(sortedSelection);
    }
  }, [selection]);

  useEffect(() => {
    if (value) {
      const alreadySelected = options?.filter(item => {
        if (value.toString().includes(item.key)) {
          return item;
        }
      });
      setSelection(alreadySelected);
    }
  }, [value]);

  return (
    <Menu as="div" className="relative w-full inline-block text-left">
      <div>
        <Menu.Button
          {...rest}
          value={selection?.map(item => item.value)}
          className={`${
            selection?.length === 0 ? 'text-gray-400 ' : 'text-gray-900 '
          } inline-flex justify-between text-left item w-full gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-medium shadow-sm ring-1 ring-inset ring-gray-300`}
        >
          {selection?.length === 0
            ? placeholder
            : selection?.map(item => ` ${item.text}`).toString()}
          <ChevronDownIcon
            className="-mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-70"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-100"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-70"
      >
        <Menu.Items className="absolute right-0 w-full z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1 h-auto max-h-48 overflow-y-scroll">
            {options?.map(item => (
              <Menu.Item key={item.key}>
                <div
                  onClick={event => handleSelection(event, item)}
                  className={`${
                    selection?.map(item => item.value).includes(item.value)
                      ? 'bg-gray-100 text-gray-900'
                      : 'text-gray-700'
                  } block px-4 relative py-2 text-sm cursor-pointer font-medium hover:bg-gray-100`}
                >
                  {selection?.map(item => item.value).includes(item.value) ? (
                    <span className="text-blue-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  ) : null}
                  <span className="ml-4">{item.text}</span>
                </div>
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default DropdownMulti;
