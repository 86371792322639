import { Route, Routes } from 'react-router-dom';
import {
  Login,
  NotFound,
  ActivateInvitation,
  ForgotPassword,
  ResetPassword,
  SuccessValidation,
  UnrelatedShipper,
} from '@pages';

import RoutesUsers from './user.routes';
import RoutesReprieve from './reprieve.routes';
import RoutesIncidents from './incidents.routes';
import RoutesExpenseType from './expenseType.routes';
import RoutesIncidentType from './incidentType.routes';
import RoutesAlerts from './alert.routes';
import RoutesLogisticCenter from './logisticCenter.routes';
import RoutesShippingOrders from './shippingOrders.routes';
import RoutesDevolutionInvoices from './devolutionInvoices.routes';
import RouterRedelivery from './redelivery.routes';
import RouterShipperIncident from './shipperIncident.routes';
import RouterDriver from './driver.routes';
import RouterShipper from './shipper.routes';
import RouterRegion from './region.routes';
import RouterTransportQueue from './transportqueue.routes';
import RouterOperator from './operator.routes';
import RoutesExpenses from './expenses.routes';
import RoutesInvoices from './invoices.routes';
import RoutesShippingRelationship from './shippingRelationship.routes';
import Navbar from '../components/Navbar';
import RoutesLogs from './logs.routes';
import RoutesCompanies from './companies.routes';
import ReceiveInvitation from '../pages/ReceiveInvitation';
import RoutesToken from './token.routes';
import RoutesReversalReason from './reversalReason.routes';
import { connect } from 'react-redux';
import RoutesMessages from './messages.routes';

const AdminRoutes = () => (
  <Route path="/companies" element={<RoutesCompanies />} />
);

const OperatorRoutes = () => (
  <>
    <Route path="/users/*" element={<RoutesUsers />} />
    <Route path="/alerts/*" element={<RoutesAlerts />} />
    <Route path="/reprieves/*" element={<RoutesReprieve />} />
    <Route path="/incidents/*" element={<RoutesIncidents />} />
    <Route path="/expense-types/*" element={<RoutesExpenseType />} />
    <Route path="/incident-types/*" element={<RoutesIncidentType />} />
    <Route path="/reversal-reason/*" element={<RoutesReversalReason />} />
    <Route path="/logistic-centers/*" element={<RoutesLogisticCenter />} />
    <Route path="/shipping-orders/*" element={<RoutesShippingOrders />} />
    <Route
      path="/devolution-invoices/*"
      element={<RoutesDevolutionInvoices />}
    />
    <Route path="/redeliveries/*" element={<RouterRedelivery />} />
    <Route path="/shipper-incidents/*" element={<RouterShipperIncident />} />
    <Route path="/drivers/*" element={<RouterDriver />} />
    <Route path="/shippers/*" element={<RouterShipper />} />
    <Route path="/regions/*" element={<RouterRegion />} />
    <Route path="/queues/*" element={<RouterTransportQueue />} />
    <Route path="/operators/*" element={<RouterOperator />} />
    <Route path="/operators/*" element={<RouterRegion />} />
    <Route path="/expenses/*" element={<RoutesExpenses />} />
    <Route path="/invoices/*" element={<RoutesInvoices />} />
    <Route path="/logs/*" element={<RoutesLogs />} />
    <Route path="/messages/*" element={<RoutesMessages />} />
    <Route
      path="/shipping-relationship/*"
      element={<RoutesShippingRelationship />}
    />
    <Route path="/token/*" element={<RoutesToken />} />
    <Route path="/unrelated-shipper" element={<UnrelatedShipper />} />
  </>
);

const Root = ({ user }) => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/receive-invitation" element={<ReceiveInvitation />} />
      <Route path="/activate-invitation" element={<ActivateInvitation />} />
      <Route path="/success-validation" element={<SuccessValidation />} />

      <Route element={<Navbar />}>
        {user?.role?.name === 'ADMIN' ? AdminRoutes() : OperatorRoutes()}
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

function mapStateToProps(state) {
  const { user } = state.auth;

  return {
    user,
  };
}

export default connect(mapStateToProps)(Root);
