export const Types = {
  CREATE_ALERT: 'alert/CREATE_ALERT',
  UPDATE_ALERT: 'alert/UPDATE_ALERT',
  SET_RESULTS: 'alert/SET_RESULTS',
  NEXT_RESULTS: 'alert/NEXT_RESULTS',
  SET_PAGE_INFO: 'alert/SET_PAGE_INFO',
  SET_ERROR_QUERY: 'alert/SET_ERROR_QUERY',
  SET_ERROR_MUTATION: 'alert/SET_ERROR_MUTATION',
  SET_SUCCESS_QUERY: 'alert/SET_SUCCESS_QUERY',
  SET_SUCCESS_MUTATION: 'alert/SET_SUCCESS_MUTATION',
  LOAD_QUERY: 'alert/LOAD_QUERY',
  LOAD_MUTATION: 'alert/LOAD_MUTATION',
  SUBSCRIBE_TO_NEW_ALERT: 'alert/SUBSCRIBE_TO_NEW_ALERT',
  FETCH_ALERTS: 'alert/FETCH_ALERTS',
  RESET_STATE: 'alert/RESET_STATE',
  USER_LOGOUT: 'USER_LOGOUT',
};

const initialState = {
  results: [],
  pageInfo: {},
  load_query: false,
  load_mutation: false,
  error_query: {},
  error_mutation: {},
  success_query: {},
  success_mutation: {},
};

export default function importalert(state = initialState, action) {
  switch (action.type) {
    case Types.SET_RESULTS:
      return { ...state, results: action.results };
    case Types.NEXT_RESULTS:
      return { ...state, results: [...state.results, ...action.results] };
    case Types.SET_PAGE_INFO:
      return { ...state, pageInfo: action.pageInfo };
    case Types.SET_ERROR_QUERY:
      return { ...state, error_query: action.error };
    case Types.SET_ERROR_MUTATION:
      return { ...state, error_mutation: action.error };
    case Types.SET_SUCCESS_QUERY:
      return { ...state, success_query: action.success };
    case Types.SET_SUCCESS_MUTATION:
      return { ...state, success_mutation: action.success };
    case Types.LOAD_QUERY:
      return { ...state, load_query: action.load };
    case Types.LOAD_MUTATION:
      return { ...state, load_mutation: action.load };
    case Types.RESET_STATE:
    case Types.USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export function saveAlert({ data, edit, success, error }) {
  return {
    type: edit === true ? Types.UPDATE_ALERT : Types.CREATE_ALERT,
    data,
    success,
    error,
  };
}

export function subscribeToNewAlert() {
  return {
    type: Types.SUBSCRIBE_TO_NEW_ALERT,
  };
}

export function fetchAlerts(args = {}) {
  const { filter } = args;

  switch (filter) {
    case 'terminated':
      return fetchTerminated(args);
    default:
      return fetchPending(args);
  }
}

export function fetchPending({ search, cursor }) {
  let filter = {
    resolvedAt: null,
  };
  if (search && search.length > 0) {
    filter = {
      ...filter,
      OR: [{ error_LIKE: search }, { resolution_LIKE: search }],
    };
  }

  return {
    type: Types.FETCH_ALERTS,
    cursor,
    filter,
  };
}

export function fetchTerminated({ search, cursor }) {
  let filter = {
    resolvedAt_NOT_EQUALS: null,
  };
  if (search && search.length > 0) {
    filter = {
      ...filter,
      OR: [{ error_LIKE: search }, { resolution_LIKE: search }],
    };
  }

  return {
    type: Types.FETCH_ALERTS,
    cursor,
    filter,
  };
}

export function resetState() {
  return {
    type: Types.RESET_STATE,
  };
}
