import { Field, reduxForm } from 'redux-form';
import { Button, Input } from '@components';
import { validate } from './validate';

const renderField = ({
  input,
  label,
  type,
  meta: { touched, error, warning },
}) => (
  <>
    <Input {...input} caption={label} type={type} />
    {touched &&
      ((error && <span className="text-sm text-red-500">{error}</span>) ||
        (warning && <span className="text-sm text-red-500">{warning}</span>))}
  </>
);

const ActivateInvitationForm = ({
  handleSubmit,
  submitFunction,
  submitting,
  invalid,
  load_mutation,
}) => {
  return (
    <form onSubmit={handleSubmit(submitFunction)}>
      <Field
        name="password"
        type="password"
        component={renderField}
        label="Senha"
      />

      <div className="mt-8">
        <Field
          name="passwordConfirmation"
          type="password"
          component={renderField}
          label="Confirmação da senha"
        />
      </div>

      <div className="mt-10">
        <Button
          type="submit"
          fullWidth
          variant="primary"
          disabled={invalid}
          isLoading={submitting || load_mutation}
        >
          Confirmar
        </Button>
      </div>
    </form>
  );
};

export default reduxForm({
  form: 'ActivateInvitationValidation',
  validate,
})(ActivateInvitationForm);
