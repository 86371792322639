import { Button } from '@components';
import Dropzone from 'react-dropzone';
import { useState } from 'react';

const Uploader = ({
  accept = '.jpeg,.jpg,.png,.doc,.pdf',
  multiple = false,
  label = 'Arquivo',
  text = 'Selecione um arquivo',
  onDropAccepted,
  removeFile,
}) => {
  const [files, setFiles] = useState();

  const handleOnDropAccepted = files => {
    setFiles(files);
    if (onDropAccepted) onDropAccepted(files);
  };

  const handleRemoveFile = () => {
    setFiles(undefined);
    if (removeFile) removeFile();
  };

  const renderFilesName = files => {
    if (!files) return null;

    return (
      <div className='flex flex-row py-1' >
      <Button
        variant='negative'
        compact
        onClick={() => handleRemoveFile()}
        className="px-2"
        >
          Remover
        </Button>
        <div>
        {
          files.reduce((names, file) => {
            return (names += file.name + '; ');
          }, '')
        }
        </div>
      </div>
    )
  };

  return (
    <>
      <label className="block text-sm font-medium leading-6 text-gray-900">
        {label}{' '}
        <span className="italic text-gray-500">{renderFilesName(files)}</span>
      </label>
      <Dropzone
        style={{ width: 'fit-content' }}
        multiple={multiple}
        accept={accept}
        onDropAccepted={handleOnDropAccepted}
        onDropRejected={() => {
          alert('Arquivo não suportado!');
        }}
      >
        {!files &&
           <Button type="button" onClick={handleRemoveFile}>
           {text}
         </Button>
        }

      </Dropzone>
    </>
  );
};

export default Uploader;
