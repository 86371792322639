import { client } from '@config/apollo';
import gql from 'graphql-tag';
import { call, put, select } from 'redux-saga/effects';
import { Types } from '../ducks/alert';
import { Types as ToastTypes } from '../ducks/toast';

export function* createAlert(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { error } = action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($importAlert: ImportAlertInput!) {
          createImportAlert(importAlert: $importAlert) {
            id
          }
        }
      `,
      variables: {
        importAlert: {
          error,
        },
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Alerta criado com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* updateAlert(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { id, resolution } = action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($id: String!, $importAlert: ImportAlertUpdateInput!) {
          updateImportAlert(id: $id, importAlert: $importAlert) {
            ok
          }
        }
      `,
      variables: {
        id,
        importAlert: {
          resolution,
        },
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Alerta finalizado com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* fetchAlerts(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    yield put({ type: Types.LOAD_QUERY, load: true });

    const { filter, cursor } = action;

    const response = yield call(cli.query, {
      query: gql`
        query ($last: Int, $before: String, $filter: ImportAlertFilter) {
          importAlerts(last: $last, before: $before, filter: $filter) {
            edges {
              node {
                id
                sequence
                error
                resolution
                createdAt
                resolvedAt
                resolvedBy {
                  firstName
                  lastName
                }
                category
              }
            }
            pageInfo {
              hasNextPage
              after
              before
            }
          }
        }
      `,
      variables: {
        last: 50,
        before: cursor,
        filter,
      },
    });

    const pageInfo = response.data.importAlerts.pageInfo;
    const results = response.data.importAlerts.edges;

    yield put({
      type: Types.SET_PAGE_INFO,
      pageInfo,
    });

    if (action.cursor) {
      yield put({ type: Types.NEXT_RESULTS, results });
    } else {
      yield put({ type: Types.SET_RESULTS, results });
    }
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
  } finally {
    yield put({ type: Types.LOAD_QUERY, load: false });
  }
}

export function* subscribeToNewAlert() {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  const response = yield call(cli.subscribe.bind(cli), {
    query: gql`
      subscription {
        importAlertNotification {
          id
        }
      }
    `,
  });

  response.subscribe({
    next() {
      // dispatch({type: Types.SET_NEW_INCIDENT, newIncident: true})
    },
    error() {},
  });
}
