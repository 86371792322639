import moment from 'moment';
import { useDispatch } from 'react-redux';
import { TableForm, Toggle } from '@components';
import { CalendarIcon } from '@heroicons/react/20/solid';
import { setDataObject as setInvoice } from '@redux/ducks/invoice';

const InvoiceList = ({
  filter,
  selectInvoiceToSendMail,
  selectInvoiceToShippingRelationship,
  state,
  allowToSendMail,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { shippingRelationshipInvoices, selectedInvoices } = state;

  function handleDetailInvoice(invoiceNumber) {
    dispatch(
      setInvoice({
        key: invoiceNumber,
      })
    );
  }

  const invoiceIsSelectedToSendMail = ({ id }) => {
    return selectedInvoices.find(item => item?.id === id);
  };

  const invoiceIsSelectedToShippingRelationship = ({ id }) => {
    return shippingRelationshipInvoices.find(item => item.node.id === id);
  };

  const selectInvoiceIfPending =
    filter === 'pending'
      ? [
          {
            label: 'Romanear',
            props: {
              align: 'center',
            },
            getValue: item => {
              return (
                <Toggle
                  checked={invoiceIsSelectedToShippingRelationship(item.node)}
                  onChange={() => {
                    selectInvoiceToShippingRelationship(item);
                  }}
                />
              );
            },
          },
        ]
      : filter === 'overdue' && allowToSendMail
      ? [
          {
            label: 'Email',
            props: {
              align: 'center',
            },
            getValue: item => {
              return (
                <Toggle
                  checked={invoiceIsSelectedToSendMail(item.node)}
                  onChange={() => {
                    selectInvoiceToSendMail(item);
                  }}
                />
              );
            },
          },
        ]
      : [];

  const defaultHeaders = [
    {
      label: 'Nota',
      getValue: item => (
        <span
          className="text-blue-500 cursor-pointer whitespace-nowrap"
          onClick={() => handleDetailInvoice(item.node.invoiceNo)}
        >
          {`${item.node.invoiceNo}`}
        </span>
      ),
    },
    {
      label: 'Emissão',
      getValue: item =>
        item?.node?.activities?.emittedAt &&
        moment(item?.node?.activities?.emittedAt).format('DD/MM/YYYY'),
    },
    {
      label: 'Previsão',
      getValue: item => {
        const date =
          item?.node?.activities?.predictedTo &&
          moment(item?.node?.activities?.predictedTo).format('DD/MM/YYYY');
        if (item?.node?.activities?.agreedTo) {
          return (
            <span className="flex flex-row items-center whitespace-nowrap">
              <CalendarIcon
                className="mr-1 h-4 w-4 text-orange-500"
                aria-hidden="true"
              />
              {`${moment(item?.node?.activities?.agreedTo).format(
                'DD/MM/YYYY'
              )}`}
            </span>
          );
        }
        return date;
      },
    },
    {
      label: 'Cidade',
      getValue: item =>
        `${item.node.customer.city && item.node.customer.city.name} - ${
          item.node.customer.city && item.node.customer.city.state
        }`,
    },
    {
      label: 'Cliente',
      getValue: item => `${item.node.customer.corporateName}`,
    },
    {
      label: 'Transportadoras',
      getValue: item => (
        <div className="flex flex-col">
          <div className="truncate">
            {item.node.dispatcher
              ? `CIF: ${item.node?.dispatcher?.company?.corporateName}`
              : `FOB: ${item.node?.externalDispatcher?.commercialName}`}
          </div>
          {item.node.redispatcher && (
            <div className="truncate text-xs text-orange-500">
              CIF: {item.node?.redispatcher?.company?.corporateName}
            </div>
          )}
          {item.node.externalRedispatcher && (
            <div className="truncate text-xs text-orange-500">
              FOB: {item.node?.externalRedispatcher?.commercialName}
            </div>
          )}
        </div>
      ),
    },
  ];

  const actionHeaders = () => {
    const options = {
      occurrence: [
        {
          label: 'Ocorrência',
          getValue: item => {
            let { incidents } = item.node;
            if (incidents && incidents.length > 0) {
              return moment(incidents[incidents.length - 1].start).format(
                'DD/MM/YYYY-HH:mm'
              );
            }
          },
        },
      ],
      delivered: [
        {
          label: 'Entrega',
          getValue: item => {
            let { deliveries } = item.node;
            if (deliveries && deliveries.length > 0) {
              return moment(deliveries[deliveries.length - 1].date).format(
                'DD/MM/YYYY-HH:mm'
              );
            }
          },
        },
      ],
      returned: [
        {
          label: 'Rejeição',
          getValue: item =>
            item.node.return &&
            moment(item.node.return.date).format('DD/MM/YYYY-HH:mm'),
        },
        {
          label: 'Reentregas',
          props: {
            align: 'center',
          },
          getValue: item =>
            item.node.redeliveries &&
            item.node.redeliveries.length,
        },
      ],
    };
    return options[filter] || [];
  };

  return (
    <TableForm
      {...rest}
      headers={[
        ...selectInvoiceIfPending,
        ...defaultHeaders,
        ...actionHeaders(),
      ]}
      keyRow={item => item.node.key}
    />
  );
};

export default InvoiceList;
