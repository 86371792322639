import { compose } from 'recompose';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import { apiKey } from '@config/googleMaps';

const MapWithAMarker = compose(
  withScriptjs,
  withGoogleMap
)(({ position }) => (
  <GoogleMap
    defaultZoom={position ? 16 : 3}
    defaultCenter={position || { lat: -22.9034, lng: -43.1917 }}
  >
    <Marker position={position} />
  </GoogleMap>
));

const Maps = ({ position }) => {
  return (
    <div className="grid grid-cols-1">
      <MapWithAMarker
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=3.exp&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        position={
          position
            ? {
                lat: Number.parseFloat(position.lat),
                lng: Number.parseFloat(position.lng),
              }
            : null
        }
      />
    </div>
  );
};

export default Maps;
