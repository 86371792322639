import { useEffect, useRef } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  createExpense,
  getExpense,
} from '@redux/ducks/expense';
import { fetchExpenseTypes } from '@redux/ducks/expenseType';

import { FormCreate, Modal, Toast } from '@components';
import validate from './validate';
import moment from 'moment';

const Form = FormCreate('ExpenseForm', validate);

const ExpenseForm = ({
  onClose,
  open,
  fetchExpenseTypes,
  createExpense,
  expensesTypes,
  expenseFormData,
  ...rest
}) => {
  const from = new Date(moment().subtract(30, 'days'));
  const to = new Date();
  const initialStateFilter = {
    from,
    to,
    status: '',
    type: null,
  };
  const { id, key } = expenseFormData;
  const toastRef = useRef(null);


  function IsReciptRequiredAndInvalid(data) {

    const { type } = data
    const files = data.files || []
    const expenseType = expensesTypes.find(( expense ) => expense.id === type)

    if (expenseType.receipt && files.length === 0) {
      return true
    }

    return false
  }

  function handleSubmit(data, edit) {

    const { files1, files2, files3, files4 } = data

    const files = []
    if (files1 && files1.length > 0) files.push(files1[0])
    if (files2 && files2.length > 0) files.push(files2[0])
    if (files3 && files3.length > 0) files.push(files3[0])
    if (files4 && files4.length > 0) files.push(files4[0])

    data.files = files
    edit = false;

    if (IsReciptRequiredAndInvalid(data)) {
      const expenseType = expensesTypes.find(( expense ) => expense.id === data.type)
      return toastRef?.current?.show({
        title: `Despesa ${expenseType.description} requer anexo`,
        message: `A despesa ${expenseType.description} é necessário informar ao menos um anexo`,
        type: 'error',
      });
    }


    createExpense({
      data: { ...data, invoiceKey: key, cost: parseFloat(data.cost || 0) },
      edit,
      success: () => {
        onClose();
      },
    });
  }

  useEffect(() => {
    fetchExpenseTypes({ id });
  }, []);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Gerar despesas para essa nota fiscal"
    >
      <Form
        {...rest}
        fields={[
          {
            name: 'type',
            component: 'select',
            type: 'select',
            label: 'Tipo de despesa:',
            placeholder: 'Selecione a despesa',
            inputProps: {
              options: [
                { key: -1, text: 'Selecione', value: -1 },
                ...expensesTypes
                  .filter(expense => expense.active === true)
                  .map(expense => ({
                    key: expense.id,
                    text: expense.description,
                    value: expense.id,
                  })),
              ],
            },
          },
          {
            name: 'cost',
            type: 'text',
            component: 'currency',
            label: 'Valor: ',
            inputProps: {
              placeholder: 'Informe o valor dessa despesa',
            },
          },

          {
            name: 'description',
            component: 'textarea',
            type: 'textarea',
            label: 'Descrição:',
            inputProps: {
              placeholder: 'Informe a descrição da despesa',
              rows: 3,
            },
          },
          [
          {
            name: 'files',
            component: 'uploadfile',
            label: 'Anexo 1:',
            inputProps: {
              onDropAccepted: (files, reduxFormChange) => {
                reduxFormChange('files1', files);
              },
              removeFile: reduxFormChange => {
                reduxFormChange('files1', null);
              },
            },
          },
          {
            name: 'files2',
            component: 'uploadfile',
            label: 'Anexo 2:',
            inputProps: {
              onDropAccepted: (files, reduxFormChange) => {
                reduxFormChange('files2', files);
              },
              removeFile: reduxFormChange => {
                reduxFormChange('files2', null);
              },
            },
          },
          {
            name: 'files3',
            component: 'uploadfile',
            label: 'Anexo 3:',
            inputProps: {
              onDropAccepted: (files, reduxFormChange) => {
                reduxFormChange('files3', files);
              },
              removeFile: reduxFormChange => {
                reduxFormChange('files3', null);
              }
            },
          },
          {
            name: 'files4',
            component: 'uploadfile',
            label: 'Anexo 4:',
            inputProps: {
              onDropAccepted: (files, reduxFormChange) => {
                reduxFormChange('files4', files);
              },
              removeFile: reduxFormChange => {
                reduxFormChange('files4', null);
              },
            },
          }
        ]
        ]}
        onSubmit={handleSubmit}
      />
      <Toast ref={toastRef} />
    </Modal>
  );
};

function mapStateToProps(state) {
  const { ExpenseForm = {} } = state.form;
  const { expenseType } = state;

  return {
    values: ExpenseForm.values,
    expensesTypes: expenseType.results,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { fetchExpenseTypes, createExpense, getExpense },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpenseForm);
