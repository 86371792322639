import { client } from '@config/apollo';
import gql from 'graphql-tag';
import { call, put, select } from 'redux-saga/effects';
import { Types } from '../ducks/expenseType';
import { Types as ToastTypes } from '../ducks/toast';

export function* createExpenseType(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { description, active, receipt } = action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });
    yield call(cli.mutate, {
      mutation: gql`
        mutation ($data: ExpenseTypeInput!) {
          createExpenseType(data: $data) {
            ok
          }
        }
      `,
      variables: {
        data: {
          description,
          active,
          receipt
        },
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Tipo de despesa criado com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* editExpenseType(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { id, description, active, receipt } = action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($id: String!, $data: ExpenseTypeInput!) {
          editExpenseType(id: $id, data: $data) {
            ok
          }
        }
      `,
      variables: {
        id,
        data: {
          description,
          active,
          receipt
        },
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Tipo de despesa alterado com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* fetchExpenseTypes() {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    yield put({ type: Types.LOAD_QUERY, load: true });

    const response = yield call(cli.query, {
      query: gql`
        query ($filter: ExpenseTypeFilterInput) {
          expenseTypes(filter: $filter) {
            id
            description
            active
            receipt
          }
        }
      `,
      variables: {
        filter: {
          companyId: auth.company.id,
        },
      },
    });

    const results = response.data.expenseTypes || [];

    yield put({ type: Types.SET_RESULTS, results });
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
  } finally {
    yield put({ type: Types.LOAD_QUERY, load: false });
  }
}
