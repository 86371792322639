import moment from 'moment';
import { useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import RedeliveryFormComponent from './components/RedeliveryForm';
import { saveRedelivery, getDeliveryPrediction } from '@redux/ducks/redelivery';
import { Confirm, Toast, Modal, Load } from '@components';

const RedeliveryForm = ({ open, onClose, prediction, invoice }) => {
  const dispatch = useDispatch();

  const confirmRef = useRef(null);
  const toastRef = useRef(null);

  useEffect(() => {
    if (invoice) dispatch(getDeliveryPrediction(invoice.key));
  }, [invoice]);

  const submitForm = async form => {
    const data = {
      predictedTo: prediction,
      customerDate: form.customerDate
        ? moment(form.customerDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null,
      number: form.number,
      value: form._value ? form._value.floatValue : null,
      redeliverForFree: form.redeliverForFree || false,
      representativeFreight: form.representativeFreight || false,
      note: form.note,
    };

    const { id, key } = invoice;
    await dispatch(saveRedelivery(data, id, key));
    onClose();
  };

  if (!invoice) return null;

  const redeliveryNumber = invoice?.redeliveries?.length || 0;

  if (redeliveryNumber > 0 && redeliveryNumber < 3) {
    confirmRef?.current.open(
      `Existem ${redeliveryNumber} entregas para a nota. Deseja continuar?`,
      () => {
        onClose();
      }
    );
  } else if (redeliveryNumber === 3) {
    toastRef?.current.show({
      title: 'Número de reentregas já excedeu o limite',
      message: `Já foram efetuadas ${redeliveryNumber} reentregas que é o máximo permitido.`,
      type: 'error',
    });
    onClose();
  }

  return (
    <Modal open={open} onClose={onClose} title="Reentrega de nota fiscal">
      {!prediction ? (
        <Load active />
      ) : (
        <RedeliveryFormComponent
          params={{
            invoiceNo: invoice.invoiceNo,
            key: invoice.key,
            prediction,
            redeliveryNumber,
          }}
          onClose={() => onClose()}
          submit={submitForm}
        />
      )}
      <Confirm ref={confirmRef} />
      <Toast ref={toastRef} />
    </Modal>
  );
};

function mapStateToProps({ redelivery }) {
  const { prediction } = redelivery;
  return {
    prediction,
  };
}

export default connect(mapStateToProps)(RedeliveryForm);
