import axios from 'axios';

export default async function GetInfoCnpj(cnpj) {
  const cnpjNumber = cnpj.replace(/[^a-zA-Z0-9]/g, '');

  if (cnpjNumber.length !== 14) return;

  try {
    const response = await axios.get(
      `https://api.rconnect.net.br/cnpj/${cnpjNumber}`
    );
    return response;
  } catch {
    return null;
  }
}
