import { FormCreate } from '@components';
import validate from './validate';

const Form = FormCreate('AlertForm', validate);

const AlertForm = props => {
  return (
    <Form
      {...props}
      fields={[
        {
          name: 'error',
          component: 'input',
          type: 'text',
          label: 'Alerta:',
          inputProps: {
            placeholder: '',
          },
          edit: false,
        },
        {
          name: 'resolution',
          component: 'input',
          type: 'text',
          label: 'Resolução:',
          inputProps: {
            placeholder: 'Informe a solução do problema',
          },
        },
      ]}
    />
  );
};

export default AlertForm;
