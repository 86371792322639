export default function Select({
  caption,
  onChange,
  options,
  defaultEmptyOption = '',
  error,
  ...rest
}) {
  const handleSelect = e => {
    const { value } = e.target;

    onChange(value);
  };

  const inputBorderClass = error ? 'ring-red-300' : 'ring-gray-300';
  const textColorClass = error ? 'text-red-900' : 'text-gray-900';
  return (
    <div>
      <label
        className={`${textColorClass} block text-sm font-medium leading-6`}
      >
        {caption}
      </label>
      <select
        onChange={e => handleSelect(e)}
        className={`${inputBorderClass} ${textColorClass} block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6`}
        {...rest}
      >
        {!!defaultEmptyOption && <option value="">{defaultEmptyOption}</option>}
        {options.map(item => {
          return (
            <option key={item.key} value={item.value}>
              {item.text}
            </option>
          );
        })}
      </select>
    </div>
  );
}
