import { Fragment, useEffect, useRef, useState } from 'react';
import packageJson from '../../../package.json';
import {
  Outlet,
  NavLink as NavLinkRRD,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import {
  MagnifyingGlassIcon,
  ChevronDownIcon,
} from '@heroicons/react/20/solid';
import { connect, useDispatch } from 'react-redux';
import { setDataObject as setSelectedInvoice } from '@redux/ducks/invoice';
import {
  Bars3Icon,
  BellIcon,
  XMarkIcon,
  Cog6ToothIcon,
} from '@heroicons/react/24/outline';
import { logout, AddCompany } from '@redux/ducks/auth';
import Logo from '@assets/img/shipy.svg';
import { loadUserMenuByRole } from '../../config/permission';
import { clearAllMessages } from '@redux/ducks/toast';
import { getContractors } from '@redux/ducks/shipper';

import { CompanySelector, Toast } from '@components';
import { bindActionCreators } from 'redux';
import { companyCustomConfiguration } from '../../config/api';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const NavLink = ({ route, name }) => {
  return (
    <NavLinkRRD
      to={route}
      className={({ isActive }) =>
        [
          isActive
            ? 'text-gray-900 border-blue-500'
            : 'border-transparent  text-gray-500 hover:border-gray-300 hover:text-gray-700',
          'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium',
        ].join(' ')
      }
    >
      {name}
    </NavLinkRRD>
  );
};

const OptionsLink = ({ route, name }) => {
  return (
    <Menu.Item>
      <NavLinkRRD
        to={route}
        className="text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900"
      >
        {name}
      </NavLinkRRD>
    </Menu.Item>
  );
};

const Navbar = ({
  user,
  contextCompany,
  message,
  clearAllMessages,
  getContractors,
  contractors,
  invoiceError,
}) => {
  const { version } = packageJson;
  const [invoice, setInvoice] = useState('');
  const [company, setCompany] = useState();
  const toastRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { navBarOptions, menuOptions, shownInvoiceTools } =
    loadUserMenuByRole(user);
  const shipperWithoutRelatedCompany =
    user?.role?.name === 'SHIPPER' && !contextCompany?.id;

  const customConfiguration = companyCustomConfiguration.find(
    company => company.cnpj === '76827344000130'
  );

  const handleLogout = () => {
    dispatch(logout());
    navigate('/');
  };

  const detail = invoiceKey => {
    dispatch(
      setSelectedInvoice({
        key: invoiceKey,
      })
    );
  };

  const handleInvoiceKeyDown = event => {
    if (event.key === 'Enter') {
      detail(invoice);
    }
  };

  useEffect(() => {
    if (company) {
      dispatch(AddCompany(company));
    }
  }, [company]);

  useEffect(() => {
    if (message) {
      toastRef?.current?.show({
        ...message,
      });

      clearAllMessages();
    }
    if (invoiceError?.message) {
      toastRef?.current?.show({
        message: invoiceError?.message,
        type: 'error',
      });
    }
  }, [message, invoiceError, toastRef, clearAllMessages]);

  useEffect(() => {
    getContractors();
  }, []);

  function cannotAccessReprieve() {
    if (user?.role?.name !== 'OPERATOR' && user?.role?.name !== 'COMPANY') {
      return true;
    }

    if (customConfiguration?.cnpj === contextCompany?.cnpj) {
      return false;
    }

    return true;
  }

  return (
    <>
      <Disclosure as="nav" className="bg-white">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-full px-2 sm:px-16 lg:px-8 shadow bg-white">
              <div className="flex h-16 justify-between">
                <div className="flex px-2 lg:px-0">
                  <div className="flex flex-shrink-0 items-center">
                    <img
                      className="block h-8 w-auto lg:hidden"
                      src={Logo}
                      alt="Logo do Shipy"
                    />
                    <img
                      className="hidden h-8 w-auto lg:block"
                      src={Logo}
                      alt="Logo do Shipy"
                    />
                  </div>
                  <div className="hidden lg:ml-6 lg:flex lg:space-x-8">
                    {!shipperWithoutRelatedCompany &&
                      navBarOptions?.map(option => (
                        <NavLink
                          key={option.route}
                          route={option.route}
                          name={option.name}
                        />
                      ))}
                  </div>

                  {!shipperWithoutRelatedCompany && menuOptions?.length > 0 && (
                    <div
                      className={`${
                        menuOptions.find(
                          item => item.route === location.pathname
                        )
                          ? 'border-b-2 border-blue-500'
                          : 'border-b-2 border-transparent'
                      } lg:ml-6 flex lg:flex lg:space-x-8 justify-center items-center`}
                    >
                      <Menu
                        as="div"
                        className={` relative inline-block text-left`}
                      >
                        <div>
                          <Menu.Button
                            className={`${
                              menuOptions.find(
                                item => item.route === location.pathname
                              )
                                ? 'text-gray-900'
                                : 'text-gray-500'
                            } inline-flex w-full pt-3 justify-center gap-x-1.5 bg-white px-3 py-2 text-sm items-center font-medium hover:text-gray-900`}
                          >
                            Mais opções
                            <ChevronDownIcon
                              className="-mr-1 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute left-0 z-10 mt-2 w-64 origin-top-right rounded-md bg-white shadow-lg shadow-gray-500 ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="py-1">
                              {menuOptions?.map(option => {
                                if (
                                  option.name === 'Consulta prorrogações' &&
                                  cannotAccessReprieve()
                                ) {
                                  return null;
                                }

                                if (
                                  option.name === 'Fila de carga' &&
                                  contextCompany.cnpj !==
                                    customConfiguration.cnpj
                                ) {
                                  return null;
                                }

                                return (
                                  <OptionsLink
                                    key={option.name}
                                    route={option.route}
                                    name={option.name}
                                  />
                                );
                              })}
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  )}
                </div>
                <div className="flex flex-1 items-center justify-center px-2 lg:ml-6 lg:justify-end">
                  {!shipperWithoutRelatedCompany && shownInvoiceTools && (
                    <div className="w-full max-w-lg lg:max-w-xs">
                      <label htmlFor="search" className="sr-only">
                        Informe uma nota fiscal
                      </label>
                      <div className="relative">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <MagnifyingGlassIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <input
                          id="search"
                          name="search"
                          className="block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                          placeholder="Informe uma nota fiscal"
                          type="search"
                          onChange={event => setInvoice(event.target.value)}
                          onKeyDown={handleInvoiceKeyDown}
                          value={invoice}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex items-center lg:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                {contractors && contractors.length > 0 && (
                  <CompanySelector
                    options={contractors}
                    setOption={setCompany}
                    selectedOption={contextCompany}
                  />
                )}
                <div className="hidden lg:ml-4 lg:flex lg:items-center">
                  {!shipperWithoutRelatedCompany && shownInvoiceTools && (
                    <button
                      type="button"
                      className="flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  )}

                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-4 flex-shrink-0">
                    <div>
                      <Menu.Button className="flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
                        <Cog6ToothIcon className="h-6 w-6" aria-hidden="true" />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 px-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <div>
                              <div className="w-full px-4 py-2 text-sm font-semibold border-b-2 border-gray-100 text-gray-700 text-ellipsis overflow-hidden">
                                {user?.firstName} {user?.lastName}
                              </div>

                              <div
                                onClick={handleLogout}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'flex w-full justify-between items-center cursor-pointer'
                                )}
                              >
                                <div className="flex w-full px-4 py-2 text-sm text-gray-700">
                                  Sair
                                </div>
                                <div className="block pr-4 py-2 lg:ml-4 lg:flex lg:items-center text-sm font-semibold text-gray-700">
                                  v{version}
                                </div>
                              </div>
                            </div>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="lg:hidden">
              <div className="space-y-1 pb-3 pt-2">
                {/* Current: "bg-blue-50 border-blue-500 text-blue-700", Default: "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800" */}
                <Disclosure.Button
                  as="a"
                  href="#"
                  className="block border-l-4 border-blue-500 bg-blue-50 py-2 pl-3 pr-4 text-base font-medium text-blue-700"
                >
                  Dashboard
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="#"
                  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800"
                >
                  Team
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="#"
                  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800"
                >
                  Projects
                </Disclosure.Button>
                <Disclosure.Button
                  as="a"
                  href="#"
                  className="block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800"
                >
                  Calendar
                </Disclosure.Button>
              </div>

              <div className="border-t border-gray-200 pb-3 pt-4">
                <div className="flex items-center px-4">
                  <div className="flex-shrink-0">
                    <img
                      className="h-10 w-10 rounded-full"
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                      alt=""
                    />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-medium text-gray-800">
                      Tom Cook
                    </div>
                    <div className="text-sm font-medium text-gray-500">
                      tom@example.com
                    </div>
                  </div>
                  <button
                    type="button"
                    className="ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                  >
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-3 space-y-1">
                  <Disclosure.Button
                    as="a"
                    href="#"
                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                  >
                    Your Profile
                  </Disclosure.Button>
                  <Disclosure.Button
                    as="a"
                    href="#"
                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                  >
                    Settings
                  </Disclosure.Button>
                  <Disclosure.Button
                    as="a"
                    href="#"
                    className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                  >
                    Sign out
                  </Disclosure.Button>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <div className="py-4 px-6">
        <Outlet />
      </div>
      <Toast ref={toastRef} />
    </>
  );
};

function mapStateToProps({ auth, toast, invoice }) {
  const { user, company, contractors } = auth;
  const { message } = toast;
  const { error_query: invoiceError } = invoice;

  return {
    user,
    contextCompany: company,
    message,
    contractors,
    invoiceError,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ clearAllMessages, getContractors }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
