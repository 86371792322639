export const Types = {
  SET_MESSAGE: 'toast/SET_MESSAGE',
  CLEAR_ALL_MESSAGES: 'toast/CLEAR_ALL_MESSAGES',
};

const initialState = {
  message: null,
};

export default function toast(state = initialState, action) {
  switch (action.type) {
    case Types.SET_MESSAGE:
      return {
        ...state,
        message: action.message,
      };
    case Types.CLEAR_ALL_MESSAGES:
      return initialState;
    default:
      return state;
  }
}

export const setMessage = message => {
  return {
    type: Types.SET_MESSAGE,
    message,
  };
};

export function clearAllMessages() {
  return {
    type: Types.CLEAR_ALL_MESSAGES,
  };
}
