import { Button, TableForm } from '@components';
import moment from 'moment';
import { forwardRef } from 'react';

const AlertList = forwardRef(({ filter, handleEdit, ...rest }, ref) => {
  if (filter === 'terminated') {
    return (
      <TableForm
        {...rest}
        ref={ref}
        headers={[
          { label: 'Alerta', getValue: item => item.node.error },
          {
            label: 'Resolvido por',
            getValue: item => {
              if (item.node.resolvedBy) {
                return `${item.node.resolvedBy.firstName} ${
                  item.node.resolvedBy.lastName || ''
                }`;
              }
              return null;
            },
          },
          {
            label: 'Solução',
            getValue: item => `${item.node.resolution || ''}`,
          },
          {
            label: 'Aberto',
            getValue: item =>
              item.node.createdAt &&
              moment(item.node.createdAt).format('DD/MM/YYYY-HH:mm'),
          },
          { label: 'Categoria', getValue: item => item.node.category },
          {
            label: 'Fechado',
            getValue: item =>
              item.node.resolvedAt &&
              moment(item.node.resolvedAt).format('DD/MM/YYYY-HH:mm'),
          },
        ]}
        keyRow={item => item.node.id}
      />
    );
  }
  return (
    <TableForm
      {...rest}
      ref={ref}
      headers={[
        { label: 'Categoria', getValue: item => item.node.category },
        { label: 'Alerta', getValue: item => item.node.error },
        {
          label: 'Aberto',
          getValue: item =>
            item.node.createdAt &&
            moment(item.node.createdAt).format('DD/MM/YYYY-HH:mm'),
        },
        {
          label: 'Ações',
          props: {
            align: 'center',
            width: '70px',
          },
          getValue: item => (
            <Button
              compact
              key={item.node.id}
              onClick={() => handleEdit(item.node)}
            >
              Encerrar
            </Button>
          ),
        },
      ]}
      keyRow={item => item.node.id}
    />
  );
});

AlertList.displayName = 'AlertList';

export default AlertList;
