import { TableForm, Button, Label } from '@components';
import { CnpjCpf } from '@utils';
import { forwardRef } from 'react';

const DriverList = forwardRef((props, ref) => {
  const { edit, removeDriver } = props;

  return (
    <TableForm
      ref={ref}
      {...props}
      headers={[
        { label: 'CPF', getValue: item => CnpjCpf.mask(item.node.cpf) },
        {
          label: 'Nome',
          getValue: item => `${item.node.firstName} ${item.node.lastName}`,
        },
        { label: 'Placa', getValue: item => item.node.shipperDriver.plate },
        { label: 'Telefone', getValue: item => item.node.shipperDriver.phone },
        {
          label: 'Situação',
          getValue: item => (
            <Label
              type={item.node.shipperDriver.active ? 'success' : 'alert'}
              text={item.node.shipperDriver.active ? 'Ativo' : 'Inativo'}
            />
          ),
        },
        {
          label: 'Ações',
          props: {
            align: 'center',
            width: '160px',
          },
          getValue: item => (
            <div className="flex gap-2 justify-end">
              <Button
                compact
                key={item.node.id}
                onClick={() => edit(item.node)}
              >
                Editar
              </Button>
              <Button
                compact
                variant="negative"
                key={item.node.id + 'REMOVE'}
                onClick={() => removeDriver(item)}
              >
                Remover
              </Button>
            </div>
          ),
        },
      ]}
    />
  );
});

DriverList.displayName = 'DriverList';

export default DriverList;
