import { forwardRef } from 'react';
import Action from './Action';
import Select from './Select';

const Input = forwardRef(({ caption, name, type = 'text', ...rest }, ref) => {
  return (
    <div>
      {!!caption && (
        <label
          htmlFor={name}
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          {caption}
        </label>
      )}
      <input
        ref={ref}
        type={type}
        name={name}
        {...rest}
        className="block bg-white disabled:bg-zinc-50 disabled:text-gray-500 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
      />
    </div>
  );
});

Input.displayName = 'Input';

Input.Action = Action;
Input.Select = Select;

export default Input;
