import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';

const DatePickerInput = ({ format, ...rest }) => {
  return (
    <DayPickerInput
      format={format || 'DD/MM/YYYY'}
      formatDate={formatDate}
      parseDate={parseDate}
      {...rest}
    />
  );
};

export default DatePickerInput;
