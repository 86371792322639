import { connect } from 'react-redux';
import { getAccessToken, setAccessToken } from '@redux/ducks/config';
import { Input, Button, Confirm } from '@components';
import {
  ClipboardDocumentCheckIcon,
  Cog6ToothIcon,
} from '@heroicons/react/24/outline';

import { useEffect, useRef, useState } from 'react';
import { bindActionCreators } from 'redux';
import { fetchWebhooks, saveSecret } from '@redux/ducks/webhook';
import WebhookItem from './WebhookItem';

const Token = ({
  token,
  fetchWebhooks,
  setAccessToken,
  getAccessToken,
  saveSecret,
  secretId,
  secret,
  webhooksList,
}) => {
  const [currentToken, setCurrentToken] = useState(token);
  const [currentSecret, setCurrentSecret] = useState('');
  const confirmRef = useRef(null);

  const webhooksSubjects = [
    'incident_created',
    'shipper_updated',
    'invoice_log_updated',
    'delivery_created',
    'delivery_updated',
    'delivery_removed',
    'reversal_updated',
    'redelivery_created',
    'shipping_order_delivery_created',
  ];

  function handleNewToken() {
    confirmRef.current.open('Deseja gerar o token?', () => {
      setAccessToken({
        success: response => {
          setCurrentToken(response);
        },
      });
    });
  }

  async function handleToken() {
    getAccessToken({
      error: ({ message }) => {
        console.log(message);
      },
      success: response => {
        setCurrentToken(response);
      },
    });
  }

  async function handleSaveSecret(secretForm) {
    await saveSecret({
      data: {
        configurationId: secretId,
        configuration: {
          secret: secretForm,
        },
      },
      edit: !!secret,
      success: () => fetchWebhooks(),
    });
  }

  const onChange = event => {
    setCurrentSecret(event.target.value.replace(/\s/g, ''));
  };

  useEffect(() => {
    handleToken();
    fetchWebhooks();
  }, []);

  useEffect(() => {
    setCurrentSecret(secret || '');
  }, [secret]);

  return (
    <>
      <div>
        <div className="text-lg font-semibold">Integração</div>
        <div className="mb-5">
          {currentToken
            ? `Você já possui uma chave de acesso a API do Shipy`
            : `Clicando no botão gerar token você cria uma chave de acesso que lhe
            permitirá acessar a API de integração do Shipy`}
        </div>
      </div>
      <div className="flex flex-1 space-x-2">
        <div className="w-2/5">
          <Input
            placeholder="Token de acesso"
            caption="Token de acesso"
            disabled
            defaultValue={currentToken}
          />
        </div>
        <div>
          <label>&nbsp;</label>
          <Button
            disabled={!currentToken}
            onClick={() => {
              navigator.clipboard.writeText(currentToken);
            }}
          >
            <ClipboardDocumentCheckIcon className="h-5 w-5" />
          </Button>
        </div>
        <div>
          <label>&nbsp;</label>
          <Button
            variant="primary"
            onClick={() => {
              handleNewToken();
            }}
          >
            {currentToken ? 'Regerar token' : 'Gerar token'}
          </Button>
        </div>
      </div>

      <div className="mt-10 text-lg font-semibold">WebHooks</div>
      <div className="mb-5">
        Selecione abaixo as opções de WebHook que melhor atendem sua necessidade
      </div>

      <div className="flex flex-1 space-x-2 mb-3">
        <div className="w-2/5">
          <Input
            caption="Palavra secreta para autenticação"
            placeholder={'Digite a palavra secreta'}
            value={currentSecret}
            onChange={onChange}
          />
        </div>
        <div className="w-36">
          <label>&nbsp;</label>
          <Button
            variant={secret ? 'default' : 'primary'}
            fullWidth
            onClick={() => handleSaveSecret(currentSecret)}
            disabled={!currentSecret}
          >
            <Cog6ToothIcon className="h-5 w-5 green" />
            {secret ? 'Editar' : 'Salvar'}
          </Button>
        </div>
      </div>

      {secret &&
        webhooksSubjects.map(webhook => (
          <WebhookItem
            key={webhook}
            webhook={webhook}
            webhooksList={webhooksList}
          />
        ))}
      <Confirm ref={confirmRef} />
    </>
  );
};

function mapStateToProps({ webhook }) {
  const secret = webhook?.results?.secret;
  const secretId = webhook?.results?.id;
  const webhooksList = webhook?.results?.webhooks;

  return {
    secretId,
    secret,
    webhooksList,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getAccessToken, fetchWebhooks, setAccessToken, saveSecret },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Token);
