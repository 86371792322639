import { useEffect, useRef, useState } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchExpenseTypes } from '@redux/ducks/expenseType';
import { fetchExpense, resetState } from '@redux/ducks/expense';

import ExpenseDetail from '@pages/Expenses/Accompaniments/Detail';
import InvoiceSearchForm from '@pages/invoices/components/invoiceSearch';

import Filter from './components/Filter';
import { Button } from '@components';
import ExpenseList from './components/ExpenseList';
import ExpenseForm from './components/ExpenseForm';

import moment from 'moment';

const Expense = ({
  fetchExpense,
  fetchExpenseTypes,
  pageInfo,
  results,
  load_query,
  expenseOptions,
  resetState,
  contextCompany,
  load_mutation
}) => {
  const [isInvoiceSearchFormModalOpen, setIsInvoiceSearchFormModalOpen] =
    useState(false);
  const [isExpenseFormModalOpen, setIsExpenseFormModalOpen] = useState(false);
  const [isExpenseDetailModalOpen, setIsExpenseDetailModalOpen] =
    useState(false);
  const [expenseDetailData, setExpenseDetailData] = useState({});
  const [expenseFormData, setExpenseFormData] = useState({});
  const listRef = useRef(null);

  const from = new Date(moment().subtract(30, 'days'));
  const to = new Date();
  const initialStateFilter = {
    from,
    to,
    status: 1,
    expense: expenseOptions,
    type: null,
  };

  function handleNextPage() {
    const search = listRef.current.getSearch();
    fetchExpense({
      cursor: pageInfo.cursor,
      search,
    });
  }

  function handleSearch(data) {
    fetchExpense({
      type: data.type,
      to: data.to,
      from: data.from,
      status: data.status,
    });
  }

  function onDetailReject(data) {
    setExpenseDetailData(data.node);
    setIsExpenseDetailModalOpen(true);
  }

  function handleExpenseForm(e) {
    setExpenseFormData(e);
    setIsInvoiceSearchFormModalOpen(false);
    setIsExpenseFormModalOpen(true);
  }

  function handleCloseExpenseForm () {
    setIsExpenseFormModalOpen(false)
    resetState();
    fetchExpense(initialStateFilter);
  }

  const Filters = () => (
    <div className="flex sm:justify-between sm:items-end">
      <Filter
        initialState={initialStateFilter}
        onSearch={handleSearch}
        onNew={() => {
          listRef.current.openForm();
        }}
      />

      <div className="px-3 py-2">
        <Button
          variant="primary"
          onClick={() => setIsInvoiceSearchFormModalOpen(true)}
        >
          Cadastrar
        </Button>
      </div>
    </div>
  );

  useEffect(() => {
    resetState();
    fetchExpenseTypes({});
    handleSearch(initialStateFilter);
  }, [contextCompany]);

  if (initialStateFilter.expense.length === 0 || expenseOptions.length === 0) {
    return (
      <div className="flex flex-col flex-1">
        <div className="sm:flex sm:items-center mb-2">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-4 text-gray-800">
              Consulta de despesas
            </h1>
            <p className="mt-1 text-sm text-gray-700">
              Gerencie e visualize as despesas
            </p>
          </div>
        </div>

        <div className="flex gap-2 justify-center items-center text-sm font-semibold bg-orange-100 py-2 text-gray-700 rounded-md">
          É necessário cadastrar algum tipo de despesa antes de prosseguir
        </div>
      </div>
    );
  }

  return (
    <>
      <ExpenseList
        formHeader={{
          title: 'Consulta de despesas',
          subtitle: 'Gerencie e visualize as despesas',
        }}
        source={results}
        hasNext={pageInfo.hasNextPage}
        load={load_query}
        next={handleNextPage}
        filters={Filters}
        ref={listRef}
        onDetailReject={data => onDetailReject(data)}
      />
      <InvoiceSearchForm
        ribbon={false}
        open={isInvoiceSearchFormModalOpen}
        onSubmit={handleExpenseForm}
        onClose={() => setIsInvoiceSearchFormModalOpen(false)}
      />
      <ExpenseForm
        open={isExpenseFormModalOpen}
        expenseFormData={expenseFormData}
        onClose={handleCloseExpenseForm}
        loadMutation={load_mutation}
      />
      <ExpenseDetail
        open={isExpenseDetailModalOpen}
        modalTitle="Detalhamento da despesa"
        onClose={() => setIsExpenseDetailModalOpen(false)}
        expenseDetailData={expenseDetailData}
        hide={['cost', 'description', 'type']}
      />
    </>
  );
};

function mapStateToProps({ expense, expenseType, auth }) {
  const { results, pageInfo, load_query, load_mutation } = expense;
  const { results: expenseOptions } = expenseType;
  const { company } = auth;
  return {
    pageInfo,
    results,
    load_query,
    expenseOptions,
    contextCompany: company,
    load_mutation
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { fetchExpense, resetState, fetchExpenseTypes },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Expense);
