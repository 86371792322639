import { client } from '@config/apollo';
import gql from 'graphql-tag';
import { call, put, select } from 'redux-saga/effects';
import { Types } from '../ducks/invoice';
import { Types as ToastTypes } from '../ducks/toast';

import moment from 'moment';

export function* fetchInvoice(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);
  const { key, invoiceNo } = action.filter;

  try {
    yield put({ type: Types.IS_FETCH_INVOICE, load: true });

    const response = yield call(cli.query, {
      query: GQL_FETCH_INVOICE,
      variables: {
        key: key ? key : undefined,
        invoiceNo: invoiceNo ? invoiceNo : undefined,
        company: auth?.company?.id,
      },
    });

    if (response.data.invoice && response.data.invoice.key) {
      yield put({
        type: Types.SET_PROP_DATA_OBJECT,
        data: { invoice: response.data.invoice, open: true },
      });
    } else {
      yield put({
        type: Types.SET_PROP_DATA_OBJECT,
        data: { invoice: {}, key: null, open: false },
      });
      throw 'Nota fiscal não existe!';
    }
  } catch (error) {
    yield put({
      type: Types.SET_PROP_DATA_OBJECT,
      data: { invoice: {}, key: null, open: false },
    });
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message || error,
      },
    });
  } finally {
    yield put({ type: Types.IS_FETCH_INVOICE, load: false });
  }
}

export function* getInvoice(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);
  const { key, invoiceNo } = action.filter;

  try {
    yield put({ type: Types.LOAD_QUERY, load: true });

    const response = yield call(cli.query, {
      query: GQL_FETCH_INVOICE,
      variables: {
        key: key ? key : undefined,
        invoiceNo: invoiceNo ? invoiceNo : undefined,
        company: auth?.company?.id,
      },
    });

    if (response.data.invoice && response.data.invoice.key) {
      if (action.success) action.success(response.data.invoice);
    } else {
      throw 'Nota fiscal não existe!';
    }
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message || error,
      },
    });
  } finally {
    yield put({ type: Types.LOAD_QUERY, load: false });
  }
}

export function* fetchInvoices(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    yield put({ type: Types.LOAD_QUERY, load: true });
    const { descending, cursor, filter, queryType } = action;
    const queryDelivery =
      queryType === 'DELIVERIES' ? 'deliveries { date }' : '';
    const queryReturn = queryType === 'RETURNS' ? 'return { id date }' : '';

    const response = yield call(cli.query, {
      query: gql`
        query (
          $last: Int
          $descending: Boolean
          $after: String
          $before: String
          $filter: InvoiceFilterInput
        ) {
          invoices(
            last: $last
            descending: $descending
            after: $after
            before: $before
            filter: $filter
          ) {
            edges {
              node {
                id
                company {
                  id
                  cnpj
                  corporateName
                  commercialName
                }
                externalRedispatcher {
                  commercialName
                }
                externalDispatcher {
                  commercialName
                }
                customer {
                  id
                  code
                  cnpj
                  commercialName
                  corporateName
                  city {
                    id
                    name
                    state
                  }
                }
                dispatcher {
                  code
                  company {
                    id
                    corporateName
                  }
                }
                redeliveries {
                  id
                }
                redispatcher {
                  code
                  company {
                    id
                    corporateName
                  }
                }
                note
                driverPlate
                key
                activities {
                  emittedAt
                  dispatchedAt
                  predictedTo
                  agreedTo
                }
                serialNo
                invoiceNo
                ${queryDelivery}
                ${queryReturn}
              }
            }
            pageInfo {
              hasNextPage
              after
              before
            }
          }
        }
      `,
      variables: {
        last: 50,
        before: cursor,
        filter: {
          ...filter,
          companyId: auth.company?.id,
        },
      },
    });

    const pageInfo = response.data.invoices.pageInfo;
    const results = response.data.invoices.edges;

    yield put({
      type: Types.SET_PAGE_INFO,
      pageInfo: {
        ...pageInfo,
        cursor: descending !== false ? pageInfo.before : pageInfo.after,
      },
    });

    if (action.cursor) {
      yield put({ type: Types.NEXT_RESULTS, results });
    } else {
      yield put({ type: Types.SET_RESULTS, results });
    }
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
  } finally {
    yield put({ type: Types.LOAD_QUERY, load: false });
  }
}

export function* fetchOverdueInvoices(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    yield put({ type: Types.LOAD_QUERY, load: true });
    const { descending, cursor, filter } = action;

    const response = yield call(cli.query, {
      query: gql`
        query (
          $first: Int
          $descending: Boolean
          $after: String
          $before: String
          $filter: InvoiceFilterInput
        ) {
          overdueInvoices(
            first: $first
            descending: $descending
            after: $after
            before: $before
            filter: $filter
          ) {
            edges {
              node {
                id
                company {
                  id
                  cnpj
                  corporateName
                  commercialName
                }
                externalRedispatcher {
                  commercialName
                }
                externalDispatcher {
                  commercialName
                }
                customer {
                  id
                  code
                  cnpj
                  commercialName
                  corporateName
                  city {
                    id
                    name
                    state
                  }
                }
                dispatcher {
                  code
                  company {
                    id
                    corporateName
                  }
                }
                redispatcher {
                  code
                  company {
                    id
                    corporateName
                  }
                }
                note
                driverPlate
                key
                activities {
                  emittedAt
                  dispatchedAt
                  predictedTo
                  agreedTo
                }
                serialNo
                invoiceNo
              }
            }
            pageInfo {
              hasNextPage
              after
              before
            }
          }
        }
      `,
      variables: {
        first: 50,
        after: cursor,
        filter: {
          ...filter,
          companyId: auth.company?.id,
        },
      },
    });

    const pageInfo = response.data.overdueInvoices.pageInfo;
    const results = response.data.overdueInvoices.edges;

    yield put({
      type: Types.SET_PAGE_INFO,
      pageInfo: {
        ...pageInfo,
        cursor: descending !== false ? pageInfo.before : pageInfo.after,
      },
    });

    if (action.cursor) {
      yield put({ type: Types.NEXT_RESULTS, results });
    } else {
      yield put({ type: Types.SET_RESULTS, results });
    }
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
  } finally {
    yield put({ type: Types.LOAD_QUERY, load: false });
  }
}

export function* addLogBackground(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  const { message, key } = action;

  try {
    yield call(cli.mutate, {
      mutation: gql`
        mutation ($invoiceLog: InvoiceLogInput!) {
          createInvoiceLog(invoiceLog: $invoiceLog) {
            ok
          }
        }
      `,
      variables: {
        invoiceLog: {
          description: message,
          key,
        },
      },
    });
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
  }
}

export function* addLog(action) {
  const auth = yield select(state => state.auth);
  const dataObject = yield select(state => state.invoice.dataObject);

  const cli = yield call(client, auth.token);

  try {
    const { message } = action;

    if (!message || message.length <= 0) {
      throw Error('Favor preencher o campo com a descrição do log.');
    }

    yield put({ type: Types.LOAD_MUTATION, load: true });

    //Cria log
    yield call(cli.mutate, {
      mutation: gql`
        mutation ($invoiceLog: InvoiceLogInput!) {
          createInvoiceLog(invoiceLog: $invoiceLog) {
            ok
          }
        }
      `,
      variables: {
        invoiceLog: {
          description: message,
          key: dataObject.invoice.key,
        },
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Adicionado log com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* removeReturn(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { invoiceKey } = action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($invoiceKey: String!) {
          removeReturn(invoiceKey: $invoiceKey) {
            ok
          }
        }
      `,
      variables: {
        invoiceKey,
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Nota removida com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* saveAgreedTo(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { invoiceId, invoiceKey, dateHour, date } = action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($id: String!, $data: InvoiceUpdateInput!) {
          updateInvoice(id: $id, data: $data) {
            ok
          }
        }
      `,
      variables: {
        id: invoiceId,
        data: {
          activities: {
            predictedTo: moment(`${date}-00:00:00`, 'DD/MM/YYYY-HH:mm'),
            agreedTo: moment(`${date}-00:00:00`, 'DD/MM/YYYY-HH:mm'),
          },
        },
      },
    });

    //Adicionar log
    yield put({
      type: Types.ADD_LOG_BACKGROUND,
      message: `Alterado data de previsão combinada com o cliente de ${dateHour} para ${date}.`,
      key: invoiceKey,
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: `Alterado data de previsão combinada com o cliente de ${dateHour} para ${date}.`,
      },
    });

    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* editInvoiceExpenseDeadlineDate(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  const { invoiceId, canExtendExpense } = action.data;

  try {
    yield call(cli.mutate, {
      mutation: gql`
        mutation EditInvoiceExpenseDeadlineDate(
          $invoiceId: String!
          $canExtendExpense: Boolean!
        ) {
          editInvoiceExpenseDeadlineDate(
            invoiceId: $invoiceId
            canExtendExpense: $canExtendExpense
          ) {
            ok
          }
        }
      `,
      variables: {
        invoiceId,
        canExtendExpense,
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Cadastro de despesas alterado com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message || error,
      },
    });
    if (action.error) action.error(error);
  }
}

const GQL_FETCH_INVOICE = gql`
  query ($key: String, $invoiceNo: String, $company: String) {
    invoice(key: $key, invoiceNo: $invoiceNo, company: $company) {
      id
      key
      driverPlate
      invoiceNo
      type
      discount
      order
      note
      canExtendExpense
      redeliveries {
        id
      }
      xmlUrl
      pdfUrl
      status
      productInfo {
        aggregated {
          totalValue
          goodsValue
          netWeight
          grossWeight
          volume
          cubage
        }
        items {
          sku
          name
          unity
          quantity
          reversedQuantity
          grossPrice
          netPrice
          totalValue
        }
      }
      externalRedispatcher {
        cnpj
        corporateName
        commercialName
        email
        phone
        contact
        neighborhood
        address
        city {
          id
          code
          name
          state
        }
      }
      externalDispatcher {
        cnpj
        corporateName
        commercialName
        email
        phone
        contact
        neighborhood
        address
        city {
          id
          code
          name
          state
        }
      }
      shippingOrder {
        key
        deliveredAt
      }
      activities {
        emittedAt
        dispatchedAt
        predictedTo
        deliveredAt
        agreedTo
      }
      company {
        id
        cnpj
        corporateName
        commercialName
        email
        phone
      }
      customer {
        id
        code
        corporateName
        city {
          id
          name
          state
        }
        address
        number
        complement
        patio
      }
      logisticInfo {
        truckType
        isDirectCargo
        deliveryTime
        restrictionNote
        deliveryNote
        isScheduled
        isPalletizedCargo
        withAssistant
      }
      deliveries {
        id
        date
        stub
        evidences
        createdBy {
          firstName
          lastName
        }
        location {
          lat
          lng
        }
        driver {
          firstName
          lastName
          user {
            phone
          }
        }
        shipper {
          company {
            corporateName
            cnpj
            commercialName
          }
        }
      }
      return {
        id
        date
        backsidePhoto
        createdBy {
          firstName
          lastName
        }
        location {
          lat
          lng
        }
        driver {
          firstName
          lastName
          user {
            phone
          }
        }
        shipper {
          company {
            commercialName
            corporateName
          }
        }
      }
      reversal {
        id
        reversedBy {
          firstName
          lastName
        }
        createdBy {
          firstName
          lastName
        }
        charges {
          freightForCompany
          representativeFreight
          representativeTaxSubstitution
        }
        reason {
          id
          active
          description
          code
        }
        invoice {
          id
          key
          invoiceNo
          activities {
            emittedAt
          }
          company {
            id
            corporateName
            commercialName
          }
          customer {
            id
            commercialName
            corporateName
            code
          }
        }
        reversedAt
        createdAt
        status
        note
        customerDocument
        bdNumber
        bdValue
        freightValue
      }
      incidents {
        id
        driver {
          firstName
          lastName
          cpf
        }
        start
        end
        note
        closedBy {
          firstName
          lastName
        }
        type {
          description
        }
        location {
          lat
          lng
        }
        invoice {
          invoiceNo
          key
        }
      }
      dispatcher {
        code
        since
        company {
          id
          cnpj
          corporateName
          commercialName
          email
          phone
          user {
            id
            email
            firstName
            lastName
            phone
          }
          stateInscription
          contact
          neighborhood
          address
          city {
            id
            code
            name
            state
          }
        }
      }
      dispatchShippingType
      redispatcher {
        code
        since
        company {
          id
          cnpj
          corporateName
          commercialName
          email
          phone
          user {
            id
            email
            firstName
            lastName
            phone
          }
          stateInscription
          contact
          neighborhood
          address
          city {
            id
            code
            name
            state
          }
        }
      }
      redispatchShippingType
      shippingOrder {
        driverName
        driverCpf
      }
      logs {
        createdBy {
          firstName
          lastName
        }
        description
        committedAt
      }
      expenses {
        id
        type {
          id
          description
        }
        auditedBy {
          id
          firstName
          lastName
        }
        cost
        description
        createdAt
        approvedAt
        rejectedAt
        attachments
        reason
      }
    }
  }
`;
