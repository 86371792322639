import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  fetchLogisticCenters,
  saveLogisticCenter,
} from '@redux/ducks/logisticCenter';
import { getCities } from '@redux/ducks/city';
import { TableForm, Button } from '@components';
import LogisticCenterForm from './components/LogisticCenterForm';
import { useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';

const LogisticCenter = ({
  results,
  load_query,
  fetchLogisticCenters,
  saveLogisticCenter,
  getCities,
  load_mutation,
}) => {
  const navigate = useNavigate();
  const formRef = useRef(null);
  const listRef = useRef(null);

  function initialize() {
    window.scroll(0, 0);
    listRef.current.closeForm();
    fetchLogisticCenters();
  }

  function handleSave(data, edit) {
    return new Promise((resolve, reject) => {
      saveLogisticCenter({
        data,
        edit,
        success: () => {
          initialize();
          resolve();
        },
        error: error => {
          reject(error);
        },
      });
    });
  }

  async function handleEdit(logisticCenter) {
    await listRef.current.openForm();
    const { id, cnpj, name, city } = logisticCenter;

    setTimeout(() => {
      formRef?.current?.edit({
        id,
        cnpj,
        name,
        cityName: city.name,
        cityCode: city.code,
      });
    }, 200);
  }

  function handleGoToRegions(logisticCenterCnpj) {
    navigate(`/logistic-centers/${logisticCenterCnpj}/regions`);
  }

  useEffect(() => {
    initialize();
  }, []);

  return (
    <>
      <TableForm
        formHeader={{
          title: 'Centros Logísticos',
          subtitle: 'Gerencie e visualize os centros logísticos',
        }}
        headers={[
          { label: 'Nome', getValue: item => item.name },
          {
            label: 'Cidade',
            getValue: item => `${item.city.name} - ${item.city.state}`,
          },
          {
            label: 'Ações',
            props: {
              align: 'center',
              width: '170px',
            },
            getValue: item => (
              <div className="flex gap-2 justify-end">
                <Button compact onClick={() => handleGoToRegions(item.cnpj)}>
                  Regiões
                </Button>
                <Button compact key={item.id} onClick={() => handleEdit(item)}>
                  Editar
                </Button>
              </div>
            ),
          },
        ]}
        keyRow={item => item.id}
        titleModal="Cadastro de centro logístico"
        onNew={() => listRef.current.openForm()}
        source={results}
        load={load_query}
        ref={listRef}
      >
        <LogisticCenterForm
          getCities={getCities}
          formRef={formRef}
          refreshFormData={data => formRef.current.change(data)}
          onSubmit={handleSave}
          loadMutation={load_mutation}
        />
      </TableForm>
    </>
  );
};

function mapStateToProps({ logisticCenter }) {
  const { results, load_query, load_mutation } = logisticCenter;
  return {
    results,
    load_query,
    load_mutation,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { fetchLogisticCenters, saveLogisticCenter, getCities },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(LogisticCenter);
