import { CnpjCpf } from '@utils';

export const validate = values => {
  const errors = {};
  if (!values.cnpj) {
    errors.cnpj = 'Favor informar o CNPJ da empresa!';
  } else if (!CnpjCpf.validate(values.cnpj)) {
    errors.cnpj = 'CNPJ inválido. Favor preencha com um CNPJ válido!';
  }
  if (!values.corporateName) {
    errors.corporateName = 'Favor informar a razão social!';
  }
  if (!values.city) {
    errors.city = 'Favor informar a cidade!';
  }
  if (!values.commercialName) {
    errors.commercialName = 'Favor informar o nome fantasia!';
  }
  if (!values.contactFirstName) {
    errors.contactFirstName = 'Favor informar o nome do contratante!';
  }
  if (!values.contactLastName) {
    errors.contactLastName = 'Favor informar o sobrenome do contratante!';
  }
  if (!values.email) {
    errors.email = 'Campo obrigatório! ';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'E-mail inválido! ';
  }
  if (!values.phone) {
    errors.phone = 'Favor informar o numero de telefone da empresa!';
  }
  if (!values.neighborhood) {
    errors.neighborhood = 'Favor informar o bairro!';
  }
  if (!values.address) {
    errors.address = 'Favor informar o endereço!';
  }
  if (!values.stateInscription) {
    errors.stateInscription = 'Favor informar inscrição estadual!';
  }
  if (!values.licenseSlots) {
    errors.licenseSlots = 'Favor informar a quantidade de licenças!';
  }

  return errors;
};
