import { FormCreate } from '@components';
import validate from './validate';

const Form = FormCreate('MessageForm', validate);

const number = value => value && value.replace(/[^0-9]/g, '');

const MessageForm = props => {
  return (
    <Form
      {...props}
      fields={[
        {
          name: 'cpf',
          component: 'input',
          type: 'text',
          label: 'CPF:',
          inputProps: {
            mask: '999.999.999-99',
            maskChar: '_',
            placeholder: 'Informe o CPF do motorista',
          },
          normalize: number,
        },
        {
          name: 'subject',
          component: 'input',
          type: 'text',
          label: 'Título:',
          inputProps: {
            placeholder: 'Informe o título da mensagem',
          },
        },
        {
          name: 'content',
          component: 'input',
          type: 'text',
          label: 'Conteúdo:',
          inputProps: {
            placeholder: 'Informe o conteúdo da mensagem',
          },
        },
      ]}
      lbSubmit="Enviar"
    />
  );
};

export default MessageForm;
