export const Types = {
  CREATE_DELIVERY: 'delivery/CREATE_DELIVERY',
  UPDATE_DELIVERY: 'delivery/UPDATE_DELIVERY',
  REVERSAL_DELIVERY: 'delivery/REVERSAL_DELIVERY',
  SET_RESULTS: 'delivery/SET_RESULTS',
  NEXT_RESULTS: 'delivery/NEXT_RESULTS',
  SET_PAGE_INFO: 'delivery/SET_PAGE_INFO',
  SET_ERROR_QUERY: 'delivery/SET_ERROR_QUERY',
  SET_ERROR_MUTATION: 'delivery/SET_ERROR_MUTATION',
  SET_SUCCESS_QUERY: 'delivery/SET_SUCCESS_QUERY',
  SET_SUCCESS_MUTATION: 'delivery/SET_SUCCESS_MUTATION',
  LOAD_QUERY: 'delivery/LOAD_QUERY',
  LOAD_MUTATION: 'delivery/LOAD_MUTATION',
  RESET_STATE: 'delivery/RESET_STATE',
  USER_LOGOUT: 'USER_LOGOUT',
};

const initialState = {
  results: [],
  pageInfo: {},
  load_query: false,
  load_mutation: false,
  error_query: {},
  error_mutation: {},
  success_query: {},
  success_mutation: {},
};

export default function delivery(state = initialState, action) {
  switch (action.type) {
    case Types.SET_RESULTS:
      return { ...state, results: action.results };
    case Types.NEXT_RESULTS:
      return { ...state, results: [...state.results, ...action.results] };
    case Types.SET_PAGE_INFO:
      return { ...state, pageInfo: action.pageInfo };
    case Types.SET_ERROR_QUERY:
      return { ...state, error_query: action.error };
    case Types.SET_ERROR_MUTATION:
      return { ...state, error_mutation: action.error };
    case Types.SET_SUCCESS_QUERY:
      return { ...state, success_query: action.success };
    case Types.SET_SUCCESS_MUTATION:
      return { ...state, success_mutation: action.success };
    case Types.LOAD_QUERY:
      return { ...state, load_query: action.load };
    case Types.LOAD_MUTATION:
      return { ...state, load_mutation: action.load };
    case Types.RESET_STATE:
    case Types.USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export function saveDelivery({ data, edit, success, error }) {
  return {
    type: edit === true ? Types.UPDATE_DELIVERY : Types.CREATE_DELIVERY,
    data,
    success,
    error,
  };
}

export function reversalDelivery({ id, success, error }) {
  return {
    type: Types.REVERSAL_DELIVERY,
    id,
    success,
    error,
  };
}

export function resetState() {
  return {
    type: Types.RESET_STATE,
  };
}
