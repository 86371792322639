import { forwardRef } from 'react';
import { TableForm } from '@components';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { setDataObject as setInvoice } from '@redux/ducks/invoice';
import { useDispatch } from 'react-redux';

const RedeliveryListComponent = forwardRef((props, ref) => {
  const dispatch = useDispatch();

  function handleDetailInvoice(invoiceNumber) {
    dispatch(
      setInvoice({
        key: invoiceNumber,
      })
    );
  }

  return (
    <TableForm
      propsRow={() => {}}
      {...props}
      ref={ref}
      headers={[
        {
          label: 'Nota Fiscal',
          getValue: item => {
            const redelivery = item.node;

            return (
              <span
                className="text-blue-500 cursor-pointer"
                onClick={() =>
                  handleDetailInvoice(redelivery.invoice.invoiceNo)
                }
              >
                {redelivery.invoice.invoiceNo}
              </span>
            );
          },
        },
        {
          label: 'Cliente',
          getValue: item => {
            const redelivery = item.node;

            return redelivery.invoice.customer.corporateName;
          },
        },
        {
          label: 'Transportadora',
          getValue: item => {
            const redelivery = item.node;

            return redelivery.shipper.company.corporateName;
          },
        },
        {
          label: 'Valor da reentrega',
          getValue: item => {
            const redelivery = item.node;

            return (
              <NumberFormat
                value={redelivery.value || 0}
                displayType={'text'}
                thousandSeparator="."
                decimalSeparator=","
                decimalScale={2}
                fixedDecimalScale
              />
            );
          },
        },
        {
          label: 'Previsão da reentrega',
          getValue: item => {
            const redelivery = item.node;
            return redelivery.customerDate
              ? moment(redelivery.customerDate).format('DD/MM/YYYY')
              : moment(redelivery?.invoice?.activities?.predictedTo).format(
                  'DD/MM/YYYY'
                );
          },
        },
        {
          label: 'Observação',
          getValue: item => {
            const redelivery = item.node;

            return `${redelivery.note || ''} `;
          },
        },
      ]}
    />
  );
});
RedeliveryListComponent.displayName = 'RedeliveryListComponent';

export default RedeliveryListComponent;
