export const Types = {
  CREATE_COMPANY: 'companies/CREATE_COMPANY',
  UPDATE_COMPANY: 'companies/UPDATE_COMPANY',
  UPDATE_STATUS_COMPANY: 'companies/UPDATE_STATUS_COMPANY',
  RESEND_COMPANY_INVITATION: 'companies/RESEND_COMPANY_INVITATION',

  FETCH_COMPANIES: 'companies/FETCH_COMPANIES',
  SET_RESULTS: 'companies/SET_RESULTS',

  SET_ERROR_QUERY: 'companies/SET_ERROR_QUERY',
  SET_SUCCESS_QUERY: 'companies/SET_SUCCESS_QUERY',
  LOAD_QUERY: 'companies/LOAD_QUERY',

  SET_ERROR_MUTATION: 'companies/SET_ERROR_MUTATION',
  SET_SUCCESS_MUTATION: 'companies/SET_SUCCESS_MUTATION',
  LOAD_MUTATION: 'companies/LOAD_MUTATION',

  RESET_STATE: 'companies/RESET_STATE',
};

const initialState = {
  results: [],
  load_query: false,
  error_query: {},
  success_query: {},
  load_mutation: false,
  error_mutation: {},
  success_mutation: {},
};

export default function companies(state = initialState, action) {
  switch (action.type) {
    case Types.SET_RESULTS:
      return { ...state, results: action.results };
    case Types.SET_ERROR_QUERY:
      return { ...state, error_query: action.error };
    case Types.SET_SUCCESS_QUERY:
      return { ...state, success_query: action.success };
    case Types.LOAD_QUERY:
      return { ...state, load_query: action.load };
    case Types.SET_ERROR_MUTATION:
      return { ...state, error_mutation: action.error };
    case Types.SET_SUCCESS_MUTATION:
      return { ...state, success_mutation: action.success };
    case Types.LOAD_MUTATION:
      return { ...state, load_mutation: action.load };
    case Types.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export function fetchCompanies() {
  return {
    type: Types.FETCH_COMPANIES,
  };
}

export function saveCompany({ data, edit, success, error }) {
  return {
    type: edit ? Types.UPDATE_COMPANY : Types.CREATE_COMPANY,
    data,
    success,
    error,
  };
}

export function changeStatusCompany({ data, success, error }) {
  return {
    type: Types.UPDATE_STATUS_COMPANY,
    data,
    success,
    error,
  };
}

export function resendCompanyInvitation({ data, success, error }) {
  return {
    type: Types.RESEND_COMPANY_INVITATION,
    data,
    success,
    error,
  };
}

export function resetState() {
  return {
    type: Types.RESET_STATE,
  };
}
