import moment from 'moment';

export const Types = {
  SET_SHIPPING_ORDER: 'shippingOrder/SET_SHIPPING_ORDER',
  SET_RESULTS: 'shippingOrder/SET_RESULTS',
  NEXT_RESULTS: 'shippingOrder/NEXT_RESULTS',
  SET_PAGE_INFO: 'shippingOrder/SET_PAGE_INFO',
  SET_ERROR_QUERY: 'shippingOrder/SET_ERROR_QUERY',
  SET_ERROR_MUTATION: 'shippingOrder/SET_ERROR_MUTATION',
  SET_SUCCESS_QUERY: 'shippingOrder/SET_SUCCESS_QUERY',
  SET_SUCCESS_MUTATION: 'shippingOrder/SET_SUCCESS_MUTATION',
  LOAD_QUERY: 'shippingOrder/LOAD_QUERY',
  LOAD_MUTATION: 'shippingOrder/LOAD_MUTATION',
  LOAD_MODAL: 'shippingorder/LOAD_MODAL',
  FETCH_SHIPPING_ORDERS: 'shippingOrder/FETCH_SHIPPING_ORDERS',
  FETCH_SHIPPING_ORDER: 'shippingOrder/FETCH_SHIPPING_ORDER',
  RESET_STATE: 'shippingOrder/RESET_STATE',
  USER_LOGOUT: 'USER_LOGOUT',
};

const initialState = {
  shippingOrder: undefined,
  results: [],
  pageInfo: {},
  load_query: false,
  load_mutation: false,
  load_modal: false,
  error_query: {},
  error_mutation: {},
  success_query: {},
  success_mutation: {},
};

export default function shippingOrder(state = initialState, action) {
  switch (action.type) {
    case Types.SET_SHIPPING_ORDER:
      return { ...state, shippingOrder: action.shippingOrder };
    case Types.SET_RESULTS:
      return { ...state, results: action.results };
    case Types.NEXT_RESULTS:
      return { ...state, results: [...state.results, ...action.results] };
    case Types.SET_PAGE_INFO:
      return { ...state, pageInfo: action.pageInfo };
    case Types.SET_ERROR_QUERY:
      return { ...state, error_query: action.error };
    case Types.SET_ERROR_MUTATION:
      return { ...state, error_mutation: action.error };
    case Types.SET_SUCCESS_QUERY:
      return { ...state, success_query: action.success };
    case Types.SET_SUCCESS_MUTATION:
      return { ...state, success_mutation: action.success };
    case Types.LOAD_MODAL:
      return { ...state, load_modal: action.load };
    case Types.LOAD_QUERY:
      return { ...state, load_query: action.load };
    case Types.LOAD_MUTATION:
      return { ...state, load_mutation: action.load };
    case Types.RESET_STATE:
    case Types.USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
}

function _dateFrom(value) {
  return moment(value).format('YYYY-MM-DD') + 'T00:00:00.000Z';
}

function _dateTo(value) {
  return moment(value).format('YYYY-MM-DD') + 'T23:59:59.999Z';
}

function _getDefaultFilter(filter = {}, params) {
  if (params.from || params.to) {
    if (params.from) {
      const from = _dateFrom(params.from);
      filter = {
        ...filter,
        dispatchedAt_GREATER_EQUALS_THAN: from,
      };
    }

    if (params.to) {
      const to = _dateTo(params.to);
      filter = {
        ...filter,
        dispatchedAt_LOWER_EQUALS_THAN: to,
      };
    }
  }

  if (params.plate) {
    filter = {
      ...filter,
      plate: params.plate,
    };
  }

  if (params.dispatcher) {
    filter = {
      ...filter,
      invoices_WITH: {
        OR: [
          {
            dispatcherId: params.dispatcher,
          },
          {
            redispatcherId: params.dispatcher,
          },
        ]
      }
    };
  }

  return filter;
}

export function fetchShippingOrders(args = {}) {
  const { filter } = args;

  switch (filter) {
    case 'pending':
      return fetchPending(args);
    case 'delivered':
      return fetchDelivered(args);
    default:
      return fetchAll(args);
  }
}

export function fetchAll({ cursor, params }) {
  const filter = _getDefaultFilter({}, params);

  return {
    type: Types.FETCH_SHIPPING_ORDERS,
    cursor,
    filter,
  };
}

export function fetchPending({ cursor, params }) {
  const filter = _getDefaultFilter(
    {
      AND: [
        {
          deliveredAt: null,
        },
        {
          invoices_WITH: {
            redispatcherId_NOT_EQUALS: null,
          }
        },
      ],
    },
    params
  );

  return {
    type: Types.FETCH_SHIPPING_ORDERS,
    cursor,
    filter,
  };
}

export function fetchDelivered({ cursor, params }) {
  const filter = _getDefaultFilter(
    {
      AND: [
        {
          deliveredAt_NOT_EQUALS: null,
        },
        {
          invoices_WITH: {
            redispatcherId_NOT_EQUALS: null,
          }
        },
      ],
    },
    params
  );

  return {
    type: Types.FETCH_SHIPPING_ORDERS,
    cursor,
    filter,
  };
}

export function fetchShippingOrder({ key, success }) {
  return {
    type: Types.FETCH_SHIPPING_ORDER,
    key,
    success,
  };
}

export function resetState() {
  return {
    type: Types.RESET_STATE,
  };
}
