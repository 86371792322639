import { useRef, useCallback } from 'react';
import { Button, Modal } from '@components';
import './print.css';

import { useReactToPrint } from 'react-to-print';

const ShippingRelationship = ({
  citySummary,
  itemsAggregation,
  invoices,
  open,
  close,
  totalizer,
}) => {
  const printRef = useRef(null);
  const reactToPrintContent = useCallback(() => {
    return printRef.current;
  }, [printRef.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
  });

  const mapCityResults = values => {
    const [items] = Object.values(values[0].items).map(item => {
      return Object.values(item);
    });
    return items;
  };

  return (
    <Modal
      open={open}
      onClose={() => close()}
      containerClassSize="max-w-full"
      title="Relação de embarque"
    >
      <div className="relative">
        <div className="absolute right-0">
          <Button variant="primary" onClick={handlePrint}>
            Imprimir
          </Button>
        </div>

        <div className="flex flex-col flex-1" ref={printRef}>
          <div className="flex justify-between items-start">
            <div className="flex flex-col mr-4">
              <p className="text-sm leading-6 text-gray-900 font-semibold ">
                Simulação da relação de embarque das notas:{' '}
              </p>
              <span className="text-sm text-justify leading-6 text-gray-900">
                {invoices?.toString().split(',').join(', ')}
              </span>
            </div>
          </div>

          <div className="flex flex-col mt-4">
            <div className="space-y-8">
              {citySummary.map((city, index) => (
                <div key={index}>
                  <div className="bg-slate-100 p-2 text-left text-sm font-semibold text-gray-900 sm:pl-3 rounded-md">
                    Cidade: {Object.keys(city)}
                  </div>

                  <table className="min-w-full">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="px-2 py-2 text-left text-sm font-semibold text-gray-900"
                        >
                          Código
                        </th>
                        <th
                          scope="col"
                          className="px-2 py-2 text-left text-sm font-semibold text-gray-900"
                        >
                          Descrição
                        </th>
                        <th
                          scope="col"
                          className="px-2 py-2 text-center text-sm font-semibold text-gray-900"
                        >
                          Unidade
                        </th>
                        <th
                          scope="col"
                          className="px-2 py-2 text-center text-sm font-semibold text-gray-900"
                        >
                          Quantidade
                        </th>
                        <th
                          scope="col"
                          className="px-2 py-2 text-center text-sm font-semibold text-gray-900"
                        >
                          Peso
                        </th>
                        <th
                          scope="col"
                          className="px-2 py-2 text-center text-sm font-semibold text-gray-900"
                        >
                          Cubagem
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {mapCityResults(Object.values(city)).map(item => (
                        <tr key={item.sku} className="hover:bg-slate-100">
                          <td className="px-2 font-normal text-left text-sm text-gray-900">
                            {item.sku}
                          </td>
                          <td className="px-2 font-normal text-left text-sm text-gray-900">
                            {item.description}
                          </td>
                          <td className="px-2 font-normal text-center text-sm text-gray-900">
                            {item.unity}
                          </td>
                          <td className="px-2 font-normal text-center text-sm text-gray-900">
                            {item.quantity}
                          </td>
                          <td className="px-2 font-normal text-center text-sm text-gray-900">
                            {parseFloat(item.weight)
                              .toFixed(3)
                              .replace('.', ',')}
                          </td>
                          <td className="px-2 font-normal text-center text-sm text-gray-900">
                            {parseFloat(item.cubage)
                              .toFixed(3)
                              .replace('.', ',')}
                          </td>
                        </tr>
                      ))}
                    </tbody>

                    <tfoot>
                      <tr>
                        <td
                          colSpan="3"
                          className="px-2 py-2 text-left text-sm font-bold text-gray-900"
                        >
                          Total da Cidade
                        </td>
                        <td className="px-2 py-2 text-center text-sm font-bold text-gray-900">
                          {mapCityResults(Object.values(city)).reduce(
                            (prev, next) => prev + next['quantity'],
                            0
                          )}
                        </td>
                        <td className="px-2 py-2 text-center text-sm font-bold text-gray-900">
                          {parseFloat(
                            mapCityResults(Object.values(city)).reduce(
                              (prev, next) => prev + next['weight'],
                              0
                            )
                          )
                            .toFixed(3)
                            .replace('.', ',')}
                        </td>
                        <td className="px-2 py-2 text-center text-sm font-bold text-gray-900">
                          {parseFloat(
                            mapCityResults(Object.values(city)).reduce(
                              (prev, next) => prev + next['cubage'],
                              0
                            )
                          )
                            .toFixed(3)
                            .replace('.', ',')}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              ))}
            </div>

            <div className="bg-slate-100 rounded-md px-6 py-5 space-y-4">
              <div className="grid gap-2 grid-cols-3">
                <div className="text-left text-sm font-semibold text-gray-900">
                  Total geral por volume:{' '}
                  {Object.values(totalizer).reduce(
                    (prev, next) => prev + next,
                    0
                  )}
                </div>
                <div className="text-left text-sm font-semibold text-gray-900">
                  Peso total geral:{' '}
                  {parseFloat(
                    Object.values(itemsAggregation).reduce(
                      (prev, next) => prev + next['weight'],
                      0
                    )
                  )
                    .toFixed(3)
                    .replace('.', ',')}
                </div>
                <div className="text-left text-sm font-semibold text-gray-900">
                  Cubagem total geral:{' '}
                  {parseFloat(
                    Object.values(itemsAggregation).reduce(
                      (prev, next) => prev + next['cubage'],
                      0
                    )
                  )
                    .toFixed(3)
                    .replace('.', ',')}
                </div>
              </div>

              <div className="h-[1px] bg-slate-400" />

              <div
                className={`grid gap-2 grid-cols-${
                  Object.keys(totalizer).length + 1
                }`}
              >
                <div className="text-left text-sm font-semibold text-gray-900">
                  Total por volume:
                </div>
                {Object.keys(totalizer).map(column => (
                  <div
                    key={column}
                    className="text-left text-sm font-semibold text-gray-900"
                  >
                    {column} {totalizer[column]}
                  </div>
                ))}
              </div>
            </div>

            <table>
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-2 py-2 text-left text-sm font-semibold text-gray-900"
                  >
                    Código
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-2 text-left text-sm font-semibold text-gray-900"
                  >
                    Descrição
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-2 text-center text-sm font-semibold text-gray-900"
                  >
                    Código de barra
                  </th>
                  <th
                    scope="col"
                    className="px-2 py-2 text-center text-sm font-semibold text-gray-900"
                  >
                    Quantidade
                  </th>
                </tr>
              </thead>

              <tbody>
                {itemsAggregation.map((item, index) => (
                  <tr key={index} className="hover:bg-slate-100">
                    <td className="px-2 font-normal text-left text-sm text-gray-900">
                      {item.sku}
                    </td>
                    <td className="px-2 font-normal text-left text-sm text-gray-900">
                      {item.description}
                    </td>
                    <td className="px-2 font-normal text-center text-sm text-gray-900">
                      {item.barCode}
                    </td>
                    <td className="px-2 font-normal text-center text-sm text-gray-900">
                      {item.quantity}
                    </td>
                  </tr>
                ))}
              </tbody>

              <tfoot>
                <tr>
                  <td
                    colSpan="3"
                    className="px-2 py-2 text-left text-sm font-bold text-gray-900"
                  >
                    Total Geral
                  </td>
                  <td className="px-2 py-2 text-center text-sm font-bold text-gray-900">
                    {itemsAggregation.reduce(
                      (prev, next) => prev + next['quantity'],
                      0
                    )}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ShippingRelationship;
