import { useEffect, useState } from 'react';

import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sendMail } from '@redux/ducks/mail';
import { addInvoiceLog } from '@redux/ducks/invoice';

import { Button, Modal, Input } from '@components';
import moment from 'moment';

const MailSender = ({
  open,
  setIsMailSenderModalOpen,
  user,
  sendMail,
  addInvoiceLog,
  mailSenderModalData,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [additionalEmails, setAdditionalEmails] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const { selectedInvoices, mail } = mailSenderModalData;

  function validateEmailList(emailList) {
    const emailListRegex =
      /^[\s;]*(?:[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,})(?:[\s,]+(?:[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}))*[\s;]*$/i;

    if (emailListRegex.test(emailList)) {
      setErrorMessage('');
    } else {
      setErrorMessage(
        'A lista de e-mail informada possui pelo menos um e-mail inválido!'
      );
    }
  }

  const defaultText = () => {
    return ` <p> Prezado </p>,
        <p> As notas em abaixo constam como <strong>não entregue</strong> em nosso sistema. </p>
        <p> Por favor efetue a entrega da mesma ou entre em contato conosco para regularizarmos a situação. </p>
      `;
  };

  const mountTable = () => {
    let table;

    if (selectedInvoices.length > 0) {
      table = `<table border=1 cellspacing=0>`;
      table += `<thead>`;
      table += `<tr>`;
      table += `<td width=100 > Nota Fiscal </td> `;
      table += `<td width=250> Data de Emissão </td>`;
      table += `<td width=100> Previsão de Entrega </td>`;
      table += `<td width=400> Cliente </td> `;
      table += `<td width=200> Cidade </td> `;
      table += `<td width=200> Chave de Acesso </td> `;
      table += `</tr>`;
      table += `</thead>`;
      table += `<tbody>`;
      for (let item of selectedInvoices) {
        table += `<tr>`;
        table += `<td width=100> ${item.invoiceNo} </td>`;
        table += `<td width=250> ${moment(item?.activities?.emittedAt).format(
          'DD/MM/YYYY hh:mm'
        )} </td> `;
        table += `<td width=100> ${moment(item?.activities?.predictedTo).format(
          'DD/MM/YYYY'
        )} </td> `;
        table += `<td with=400> ${item.customer.corporateName} </td>`;
        table += `<td width=200> ${item.customer.city.name} - ${item.customer.city.state} </td>`;
        table += `<td width=300> ${item.key} </td>`;
        table += `</tr>`;
      }
      table += `</tbody>`;
    }
    return table;
  };

  const setDefaultText = () => {
    const contentBlock = htmlToDraft(defaultText());
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setEditorState(editorState);
    }
  };

  const send = async () => {
    const title = `Notas em atraso`;
    let html = convertToHtml(editorState);
    html += mountTable();

    await sendMail(mail, additionalEmails, user, title, html);
    await addLogs();
    setIsMailSenderModalOpen(false);
  };

  const addLogs = async () => {
    const message = 'Enviado e-mail de atraso para transportadora';
    selectedInvoices.map(invoice => {
      addInvoiceLog(invoice.key, message);
    });
  };

  const convertToHtml = editorState =>
    draftToHtml(convertToRaw(editorState.getCurrentContent()));

  useEffect(() => {
    setDefaultText();
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={() => setIsMailSenderModalOpen(false)}
      closeIcon={true}
      title="Envio de e-mail"
    >
      <div className="flex flex-col mb-4 space-y-3">
        <div className="flex-1">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Destinatário:
          </label>
          <Input value={mail} disabled />
        </div>

        <div className="flex-1">
          <label className="block text-sm font-medium leading-6 text-gray-900">
            Destinatários adicionais:{' '}
            <label className="text-xs italic leading-6 text-gray-400">
              (emails separado por , )
            </label>
          </label>
          <Input
            onChange={e => setAdditionalEmails(e.target.value)}
            onBlur={() => validateEmailList(additionalEmails)}
            value={additionalEmails}
          />
          {errorMessage && (
            <span className="block text-xs font-normal leading-6 text-red-500">
              {errorMessage}
            </span>
          )}
        </div>
      </div>

      <Editor
        wrapperStyle={{
          marginBottom: 15,
        }}
        toolbarStyle={{
          borderColor: 'rgba(0,0,0,0.2)',
          borderRadius: 8,
        }}
        editorStyle={{
          marginTop: 15,
          border: 'solid',
          borderColor: 'rgba(0,0,0,0.3)',
          borderWidth: 0.5,
          borderRadius: 8,
          paddingLeft: 10,
          paddingRight: 10,
          height: 300,
          fontSize: 14,
        }}
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={editorChange => setEditorState(editorChange)}
      />
      <div className="flex justify-end">
        <Button variant="primary" disabled={!!errorMessage} onClick={send}>
          Enviar e-mail
        </Button>
      </div>
    </Modal>
  );
};

function mapStateToProps({ auth }) {
  const { user } = auth;
  return {
    user: user.email,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ sendMail, addInvoiceLog }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MailSender);
