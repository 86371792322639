export const Types = {
  SET_LOADER: 'loading/SET_LOADER',
  UNSET_LOADER: 'loading/UNSET_LOADER',
};

const initialState = {
  loading: false,
};

export default function loading(state = initialState, action) {
  switch (action.type) {
    case Types.SET_LOADER:
      return {
        ...state,
        loading: true,
      };
    case Types.UNSET_LOADER:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

export const setLoader = () => {
  return {
    type: Types.SET_LOADER,
  };
};

export const unsetLoader = () => {
  return {
    type: Types.UNSET_LOADER,
  };
};
