import { TableForm } from '@components';
import moment from 'moment';
import { forwardRef } from 'react';

const TransportQueueList = forwardRef((props, ref) => {
  return (
    <TableForm
      {...props}
      ref={ref}
      headers={[
        { label: 'Posição', getValue: (item, index) => index + 1 },
        {
          label: 'Mont.Carga',
          getValue: item => {
            if (item.Carga_Pronta_c1 || item.Carga_Pronta_c1 === 0) {
              if (item.Carga_Pronta_c1 === 1) {
                return 'Sim';
              } else if (item.Carga_Pronta_c1 === 0) {
                return <strong style={{ color: 'red' }}>Não</strong>;
              }
            }
          },
        },
        { label: 'Doca', getValue: item => item.doca_c10 },
        {
          label: 'Carregamento',
          getValue: item =>
            item.data_c12 &&
            moment(`${item.data_c12}-${item.hora_c11}`).format(
              'DD/MM/YYYY-HH:mm'
            ),
        },
        { label: 'Situação', getValue: item => item.situacao_c16 },
        { label: 'Motorista', getValue: item => item.c5 },
        { label: 'Placa', getValue: item => item.c6 },
        { label: 'Transportadora', getValue: item => item.c7 },
        {
          label: 'Cadastro',
          getValue: item =>
            `${moment(`${item.c2}-${item.c3}`).format(
              'DD/MM/YYYY-HH:mm:ss'
            )} - ${item.c8}`,
        },
      ]}
      keyRow={(item, index) => index}
    />
  );
});

TransportQueueList.displayName = 'TransportQueueList';

export default TransportQueueList;
