import { Route, Routes } from 'react-router-dom';
import { NotFound, RedeliveryList } from '@pages';
import Protected from '@components/Protected';

function RoutesRedelivery() {
  return (
    <Protected>
      <Routes>
        <Route index path="/" element={<RedeliveryList />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Protected>
  );
}

export default RoutesRedelivery;
