const validate = values => {
  const errors = {};
  if (!values.value) {
    errors.value = 'Favor informar o Preço da reentrega!';
  }

  if (!values.customerDate) {
    errors.customerDate = 'Favor informar o campo Data Agendada Cliente!';
  }

  return errors;
};

export default validate;
