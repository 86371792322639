import { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Input, Button } from '@components';
import moment from 'moment';
import { addLog, fetchInvoice } from '@redux/ducks/invoice';
import { permissions } from '@config/permission';
import {
  CalendarIcon,
  ClipboardIcon,
  MapPinIcon,
  ChatBubbleLeftEllipsisIcon,
} from '@heroicons/react/20/solid';

const Historic = props => {
  const { load_mutation } = props;
  const dispatch = useDispatch();
  const [state, setState] = useState({ message: '' });

  const onAddLog = () => {
    const { invoiceKey } = props;

    dispatch(
      addLog(state.message, () => {
        setState({ message: '' });
        dispatch(fetchInvoice(invoiceKey));
      })
    );
  };

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const createHistoric = () => {
    const { source } = props;
    const invoice = source || {};

    const activities = invoice.activities || {};

    let historic = [
      {
        date: activities?.emittedAt,
        icon: CalendarIcon,
        summary: 'Data de emissão da nota',
        extra: undefined,
        iconBackground: 'bg-blue-500',
      },
    ];

    if (invoice.deliveries && invoice.deliveries.length > 0) {
      invoice.deliveries.forEach(delivery => {
        historic.push({
          date: delivery.date,
          icon: MapPinIcon,
          summary: 'Nota fiscal entregue',
          extra: undefined,
          iconBackground: 'bg-green-500',
        });
      });
    }

    if (invoice.incidents && invoice.incidents.length > 0) {
      invoice.incidents.forEach(incident => {
        const end = incident.end;
        historic.push({
          date: incident.start,
          icon: ClipboardIcon,
          iconBackground: 'bg-yellow-500',
          summary:
            `Incidente - (${
              !end
                ? 'Aberto'
                : 'Finalizado em ' + moment(end).format('DD/MM/YYYY-HH:mm')
            } ` + ` ${' / Tipo: ' + incident.type.description})`,
          extra:
            `${incident.note ? `Motivo da abertura: ${incident.note}` : ''} ` +
            ` ${
              incident.driver
                ? `Por:  ${
                    incident.driver.firstName + ' ' + incident.driver.lastName
                  } `
                : ''
            }`,
        });
      });
    }

    if (invoice.logs && invoice.logs.length > 0) {
      invoice.logs.forEach(log => {
        const { createdBy } = log;
        historic.push({
          date: log.committedAt,
          icon: ChatBubbleLeftEllipsisIcon,
          summary: `Log - (Usuário: ${createdBy.firstName} ${
            createdBy.lastName ? createdBy.lastName : ''
          })`,
          extra: log.description,
          iconBackground: 'bg-gray-500',
        });
      });
    }

    historic = historic.sort((a, b) => {
      let horaA = new Date(a.date).getTime();
      let horaB = new Date(b.date).getTime();

      return horaB - horaA;
    });

    return (
      <div className="mt-4 flow-root">
        <ul role="list" className="-mb-8">
          {historic.map((event, eventIdx) => (
            <li key={eventIdx + '-1'}>
              <div className="relative pb-8">
                {eventIdx !== historic.length - 1 ? (
                  <span
                    className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="relative flex space-x-3">
                  <div>
                    <span
                      className={classNames(
                        event.iconBackground,
                        'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                      )}
                    >
                      <event.icon
                        className="h-5 w-5 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="flex min-w-0 flex-1 justify-between space-x-4">
                    <div>
                      <p className="text-sm text-gray-500">
                        {moment(event.date).format('DD/MM/YYYY-HH:mm')}{' '}
                      </p>
                      <p className="text-sm text-gray-900">{event.summary} </p>

                      {event.extra && (
                        <p className="mt-2 text-sm text-gray-500">
                          {event.extra}{' '}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="pt-2">
      {permissions.invoice.createLog() && (
        <>
          <div className="flex">
            <div className="flex-1 w-64 pr-2">
              <Input
                caption="Crie um novo log para a nota fiscal."
                type="text"
                autoComplete="text"
                value={state.message}
                onChange={({ target }) => {
                  setState({ message: target.value });
                }}
              />
            </div>
            <div className="flex-3 pl-2 mt-6">
              <Button
                variant="primary"
                onClick={onAddLog}
                isLoading={load_mutation}
              >
                Adicionar Log
              </Button>
            </div>
          </div>
        </>
      )}
      {createHistoric()}
    </div>
  );
};

function mapStateToProps(state) {
  let { load_mutation } = state.invoice;

  return {
    load_mutation,
  };
}

export default connect(mapStateToProps)(Historic);
