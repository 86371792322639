export const Types = {
  SEND_MAIL: 'mail/SEND_MAIL',
  SEND_MAIL_HANDLER: 'mail/SEND_MAIL_HANDLER',
};

const initialState = {
  isLoading: false,
};

export default function mail(state = initialState, action) {
  switch (action.type) {
    case Types.SEND_MAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
}

export function sendMail(to, copy, from, title, content) {
  return {
    type: Types.SEND_MAIL_HANDLER,
    to,
    copy,
    from,
    title,
    content,
  };
}
