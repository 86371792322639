export const validate = values => {
  const errors = {};
  const minLength = 8;
  const specialCharacters = '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~';

  if (!values.password) {
    errors.password = 'Campo obrigatório! ';
  } else if (values.password.length < minLength) {
    errors.password = `A nova senha precisa ter no mínimo ${minLength} caracteres.`;
  } else if (values.password.toLowerCase() === values.password) {
    errors.password =
      'A nova senha precisa ter pelo menos um caractere em maiúsculo.';
  } else if (values.password.toUpperCase() === values.password) {
    errors.password =
      'A nova senha precisa ter pelo menos um caractere em minúsculo.';
  } else if (
    !values.password.split('').some(char => specialCharacters.includes(char))
  ) {
    errors.password =
      'A nova senha precisa ter pelo menos um caractere especial.';
  }

  if (!values.passwordConfirmation) {
    errors.passwordConfirmation = 'Campo obrigatório! ';
  } else if (values.passwordConfirmation !== values.password) {
    errors.passwordConfirmation = 'As senhas devem ser iguais';
  }

  return errors;
};
