import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { effectReversal } from '@redux/ducks/reversal';

import { FormCreate, Modal } from '@components';

import validate from './validate';
import moment from 'moment';

const Form = FormCreate('DevolutionCloseForm', validate, {
  freightForCompany: true,
});

const DevolutionCloseForm = ({
  load_mutation,
  effectReversal,
  effectDevolutionItem,
  open,
  setOpenEffectDevolution,
  ...rest
}) => {
  const { invoice, id } = effectDevolutionItem;

  function handleSubmit(data, edit) {
    return new Promise((resolve, reject) => {
      effectReversal({
        data: { ...data, reversalId: id },
        edit,
        success: () => {
          resolve();
          setOpenEffectDevolution(false);
        },
        error: error => {
          reject(error);
        },
      });
    });
  }

  return (
    <>
      <Modal
        open={open}
        onClose={() => setOpenEffectDevolution(false)}
        title="Devolução de nota fiscal - Efetivação de devolução"
      >
        <Form
          {...rest}
          edit={true}
          ribbon={false}
          lbSubmit="Efetivar"
          onSubmit={handleSubmit}
          loadMutation={load_mutation}
          fields={[
            {
              name: 'invoiceNo',
              component: 'input',
              type: 'text',
              label: 'NF:',
              edit: false,
              inputProps: {
                value: invoice?.invoiceNo,
              },
            },
            {
              name: 'emittedAt',
              component: 'input',
              type: 'text',
              label: 'Data de emissão:',
              edit: false,
              inputProps: {
                value: moment(invoice?.activities?.emittedAt).format(
                  'DD/MM/YYYY-HH:mm'
                ),
              },
            },
            {
              name: 'customer',
              component: 'input',
              type: 'text',
              label: 'Cliente:',
              edit: false,
              inputProps: {
                value: `${
                  invoice?.customer?.code
                    ? '(' + invoice?.customer?.code + ') '
                    : ''
                }${invoice?.customer?.corporateName}`,
              },
            },
            {
              name: 'bdNumber',
              component: 'input',
              type: 'text',
              label: 'Numero do BD/ Nota fiscal do cliente:',
              inputProps: {
                placeholder: 'Informe o numero do documento',
              },
            },
            [
              {
                name: 'bdValue',
                component: 'currency',
                type: 'text',
                label: 'Valor do BD/ Valor NF cliente:',
                inputProps: {
                  placeholder: 'Informe o valor',
                },
              },
            ],
            {
              name: 'freightForCompany',
              component: 'toggle',
              type: 'checkbox',
              label: 'Pagar frete:',
            },
            [
              {
                name: 'freightValue',
                component: 'currency',
                type: 'text',
                label: 'Valor do frete:',
                inputProps: {
                  placeholder: 'Informe o valor',
                },
              },
            ],
          ]}
        />
      </Modal>
    </>
  );
};

function mapStateToProps({ reversal }) {
  const { load_mutation } = reversal;

  return {
    load_mutation,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ effectReversal }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DevolutionCloseForm);
