import { useState, useRef, useEffect } from 'react';
import { Button, TableTail, Modal, Input, Toggle } from '@components';

function SearchModal({
  onSearch,
  onSelect,
  textExtractor,
  keyExtractor,
  forceChange,
  name,
  disabled,
  caption,
  value,
  isShipperQuery = false,
  shipperToggleQuery,
  setShipperToggleQuery,
}) {
  const inputRef = useRef(null);
  const [results, setResults] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setloading] = useState(false);

  const [search, setSearch] = useState('');

  const handleOnSelect = item => {
    setOpen(false);
    const reduxFormChange = forceChange || (() => {});
    onSelect(item, reduxFormChange);
  };

  const handleChangeSearch = async () => {
    try {
      setloading(true);
      const response = await onSearch(search);
      setResults(response);
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    if (open) {
      handleChangeSearch();
    }
  }, [open]);

  const renderResults = () => {
    if (loading) {
      return null;
    }

    if (results && results.length > 0) {
      return (
        <TableTail>
          <TableTail.Header className="flex flex-1 justify-between pr-8">
            <TableTail.Row>
              <TableTail.HeaderCell>Descrição</TableTail.HeaderCell>
              <TableTail.HeaderCell>Ação</TableTail.HeaderCell>
            </TableTail.Row>
          </TableTail.Header>

          <TableTail.Body>
            {results.map(item => {
              return (
                <TableTail.Row
                  key={keyExtractor(item)}
                  className="flex flex-1 justify-between pt-2"
                >
                  <TableTail.Cell>{textExtractor(item)}</TableTail.Cell>
                  <TableTail.Cell align="right">
                    <Button
                      onClick={() => {
                        handleOnSelect(item);
                      }}
                    >
                      Selecionar
                    </Button>
                  </TableTail.Cell>
                </TableTail.Row>
              );
            })}
          </TableTail.Body>
        </TableTail>
      );
    }

    return (
      <TableTail>
        <TableTail.Body>
          <TableTail.Row>
            <TableTail.Cell>Nenhum registro encontrado...</TableTail.Cell>
          </TableTail.Row>
        </TableTail.Body>
      </TableTail>
    );
  };
  return (
    <>
      <div className="block text-sm font-medium leading-6 text-gray-900">
        {caption}
      </div>

      <Input
        name={name}
        defaultValue={value}
        disabled={disabled}
        placeholder="Buscar..."
        readOnly={true}
        onClick={() => setOpen(true)}
      />

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        title="Selecione uma opção"
      >
        <div className="flex flex-row">
          <div className="flex-1">
            <Input
              ref={inputRef}
              value={search}
              placeholder="Buscar..."
              onChange={text => setSearch(text.currentTarget.value)}
            />
          </div>
          <div className="flex-3 pl-2">
            <Button isLoading={loading} onClick={handleChangeSearch}>
              Buscar
            </Button>
          </div>
        </div>

        {isShipperQuery && (
          <div className="flex flex-row mt-2 mb-2 items-center">
            <Toggle
              checked={shipperToggleQuery}
              onChange={() => setShipperToggleQuery(!shipperToggleQuery)}
            />
            <span className="ml-4 text-sm font-medium leading-6 text-gray-900">
              Utilizar transportadora da nota
            </span>
          </div>
        )}
        {renderResults()}
      </Modal>
    </>
  );
}

export default SearchModal;
