import { useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import NumberFormat from 'react-number-format';
import { ViewFile } from '@components';
//import { fetchInvoice } from '@redux/ducks/invoice';
import { fetchExpense } from '@redux/ducks/expense';

const DetailReversal = props => {
  const dispatch = useDispatch();
  //static defaultProps = {
  //  onTerminate: () => {},
  //};

  useEffect(() => {
    dispatch(
      fetchExpense({
        invoice: props.invoiceId,
      })
    );
  }, []);

  const { source: item } = props;
  return (
    <div className="p-3">
      <div
        className={`${
          item.status === 'REALIZED'
            ? 'text-teal-700 bg-teal-100'
            : 'text-red-700 bg-red-100'
        } inline-block items-center rounded-md px-2 py-1 text-xs font-medium`}
      >
        {item.status === 'REALIZED' ? 'Efetivado' : 'Pendente'}
      </div>

      <div className="px-2 py-2 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-0">
        <div className="mt-1 text-sm leading-6 text-gray-900 sm:mt-0">
          <b>Nota Fiscal: </b>
          <span>{item.invoice && item.invoice.invoiceNo}</span>
        </div>
        <div className="mt-1 text-sm leading-6 text-gray-900 sm:mt-0">
          <b>Cliente: </b>
          <span>
            {item.invoice &&
              `${item.invoice.customer.corporateName} (${item.invoice.customer.code})`}
          </span>
        </div>
        <div className="mt-1 text-sm leading-6 text-gray-900 sm:mt-0">
          <b>Chave da nota fiscal: </b>
          <span>{item.invoice && item.invoice.key}</span>
        </div>
        <div className="mt-1 text-sm leading-6 text-gray-900 sm:mt-0">
          <b>Motivo devolução: </b>
          <span>{item.reason && item.reason.description}</span>
        </div>
        <div className="mt-1 text-sm leading-6 text-gray-900 sm:mt-0">
          <b>Transportadora: </b>
          <span>
            {item.invoice &&
              item.invoice.dispatcher &&
              `${item.invoice.dispatcher.company.corporateName}`}
          </span>
        </div>
        <div className="mt-1 text-sm leading-6 text-gray-900 sm:mt-0">
          <b>Frete da Empresa: </b>
          <span>
            {item.charges && item.charges.freightForCompany ? 'Sim' : 'Não'}
          </span>
        </div>
        <div className="mt-1 text-sm leading-6 text-gray-900 sm:mt-0">
          <b>Cobra frete do representante: </b>
          <span>
            {item.charges && item.charges.representativeFreight ? 'Sim' : 'Não'}
          </span>
        </div>
        <div className="mt-1 text-sm leading-6 text-gray-900 sm:mt-0">
          <b>Cobra ST do Representante: </b>
          <span>
            {item.charges && item.charges.representativeTaxSubstitution
              ? 'Sim'
              : 'Não'}
          </span>
        </div>
      </div>

      {item.status === 'REALIZED' && (
        <div className="px-2 py-2 sm:grid sm:grid-cols-2 sm:gap-2 sm:px-0">
          <div className="mt-1 text-sm leading-6 text-gray-900 sm:mt-0">
            <b>Número do BD / Nota fiscal do cliente: </b>
            <span>{item.bdNumber}</span>
          </div>
          <div className="mt-1 text-sm leading-6 text-gray-900 sm:mt-0">
            <b>Valor do Frete: </b>
            <span>
              {item.invoice && (
                <NumberFormat
                  value={item.freightValue}
                  displayType={'text'}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale
                />
              )}
            </span>
          </div>
        </div>
      )}
      <div className="text-sm leading-6 text-gray-900">
        <b>Observação: </b>
        <span>{item.note}</span>
      </div>

      {item.customerDocument && (
        <div className="mt-4">
          <ViewFile url={item.customerDocument} />
        </div>
      )}
    </div>
  );
};

function mapStateToProps({ expense }) {
  let { load_mutation, results } = expense;

  return {
    load_mutation,
    results,
  };
}

export default connect(mapStateToProps)(DetailReversal);
