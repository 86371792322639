const validate = values => {
  const errors = {};
  if (!values.deliveredAt && !values.customerDate) {
    errors.customerDate =
      'Favor preencher a data de entrega informada pelo cliente!';
  }
  if (!values.reasonDescription) {
    errors.reasonDescription = 'Favor informar o motivo da prorrogação!';
  }
  if (!values.note) {
    errors.note = 'Favor informar uma observação!';
  }
  if (!values.baseDate) {
    errors.baseDate = 'Favor informar a data base!';
  }
  if (values.deadline == null) {
    errors.deadline = 'Favor informar a carência!';
  }
  return errors;
};

export default validate;
