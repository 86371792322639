import { client } from '@config/apollo';
import gql from 'graphql-tag';
import { call, put, select } from 'redux-saga/effects';
import { Types } from '../ducks/delivery';
import { Types as InvoiceTypes } from '../ducks/invoice';
import moment from 'moment';
import { Types as ToastTypes } from '../ducks/toast';

export function* updateDelivery(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { id, date, hour } = action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($id: String, $data: DeliveryUpdateInput) {
          updateDelivery(id: $id, data: $data) {
            ok
          }
        }
      `,
      variables: {
        id,
        data: {
          date: moment(`${date}-${hour}`, 'DD/MM/YYYY-HH:mm'),
        },
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Entrega alterada com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* reversalDelivery(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { id } = action;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($ids: [String]) {
          removeDeliveries(ids: $ids) {
            ok
          }
        }
      `,
      variables: {
        ids: [id],
      },
    });

    yield put({
      type: InvoiceTypes.REMOVE_DELIVERY,
      id,
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Entrega estornada com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}
