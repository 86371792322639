import { Route, Routes } from 'react-router-dom';
import { NotFound, Invoices, InvoicesQuery } from '@pages';
import Protected from '@components/Protected';

function RoutesInvoices() {
  return (
    <Protected>
      <Routes>
        <Route path="/" element={<Invoices />}>
          <Route index path=":filter" element={<InvoicesQuery />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Protected>
  );
}

export default RoutesInvoices;
