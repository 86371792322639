const DetailLogistic = props => {
  const translateTrueFalse = (param = false) => {
    let ret;
    if (param === true) ret = 'Sim';
    else ret = 'Não';
    return ret;
  };

  let { source: logistic } = props;

  return (
    logistic && (
      <div className="mt-6">
        <dl className="grid grid-cols-1 sm:grid-cols-4">
          <div className="border-t border-gray-100 px-2 py-2 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-bold leading-6 text-gray-900">
              Tipo Caminhão
            </dt>
            <dd className="text-sm leading-6 text-gray-700">
              {`${logistic?.truckType || ''} `}
            </dd>
          </div>
          <div className="border-t border-gray-100 px-2 py-2 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-bold leading-6 text-gray-900">
              Carga Direta
            </dt>
            <dd className="text-sm leading-6 text-gray-700">{`${translateTrueFalse(
              logistic?.isDirectCargo
            )}`}</dd>
          </div>
        </dl>
        <dl className="grid grid-cols-1 sm:grid-cols-4">
          <div className="border-t border-gray-100 px-2 py-2 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-bold leading-6 text-gray-900">
              Horário para entrega
            </dt>
            <dd className="text-sm leading-6 text-gray-700">
              {`${translateTrueFalse(logistic?.restrictNote)}`}
            </dd>
          </div>
          <div className="border-t border-gray-100 px-2 py-2 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-bold leading-6 text-gray-900">
              Horário restritivo
            </dt>
            <dd className="text-sm leading-6 text-gray-700">
              {' '}
              {`${translateTrueFalse(logistic?.restrictNote)}`}
            </dd>
          </div>
        </dl>
        <dl className="grid grid-cols-1">
          <div className="border-t border-gray-100 px-2 py-2 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-bold leading-6 text-gray-900">
              Obs. entrega
            </dt>
            <dd className="text-sm leading-6 text-gray-700">
              {`${logistic?.deliveryNote || ''}`}
            </dd>
          </div>
        </dl>
        <dl className="grid grid-cols-1 sm:grid-cols-4">
          <div className="border-t border-gray-100 px-2 py-2 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-bold leading-6 text-gray-900">
              Agendar entrega
            </dt>
            <dd className="text-sm leading-6 text-gray-700">
              {`${translateTrueFalse(logistic?.isScheduled)}`}
            </dd>
          </div>
          <div className="border-t border-gray-100 px-2 py-2 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-bold leading-6 text-gray-900">
              Carga paletizada
            </dt>
            <dd className="text-sm leading-6 text-gray-700">
              {' '}
              {`${translateTrueFalse(logistic?.isPalletizedCargo)}`}
            </dd>
          </div>
          <div className="border-t border-gray-100 px-2 py-2 sm:col-span-1 sm:px-0">
            <dt className="text-sm font-bold leading-6 text-gray-900">
              Levar ajudante
            </dt>
            <dd className="text-sm leading-6 text-gray-700">
              {' '}
              {`${translateTrueFalse(logistic?.withAssistant)}`}
            </dd>
          </div>
        </dl>
      </div>
    )
  );
};
//Tipo Caminhão (tipo texto)
//Carga Direta (boleano)
//Horário para entrega (texto)
//Horario restritivo (texto)
//Obs. entrega (texto)
//Agendar entrega (boleano)
//Carga paletizada(boleano)
//Levar Ajudante(boleano)

export default DetailLogistic;
