export const Types = {
  SET_RESULTS: 'city/SET_RESULTS',
  NEXT_RESULTS: 'city/NEXT_RESULTS',
  SET_PAGE_INFO: 'city/SET_PAGE_INFO',
  SET_ERROR_QUERY: 'city/SET_ERROR_QUERY',
  SET_ERROR_MUTATION: 'city/SET_ERROR_MUTATION',
  SET_SUCCESS_QUERY: 'city/SET_SUCCESS_QUERY',
  SET_SUCCESS_MUTATION: 'city/SET_SUCCESS_MUTATION',
  LOAD_QUERY: 'city/LOAD_QUERY',
  LOAD_MUTATION: 'city/LOAD_MUTATION',
  FETCH_CITIES: 'city/FETCH_CITIES',
  FETCH_STATES: 'city/FETCH_STATES',
  GET_CITIES: 'city/GET_CITIES',
  RESET_STATE: 'city/RESET_STATE',
  USER_LOGOUT: 'USER_LOGOUT',
};

const states = [
  { AC: 'Acre' },
  { AL: 'Alagoas' },
  { AP: 'Amapá' },
  { AM: 'Amazonas' },
  { BA: 'Bahia' },
  { CE: 'Ceará' },
  { DF: 'Distrito Federal' },
  { ES: 'Espírito Santo' },
  { GO: 'Goías' },
  { MA: 'Maranhão' },
  { MT: 'Mato Grosso' },
  { MS: 'Mato Grosso do Sul' },
  { MG: 'Minas Gerais' },
  { PA: 'Pará' },
  { PB: 'Paraíba' },
  { PR: 'Paraná' },
  { PE: 'Pernambuco' },
  { PI: 'Piauí' },
  { RJ: 'Rio de Janeiro' },
  { RN: 'Rio Grande do Norte' },
  { RS: 'Rio Grande do Sul' },
  { RO: 'Rondônia' },
  { RR: 'Roraíma' },
  { SC: 'Santa Catarina' },
  { SP: 'São Paulo' },
  { SE: 'Sergipe' },
  { TO: 'Tocantins' },
];

const initialState = {
  results: [],
  states,
  pageInfo: {},
  load_query: false,
  load_mutation: false,
  error_query: {},
  error_mutation: {},
  success_query: {},
  success_mutation: {},
};

export default function regionCities(state = initialState, action) {
  switch (action.type) {
    case Types.SET_RESULTS:
      return { ...state, results: action.results };
    case Types.NEXT_RESULTS:
      return { ...state, results: [...state.results, ...action.results] };
    case Types.SET_PAGE_INFO:
      return { ...state, pageInfo: action.pageInfo };
    case Types.SET_ERROR_QUERY:
      return { ...state, error_query: action.error };
    case Types.SET_ERROR_MUTATION:
      return { ...state, error_mutation: action.error };
    case Types.SET_SUCCESS_QUERY:
      return { ...state, success_query: action.success };
    case Types.SET_SUCCESS_MUTATION:
      return { ...state, success_mutation: action.success };
    case Types.LOAD_QUERY:
      return { ...state, load_query: action.load };
    case Types.LOAD_MUTATION:
      return { ...state, load_mutation: action.load };
    case Types.RESET_STATE:
    case Types.USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export function fetchAvailableCities({ params }) {
  let filter = {};

  if (params) {
    if (params.state && params.state.length > 0) {
      filter = {
        ...filter,
        state_LIKE: params.state,
      };
    }
    if (params.name && params.name.length > 0) {
      filter = {
        ...filter,
        name_LIKE: params.name,
      };
    }
  }

  return {
    type: Types.FETCH_CITIES,
    filter,
  };
}

export function resetState() {
  return {
    type: Types.RESET_STATE,
  };
}

export function getCities({ search, first, success, error }) {
  let filter;
  if (search && search.length > 0) {
    filter = {
      name_LIKE: search,
    };
  }

  return {
    type: Types.GET_CITIES,
    first,
    filter,
    success,
    error,
  };
}
