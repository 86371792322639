export default function Status(expense) {
  const { approvedAt, rejectedAt } = expense;

  if (!approvedAt && !rejectedAt) {
    return 'Pendente';
  }
  if (approvedAt) {
    return 'Aprovado';
  }
  if (rejectedAt) {
    return 'Rejeitado';
  }
}
