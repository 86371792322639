import { Button } from '@components';
import { useState } from 'react';

const ImageEditor = ({ src, onSave }) => {
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [state, setState] = useState({
    rotation: 0,
    editatedImg: null,
    size: {},
    src: '',
  });

  const img = new Image();
  img.src = src;
  img.crossOrigin = 'use-credentials';

  img.addEventListener('load', () => {
    setState(prev => ({
      ...prev,
      src,
      size: {
        width: img.naturalHeight,
        height: img.naturalWidth,
      },
    }));
  });

  const styles = {
    transform: `rotate(${state.rotation}deg)`,
  };

  function handleRotate() {
    setState(prev => ({
      ...prev,
      rotation: prev.rotation === 270 ? 0 : prev.rotation + 90,
    }));
  }

  function handleSaveImage() {
    const img = document.getElementById('image');
    const imgInfo = rotateImage(img, state.rotation);

    onSave(imgInfo.toDataURL('image/png', 1.0));
  }

  function rotateImage(img, degree) {
    const canvas = document.createElement('canvas');
    let cContext = canvas.getContext('2d');
    let cw = img.width,
      ch = img.height,
      cx = 0,
      cy = 0;

    //   Calculate new canvas size and x/y coorditates for image
    switch (degree) {
      case 90:
        cw = img.height;
        ch = img.width;
        cy = img.height * -1;
        break;
      case 180:
        cx = img.width * -1;
        cy = img.height * -1;
        break;
      case 270:
        cw = img.height;
        ch = img.width;
        cx = img.width * -1;
        break;
    }

    //  Rotate image
    canvas.setAttribute('width', cw);
    canvas.setAttribute('height', ch);
    cContext.rotate((degree * Math.PI) / 180);
    cContext.drawImage(img, cx, cy);

    return canvas;
  }

  return (
    <div className="relative rounded-lg max-h-[750px]">
      <img
        id="image"
        onLoad={() => setIsImageLoading(true)}
        src={state.src}
        rotate={state.rotation}
        className="rounded-lg max-h-[750px]"
        style={styles}
        crossOrigin="use-credentials"
      />

      {isImageLoading && (
        <div className="flex space-x-4 absolute top-0 right-0 p-4">
          <Button onClick={() => handleSaveImage()}>Salvar</Button>

          <Button onClick={() => handleRotate()}>Girar Imagem</Button>
        </div>
      )}
    </div>
  );
};

export default ImageEditor;
