import { client } from '@config/apollo';
import gql from 'graphql-tag';
import { setLoader, unsetLoader } from '../ducks/loading';
import { call, put, select } from 'redux-saga/effects';
import { Types } from '../ducks/toast';

export function* sendMail(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  const { from: sender, to, copy, title, content } = action;
  const destination = `${to}, ${copy}`;

  yield put(setLoader());

  try {
    yield call(cli.mutate, {
      mutation: gql`
        mutation (
          $destination: String!
          $subject: String!
          $content: String!
          $sender: String!
        ) {
          sendEmail(
            destination: $destination
            subject: $subject
            content: $content
            sender: $sender
          ) {
            ok
          }
        }
      `,
      variables: {
        destination,
        subject: title,
        content,
        sender,
      },
    });

    yield put({
      type: Types.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'E-mail enviado com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: Types.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put(unsetLoader());
  }
}
