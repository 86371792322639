import React, { useEffect, useState } from 'react';
import { CnpjCpf } from '@utils';
import InvoiceDetail from './components/invoiceDetail';
import CustomerDetail from '@pages/Customer/components/CustomerDetail';
import moment from 'moment';
import NumberFormat from 'react-number-format';
import { permissions } from '@config/permission';

const Format = ({ value }) => {
  value = value || 0;
  return (
    <NumberFormat
      value={value}
      displayType={'text'}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      fixedDecimalScale
    />
  );
};

const InvoiceList = props => {
  const [invoiceNo, setInvoiceNo] = useState('');
  const [state, setState] = useState({
    values: {
      totalQuantity: 0,
      totalValue: 0,
      totalWeight: 0,
    },
  });
  const [isCustomerDetailModalOpen, setIsCustomerDetailModalOpen] =
    useState(false);
  const [customerDetailModalData, setCustomerDetailModalData] = useState();

  const { source, shipper } = props;
  const { values } = state;

  const detailInvoice = invoiceNo => {
    setInvoiceNo(invoiceNo);
  };

  const calculatedValues = () => {
    if (!source || source.length <= 0) {
      return null;
    }

    let values = {
      totalQuantity: 0,
      totalValue: 0,
      totalWeight: 0,
      totalFreightDispatcher: 0,
      totalFreightRedispatcher: 0,
    };

    source.map(invoice => {
      const {
        productInfo,
        dispatcher,
        redispatcher,
        redispatcherFreightCost,
        dispatcherFreightCost,
      } = invoice;
      const { aggregated } = productInfo;

      values.totalQuantity += aggregated.quantity;
      values.totalValue += aggregated.totalValue;
      values.totalWeight += aggregated.grossWeight;

      if (
        dispatcher &&
        (shipper?.cnpj === dispatcher?.company?.cnpj ||
          permissions.shippingOrder.viewFreightValue()) &&
        dispatcherFreightCost
      ) {
        values.totalFreightDispatcher += dispatcherFreightCost;
      }
      if (
        redispatcher &&
        (shipper?.cnpj === redispatcher?.company?.cnpj ||
          permissions.shippingOrder.viewFreightValue()) &&
        redispatcherFreightCost
      ) {
        values.totalFreightRedispatcher += redispatcherFreightCost;
      }
    });

    setState({ values });
  };

  useEffect(() => {
    calculatedValues();
  }, [source]);

  if (!source || source.length <= 0) {
    return null;
  }

  return (
    <>
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
              Emissão
            </th>
            <th className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
              Nota
            </th>
            <th className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
              CNPJ
            </th>
            <th className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
              Insc.Estadual
            </th>
            <th className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
              Cliente
            </th>
            <th className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
              Destino
            </th>
            <th className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900">
              UF
            </th>
            <th className="px-2 py-3.5 text-right text-sm font-semibold text-gray-900">
              Peso Bruto (KG)
            </th>
            <th className="px-2 py-3.5 text-right text-sm font-semibold text-gray-900">
              Vlr.Nota
            </th>
            {permissions.shippingOrder.viewFreightValue() ? (
              <>
                <th className="px-2 py-3.5 text-right text-sm font-semibold text-gray-900">
                  Frete Despacho
                </th>
                <th className="px-2 py-3.5 text-right text-sm font-semibold text-gray-900">
                  Frete Redespacho
                </th>
              </>
            ) : (
              <th className="px-2 py-3.5 text-right text-sm font-semibold text-gray-900">
                Vlr.Frete
              </th>
            )}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {source.map(invoice => {
            const {
              invoiceNo,
              activities,
              productInfo,
              customer,
              dispatcher,
              redispatcher,
              dispatcherFreightCost,
              redispatcherFreightCost,
            } = invoice;
            const { items, aggregated } = productInfo;
            return (
              <React.Fragment key={invoiceNo}>
                <tr className="hover:bg-gray-200">
                  <td className="px-2 py-2 text-sm text-gray-800">
                    {activities.emittedAt &&
                      moment(activities?.emittedAt).format('DD/MM/YYYY')}
                  </td>
                  <td className="px-2 py-2 text-sm text-gray-800">
                    <span
                      className="text-blue-500 cursor-pointer"
                      onClick={() => {
                        detailInvoice(invoiceNo);
                      }}
                    >
                      {invoiceNo}
                    </span>
                  </td>
                  <td className="px-2 py-2 text-sm text-gray-800 nowrap">
                    <span
                      className="text-blue-500 cursor-pointer"
                      onClick={() => {
                        setCustomerDetailModalData(customer.cnpj);
                        setIsCustomerDetailModalOpen(true);
                      }}
                    >
                      {CnpjCpf.mask(customer.cnpj)}
                    </span>
                  </td>
                  <td className="px-2 py-2 text-sm text-gray-800">
                    {customer.stateInscription}
                  </td>
                  <td className="px-2 py-2 text-sm text-gray-800">
                    <span
                      className="text-blue-500 cursor-pointer"
                      onClick={() => {
                        setIsCustomerDetailModalOpen(true);
                        setCustomerDetailModalData(customer.cnpj);
                      }}
                    >
                      {`${customer.code ? '(' + customer.code + ') ' : ''}${
                        customer.corporateName
                      }`}
                    </span>
                  </td>
                  <td className="px-2 py-2 text-sm text-gray-800">
                    {customer.city.name}
                  </td>
                  <td className="px-2 py-2 text-sm text-gray-800">
                    {customer.city.state}
                  </td>
                  <td className="px-2 py-2 text-sm text-gray-800 text-right">
                    <Format value={aggregated.grossWeight} />
                  </td>
                  <td className="px-2 py-2 text-sm text-gray-800 text-right">
                    <Format value={aggregated.totalValue} />
                  </td>
                  {permissions.shippingOrder.viewFreightValue() ? (
                    <>
                      <td className="px-2 py-2 text-sm text-gray-800 font-bold text-right">
                        {dispatcher && dispatcherFreightCost && (
                          <Format value={dispatcherFreightCost} />
                        )}
                      </td>
                      <td className="px-2 py-2 text-sm text-gray-800 font-bold text-right">
                        {redispatcher && redispatcherFreightCost && (
                          <Format value={redispatcherFreightCost} />
                        )}
                      </td>
                    </>
                  ) : (
                    <td className="px-2 py-2 text-sm text-gray-800 font-bold text-right">
                      {dispatcher &&
                        shipper?.cnpj === dispatcher?.company?.cnpj &&
                        dispatcherFreightCost && (
                          <Format value={dispatcherFreightCost} />
                        )}
                      {redispatcher &&
                        shipper?.cnpj === redispatcher?.company?.cnpj &&
                        redispatcherFreightCost && (
                          <Format value={redispatcherFreightCost} />
                        )}
                    </td>
                  )}
                </tr>

                <tr
                  className={
                    !props.printItem
                      ? 'no-print bg-gray-50 hover:bg-gray-200'
                      : null
                  }
                >
                  <td colSpan="4" className="text-sm text-gray-500 font-bold ">
                    &nbsp;
                  </td>
                  <td className="px-2 text-sm text-gray-500 font-bold text-right ">
                    Volumes
                  </td>
                  <td
                    colSpan={
                      permissions.shippingOrder.viewFreightValue() ? 6 : 5
                    }
                    className="px-2 text-sm text-gray-500 font-bold text-left"
                  >
                    <b>Produtos</b>
                  </td>
                </tr>
                {items.map(item => {
                  return (
                    <tr
                      key={item.sku}
                      className={
                        !props.printItem
                          ? 'no-print bg-gray-50 hover:bg-gray-200'
                          : null
                      }
                    >
                      <td colSpan="4" className="px-2 text-sm text-gray-500">
                        &nbsp;
                      </td>
                      <td className="px-2 text-sm text-gray-500 text-right">
                        {item.quantity}
                      </td>
                      <td
                        colSpan={
                          permissions.shippingOrder.viewFreightValue() ? 6 : 5
                        }
                        className="px-2 text-sm text-gray-500 text-left"
                      >
                        {item.sku} {item.name}
                      </td>
                    </tr>
                  );
                })}
              </React.Fragment>
            );
          })}
          <tr>
            <td
              colSpan="4"
              className="px-2 py-2 text-sm text-gray-800 font-bold text-right"
            >
              Totais
            </td>
            <td className="px-2 py-2 text-sm text-gray-800 font-bold text-right">
              {values.totalQuantity}
            </td>
            <td colSpan="2">&nbsp;</td>
            <td className="px-2 py-2 text-sm text-gray-800 font-bold text-right">
              <Format value={values.totalWeight} />
            </td>
            <td className="px-2 py-2 text-sm text-gray-800 font-bold text-right">
              <Format value={values.totalValue} />
            </td>
            {permissions.shippingOrder.viewFreightValue() ? (
              <React.Fragment>
                <td className="px-2 py-2 text-sm text-gray-800 font-bold text-right">
                  <Format value={values.totalFreightDispatcher} />
                </td>
                <td className="px-2 py-2 text-sm text-gray-800 font-bold text-right">
                  <Format value={values.totalFreightRedispatcher} />
                </td>
              </React.Fragment>
            ) : (
              <td className="px-2 py-2 text-sm text-gray-800 font-bold text-right">
                <Format
                  value={
                    values.totalFreightDispatcher +
                    values.totalFreightRedispatcher
                  }
                />
              </td>
            )}
          </tr>
        </tbody>
      </table>
      <CustomerDetail
        isCustomerDetailModalOpen={isCustomerDetailModalOpen}
        setIsCustomerDetailModalOpen={setIsCustomerDetailModalOpen}
        customerDetailModalData={customerDetailModalData}
      />
      <InvoiceDetail invoiceNo={invoiceNo} setInvoiceNo={setInvoiceNo} />
    </>
  );
};

export default InvoiceList;
