import { useState, useEffect, useRef } from 'react';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fetchShippingOrders } from '@redux/ducks/shippingOrder';

import { ShippingOrderDetail } from '@pages';

import { Tabs } from '@components';
import Filter from '../components/filter';
import ShippingOrderList from '../components/ShippingOrderList';

const filtersInitialState = {
  from: '',
  to: '',
  plate: '',
  dispatcher: '',
  dispatcherName: '',
  dispatcherMail: '',
};

const ShippingOrderQuery = ({
  results,
  pageInfo: { cursor, hasNextPage },
  load_query,
  fetchShippingOrders,
  contextCompany,
}) => {
  const [queryType, setQueryType] = useState('all');
  const [shippingOrderDetailModalData, setShippingOrderDetailModalData] =
    useState(false);
  const [isShippingOrderDetailModalOpen, setIsShippingOrderDetailModalOpen] =
    useState(false);
  const [filters, setFilters] = useState(filtersInitialState);
  const listRef = useRef(null);

  async function handleFetchShippingOrders() {
    fetchShippingOrders({ filter: queryType, params: { ...filters } });
  }

  const handleNextPage = async () => {
    fetchShippingOrders({
      filter: queryType,
      cursor,
      params: { ...filters },
    });
  };

  function handleShippingOrderDetail(shippingOrder) {
    setShippingOrderDetailModalData(shippingOrder.key);
    setIsShippingOrderDetailModalOpen(true);
  }

  function onClose (){
    setIsShippingOrderDetailModalOpen(false)
    setShippingOrderDetailModalData('')
  }

  useEffect(() => {
    handleFetchShippingOrders();
  }, [queryType, filters, contextCompany]);

  const Filters = () => (
    <>
      <Tabs>
        <Tabs.Item
          active={queryType === 'all'}
          onClick={() => setQueryType('all')}
        >
          Todas
        </Tabs.Item>
        <Tabs.Item
          active={queryType === 'pending'}
          onClick={() => setQueryType('pending')}
        >
          Pendentes
        </Tabs.Item>
        <Tabs.Item
          active={queryType === 'delivered'}
          onClick={() => setQueryType('delivered')}
        >
          Entregues
        </Tabs.Item>
      </Tabs>
      <Filter initialState={filters} onSearch={setFilters} />
    </>
  );

  return (
    <>
      <ShippingOrderList
        formHeader={{
          title: 'Ordens de transportes',
          subtitle: 'Consulta de ordens de transportes',
        }}
        ref={listRef}
        source={results}
        hasNext={hasNextPage}
        load={load_query}
        filters={Filters}
        next={handleNextPage}
        actionsBtn={[
          {
            content: 'Detalhar',
            onClick: item => handleShippingOrderDetail(item.node)
          },
        ]}
      />
      <ShippingOrderDetail
        open={isShippingOrderDetailModalOpen}
        setIsShippingOrderDetailModalOpen={setIsShippingOrderDetailModalOpen}
        shippingOrderDetailModalData={shippingOrderDetailModalData}
        onClose={onClose}
      />
    </>
  );
};

function mapStateToProps({ shippingOrder, auth }) {
  const { results, pageInfo, load_query } = shippingOrder;
  const company = auth;

  return {
    results,
    pageInfo,
    load_query,
    contextCompany: company,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchShippingOrders }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ShippingOrderQuery);
