export const Types = {
  CREATE_REVERSAL_REASON: 'reversalReason/CREATE_REVERSAL_REASON',
  UPDATE_REVERSAL_REASON: 'reversalReason/UPDATE_REVERSAL_REASON',
  REMOVE_REVERSAL_REASON: 'reversalReason/REMOVE_REVERSAL_REASON',
  SET_RESULTS: 'reversalReason/SET_RESULTS',
  NEXT_RESULTS: 'reversalReason/NEXT_RESULTS',
  SET_PAGE_INFO: 'reversalReason/SET_PAGE_INFO',
  LOAD_QUERY: 'reversalReason/LOAD_QUERY',
  LOAD_MUTATION: 'reversalReason/LOAD_MUTATION',
  FETCH_REASONS: 'reversalReason/FETCH_REASONS',
  GET_REASONS: 'reversalReason/GET_REASONS',
  RESET_STATE: 'reversalReason/RESET_STATE',
  USER_LOGOUT: 'USER_LOGOUT',
};

const initialState = {
  results: [],
  pageInfo: {},
  load_query: false,
  load_mutation: false,
};

export default function reversal(state = initialState, action) {
  switch (action.type) {
    case Types.SET_RESULTS:
      return { ...state, results: action.results };
    case Types.NEXT_RESULTS:
      return { ...state, results: [...state.results, ...action.results] };
    case Types.SET_PAGE_INFO:
      return { ...state, pageInfo: action.pageInfo };
    case Types.LOAD_QUERY:
      return { ...state, load_query: action.load };
    case Types.LOAD_MUTATION:
      return { ...state, load_mutation: action.load };
    case Types.RESET_STATE:
    case Types.USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export function saveReason({ data, edit, success, error }) {
  return {
    type:
      edit === true
        ? Types.UPDATE_REVERSAL_REASON
        : Types.CREATE_REVERSAL_REASON,
    data,
    success,
    error,
  };
}

export function removeReason({ data, success, error }) {
  return {
    type: Types.REMOVE_REVERSAL_REASON,
    data,
    success,
    error,
  };
}

export function fetchReasons({ cursor }) {
  return {
    type: Types.FETCH_REASONS,
    cursor,
  };
}

export function getReasons({ search, success, error }) {
  let filter;
  if (search && search.length > 0) {
    filter = {
      description_LIKE: search,
    };
  }

  return {
    type: Types.GET_REASONS,
    filter,
    success,
    error,
  };
}

export function resetState() {
  return {
    type: Types.RESET_STATE,
  };
}
