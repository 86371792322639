import { useEffect, useRef, useState } from 'react';
import { fetchReversals, removeReversal } from '@redux/ducks/reversal';
import { setDataObject as setInvoice } from '@redux/ducks/invoice';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import InvoiceSearchForm from '@pages/invoices/components/invoiceSearch';

import DevolutionOpenForm from './components/DevolutionOpenForm';
import DevolutionInvoiceDetail from '../DevolutionInvoiceDetail';
import DevolutionCloseForm from './components/DevolutionCloseForm';

import { Load, Button, Tabs, Input, Confirm, TableForm } from '@components';
import { permissions } from '@config/permission';
import moment from 'moment';
import NumberFormat from 'react-number-format';

const DevolutionInvoices = ({
  results,
  pageInfo,
  load_mutation,
  load_query,
  fetchReversals,
  removeReversal,
  setInvoice,
  contextCompany,
}) => {
  const [queryType, setQueryType] = useState('pending');
  const [searchQuery, setSearchQuery] = useState('');
  const [openCreateDevolutionModal, setOpenCreateDevolutionModal] =
    useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openEffectDevolutionModal, setOpenEffectDevolutionModal] =
    useState(false);
  const [openDevolutionFormModal, setOpenDevolutionFormModal] = useState(false);
  const [effectDevolutionItem, setEffectDevolutionItem] = useState({});
  const [devolutionInvoiceId, setDevolutionInvoiceId] = useState({});
  const [devolutionInvoiceToForm, setDevolutionInvoiceToForm] = useState({});
  const confirmRef = useRef(null);

  function handleSearch() {
    fetchReversals({
      status: queryType,
      search: searchQuery,
    });
  }

  function effectDevolution(item) {
    setEffectDevolutionItem(item);
    setOpenEffectDevolutionModal(true);
  }

  function seeDetails(item) {
    setDevolutionInvoiceId(item.id);
    setOpenDetailsModal(true);
  }

  function handleCancelReversal(reversal) {
    confirmRef.current.open(
      'Deseja realmente cancelar a devolução selecionada?',
      () => {
        removeReversal({
          data: {
            reversalId: reversal?.node?.id,
          },
          success: () => {
            handleSearch();
          },
        });
      }
    );
  }

  function handleCreateDevolution(invoice) {
    if (invoice.reversal) {
      throw `Já possuí uma devolução cadastrada para a nota fiscal ${invoice.invoiceNo}`;
    } else {
      setDevolutionInvoiceToForm(invoice);
      setOpenCreateDevolutionModal(false);
      setOpenDevolutionFormModal(true);
    }
  }

  function handleDetailInvoice(invoiceNumber) {
    setInvoice({
      key: invoiceNumber,
    });
  }

  const Filters = () => {
    return (
      <div className="flex justify-between">
        <Tabs>
          <Tabs.Item
            active={queryType === 'pending'}
            onClick={() => setQueryType('pending')}
          >
            Pendentes
          </Tabs.Item>
          <Tabs.Item
            active={queryType === 'approved'}
            onClick={() => setQueryType('approved')}
          >
            Encerradas
          </Tabs.Item>
        </Tabs>

        <div className="flex justify-between">
          <Input.Action
            onFireAction={() => {
              handleSearch();
            }}
            value={searchQuery}
            placeholder="Pesquisar..."
            onChange={e => setSearchQuery(e.target.value)}
          />
          <div className="ml-5">
            {permissions.reversal.create() && (
              <Button
                variant="primary"
                onClick={() => setOpenCreateDevolutionModal(true)}
              >
                Criar Devolução
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  };

  const next = () => {
    fetchReversals({
      status: queryType,
      cursor: pageInfo.cursor,
      search: searchQuery,
    });
  };

  useEffect(() => {
    setSearchQuery('');
    const status = { status: queryType };
    fetchReversals(status);
  }, [
    queryType,
    !openEffectDevolutionModal,
    !openDevolutionFormModal,
    contextCompany,
  ]);

  return (
    <>
      <TableForm
        formHeader={{
          title: 'Notas Devolvidas',
          subtitle: `Notas com devolução ${
            queryType === 'pending' ? 'pendentes' : 'aceita'
          }`,
        }}
        filters={Filters}
        hasNext={pageInfo.hasNextPage}
        next={next}
        load={load_query}
        source={results}
        headers={[
          {
            label: 'Nota Fiscal',
            getValue: item => {
              if (item.node.invoice.invoiceNo)
                return (
                  <span
                    className="text-blue-500 cursor-pointer"
                    onClick={() =>
                      handleDetailInvoice(item.node.invoice.invoiceNo)
                    }
                  >
                    {item.node.invoice.invoiceNo}
                  </span>
                );
              else return 'Sem nota Fiscal';
            },
          },
          {
            label: 'Emissão',
            getValue: item => {
              if (item.node.invoice.activities.emittedAt)
                return moment(item.node.invoice.activities.emittedAt).format(
                  'DD/MM/YYYY'
                );
              else return moment().format('DD/MM/YYYY');
            },
          },
          {
            label: 'Devolução',
            getValue: item => {
              if (item.node.reversedAt)
                return moment(item.node.reversedAt).format(
                  'DD/MM/YYYY'
                );

            },
          },
          {
            label: 'Cliente ',
            getValue: item => item?.node?.invoice?.customer?.corporateName,
          },
          {
            label: 'Observação',
            getValue: item => {
              return item?.node?.note;
            },
          },
          {
            label: 'Valor do frete',
            getValue: item => {
              return (
                <NumberFormat
                  value={item.node.freightValue || 0}
                  displayType={'text'}
                  thousandSeparator="."
                  decimalSeparator=","
                  decimalScale={2}
                  fixedDecimalScale
                />
              );
            },
          },
          {
            label: 'Ações',
            props: {
              align: 'center',
              width: queryType === 'pending' ? '320px' : '150px',
            },
            getValue: item => (
              <div className="flex gap-2 justify-end">
                <Button
                  compact
                  key={item.node.id}
                  onClick={() => seeDetails(item.node)}
                >
                  Det.Devolução
                </Button>
                {queryType === 'pending' && (
                  <>
                    {permissions.reversal.effect() && (
                      <Button
                        compact
                        variant="positive"
                        onClick={() => effectDevolution(item.node)}
                      >
                        Efetivar
                      </Button>
                    )}
                    {permissions.reversal.cancel() && (
                      <Button
                        compact
                        variant="negative"
                        onClick={() => handleCancelReversal(item)}
                      >
                        Cancelar
                      </Button>
                    )}
                  </>
                )}
              </div>
            ),
          },
        ]}
      />

      <InvoiceSearchForm
        open={openCreateDevolutionModal}
        ribbon={false}
        onSubmit={handleCreateDevolution}
        onClose={() => setOpenCreateDevolutionModal(false)}
      />

      <DevolutionOpenForm
        devolutionInvoice={devolutionInvoiceToForm}
        openDevolutionFormModal={openDevolutionFormModal}
        setDevolutionOpenForm={setOpenDevolutionFormModal}
      />

      <DevolutionInvoiceDetail
        title="Detalhe da devolução"
        open={openDetailsModal}
        containerClassSize="max-w-4xl"
        openDetailsModal={openDetailsModal}
        onClose={() => setOpenDetailsModal(false)}
        id={devolutionInvoiceId}
      />

      <DevolutionCloseForm
        open={openEffectDevolutionModal}
        effectDevolutionItem={effectDevolutionItem}
        setOpenEffectDevolution={setOpenEffectDevolutionModal}
      />

      <Confirm ref={confirmRef} />
      <Load active={load_mutation} />
    </>
  );
};

function mapStateToProps({ reversal, auth }) {
  const { results, pageInfo, load_mutation, load_query } = reversal;
  const company = auth;

  return {
    results,
    pageInfo,
    load_mutation,
    load_query,
    contextCompany: company,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { fetchReversals, removeReversal, setInvoice },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(DevolutionInvoices);
