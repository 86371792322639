import { useEffect, useRef, useState } from 'react';

import moment from 'moment';
import { Button, Input, Toast, DateRangePicker } from '@components';

import { bindActionCreators } from 'redux';
import { fetchLogs } from '@redux/ducks/log';
import { connect, useDispatch } from 'react-redux';
import { fetchOperatorsWithoutFilter } from '@redux/ducks/operator';

const LogFilter = ({ operators, fetchOperatorsWithoutFilter }) => {
  const [filter, setFilter] = useState({
    from: new Date(moment().subtract(30, 'd').format('YYYY-MM-DD')),
    to: new Date(moment(Date.now()).format('YYYY-MM-DD')),
    error: {
      operator: false,
      date: false,
    },
    operator: null,
  });
  const dispatch = useDispatch();
  const toastRef = useRef(null);

  function applyFilters(e) {
    e.preventDefault();

    if (!filter.operator) {
      setFilter(prev => ({
        ...prev,
        error: {
          ...prev.error,
          operator: true,
        },
      }));

      toastRef?.current?.show({
        message: 'Informe um operador',
        type: 'error',
      });
    }

    if (!filter.from || !filter.to) {
      setFilter(prev => ({
        ...prev,
        error: {
          ...prev.error,
          date: true,
        },
      }));

      toastRef?.current?.show({
        message: 'Informe um período',
        type: 'error',
      });
    }

    if (filter.to && filter.from && filter.operator) {
      dispatch(fetchLogs(filter));
    }
  }

  const selectOperator = operatorSelected => {
    setFilter(prev => ({
      ...prev,
      operator: operatorSelected,
      error: {
        ...prev.error,
        operator: false,
      },
    }));
  };

  useEffect(() => {
    fetchOperatorsWithoutFilter();
  }, []);

  return (
    <div className="flex sm:items-end gap-2 flex-col sm:flex-row">
      <div>
        <DateRangePicker
          placeholderFrom="Ocorrência de"
          placeholderTo="Até"
          from={filter.from}
          to={filter.to}
          onChangeFrom={from => {
            setFilter(prev => ({
              ...prev,
              error: {
                ...prev.error,
                date: prev.to ? false : true,
              },
              from: new Date(moment(from).startOf('day')),
            }));
          }}
          onChangeTo={to => {
            setFilter(prev => ({
              ...prev,
              error: {
                ...prev.error,
                date: prev.from ? false : true,
              },
              to: new Date(moment(to).endOf('day')),
            }));
          }}
        />
      </div>

      <Input.Select
        onChange={selectOperator}
        options={operators}
        caption="Operador"
        defaultEmptyOption="Selecione um operador..."
        error={filter.error.operator}
      />

      <div className="flex gap-2">
        <Button variant="primary" onClick={applyFilters}>
          Consultar
        </Button>
      </div>
      <Toast ref={toastRef} />
    </div>
  );
};

function mapStateToProps({ operator }) {
  return {
    operators: operator.results
      ? operator.results.map(op => {
          return {
            key: op.node.user.id,
            value: op.node.user.id,
            text: `${op.node.user.firstName} ${op.node.user.lastName}`,
          };
        })
      : [],
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ fetchOperatorsWithoutFilter }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(LogFilter);
