import { useEffect, useRef } from 'react';
import { connect, useDispatch } from 'react-redux';
import { fetchTypes, saveType } from '@redux/ducks/incidentType';
import IncidentTypeForm from './components/IncidentTypeForm';
import { TableForm, Button } from '@components';

const IncidentType = props => {
  const { results, load_query, load_mutation } = props;
  const dispatch = useDispatch();
  const formRef = useRef(null);
  const listRef = useRef(null);

  function initialize() {
    window.scroll(0, 0);
    listRef.current.closeForm();
    dispatch(fetchTypes({}));
  }

  function onSave(data, edit) {
    return new Promise((resolve, reject) => {
      dispatch(
        saveType({
          data,
          edit,
          success: () => {
            initialize();
            resolve();
          },
          error: error => {
            reject(error);
          },
        })
      );
    });
  }

  async function edit(type) {
    await listRef.current.openForm();
    const { id, description, purpose } = type;

    setTimeout(() => {
      formRef?.current?.edit({
        id,
        description,
        purpose,
      });
    }, 200);
  }

  useEffect(() => {
    initialize();
  }, []);

  return (
    <TableForm
      formHeader={{
        title: 'Tipo de ocorrências',
        subtitle: 'Gerencie e visualize os tipos de ocorrências',
      }}
      titleModal="Cadastro de tipo de ocorrência"
      onNew={() => listRef.current.openForm()}
      source={results}
      load={load_query}
      ref={listRef}
      headers={[
        { label: 'Descrição', getValue: item => item.description },
        { label: 'Natureza', getValue: item => item.purpose },
        {
          label: 'Ações',
          props: {
            align: 'center',
            width: '70px',
          },
          getValue: item => (
            <Button compact onClick={() => edit(item)}>
              Editar
            </Button>
          ),
        },
      ]}
    >
      <IncidentTypeForm
        formRef={formRef}
        onSubmit={(values, edit) => onSave(values, edit)}
        loadMutation={load_mutation}
      />
    </TableForm>
  );
};

function mapStateToProps({ incidentType }) {
  const { results, load_query, load_mutation } = incidentType;
  return {
    results,
    load_query,
    load_mutation,
  };
}

export default connect(mapStateToProps)(IncidentType);
