import { FormCreate } from '@components/ReduxForm/Form';
import { getDrivers } from '@redux/ducks/driver';
import validate from './validate';
import { useDispatch } from 'react-redux';

const Form = FormCreate('TransportQueueForm', validate, { aparas: false });

const TransportQueueForm = ({ ...rest }) => {
  const dispatch = useDispatch();

  return (
    <Form
      {...rest}
      fields={[
        {
          name: 'driver',
          component: 'search',
          type: 'search',
          label: 'Motorista:',
          inputProps: {
            keyExtractor: item => item.node.id,
            textExtractor: item =>
              `(${
                item.node.shipperDriver.plate
                  ? item.node.shipperDriver.plate
                  : 'Sem placa'
              }) ${item.node.firstName} ${
                item.node.lastName ? item.node.lastName : ''
              }`,
            onSearch: search => {
              return new Promise((resolve, reject) => {
                dispatch(
                  getDrivers({
                    search,
                    success: results => {
                      resolve(results);
                    },
                    error: error => {
                      reject(error);
                    },
                  })
                );
              });
            },
            onSelect: (item, reduxFormChange) => {
              reduxFormChange(
                'driver',
                `${item.node.firstName} ${item.node.lastName}`
              );
              reduxFormChange('plate', item.node.shipperDriver.plate);
            },
          },
        },
        {
          name: 'plate',
          component: 'input',
          type: 'text',
          label: 'Placa:',
          inputProps: {
            disabled: true,
          },
        },
        {
          name: 'data',
          component: 'date',
          type: 'text',
          label: 'Chegada:',
          inputProps: {
            placeholder: 'Selecione',
          },
        },
        {
          name: 'hora',
          component: 'time',
          type: 'text',
          label: 'Hora:',
          inputProps: {
            placeholder: 'Informe a hora',
          },
        },
      ]}
      lbSubmit="Confirmar"
    />
  );
};

export default TransportQueueForm;
