import { FormCreate } from '@components';
import validate from './validate';

const Form = FormCreate('RegionForm', validate, { active: true });

const RegionForm = props => {
  return (
    <Form
      {...props}
      fields={[
        {
          name: 'id',
          component: 'input',
          type: 'text',
          label: 'ID:',
          edit: false,
          display: 'none',
        },
        {
          name: 'key',
          component: 'input',
          type: 'text',
          label: 'Código:',
          disable: true,
          inputProps: {
            mask: '99999',
            placeholder: 'Informe o código',
          },
          edit: false,
        },
        {
          name: 'description',
          component: 'input',
          type: 'text',
          label: 'Descrição:',
          inputProps: {
            placeholder: 'Informe a descrição',
          },
        },
        {
          name: 'active',
          component: 'toggle',
          type: 'checkbox',
          label: 'Situação:',
        },
      ]}
    />
  );
};

export default RegionForm;
