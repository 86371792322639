import { FormCreate } from '@components';
import validate from './validate';

const Form = FormCreate('ReversalReasonForm', validate);

export default function ReversalReasonForm({ ...rest }) {
  return (
    <Form
      {...rest}
      fields={[
        {
          name: 'description',
          component: 'input',
          type: 'text',
          label: 'Descrição',
          inputProps: {
            placeholder: 'Informe a descrição',
          },
        },
      ]}
    />
  );
}
