import { useEffect, useRef } from 'react';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';

import { formatDate, parseDate } from 'react-day-picker/moment';

function DateRangePicker({
  placeholderFrom,
  placeholderTo,
  onChangeFrom,
  onChangeTo,
  from,
  to,
}) {
  const refTo = useRef(null);
  const modifiers = { start: from, end: to };

  useEffect(() => {
    if (from) {
      if (moment(to).diff(moment(from), 'months') < 2) {
        refTo.current.getDayPicker()?.showMonth(from);
      }
    }
  }, [from, to, refTo]);

  return (
    <div className="flex max-w-sm gap-2 flex-col sm:flex-row">
      <div className="flex flex-1 flex-col">
        <label className="text-sm font-medium leading-6 text-gray-900">
          {placeholderFrom}
        </label>
        <DayPickerInput
          value={from}
          placeholder="dd/mm/aaaa"
          format="DD/MM/YYYY"
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            selectedDays: [from, { from, to }],
            disabledDays: { after: to },
            toMonth: to,
            modifiers,
            numberOfMonths: 2,
            onDayClick: () => refTo.current.input.focus(),
          }}
          onDayChange={onChangeFrom}
        />
      </div>
      <div className="flex flex-1 flex-col">
        <label className="text-sm font-medium leading-6 text-gray-900">
          {placeholderTo}
        </label>
        <DayPickerInput
          ref={refTo}
          value={to}
          placeholder="dd/mm/aaaa"
          format="DD/MM/YYYY"
          formatDate={formatDate}
          parseDate={parseDate}
          dayPickerProps={{
            selectedDays: [from, { from, to }],
            disabledDays: { before: from },
            modifiers,
            month: from,
            fromMonth: from,
            numberOfMonths: 2,
          }}
          onDayChange={onChangeTo}
        />
      </div>
    </div>
  );
}

export default DateRangePicker;
