import { CnpjCpf } from '@utils';

const validate = values => {
  const errors = {};
  if (!values.cpf) {
    errors.cpf = 'Favor informar o CPF do motorista!';
  } else if (!CnpjCpf.validate(values.cpf)) {
    errors.cpf = 'CPF inválido. Favor preencha com um CPF válido!';
  }
  if (!values.firstName) {
    errors.firstName = 'Favor informar o nome do motorista!';
  }
  if (!values.lastName) {
    errors.lastName = 'Favor informar o sobrenome do motorista!';
  }
  if (!values.phone) {
    errors.phone = 'Favor informar o numero de celular do motorista!';
  }
  return errors;
};

export default validate;
