import { forwardRef, useRef, useState } from 'react';
import { Modal, Button, Toast } from '@components';
import ImageEditor from './ImageEditor';
import { HOST, PROTOCOL_HTTP } from '@config/apollo';

const ModalImage = ({
  open,
  src,
  onClose,
  handleEdit,
  isEdit,
  onSave,
  ...rest
}) => (
  <Modal open={open} onClose={onClose} title="Detalhamento da imagem" {...rest}>
    <div className="flex flex-col relative justify-center items-center mb-3 space-y-4">
      {!isEdit ? (
        <img src={src} className="rounded-lg max-h-[750px]" />
      ) : (
        <ImageEditor
          src={src}
          onSave={img => {
            onSave(img);
          }}
        />
      )}
      <div className="absolute top-0 left-0 px-4">
        <Button variant="primary" onClick={() => handleEdit()}>
          {isEdit ? 'Visualizar' : 'Editar'}
        </Button>
      </div>
    </div>
  </Modal>
);

const CardImages = forwardRef(({ source, close, imgs }, ref) => {
  const [state, setState] = useState({
    open: false,
    src: '',
    rotation: 0,
    editatedImg: null,
    isEdit: false,
    imgInfo: {},
  });
  const toastRef = useRef(null);

  const onSave = async img => {
    setState(restoredState => {
      return {
        ...restoredState,
        open: false,
        src: img,
      };
    });

    let blob = await fetch(img).then(res => res.blob());

    const imageURL = await window.URL.createObjectURL(blob);

    let formData = new FormData();

    const { imgInfo } = state;
    formData.append(imgInfo.type, blob, imgInfo.fileName);

    setState(restoredState => {
      return {
        ...restoredState,
        imgInfo: {
          ...restoredState.imgInfo,
          url: imageURL,
        },
      };
    });

    try {
      const url = `${PROTOCOL_HTTP}://${HOST}`;

      const result = await fetch(`${url}${source}${imgInfo.key}`, {
        method: 'PATCH',
        credentials: 'include',
        body: formData,
      });

      if (result.status === 201) {
        toastRef?.current?.show({
          message: 'Foto alterada com sucesso',
          type: 'success',
        });
        if (typeof close === 'function') {
          close();
        }
      }
    } catch (error) {
      toastRef?.current?.show({
        title: 'Erro ao alterar a foto',
        message: error,
        type: 'error',
      });
      throw new Error(error);
    }
  };

  return (
    <div className="flex gap-3 flex-wrap">
      {imgs.map((img, index) => {
        const [, , , , , key, type, fileName] = img.split('/', 8);
        const urlImg = `${img}?date=${Date.now()}`;
        return (
          <div key={index}>
            <img
              className="cursor-pointer w-60 h-40 rounded-lg object-cover"
              src={urlImg}
              onClick={() => {
                setState(restoredState => {
                  return {
                    ...restoredState,
                    open: true,
                    src: urlImg,
                    imgInfo: {
                      type,
                      key,
                      fileName,
                    },
                  };
                });
              }}
            />
          </div>
        );
      })}
      <ModalImage
        open={state.open}
        src={state.src}
        onClose={() => {
          setState(restoredState => {
            return {
              ...restoredState,
              open: false,
              src: '',
              isEdit: false,
            };
          });
        }}
        size="fullscreen"
        ref={ref}
        handleEdit={() => {
          setState(restoredState => {
            return {
              ...restoredState,
              isEdit: !restoredState.isEdit,
            };
          });
        }}
        isEdit={state.isEdit}
        onSave={onSave}
      />
      <Toast ref={toastRef} />
    </div>
  );
});

CardImages.displayName = 'CardImages';

export default CardImages;
