import { useEffect, useRef } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createReprieve, getReprieveReasons } from '@redux/ducks/reprieve';

import { FormCreate, Modal } from '@components';

import validate from './validate';
import moment from 'moment';

const Form = FormCreate('ReprieveForm', validate, {
  representativeFreight: false,
  freightForCompany: false,
});

const number = value => value && value.replace(/[^0-9]/g, '');

const ReprieveForm = ({
  data,
  values,
  getReprieveReasons,
  createReprieve,
  open,
  onClose,
  onSuccess,
  ...rest
}) => {
  const invoice = data?.invoice;
  const reprieve = data?.reprieve;
  const invoiceNo = invoice?.invoiceNo;
  const customer = invoice?.customer;
  const activities = invoice?.activities;
  const formRef = useRef(null);

  const getNameTitle = code => {
    return `title:${code}`;
  };

  const onSubmit = (data, edit) => {
    return new Promise((resolve, reject) => {
      createReprieve({
        titles: reprieve.titulos,
        data: { ...data, invoiceKey: invoice.key },
        edit,
        success: () => {
          onSuccess();
          resolve();
        },
        error: error => {
          reject(error);
        },
      });
    });
  };

  const populateItemsField = () => {
    formRef?.current?.change({
      clientEmail: reprieve?.emailCliente,
      emails: reprieve?.emails,
      baseDate: reprieve?.dataBase,
      deadline: reprieve?.carencia,
      averageTerm: reprieve?.prazoMedio,
    });

    if (reprieve?.deliveredAt) {
      formRef?.current?.change({
        deliveredAt: reprieve?.deliveredAt,
        customerDate: reprieve?.deliveredAt,
      });
    }

    suggestDate({
      baseDate: reprieve?.dataBase,
      deadline: reprieve?.carencia,
    });
  };

  const suggestDate = ({ baseDate, deadline }) => {
    if (deadline != null && baseDate && reprieve.titulos) {
      const date = moment(baseDate, 'DD/MM/YYYY');
      if (date._isValid) {
        reprieve.titulos.map((title, index) => {
          const dateTemp = moment(date);
          const suggestValue = dateTemp
            .add(title.prazo, 'days')
            .subtract(deadline, 'days');

          const rowName = getNameTitle(title.codigo, index);

          formRef?.current?.change({
            [rowName]: moment(suggestValue).format('DD/MM/YYYY'),
          });
        });
      }
    }
  };

  const handleClickSuggest = () => {
    const { deadline, baseDate } = values;
    if (deadline != null && baseDate) {
      suggestDate({ deadline, baseDate });
    } else {
      alert(
        'Favor verificar se os campos data base e carência estão preenchidos!'
      );
    }
  };

  useEffect(() => {
    setTimeout(() => {
      populateItemsField();
    }, 200);
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Prorrogação de nota fiscal"
      containerClassSize="max-w-4xl"
    >
      <Form
        {...rest}
        formRef={formRef}
        ribbon={false}
        edit={true}
        fields={[
          {
            name: 'invoiceNo',
            component: 'input',
            type: 'text',
            label: 'NF:',
            edit: false,
            inputProps: {
              value: invoiceNo,
            },
          },
          {
            name: 'emittedAt',
            component: 'input',
            type: 'text',
            label: 'Data de emissão:',
            edit: false,
            inputProps: {
              value: moment(activities?.emittedAt).format('DD/MM/YYYY-HH:mm'),
            },
          },
          {
            name: 'deliveredAt',
            component: 'input',
            type: 'text',
            label: 'Data de entrega:',
            edit: false,
            inputProps: {
              placeholder: '',
            },
          },
          {
            name: 'averageTerm',
            component: 'input',
            type: 'text',
            label: 'Vencimentos:',
            edit: false,
          },
          {
            name: 'customer',
            component: 'input',
            type: 'text',
            label: 'Cliente:',
            edit: false,
            inputProps: {
              value: `${customer?.code ? '(' + customer?.code + ') ' : ''}${
                customer?.corporateName
              }`,
            },
          },
          {
            name: 'reasonDescription',
            component: 'search',
            type: 'search',
            label: 'Motivo da prorrogação:',
            inputProps: {
              keyExtractor: item => item.id,
              textExtractor: item => item.nome,
              onSearch: search => {
                return new Promise((resolve, reject) => {
                  getReprieveReasons({
                    search,
                    success: results => {
                      resolve(results);
                    },
                    error: error => {
                      reject(error);
                    },
                  });
                });
              },
              onSelect: (item, reduxFormChange) => {
                reduxFormChange('reasonDescription', item.nome);
                reduxFormChange('reason', item.id);
              },
            },
          },
          {
            name: 'clientEmail',
            component: 'input',
            type: 'text',
            label: 'Email cliente p/ financeiro:',
            inputProps: {
              placeholder: 'Informe o email',
            },
          },
          {
            name: 'emails',
            component: 'textarea',
            type: 'textarea',
            label: 'Outros emails para carta de ocorrencia:',
            inputProps: {
              placeholder: 'Informe o(s) email(s)',
            },
          },
          {
            name: 'note',
            component: 'textarea',
            type: 'textarea',
            label: 'Observação:',
            inputProps: {
              placeholder: 'Informe uma observação',
            },
          },
          {
            name: 'representativeFreight',
            component: 'toggle',
            type: 'checkbox',
            label: 'Cobrar frete do representante:',
          },
          {
            name: 'freightForCompany',
            component: 'toggle',
            type: 'checkbox',
            label: 'Não pagar frete:',
          },
          {
            name: 'customerDate',
            component: 'date',
            label: 'Data de entrega informada pelo cliente:',
            inputProps: {
              placeholder: 'Data informada cliente',
            },
            display: reprieve?.deliveredAt ? 'none' : '',
          },
          {
            name: 'baseDate',
            component: 'date',
            label: 'Data base:',
            inputProps: {
              placeholder: 'Data base',
            },
          },
          {
            name: 'deadline',
            component: 'input',
            type: 'text',
            label: 'Carência:',
            refs: 'teste',
            inputProps: {
              placeholder: 'Informe a carência',
            },
            normalize: number,
          },
          [
            {
              name: 'btnSuggestDeadline',
              component: 'button',
              label: 'Calcular prazo',
              btnProps: {
                onClick: () => {
                  handleClickSuggest();
                },
              },
            },
          ],
          {
            component: 'table',
            headers: [
              {
                component: 'text',
                label: 'Título',
              },
              {
                component: 'text',
                label: 'Portador',
              },
              {
                component: 'text',
                label: 'Prorrogar para data',
              },
              {
                component: 'text',
                label: 'Vencimento',
              },
              {
                component: 'text',
                label: 'Valor',
              },
            ],
            body: (() => {
              if (reprieve?.titulos) {
                return reprieve?.titulos?.map((item, index) => {
                  return [
                    { component: 'text', label: item.codigo },
                    { component: 'text', label: item.portador },
                    {
                      name: getNameTitle(item.codigo, index),
                      component: 'date',
                      type: 'text',
                      inputProps: {
                        placeholder: 'dd/mm/yyyy',
                      },
                    },
                    { component: 'text', label: item.vencimento },
                    { component: 'text', label: item.valor },
                  ];
                });
              }
            })(),
          },
        ]}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

function mapStateToProps(state) {
  const { ReprieveForm = {} } = state.form;

  return {
    values: ReprieveForm.values,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getReprieveReasons, createReprieve }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReprieveForm);
