import { client } from '@config/apollo';
import gql from 'graphql-tag';
import { call, put, select } from 'redux-saga/effects';
import { Types } from '../ducks/operator';
import { Types as ToastTypes } from '../ducks/toast';

export function* createOperator(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { firstName, lastName, email, states } = action.data;

    const statesToArray = states.split(',');

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($operator: OperatorInput!) {
          createOperator(operator: $operator) {
            id
          }
        }
      `,
      variables: {
        operator: {
          states: statesToArray,
          user: {
            firstName,
            lastName,
            email,
            active: false,
          },
        },
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Usuário interno convidado com sucesso',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* updateOperator(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { id, firstName, lastName, email, states } = action.data;

    const statesToArray = Array.isArray(states) ? states : states.split(',');

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($id: ID, $operator: OperatorInput) {
          updateOperator(id: $id, operator: $operator) {
            ok
          }
        }
      `,
      variables: {
        id,
        operator: {
          states: statesToArray,
          user: {
            firstName,
            lastName,
            email,
          },
        },
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Usuário interno alterado com sucesso',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* changeEmailOperator(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { operatorId, newEmail } = action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($operatorId: String!, $newEmail: String!) {
          changeOperatorUserEmail(
            operatorId: $operatorId
            newEmail: $newEmail
          ) {
            ok
          }
        }
      `,
      variables: {
        operatorId,
        newEmail,
      },
    });

    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* changeStatusOperator(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { id, email, firstName, states, active } = action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($id: ID, $operator: OperatorInput) {
          updateOperator(id: $id, operator: $operator) {
            ok
          }
        }
      `,
      variables: {
        id,
        operator: {
          states,
          user: {
            email,
            firstName,
            active,
          },
        },
      },
    });
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Usuário interno alterado com sucesso',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* fetchOperators(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    yield put({ type: Types.LOAD_QUERY, load: true });
    const { filter, cursor } = action;

    const response = yield call(cli.query, {
      query: gql`
        query operators(
          $first: Int!
          $filter: OperatorFilterInput
          $after: String
        ) {
          operators(first: $first, filter: $filter, after: $after) {
            pageInfo {
              hasNextPage
              hasPreviousPage
              after
              before
            }
            edges {
              node {
                id
                status
                states
                user {
                  firstName
                  lastName
                  email
                  id
                  active
                }
              }
            }
          }
        }
      `,
      variables: {
        first: 50,
        filter,
        after: cursor,
      },
    });

    const pageInfo = response.data.operators.pageInfo;
    const results = response.data.operators.edges;

    yield put({
      type: Types.SET_PAGE_INFO,
      pageInfo: { ...pageInfo, cursor: pageInfo.after },
    });

    if (cursor) {
      yield put({ type: Types.NEXT_RESULTS, results });
    } else {
      yield put({ type: Types.SET_RESULTS, results });
    }
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
  } finally {
    yield put({ type: Types.LOAD_QUERY, load: false });
  }
}

export function* handleResendInvitation(action) {
  const cli = yield call(client);
  const { userId } = action.data;

  yield put({ type: Types.LOAD_MUTATION, load: true });

  try {
    yield call(cli.mutate, {
      mutation: gql`
        mutation resendInvitation($userId: String!) {
          resendInvitation(userId: $userId) {
            ok
          }
        }
      `,
      variables: {
        userId,
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Convite reenviado com sucesso',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}
