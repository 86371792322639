const validate = values => {
  const errors = {};
  if (!values.description) {
    errors.description = 'Favor informar a descrição do tipo de ocorrência! ';
  }
  if (!values.purpose) {
    errors.purpose = 'Favor selecionar uma natureza para o tipo de ocorrência!';
  }
  return errors;
};

export default validate;
