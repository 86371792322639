import { client } from '@config/apollo';
import gql from 'graphql-tag';
import { call, put, select } from 'redux-saga/effects';
import { Types } from '../ducks/reversalReason';
import { Types as ToastTypes } from '../ducks/toast';

export function* getReasons(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  const { filter } = action;
  yield put({ type: Types.LOAD_QUERY, load: true });

  try {
    const response = yield call(cli.query, {
      query: gql`
        query ($filter: ReversalReasonFilterInput) {
          reversalReasons(filter: $filter) {
            id
            description
          }
        }
      `,
      variables: {
        filter,
      },
    });

    const results = response.data.reversalReasons;

    yield put({ type: Types.SET_RESULTS, results });

    if (action.success) action.success(results);
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_QUERY, load: false });
  }
}

export function* createReason(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  const { description } = action.data;

  yield put({ type: Types.LOAD_MUTATION, load: true });

  try {
    yield call(cli.mutate, {
      mutation: gql`
        mutation createReversalReason($data: ReversalReasonInput!) {
          createReversalReason(data: $data) {
            active
            description
          }
        }
      `,
      variables: {
        data: {
          active: true,
          description,
        },
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Motivo de devolução criado com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* updateReason(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  const { id, description } = action.data;

  yield put({ type: Types.LOAD_MUTATION, load: true });

  try {
    yield call(cli.mutate, {
      mutation: gql`
        mutation updateReversalReason(
          $id: String!
          $data: ReversalReasonInput!
        ) {
          updateReversalReason(id: $id, data: $data) {
            ok
          }
        }
      `,
      variables: {
        id,
        data: {
          active: true,
          description,
        },
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Motivo de devolução editado com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* removeReason(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  const { id } = action.data;

  yield put({ type: Types.LOAD_MUTATION, load: true });

  try {
    yield call(cli.mutate, {
      mutation: gql`
        mutation removeReversalReason($id: String!) {
          removeReversalReason(id: $id) {
            ok
          }
        }
      `,
      variables: {
        id,
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Motivo de devolução excluído com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}
