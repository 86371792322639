import { FormCreate, Modal } from '@components';
import validate from './validate';
import { forwardRef } from 'react';

const Form = FormCreate('DeliveredAtForm', validate);

const DeliveredAtForm = forwardRef(({ open, onClose, ...rest }, ref) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      title="Altere a data de entrega"
      modalOverflow
    >
      <Form
        {...rest}
        formRef={ref}
        fields={[
          {
            name: 'id',
            component: 'input',
            type: 'text',
            label: 'ID:',
            edit: false,
            display: 'none',
          },
          {
            name: 'dateHour',
            component: 'input',
            type: 'text',
            label: 'Data de entrega atual:',
          },
          {
            name: 'date',
            component: 'date',
            type: 'text',
            label: 'Nova data:',
            inputProps: {
              placeholder: 'Selecione',
            },
          },
          {
            name: 'hour',
            component: 'time',
            type: 'text',
            label: 'Hora:',
            inputProps: {
              placeholder: 'Informe a hora',
            },
          },
        ]}
      />
    </Modal>
  );
});

DeliveredAtForm.displayName = 'DeliveredAtForm';

export default DeliveredAtForm;
