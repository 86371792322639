import { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import OperatorForm from './components/OperatorForm';
import {
  fetchOperators,
  saveOperator,
  changeStatusOperator,
} from '@redux/ducks/operator';
import { refreshUser } from '@redux/ducks/auth';

import {
  Confirm,
  TableForm,
  Label,
  Button,
  ResendEmailForm,
} from '@components';

const Operator = ({
  results,
  pageInfo,
  load_query,
  load_mutation,
  licenseSlots,
  operatorsCount,
  shippersCount,
  inviteesCount,
}) => {
  const [itemInUpdate, setItemInUpdate] = useState(null);
  const [isResendEmailModalOpen, setIsResendEmailModalOpen] = useState(false);
  const [resendEmailData, setResendEmailData] = useState({});
  const totalCount = operatorsCount + shippersCount + inviteesCount + 1;
  const listRef = useRef(null);
  const confirmRef = useRef(null);
  const formRef = useRef(null);
  const dispatch = useDispatch();

  function handleSearch(search) {
    dispatch(fetchOperators({ search }));
  }

  function handleSave(data, edit) {
    return new Promise((resolve, reject) => {
      dispatch(
        saveOperator({
          data,
          edit,
          success: () => {
            dispatch(fetchOperators({}));
            listRef.current.closeForm();
            resolve();
          },
          error: error => {
            reject(error);
          },
        })
      );
    });
  }

  async function handleEdit(operator) {
    const { id, states, user } = operator;

    listRef?.current.openForm();
    setTimeout(() => {
      formRef?.current?.edit({
        id,
        states,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        status: operator.status,
      });
    }, 200);
  }

  async function handleResendEmail(data) {
    setResendEmailData(data);
    setIsResendEmailModalOpen(true);
  }

  async function handleOpenRegister() {
    if (totalCount >= licenseSlots) {
      confirmRef.current.open(
        `Você não possui licenças disponíveis. Seu contrato prevê ${licenseSlots} licenças.
        Entre contato com a Qualix para aumentar as licenças ou inative operadores e/ou transportadoras para liberar licenças que estejam em desuso`,
        () => {},
        () => {},
        true
      );
      return listRef.current.closeForm();
    }

    listRef.current.openForm();
  }

  const handleOperateStatus = item => {
    const active = item?.node?.user.active;
    const action = active ? 'inativar' : 'ativar';

    if (!active && totalCount >= licenseSlots) {
      confirmRef.current.open(
        `Seu contrato prevê ${licenseSlots} licenças.
        Entre contato com a Qualix para aumentar as licenças ou inative
        operadores e/ou transportadoras para liberar licenças que estejam em desuso.`,
        () => {},
        () => {},
        true,
        'Você não possui licenças disponíveis!'
      );
      return;
    }

    confirmRef.current.open(
      `Deseja realmente ${action} o operador ${item.node?.user?.firstName} ${item.node?.user?.lastName}?`,
      () => {
        setItemInUpdate(item.node.id);
        dispatch(
          changeStatusOperator({
            data: {
              id: item.node.id,
              firstName: item.node.user.firstName,
              email: item.node.user.email,
              states: item.node.states,
              active: !active,
            },
            error: () => {
              setItemInUpdate(null);
            },
            success: () => {
              setItemInUpdate(null);
              dispatch(fetchOperators({}));
            },
          })
        );
      }
    );
  };

  function handleNextPage() {
    dispatch(
      fetchOperators({
        cursor: pageInfo.cursor,
      })
    );
  }

  useEffect(() => {
    dispatch(refreshUser({}));
    dispatch(fetchOperators({}));
  }, []);

  return (
    <>
      <TableForm
        formHeader={{
          title: 'Operadores',
          subtitle: 'Defina os operadores',
        }}
        headers={[
          {
            label: 'Nome',
            getValue: item =>
              `${item.node?.user?.firstName} ${
                item.node?.user?.lastName || ''
              }`,
          },
          { label: 'Email', getValue: item => item.node?.user?.email },
          {
            label: 'Estados',
            getValue: item => item.node?.states && item.node?.states.toString(),
          },
          {
            label: 'Situação',
            getValue: item => {
              return (
                <>
                  {item.node.status === 'CONFIRMED' ? (
                    <Label
                      type={item.node.user.active ? 'success' : 'alert'}
                      text={item.node.user.active ? 'Ativo' : 'Inativo'}
                    />
                  ) : (
                    <Label
                      type={
                        item.node.status === 'INVITED' ? 'warning' : 'alert'
                      }
                      text={
                        item.node.status === 'INVITED'
                          ? 'Convidado'
                          : 'Expirado'
                      }
                    />
                  )}

                  {(item.node.status === 'INVITED' ||
                    item.node.status === 'EXPIRED') && (
                    <button
                      type="button"
                      className="text-blue-500 text-xs ml-2"
                      onClick={() => handleResendEmail(item?.node)}
                    >
                      Reenviar
                    </button>
                  )}
                </>
              );
            },
          },
          {
            label: 'Ações',
            props: {
              align: 'center',
              width: '230px',
            },
            getValue: item => (
              <div className="flex gap-2 justify-end">
                <Button
                  compact
                  onClick={() => handleEdit(item.node)}
                  disabled={
                    item.node.status === 'INVITED' ||
                    item.node.user.active === false
                  }
                >
                  Editar
                </Button>

                <Button
                  compact
                  isLoading={load_mutation && item.node.id === itemInUpdate}
                  key={item.node.id}
                  onClick={() => handleOperateStatus(item)}
                  disabled={
                    item.node.status === 'INVITED' ||
                    item.node.status === 'EXPIRED'
                  }
                >
                  Alterar situação
                </Button>
              </div>
            ),
          },
        ]}
        labelModalOpen="Convidar operador"
        titleModal="Cadastro de operador"
        modalOverflow
        source={results}
        load={load_query}
        onSearch={handleSearch}
        ref={listRef}
        next={handleNextPage}
        hasNext={pageInfo.hasNextPage}
        onNew={handleOpenRegister}
      >
        <OperatorForm
          formRef={formRef}
          onSubmit={handleSave}
          loadMutation={load_mutation}
        />
      </TableForm>
      <ResendEmailForm
        isResendEmailModalOpen={isResendEmailModalOpen}
        setIsResendEmailModalOpen={setIsResendEmailModalOpen}
        resendEmailData={resendEmailData}
        invitationRole="OPERATOR"
      />
      <Confirm ref={confirmRef} />
    </>
  );
};

function mapStateToProps({ auth, operator }) {
  const { results, pageInfo, load_query, load_mutation } = operator;
  const { licenseSlots, operatorsCount, shippersCount, inviteesCount } =
    auth.licenses || {};

  return {
    results,
    pageInfo,
    load_query,
    load_mutation,
    licenseSlots,
    operatorsCount,
    shippersCount,
    inviteesCount,
  };
}

export default connect(mapStateToProps)(Operator);
