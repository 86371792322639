import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { TableForm, Button, Input } from '@components';
import moment from 'moment';
import { terminateIncident } from '@redux/ducks/incident';
import { permissions } from '@config/permission';

const DetailIncident = ({
  source,
  invoiceKey,
  onTerminate,
  load_mutation,
  load_query,
}) => {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    incident: undefined,
    message: '',
  });

  const onTerminateIncident = () => {
    const { incident, message } = state;
    dispatch(
      terminateIncident({
        incident,
        message,
        success: () => {
          setState({
            incident: undefined,
            message: '',
          });
          onTerminate(invoiceKey);
        },
      })
    );
  };

  const loadIncidentToTerminate = source => {
    const incident = source.find(element => {
      return !element.end;
    });
    setState({ incident, message: '' });
  };

  useEffect(() => {
    loadIncidentToTerminate(source);
  }, []);

  return (
    <div className="p-3">
      {permissions.invoice?.terminateIncident() && state.incident && (
        <div className="flex space-x-3 items-center">
          <div className="w-[600px]">
            <Input
              value={state.message}
              onChange={({ target }) => {
                setState(prev => ({ ...prev, message: target.value }));
              }}
              placeholder="Instruções para o motorista."
            />
          </div>
          <div>
            <Button onClick={onTerminateIncident} isLoading={load_mutation}>
              Encerrar ocorrência
            </Button>
          </div>
        </div>
      )}
      <TableForm
        headers={[
          {
            label: 'Usuário',
            getValue: item =>
              item.driver
                ? `${item.driver?.firstName} ${item.driver?.lastName}`
                : 'Gerado pelo portal',
          },
          {
            label: 'Telefone',
            getValue: item => item?.driver?.user?.phone || 'Gerado pelo portal',
          },
          { label: 'Descrição', getValue: item => item.type?.description },
          {
            label: 'Aberto em',
            getValue: item =>
              item.start && moment(item.start).format('DD/MM/YYYY - HH:mm'),
          },
          {
            label: 'Encerrado',
            getValue: item => {
              return item.closedBy
                ? `${item.closedBy?.firstName || ''} ${
                    item.closedBy?.lastName || ''
                  } ${item.end && moment(item.end).format('DD/MM/YYYY-HH:mm')}`
                : '';
            },
          },
        ]}
        source={source}
        load={load_query}
        keyRow={item => item.id}
      />
    </div>
  );
};

function mapStateToProps(state) {
  let { load_query, load_mutation } = state.incident;

  return {
    load_query,
    load_mutation,
  };
}

export default connect(mapStateToProps)(DetailIncident);
