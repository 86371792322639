export const Types = {
  SEND_EMAIL: 'changePassword/SEND_EMAIL',
  CHECK_TOKEN: 'changePassword/CHECK_TOKEN',
  SET_TOKEN_VALID: 'changePassword/SET_TOKEN_VALID',
  CHANGE_PASSWORD: 'changePassword/CHANGE_PASSWORD',
};

const initialState = {
  validatedToken: undefined,
};

export default function changePassword(state = initialState, action) {
  switch (action.type) {
    case Types.SET_TOKEN_VALID:
      return {
        ...state,
        validatedToken: action.token,
      };
    default:
      return state;
  }
}

export function sendEmail({ data, success, error }) {
  return {
    type: Types.SEND_EMAIL,
    data,
    success,
    error,
  };
}

export function checkToken({ data, success, error }) {
  return {
    type: Types.CHECK_TOKEN,
    data,
    success,
    error,
  };
}

export function sendNewPassword({ data, success, error }) {
  return {
    type: Types.CHANGE_PASSWORD,
    data,
    success,
    error,
  };
}
