import { client, upload } from '@config/apollo';
import gql from 'graphql-tag';
import { call, put, select } from 'redux-saga/effects';
import { Types } from '../ducks/reversal';
import { Types as ToastTypes } from '../ducks/toast';

export function* createReversal(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const {
      invoiceKey,
      invoiceId,
      items,
      reason,
      note,
      representativeFreight,
      representativeTaxSubstitution,
      files,
    } = action.data;

    const reversalItems = items.map((item, index) => {
      return parseInt(action.data[`item:${index}`] || 0);
    });

    yield put({ type: Types.LOAD_MUTATION, load: true });

    const response = yield call(cli.mutate, {
      mutation: gql`
        mutation (
          $data: ReversalInput
          $invoiceKey: String!
          $reversalItems: [Float!]!
        ) {
          createReversal(data: $data) {
            id
          }
          reverseInvoiceItems(invoiceKey: $invoiceKey, data: $reversalItems) {
            ok
          }
        }
      `,
      variables: {
        data: {
          invoice: invoiceId,
          reason,
          note,
          charges: {
            representativeFreight,
            representativeTaxSubstitution,
          },
        },
        invoiceKey,
        reversalItems,
      },
    });

    if (files) {
      const formData = new FormData();
      formData.append('id', response.data.createReversal.id);
      formData.append('customerDocument', files[0]);
      yield upload(auth.token, `/uploads/reversals/${invoiceKey}`, formData);
    }

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Devolução da nota fiscal criada com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* effectReversal(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { reversalId, bdNumber, _bdValue, freightForCompany, _freightValue } =
      action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($id: String!, $data: ReversalUpdateInput) {
          updateReversal(id: $id, data: $data) {
            ok
          }
        }
      `,
      variables: {
        id: reversalId,
        data: {
          bdNumber,
          bdValue: _bdValue.floatValue,
          charges: {
            freightForCompany,
          },
          freightValue: _freightValue.floatValue,
          status: 'REALIZED',
        },
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Devolução da nota fiscal efetivada com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* removeReversal(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { reversalId } = action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation ($id: String!) {
          removeReversal(id: $id) {
            ok
          }
        }
      `,
      variables: {
        id: reversalId,
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Devolução cancelada com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* getReversalDetails(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    yield put({ type: Types.LOAD_QUERY, load: true });
    const { id } = action;

    const response = yield call(cli.query, {
      query: gql`
        query ($id: String) {
          reversal(id: $id) {
            id
            invoice {
              key
              invoiceNo
              customer {
                code
                commercialName
                corporateName
              }
              activities {
                emittedAt
              }
              dispatcher {
                code
                company {
                  corporateName
                }
              }
            }
            reason {
              description
            }
            note
            charges {
              freightForCompany
              representativeFreight
              representativeTaxSubstitution
            }
            customerDocument
            bdNumber
            bdValue
            freightValue
            status
            reversedBy {
              firstName
              lastName
            }
            reversedAt
          }
        }
      `,
      variables: {
        id,
      },
    });

    const { reversal } = response.data;

    yield put({ type: Types.SET_DETAIL, reversal });
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
  } finally {
    yield put({ type: Types.LOAD_QUERY, load: false });
  }
}

export function* fetchReversals(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    yield put({ type: Types.LOAD_QUERY, load: true });
    const { filter, cursor } = action;

    const response = yield call(cli.query, {
      query: gql`
        query ($filter: ReversalFilterInput!, $last: Int, $before: String) {
          reversals(filter: $filter, last: $last, before: $before) {
            edges {
              node {
                id
                invoice {
                  key
                  invoiceNo
                  customer {
                    code
                    corporateName
                    commercialName
                  }
                  activities {
                    emittedAt
                  }
                }
                reason {
                  description
                }
                note
                charges {
                  freightForCompany
                  representativeFreight
                  representativeTaxSubstitution
                }
                customerDocument
                bdNumber
                bdValue
                freightValue
                status
                reversedBy {
                  firstName
                  lastName
                }
                reversedAt
              }
            }
            pageInfo {
              hasNextPage
              before
            }
          }
        }
      `,
      variables: {
        last: 50,
        before: cursor,
        filter: {
          ...filter,
          companyId: auth.company?.id,
        },
      },
    });

    const pageInfo = response.data.reversals.pageInfo;
    const results = response.data.reversals.edges;

    yield put({
      type: Types.SET_PAGE_INFO,
      pageInfo: {
        ...pageInfo,
        cursor: pageInfo.before,
      },
    });

    if (action.cursor) {
      yield put({ type: Types.NEXT_RESULTS, results });
    } else {
      yield put({ type: Types.SET_RESULTS, results });
    }
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
  } finally {
    yield put({ type: Types.LOAD_QUERY, load: false });
  }
}
