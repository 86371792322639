import { FormCreate } from '@components';
import validate from './validate';
import moment from 'moment';
const Form = FormCreate('RedeliveryForm', validate);

const RedeliveryFormComponent = ({ params, goBack, submit, ...rest }) => {
  const fields = () => {
    return [
      {
        name: 'invoiceNo',
        component: 'input',
        type: 'text',
        label: 'NF:',

        inputProps: {
          value: params.invoiceNo,
          readOnly: true,
          disabled: true,
        },
      },
      {
        name: 'invoiceKey',
        component: 'input',
        type: 'text',
        label: 'Chave NF:',
        disabled: true,
        inputProps: {
          value: params.key,
          readOnly: true,
          disabled: true,
        },
      },
      {
        name: 'redeliveryNumber',
        component: 'input',
        label: 'Reentrega número',
        edit: false,
        inputProps: {
          value: params.redeliveryNumber + 1,
          readOnly: true,
          disabled: true,
        },
      },
      {
        name: 'representativeFreight',
        component: 'toggle',
        type: 'checkbox',
        label: 'Cobrar Frete do representante:',
      },
      {
        name: 'redeliverForFree',
        component: 'toggle',
        type: 'checkbox',
        label: 'Liberar com Custo Zero:',
      },
      {
        name: 'value',
        component: 'currency',
        type: 'text',
        label: 'Valor Re-entrega',
        inputProps: { placeholder: 'Valor Re-entrega' },
      },
      [
        {
          name: 'customerDate',
          component: 'date',
          label: 'Data Agendada Cliente',
          inputProps: {
            placeholder: 'Data Agendada ',
          },
        },
        {
          name: 'deliveryPrediction',
          component: 'input',
          label: 'Previsão de entrega ',
          inputProps: {
            placeholder: 'Previsão de entrega ',
            value: moment(params.prediction).format('DD/MM/YYYY'),
            disabled: true,
          },
        },
      ],
      {
        name: 'note',
        component: 'textarea',
        label: 'Observação',
        inputProps: {
          placeholder: 'Observação',
          rows: 2,
        },
      },
    ];
  };

  return (
    <Form
      {...rest}
      edit={false}
      cancel={true}
      onCancel={goBack}
      onSubmit={submit}
      fields={fields()}
    />
  );
};

export default RedeliveryFormComponent;
