export const Types = {
  FETCH_LOGS: 'logs/FETCH_LOGS',
  SET_LOGS: 'logs/SET_LOGS',
  SET_LOADER: 'logs/SET_LOADER',
  UNSET_LOADER: 'logs/UNSET_LOADER',
};

const initialSate = { logs: [], loader: false };

export default (state = initialSate, action) => {
  switch (action.type) {
    case Types.SET_LOGS:
      return {
        ...state,
        logs: action.logs,
      };
    case Types.SET_LOADER:
      return {
        logs: [],
        loader: true,
      };
    case Types.UNSET_LOADER:
      return {
        ...state,
        loader: false,
      };
    default:
      return state;
  }
};

export const fetchLogs = filter => {
  const { from, to, operator } = filter;
  const filters = {
    createdBy: operator,
    committedAt_GREATER_EQUALS_THAN: from,
    committedAt_LOWER_EQUALS_THAN: to
  };

  return {
    type: Types.FETCH_LOGS,
    filters,
  };
};
