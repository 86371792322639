import { Route, Routes } from 'react-router-dom';
import { NotFound, ShipperIncident } from '@pages';
import Protected from '@components/Protected';

function RoutesShipperIncident() {
  return (
    <Protected>
      <Routes>
        <Route index element={<ShipperIncident />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Protected>
  );
}

export default RoutesShipperIncident;
