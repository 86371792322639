import { client } from '@config/apollo';
import gql from 'graphql-tag';
import { call, put, select } from 'redux-saga/effects';
import { Types } from '../ducks/redelivery';
import { Types as ToastTypes } from '../ducks/toast';

export function* saveRedelivery(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  const { redelivery, key } = action;

  try {
    yield call(cli.mutate, {
      mutation: gql`
        mutation ($invoiceKey: String, $redelivery: RedeliveryInput!) {
          createRedelivery(invoiceKey: $invoiceKey, data: $redelivery) {
            id
          }
        }
      `,
      variables: {
        invoiceKey: key,
        redelivery,
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Reentrega liberada com sucesso!',
      },
    });

    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  }
}

export function* getPrediction(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);
  yield put({ type: Types.LOAD_QUERY, load: true });
  try {
    const { key } = action;
    const response = yield call(cli.query, {
      query: gql`
        query ($key: String!) {
          getDeliveryPrediction(invoiceKey: $key) {
            result
          }
        }
      `,
      variables: {
        key,
      },
    });

    const { result } = response.data.getDeliveryPrediction;

    yield put({ type: Types.SET_PREDICTION, prediction: result });
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
  } finally {
    yield put({ type: Types.LOAD_QUERY, load: false });
  }
}

export function* getRedeliveries(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);
  const { filter, pageInfo } = action;

  const { before } = pageInfo;

  yield put({ type: Types.LOAD_QUERY, load: true });
  try {
    const response = yield call(cli.query, {
      query: gql`
        query ($last: Int, $before: String, $filter: RedeliveryFilterInput) {
          redeliveries(last: $last, before: $before, filter: $filter) {
            edges {
              node {
                id
                invoice {
                  customer {
                    code
                    corporateName
                    commercialName
                  }
                  activities {
                    predictedTo
                  }
                  invoiceNo
                  key
                }
                shipper {
                  code
                  company {
                    corporateName
                  }
                }
                number
                note
                representativeFreight
                redeliverForFree
                value
                customerDate
                redeliveredAt
              }
            }
            pageInfo {
              hasNextPage
              after
              before
            }
          }
        }
      `,
      variables: {
        last: 50,
        before,
        filter: {
          ...filter,
          companyId: auth.company?.id,
        },
      },
    });

    const pageInfoResponse = response.data.redeliveries.pageInfo;
    const results = response.data.redeliveries.edges;

    yield put({
      type: Types.SET_PAGE_INFO,
      pageInfo: { ...pageInfoResponse, before: pageInfoResponse.before },
    });

    if (before) {
      yield put({ type: Types.NEXT_RESULTS, results });
    } else {
      yield put({ type: Types.SET_RESULTS, results });
    }
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
  } finally {
    yield put({ type: Types.LOAD_QUERY, load: false });
  }
}
