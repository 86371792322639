import moment from 'moment';

export const Types = {
  SET_RESULTS: 'overview/SET_RESULTS',
  SET_DATA_OBJECT: 'overview/SET_DATA_OBJECT',
  SET_PROP_DATA_OBJECT: 'overview/SET_PROP_DATA_OBJECT',
  SET_ERROR_QUERY: 'overview/SET_ERROR_QUERY',
  SET_ERROR_MUTATION: 'overview/SET_ERROR_MUTATION',
  SET_SUCCESS_QUERY: 'overview/SET_SUCCESS_QUERY',
  SET_SUCCESS_MUTATION: 'overview/SET_SUCCESS_MUTATION',
  LOAD_QUERY: 'overview/LOAD_QUERY',
  LOAD_MUTATION: 'overview/LOAD_MUTATION',
  FETCH_OVERVIEW: 'overview/FETCH_OVERVIEW',
  USER_LOGOUT: 'USER_LOGOUT',
};

const initialState = {
  results: [],
  dataObject: {},
  load_query: false,
  load_mutation: false,
  error_query: {},
  error_mutation: {},
  success_query: {},
  success_mutation: {},
};

export default function message(state = initialState, action) {
  switch (action.type) {
    case Types.SET_RESULTS:
      return { ...state, results: action.results };
    case Types.SET_DATA_OBJECT:
      return { ...state, dataObject: action.dataObject };
    case Types.SET_PROP_DATA_OBJECT:
      return {
        ...state,
        dataObject: { ...state.dataObject, ...action.data },
        error_mutation: {},
        success_mutation: {},
      };
    case Types.SET_ERROR_QUERY:
      return { ...state, error_query: action.error };
    case Types.SET_ERROR_MUTATION:
      return { ...state, error_mutation: action.error };
    case Types.SET_SUCCESS_QUERY:
      return { ...state, success_query: action.success };
    case Types.SET_SUCCESS_MUTATION:
      return { ...state, success_mutation: action.success };
    case Types.LOAD_QUERY:
      return { ...state, load_query: action.load };
    case Types.LOAD_MUTATION:
      return { ...state, load_mutation: action.load };
    case Types.USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export function setDataObject(dataObject) {
  return {
    type: Types.SET_DATA_OBJECT,
    dataObject,
  };
}

export function setPropDataObject(data) {
  return {
    type: Types.SET_PROP_DATA_OBJECT,
    data,
  };
}

function _dateFrom(value) {
  return moment(value).format('YYYY-MM-DD') + 'T00:00:00.000Z';
}

function _dateTo(value) {
  return moment(value).format('YYYY-MM-DD') + 'T23:59:59.999Z';
}

function _getDefaultFilter(filter = {}, params) {
  const isDispatcherId = params?.dispatcher?.length > 14;

  if (params) {
    if (params.dispatcher && isDispatcherId) {
      filter = {
        ...filter,
        OR: [
          {
            redispatcherId: params.dispatcher,
          },
          {
            dispatcherId: params.dispatcher,
            redispatcherId_NOT_EQUALS: null,
            OR: [
              { shippingOrderId: null },
              { shippingOrder_WITH: { deliveredAt: null } },
            ],
          },
          {
            dispatcherId: params.dispatcher,
            redispatcherId: null,
          },
        ],
      };
    }

    if (params.customer) {
      filter = {
        ...filter,
        customer_WITH: {
          OR: [
            {
              code: params.customer,
            },
            {
              commercialName_LIKE: params.customer,
            },
            {
              corporateName_LIKE: params.customer,
            },
          ],
        },
      };
    }
  }

  if (params.emittedFrom || params.emittedTo) {
    if (params.emittedFrom) {
      const emittedFrom = _dateFrom(params.emittedFrom);
      filter = {
        ...filter,
        emittedAt_GREATER_EQUALS_THAN: emittedFrom,
      };
    }

    if (params.emittedTo) {
      const emittedTo = _dateTo(params.emittedTo);
      filter = {
        ...filter,
        emittedAt_LOWER_EQUALS_THAN: emittedTo,
      };
    }
  }

  if (params.companyId) {
    filter = {
      ...filter,
      companyId: params.companyId,
    };
  }

  return filter;
}

export function fetchOverview(params) {
  const filter = _getDefaultFilter(undefined, params);

  return {
    type: Types.FETCH_OVERVIEW,
    filter,
  };
}
