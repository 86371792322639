import moment from 'moment';
export const Types = {
  CREATE_EXPENSE: 'expense/CREATE_EXPENSE',
  EDIT_EXPENSE: 'expense/EDIT_EXPENSE',
  SET_RESULTS: 'expense/SET_RESULTS',
  NEXT_RESULTS: 'expense/NEXT_RESULTS',
  SET_PAGE_INFO: 'expense/SET_PAGE_INFO',
  SET_ERROR_QUERY: 'expense/SET_ERROR_QUERY',
  SET_ERROR_MUTATION: 'expense/SET_ERROR_MUTATION',
  SET_SUCCESS_QUERY: 'expense/SET_SUCCESS_QUERY',
  SET_SUCCESS_MUTATION: 'expense/SET_SUCCESS_MUTATION',
  LOAD_QUERY: 'expense/LOAD_QUERY',
  LOAD_MUTATION: 'expense/LOAD_MUTATION',
  FETCH_EXPENSE: 'expense/FETCH_EXPENSE',
  GET_EXPENSE: 'expense/GET_EXPENSE',
  RESET_STATE: 'expense/RESET_STATE',
  USER_LOGOUT: 'USER_LOGOUT',
};

const initialState = {
  results: [],
  pageInfo: {},
  load_query: false,
  load_mutation: false,
  error_query: {},
  error_mutation: {},
  success_query: {},
  success_mutation: {},
};

export default function expense(state = initialState, action) {
  switch (action.type) {
    case Types.SET_RESULTS:
      return { ...state, results: action.results };
    case Types.NEXT_RESULTS:
      return { ...state, results: [...state.results, ...action.results] };
    case Types.SET_PAGE_INFO:
      return { ...state, pageInfo: action.pageInfo };
    case Types.SET_ERROR_QUERY:
      return { ...state, error_query: action.error };
    case Types.SET_ERROR_MUTATION:
      return { ...state, error_mutation: action.error };
    case Types.SET_SUCCESS_QUERY:
      return { ...state, success_query: action.success };
    case Types.SET_SUCCESS_MUTATION:
      return { ...state, success_mutation: action.success };
    case Types.LOAD_QUERY:
      return { ...state, load_query: action.load };
    case Types.LOAD_MUTATION:
      return { ...state, load_mutation: action.load };
    case Types.RESET_STATE:
    case Types.USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export function createExpense({ data, edit, success, error }) {
  return {
    type: edit === true ? Types.EDIT_EXPENSE : Types.CREATE_EXPENSE,
    data,
    success,
    error,
  };
}

export function fetchExpense({
  cursor,
  type,
  to,
  from,
  status,
  invoice,
  shipper,
}) {
  let filter = {};
  const dateTo = _dateTo(to);
  const dataFrom = _dateFrom(from);

  switch (status) {
    case 1:
      filter = {
        createdAt_GREATER_EQUALS_THAN: dataFrom,
        createdAt_LOWER_EQUALS_THAN: dateTo,
        approved: null,
        rejectedAt: null,
        approvedAt: null,
      };
      break;
    case 2:
      filter = {
        approvedAt_GREATER_EQUALS_THAN: dataFrom,
        approvedAt_LOWER_EQUALS_THAN: dateTo,
        approved: true,
      };
      break;
    case 3:
      filter = {
        rejectedAt_GREATER_EQUALS_THAN: dataFrom,
        rejectedAt_LOWER_EQUALS_THAN: dateTo,
        approved: false,
      };
      break;
    case 4:
      filter = {
        rejectedAt: null,
        approvedAt: null,
      };
      break;
    case '':
      filter = {
        createdAt_GREATER_EQUALS_THAN: dataFrom,
        createdAt_LOWER_EQUALS_THAN: dateTo,
      };
      break;
    default:
      break;
  }

  if (invoice) filter = { ...filter, invoiceId: invoice };
  if (shipper) filter = { ...filter, shipperId: shipper };
  if (type) filter = { ...filter, typeId: type };

  return {
    type: Types.FETCH_EXPENSE,
    cursor,
    filter,
  };
}

export function getExpense({ id, success, error }) {
  return {
    type: Types.GET_EXPENSE,
    id,
    success,
    error,
  };
}

export function editExpense({ id, data, success, error }) {
  return {
    type: Types.EDIT_EXPENSE,
    id,
    data,
    success,
    error,
  };
}

export function resetState() {
  return {
    type: Types.RESET_STATE,
  };
}

function _dateFrom(value) {
  return moment(value).format('YYYY-MM-DD') + 'T00:00:00.000Z';
}

function _dateTo(value) {
  return moment(value).format('YYYY-MM-DD') + 'T23:59:59.999Z';
}
