import validate from './validate';
import { FormCreate } from '@components';
import GetInfoCnpj from '@utils/GetInfoCnpj';

const Form = FormCreate('LogisticCenterForm', validate);

const number = value => value && value.replace(/[^0-9]/g, '');

function LogisticCenterForm({ getCities, refreshFormData, ...rest }) {
  async function handleGetDataCnpj(cnpj) {
    const response = await GetInfoCnpj(cnpj);
    if (response && response.data) {
      const { razao_social } = response.data;
      if (refreshFormData) {
        refreshFormData({
          name: razao_social,
        });
      }
    }
  }

  return (
    <Form
      {...rest}
      fields={[
        {
          name: 'cnpj',
          component: 'input',
          edit: false,
          type: 'text',
          label: 'CNPJ:',
          inputProps: {
            mask: '99.999.999/9999-99',
            placeholder:
              'Informe o CNPJ da empresa no formato 00.000.000/0000-00',
            onBlur: e => handleGetDataCnpj(e.target.value),
          },
          normalize: number,
        },
        {
          name: 'name',
          component: 'input',
          type: 'text',
          label: 'Nome:',
          inputProps: {
            placeholder: 'Informe o nome',
          },
        },
        {
          name: 'cityName',
          component: 'search',
          type: 'search',
          label: 'Cidade:',
          inputProps: {
            keyExtractor: item => item.node.code,
            textExtractor: item => item.node.name,
            onSearch: search => {
              return new Promise((resolve, reject) => {
                getCities({
                  search,
                  success: results => {
                    resolve(results);
                  },
                  error: error => {
                    reject(error);
                  },
                });
              });
            },
            onSelect: (item, reduxFormChange) => {
              reduxFormChange('cityName', item.node.name);
              reduxFormChange('cityCode', item.node.code);
            },
          },
        },
        {
          name: 'cityCode',
          component: 'input',
          type: 'text',
          label: 'Cidade:',
          display: 'none',
        },
      ]}
    />
  );
}

export default LogisticCenterForm;
