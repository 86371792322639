import { client } from '@config/apollo';
import gql from 'graphql-tag';
import { call, put } from 'redux-saga/effects';
import { Types } from '../ducks/changePassword';

export function* handleSendEmail(action) {
  const cli = yield call(client);
  const { email } = action.data;

  try {
    yield call(cli.mutate, {
      mutation: gql`
        mutation requestPasswordChangeByEmaik($email: String!) {
          requestPasswordChangeByEmail(email: $email) {
            ok
          }
        }
      `,
      variables: {
        email,
      },
    });

    if (action.success) action.success('Solicitação feita com sucesso');
  } catch (error) {
    if (action.error) action.error(error);
  }
}

export function* handleCheckToken(action) {
  const cli = yield call(client);
  const { token } = action.data;

  try {
    const response = yield call(cli.mutate, {
      mutation: gql`
        mutation validateResetPasswordToken($token: String!) {
          validateResetPasswordToken(token: $token)
        }
      `,
      variables: {
        token,
      },
    });

    const isTokenValid = response.data.validateResetPasswordToken;

    if (isTokenValid) {
      yield put({
        type: Types.SET_TOKEN_VALID,
        token,
      });
    }

    if (action.success) action.success({ isTokenValid });
  } catch (error) {
    if (action.error) action.error(error);
  }
}

export function* handleSendNewPassword(action) {
  const cli = yield call(client);
  const { newPassword, passwordConfirmation, validatedToken } = action.data;

  try {
    yield call(cli.mutate, {
      mutation: gql`
        mutation confirmPasswordChange(
          $token: String!
          $newPassword: String!
          $passwordConfirmation: String!
        ) {
          confirmPasswordChange(
            token: $token
            newPassword: $newPassword
            passwordConfirmation: $passwordConfirmation
          ) {
            ok
          }
        }
      `,
      variables: {
        token: validatedToken,
        newPassword,
        passwordConfirmation,
      },
    });

    if (action.success)
      action.success(
        'Senha redefinida com sucesso! Você será redirecionado para o Login'
      );
  } catch (error) {
    if (action.error) action.error(error);
  }
}
