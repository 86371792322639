export const Types = {
  CREATE_DRIVER: 'driver/CREATE_DRIVER',
  UPDATE_DRIVER: 'driver/UPDATE_DRIVER',
  SET_RESULTS: 'driver/SET_RESULTS',
  NEXT_RESULTS: 'driver/NEXT_RESULTS',
  SET_PAGE_INFO: 'driver/SET_PAGE_INFO',
  SET_ERROR_QUERY: 'driver/SET_ERROR_QUERY',
  SET_ERROR_MUTATION: 'driver/SET_ERROR_MUTATION',
  SET_SUCCESS_QUERY: 'driver/SET_SUCCESS_QUERY',
  SET_SUCCESS_MUTATION: 'driver/SET_SUCCESS_MUTATION',
  LOAD_QUERY: 'driver/LOAD_QUERY',
  LOAD_MUTATION: 'driver/LOAD_MUTATION',
  FETCH_DRIVERS: 'driver/FETCH_DRIVERS',
  FETCH_DRIVERS_BY_SHIPPER: 'driver/FETCH_DRIVERS_BY_SHIPPER',
  GET_DRIVERS: 'driver/GET_DRIVERS',
  RESET_STATE: 'driver/RESET_STATE',
  USER_LOGOUT: 'USER_LOGOUT',
  REMOVE_DRIVER: 'REMOVER_DRIVE',
  REMOVE_DRIVER_HANDLE: 'REMOVE_DRIVER_HANDLE',
};

const initialState = {
  results: [],
  pageInfo: {},
  load_query: false,
  load_mutation: false,
  error_query: {},
  error_mutation: {},
  success_query: {},
  success_mutation: {},
};

export default function driver(state = initialState, action) {
  switch (action.type) {
    case Types.SET_RESULTS:
      return { ...state, results: action.results };
    case Types.NEXT_RESULTS:
      return { ...state, results: [...state.results, ...action.results] };
    case Types.SET_PAGE_INFO:
      return { ...state, pageInfo: action.pageInfo };
    case Types.SET_ERROR_QUERY:
      return { ...state, error_query: action.error };
    case Types.SET_ERROR_MUTATION:
      return { ...state, error_mutation: action.error };
    case Types.SET_SUCCESS_QUERY:
      return { ...state, success_query: action.success };
    case Types.SET_SUCCESS_MUTATION:
      return { ...state, success_mutation: action.success };
    case Types.LOAD_QUERY:
      return { ...state, load_query: action.load };
    case Types.LOAD_MUTATION:
      return { ...state, load_mutation: action.load };
    case Types.REMOVE_DRIVER_HANDLE:
      // eslint-disable-next-line no-case-declarations
      const results = state.results.filter(item => item.node.id !== action.id);
      return {
        ...state,
        results,
      };
    case Types.RESET_STATE:
    case Types.USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export function removeDriver(id) {
  return {
    type: Types.REMOVE_DRIVER,
    id,
  };
}

export function saveDriver({ data, edit, success, error }) {
  return {
    type: edit === true ? Types.UPDATE_DRIVER : Types.CREATE_DRIVER,
    data,
    success,
    error,
  };
}

export function fetchDrivers({ cursor, id, search }) {
  let filter;
  if (id) {
    filter = {
      shipper_WITH: {
        id: id,
      },
    };
  }

  if (search && search.length > 0) {
    filter = {
      ...filter,
      OR: [
        { plate_LIKE: search },
        { firstName_LIKE: search },
        { lastName_LIKE: search },
      ],
    };
  }

  return {
    type: Types.FETCH_DRIVERS,
    cursor,
    filter,
  };
}



export function fetchDriversByShipper({ cursor, shipperCnpj, search }) {
  let filter;


  if (search && search.length > 0) {
    filter = {
      ...filter,
      OR: [
        { plate_LIKE: search },
        { firstName_LIKE: search },
        { lastName_LIKE: search },
      ],
    };
  }

  return {
    type: Types.FETCH_DRIVERS_BY_SHIPPER,
    shipperCnpj,
    cursor,
    filter,
  };
}


export function getDrivers({ search, success, error }) {
  let filter = {};
  if (search && search.length > 0) {
    filter = {
      ...filter,
      OR: [
        { shipperDrivers_WITH: { plate_LIKE: search } },
        { firstName_LIKE: search },
        { lastName_LIKE: search },
      ],
    };
  }

  return {
    type: Types.GET_DRIVERS,
    filter,
    success,
    error,
  };
}

export function resetState() {
  return {
    type: Types.RESET_STATE,
  };
}
