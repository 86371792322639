import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from '@redux-devtools/extension';

import sagas from '@redux/sagas';
import reducers from '@redux/ducks';

const rootReducer = persistReducer(
  {
    key: 'root',
    storage,
    whitelist: ['auth'],
  },
  reducers
);

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  rootReducer,
  undefined,
  composeWithDevTools(applyMiddleware(sagaMiddleware))
);

export const persistor = persistStore(store);

sagaMiddleware.run(sagas, store.dispatch);
