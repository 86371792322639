const validate = values => {
  const errors = {};
  if (!values.shipper) {
    errors.shipper = 'Favor selecionar uma transportadora!';
  }

  if (!values.redispatch) {
    errors.redispatch =
      'Favor informar o numero de dias para entrega com redespacho!';
  }

  return errors;
};

export default validate;
