import { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { fetchAlerts, saveAlert, resetState } from '@redux/ducks/alert';
import { Tabs } from '@components';
import AlertList from './components/AlertList';
import AlertForm from './components/AlertForm';

const Alert = props => {
  const { results, pageInfo, load_query } = props;
  const [queryType, setQueryType] = useState('pending');
  const dispatch = useDispatch();
  const listRef = useRef(null);
  const formRef = useRef(null);

  function initialize() {
    const search = listRef.current.getSearch();
    window.scroll(0, 0);
    listRef.current.closeForm();
    dispatch(fetchAlerts({ search, filter: queryType }));
  }

  function onSearch(search) {
    dispatch(
      fetchAlerts({
        search,
        filter: queryType,
      })
    );
  }

  function handleNextPage() {
    const search = listRef.current.getSearch();
    dispatch(
      fetchAlerts({
        cursor: pageInfo.before,
        search,
        filter: queryType,
      })
    );
  }

  function onSave(data, edit) {
    return new Promise((resolve, reject) => {
      dispatch(
        saveAlert({
          data,
          edit,
          success: () => {
            initialize();
            resolve();
          },
          error: error => {
            reject(error);
          },
        })
      );
    });
  }

  async function edit(importalert) {
    await listRef.current.openForm();
    const { id, error, resolution } = importalert;
    setTimeout(() => {
      formRef?.current?.edit({
        id,
        error,
        resolution,
      });
    }, 200);
  }

  const Filters = () => (
    <Tabs>
      <Tabs.Item
        active={queryType === 'pending'}
        onClick={() => setQueryType('pending')}
      >
        Pendentes
      </Tabs.Item>
      <Tabs.Item
        active={queryType === 'terminated'}
        onClick={() => setQueryType('terminated')}
      >
        Encerradas
      </Tabs.Item>
    </Tabs>
  );

  useEffect(() => {
    dispatch(resetState());
    initialize();
  }, [queryType]);

  return (
    <AlertList
      formHeader={{
        title: 'Alertas',
        subtitle: 'Gerencie e visualize os alertas internos do sistema',
      }}
      titleModal="Encerrar alerta"
      filters={Filters}
      filter={queryType}
      source={results}
      hasNext={pageInfo.hasNextPage}
      load={load_query}
      next={handleNextPage}
      onSearch={onSearch}
      ref={listRef}
      handleEdit={item => edit(item)}
      btnNew={false}
    >
      <AlertForm edit={true} formRef={formRef} onSubmit={onSave} />
    </AlertList>
  );
};

function mapStateToProps({ importalert }) {
  const { results, pageInfo, load_query } = importalert;
  return {
    results,
    pageInfo,
    load_query,
  };
}

export default connect(mapStateToProps)(Alert);
