import { connect, useDispatch } from 'react-redux';
import { getTypes } from '@redux/ducks/incidentType';
import { Modal } from '@components';
import { saveIncident } from '@redux/ducks/incident';
import { FormCreate } from '@components';

const Form = FormCreate('ShipperIncidentForm');

const ShipperIncidentForm = ({ open, onClose, clearData, data, ...rest }) => {
  const dispatch = useDispatch();
  const cnpj = data?.company?.cnpj;
  const externalDispatcher = data?.externalDispatcher;
  const hasExternalDispatcher = externalDispatcher ? true : false;

  const onSubmit = props => {
    const { key: invoiceKey } = data;
    const { incidentTypeId: type, incidentNote: note } = props;
    const start = new Date();

    return new Promise((resolve, reject) => {
      dispatch(
        saveIncident({
          data: {
            type,
            invoiceKey,
            start,
            note,
          },
          success: () => {
            onClose();
            clearData();
          },
          error: error => {
            reject(error);
          },
        })
      );
    });
  };

  return (
    <Modal open={open} onClose={onClose} title="Gerar ocorrência">
      <Form
        {...rest}
        onSubmit={onSubmit}
        fields={[
          {
            name: 'incidentType',
            component: 'search',
            type: 'search',
            label: 'Ocorrência:',
            edit: false,
            inputProps: {
              disabled: hasExternalDispatcher,
              keyExtractor: item => item.id,
              textExtractor: item => item.description,
              onSearch: () => {
                return new Promise((resolve, reject) => {
                  dispatch(
                    getTypes({
                      cnpj,
                      success: results => {
                        resolve(results);
                      },
                      error: error => {
                        reject(error);
                      },
                    })
                  );
                });
              },
              onSelect: (item, reduxFormChange) => {
                reduxFormChange('incidentType', item.description);
                reduxFormChange('incidentTypeId', item.id);
              },
            },
          },
          {
            name: 'incidentNote',
            label: 'Observação',
            inputProps: {
              disabled: hasExternalDispatcher,
            },
            edit: true,
            onClick: (e, reduxFormChange) => {
              reduxFormChange('incidentNote', e.target.value);
            },
          },
        ]}
        lbSubmit="Confirmar"
      />
    </Modal>
  );
};

function mapStateToProps(state) {
  let { load_mutation } = state.incident;

  return {
    load_mutation,
  };
}

export default connect(mapStateToProps)(ShipperIncidentForm);
