import { FormCreate } from '@components';
import validate from './validate';

const Form = FormCreate('ExpenseTypeForm', validate, {
  active: true,
  states: [],
  receipt: false
});

const ExpenseTypeForm = props => {

  return (
    <Form
      {...props}
      fields={[
        {
          name: 'description',
          component: 'input',
          type: 'text',
          label: 'Descrição:',
          inputProps: {
            placeholder: 'Informe a descrição',
          },
        },
        {
          name: 'receipt',
          component: 'toggle',
          type: 'checkbox',
          label: 'Informar comprovante:',
        },
        {
          name: 'active',
          component: 'toggle',
          type: 'checkbox',
          label: 'Situação:',
        },
      ]}
    />
  );
};

export default ExpenseTypeForm;
