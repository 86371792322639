import { client } from '@config/apollo';
import gql from 'graphql-tag';
import { call, put, select } from 'redux-saga/effects';

import { Types } from '../ducks/webhook';
import { Types as ToastTypes } from '../ducks/toast';

export function* createSecret(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { configuration } = action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation createWebhookConfiguration(
          $configuration: WebhookConfigurationInput
        ) {
          createWebhookConfiguration(configuration: $configuration) {
            ok
          }
        }
      `,
      variables: {
        configuration,
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Segredo criado com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* updateSecret(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { configurationId, configuration } = action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation updateWebhookConfiguration(
          $configurationId: String!
          $configuration: WebhookConfigurationUpdateInput
        ) {
          updateWebhookConfiguration(
            configurationId: $configurationId
            configuration: $configuration
          ) {
            ok
          }
        }
      `,
      variables: {
        configurationId,
        configuration,
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Segredo alterado com sucesso',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* createWebhook(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { subject, url } = action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation createWebhook($subject: String!, $url: String!) {
          createWebhook(subject: $subject, url: $url) {
            ok
          }
        }
      `,
      variables: {
        subject,
        url,
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Webhook criado com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* removeWebhook(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { subject } = action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation RemoveWebhook($subject: String!) {
          removeWebhook(subject: $subject) {
            ok
          }
        }
      `,
      variables: {
        subject,
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Webhook removido com sucesso!',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* updateWebhook(action) {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    const { subject, url } = action.data;

    yield put({ type: Types.LOAD_MUTATION, load: true });

    yield call(cli.mutate, {
      mutation: gql`
        mutation updateWebhook($subject: String!, $url: String!) {
          updateWebhook(subject: $subject, url: $url) {
            ok
          }
        }
      `,
      variables: {
        subject,
        url,
      },
    });

    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'success',
        message: 'Webhook alterado com sucesso',
      },
    });
    if (action.success) action.success();
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
    if (action.error) action.error(error);
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* fetchWebhooks() {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    yield put({ type: Types.LOAD_QUERY, load: true });

    const response = yield call(cli.query, {
      query: gql`
        query webhookConfiguration {
          webhookConfiguration {
            id
            secret
            webhooks {
              id
              subject
              url
            }
          }
        }
      `,
    });

    const results = response.data.webhookConfiguration;

    yield put({ type: Types.SET_RESULTS, results });
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
  } finally {
    yield put({ type: Types.LOAD_QUERY, load: false });
  }
}
