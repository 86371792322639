import React, { useState } from 'react';
import { Tabs } from '@components';

import ExpensePending from '@pages/Expenses/Accompaniments/Pending';
import ExpenseEffect from '@pages/Expenses/Accompaniments/Effect';

const ExpenseAccompaniment = () => {
  const [isPending, setIsPending] = useState(true);

  return (
    <React.Fragment>
      <div className="sm:flex sm:items-center mb-4">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-4 text-gray-800">
            Acompanhamento de despesas
          </h1>
          <p className="mt-1 text-sm text-gray-700">
            Gerencie e visualize as despesas
          </p>
        </div>
      </div>
      <Tabs>
        <Tabs.Item
          name="pending"
          active={isPending}
          onClick={() => setIsPending(true)}
        >
          Pendentes
        </Tabs.Item>
        <Tabs.Item
          name="effect"
          active={!isPending}
          onClick={() => setIsPending(false)}
        >
          Encerradas
        </Tabs.Item>
      </Tabs>
      {isPending ? <ExpensePending /> : <ExpenseEffect />}
    </React.Fragment>
  );
};

export default ExpenseAccompaniment;
