import { Hora } from '@utils';

const validate = values => {
  const errors = {};
  if (!values.data) {
    errors.data = 'Favor informar a data de chegada!';
  }
  if (!values.hora) {
    errors.hora = 'Favor informar a hora de chegada!';
  }
  if (values.hora && !Hora.validate(values.hora)) {
    errors.hora = 'Hora inválida!';
  }
  return errors;
};

export default validate;
