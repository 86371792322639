export const Types = {
  CREATE_OPERATOR: 'operator/CREATE_OPERATOR',
  UPDATE_OPERATOR: 'operator/UPDATE_OPERATOR',
  RESEND_INVITATION: 'operator/RESEND_INVITATION',
  UPDATE_STATUS_OPERATOR: 'operator/UPDATE_STATUS_OPERATOR',
  CHANGE_EMAIL_OPERATOR: 'operator/CHANGE_EMAIL_OPERATOR',
  SET_RESULTS: 'operator/SET_RESULTS',
  NEXT_RESULTS: 'operator/NEXT_RESULTS',
  SET_PAGE_INFO: 'operator/SET_PAGE_INFO',
  LOAD_QUERY: 'operator/LOAD_QUERY',
  LOAD_MUTATION: 'operator/LOAD_MUTATION',
  FETCH_OPERATORS: 'operator/FETCH_OPERATORS',
  RESET_STATE: 'operator/RESET_STATE',
  USER_LOGOUT: 'USER_LOGOUT',
};

const initialState = {
  results: [],
  pageInfo: {},
  load_query: false,
  load_mutation: false,
};

export default function operator(state = initialState, action) {
  switch (action.type) {
    case Types.SET_RESULTS:
      return { ...state, results: action.results };
    case Types.NEXT_RESULTS:
      return { ...state, results: [...state.results, ...action.results] };
    case Types.SET_PAGE_INFO:
      return { ...state, pageInfo: action.pageInfo };
    case Types.LOAD_QUERY:
      return { ...state, load_query: action.load };
    case Types.LOAD_MUTATION:
      return { ...state, load_mutation: action.load };
    case Types.RESET_STATE:
    case Types.USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export function saveOperator({ data, edit, success, error }) {
  return {
    type: edit === true ? Types.UPDATE_OPERATOR : Types.CREATE_OPERATOR,
    data,
    success,
    error,
  };
}

export function resendInvitation({ data, success, error }) {
  return {
    type: Types.RESEND_INVITATION,
    data,
    success,
    error,
  };
}

export function changeStatusOperator({ data, success, error }) {
  return {
    type: Types.UPDATE_STATUS_OPERATOR,
    data,
    success,
    error,
  };
}

export function changeEmailOperator({ data, success, error }) {
  return {
    type: Types.CHANGE_EMAIL_OPERATOR,
    data,
    success,
    error,
  };
}

export function fetchOperatorsWithoutFilter() {
  return {
    type: Types.FETCH_OPERATORS,
  };
}

export function fetchOperators({ search, cursor }) {
  let filter;
  if (search && search.length > 0) {
    filter = {
      user_WITH: {
        OR: [{ firstName_LIKE: search }, { lastName_LIKE: search }],
      },
    };
  }

  return {
    type: Types.FETCH_OPERATORS,
    cursor,
    filter,
  };
}

export function resetState() {
  return {
    type: Types.RESET_STATE,
  };
}
