export const Types = {
  CREATE_SECRET: 'webhook/CREATE_SECRET',
  UPDATE_SECRET: 'webhook/UPDATE_SECRET',
  CREATE_WEBHOOK: 'webhook/CREATE_WEBHOOK',
  UPDATE_WEBHOOK: 'webhook/UPDATE_WEBHOOK',
  REMOVE_WEBHOOK: 'webhook/REMOVE_WEBHOOK',
  SET_RESULTS: 'webhook/SET_RESULTS',
  LOAD_QUERY: 'webhook/LOAD_QUERY',
  LOAD_MUTATION: 'webhook/LOAD_MUTATION',
  FETCH_WEBHOOKS: 'webhook/FETCH_WEBHOOKS',
  RESET_STATE: 'webhook/RESET_STATE',
};

const initialState = {
  results: [],
  load_query: false,
  load_mutation: false,
};

export default function webhook(state = initialState, action) {
  switch (action.type) {
    case Types.SET_RESULTS:
      return { ...state, results: action.results };
    case Types.LOAD_QUERY:
      return { ...state, load_query: action.load };
    case Types.LOAD_MUTATION:
      return { ...state, load_mutation: action.load };
    case Types.RESET_STATE:
      return initialState;
    default:
      return state;
  }
}

export function saveSecret({ data, edit, success, error }) {
  return {
    type: edit ? Types.UPDATE_SECRET : Types.CREATE_SECRET,
    data,
    success,
    error,
  };
}

export function saveWebhook({ data, edit, success, error }) {
  return {
    type: edit ? Types.UPDATE_WEBHOOK : Types.CREATE_WEBHOOK,
    data,
    success,
    error,
  };
}

export function removeWebhook({ data, success, error }) {
  return {
    type: Types.REMOVE_WEBHOOK,
    data,
    success,
    error,
  };
}

export function fetchWebhooks() {
  return {
    type: Types.FETCH_WEBHOOKS,
  };
}

export function resetState() {
  return {
    type: Types.RESET_STATE,
  };
}
