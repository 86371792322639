import { useRef, useState } from 'react';
import { Modal, Toast } from '@components';
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowDownTrayIcon,
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
} from '@heroicons/react/20/solid';

export default function ImageCarousel({
  open,
  onClose,
  images = [],
  modalTitle = 'Visualizar imagens',
}) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1);
  const toastRef = useRef(null);

  const handleThumbnailClick = index => {
    setZoomLevel(1);
    setCurrentImageIndex(index);
  };

  const handleOnClose = () => {
    setCurrentImageIndex(0);
    setZoomLevel(1);
    onClose();
  };

  const previousImage = () => {
    setZoomLevel(1);
    setCurrentImageIndex(prevIndex =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const nextImage = () => {
    setZoomLevel(1);
    setCurrentImageIndex(prevIndex =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const increaseZoom = () => {
    setZoomLevel(prevZoom => prevZoom + 0.5);
  };

  const decreaseZoom = () => {
    if (zoomLevel > 1) {
      setZoomLevel(prevZoom => (prevZoom > 0.5 ? prevZoom - 0.5 : prevZoom));
    }
  };

  if (images && images.length === 0) {

    toastRef?.current?.show({
      title: `Não foi possível ver anexo`,
      message: `Não há anexo para ser mostrado`,
      type: 'error',
    });

    return  <Toast ref={toastRef} />
  }

  return (
    <Modal
      open={open}
      onClose={handleOnClose}
      title={modalTitle}
      containerClassSize="max-w-4xl"
    >
      {images && (
        <div className="flex flex-col justify-center items-center relative">
          <div className="flex flex-col justify-center items-center h-[70vh] w-full overflow-auto">
            <img
              src={images[currentImageIndex]}
              className="rounded-lg max-w-full max-h-full"
              style={{
                transform: `scale(${zoomLevel})`,
                transition: 'transform 0.35s ease',
                transformOrigin: 'top left',
              }}
              alt={`Imagem ${currentImageIndex}`}
            />
          </div>

          <div className="flex flex-col absolute top-3 right-3 gap-3 text-white">
            <button
              className="bg-gray-600 rounded-md p-2 bg-opacity-50"
              onClick={() => increaseZoom()}
            >
              <MagnifyingGlassPlusIcon
                className="h-6 w-6 "
                aria-hidden="true"
              />
            </button>

            <button
              className="bg-gray-600 rounded-md p-2 bg-opacity-50"
              onClick={() => decreaseZoom()}
            >
              <MagnifyingGlassMinusIcon
                className="h-6 w-6 "
                aria-hidden="true"
              />
            </button>
          </div>

          <div className="flex flex-row right-3 left-3 justify-between absolute text-white">
            <button onClick={previousImage}>
              <ChevronLeftIcon
                className="h-8 w-8 bg-gray-600 bg-opacity-50 rounded-3xl"
                aria-hidden="true"
              />
            </button>
            <button onClick={nextImage}>
              <ChevronRightIcon
                className="h-8 w-8 bg-gray-600 bg-opacity-50 rounded-3xl"
                aria-hidden="true"
              />
            </button>
          </div>

          <div className="flex justify-center gap-3 absolute bottom-5 text-white">
            {images?.map((img, index) => (
              <img
                key={index}
                src={img}
                className={`h-12 rounded-md cursor-pointer z-20 ${
                  index === currentImageIndex ? 'border-2 border-blue-500' : ''
                }`}
                onClick={() => handleThumbnailClick(index)}
                alt={`Thumbnail ${index}`}
              />
            ))}
          </div>

          <button className="bg-gray-600 rounded-md p-2 bg-opacity-50 absolute bottom-5 right-3 text-white">
            <a
              href={images && images[currentImageIndex]}
              download
              target="_blank"
              rel="noreferrer"
            >
              <ArrowDownTrayIcon className="h-6 w-6 " aria-hidden="true" />
            </a>
          </button>
        </div>
      )}
    </Modal>
  );
}
