import { Route, Routes } from 'react-router-dom';
import {
  NotFound,
  LogisticCenter,
  LogisticCenterRegions,
  LogisticCenterShippers,
} from '@pages';
import { Protected } from '@components';

function RoutesLogisticCenter() {
  return (
    <Protected>
      <Routes>
        <Route index element={<LogisticCenter />} />
        <Route path=":cnpj/regions" element={<LogisticCenterRegions />} />
        <Route path=":cnpj/region/:id" element={<LogisticCenterShippers />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Protected>
  );
}

export default RoutesLogisticCenter;
