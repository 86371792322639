export const Types = {
  CREATE_REVERSAL: 'reversal/CREATE_REVERSAL',
  UPDATE_REVERSAL: 'reversal/UPDATE_REVERSAL',
  EFFECT_REVERSAL: 'reversal/EFFECT_REVERSAL',
  REMOVE_REVERSAL: 'reversal/REMOVE_REVERSAL',
  FETCH_DETAIL: 'reversal/FETCH_DETAIL',
  SET_DETAIL: 'reversal/SET_DETAIL',
  SET_RESULTS: 'reversal/SET_RESULTS',
  NEXT_RESULTS: 'reversal/NEXT_RESULTS',
  SET_PAGE_INFO: 'reversal/SET_PAGE_INFO',
  SET_ERROR_QUERY: 'reversal/SET_ERROR_QUERY',
  SET_ERROR_MUTATION: 'reversal/SET_ERROR_MUTATION',
  SET_SUCCESS_QUERY: 'reversal/SET_SUCCESS_QUERY',
  SET_SUCCESS_MUTATION: 'reversal/SET_SUCCESS_MUTATION',
  LOAD_QUERY: 'reversal/LOAD_QUERY',
  LOAD_MUTATION: 'reversal/LOAD_MUTATION',
  FETCH_REVERSALS: 'reversal/FETCH_REVERSALS',
  RESET_STATE: 'reversal/RESET_STATE',
  USER_LOGOUT: 'USER_LOGOUT',
};

const initialState = {
  results: [],
  reversalDetail: {},
  pageInfo: {},
  load_query: false,
  load_mutation: false,
  error_query: {},
  error_mutation: {},
  success_query: {},
  success_mutation: {},
};

export default function reversal(state = initialState, action) {
  switch (action.type) {
    case Types.SET_RESULTS:
      return { ...state, results: action.results };
    case Types.SET_DETAIL:
      return { ...state, reversalDetail: action.reversal };
    case Types.NEXT_RESULTS:
      return { ...state, results: [...state.results, ...action.results] };
    case Types.SET_PAGE_INFO:
      return { ...state, pageInfo: action.pageInfo };
    case Types.SET_ERROR_QUERY:
      return { ...state, error_query: action.error };
    case Types.SET_ERROR_MUTATION:
      return { ...state, error_mutation: action.error };
    case Types.SET_SUCCESS_QUERY:
      return { ...state, success_query: action.success };
    case Types.SET_SUCCESS_MUTATION:
      return { ...state, success_mutation: action.success };
    case Types.LOAD_QUERY:
      return { ...state, load_query: action.load };
    case Types.LOAD_MUTATION:
      return { ...state, load_mutation: action.load };
    case Types.RESET_STATE:
    case Types.USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export function saveReversal({ data, edit, success, error }) {
  return {
    type: edit === true ? Types.UPDATE_REVERSAL : Types.CREATE_REVERSAL,
    data,
    success,
    error,
  };
}

export function createReversal({ data, success, error }) {
  return {
    type: Types.CREATE_REVERSAL,
    data,
    success,
    error,
  };
}

export function effectReversal({ data, success, error }) {
  return {
    type: Types.EFFECT_REVERSAL,
    data,
    success,
    error,
  };
}

export function removeReversal({ data, success, error }) {
  return {
    type: Types.REMOVE_REVERSAL,
    data,
    success,
    error,
  };
}

export function fetchDetail(id) {
  return {
    type: Types.FETCH_DETAIL,
    id,
  };
}

export function fetchReversals(args = {}) {
  const { status } = args;
  switch (status) {
    case 'pending':
      return fetchPending(args);
    case 'approved':
      return fetchEffect(args);
    default:
      return fetchPending(args);
  }
}

export function fetchPending(args) {
  let filter = { status: 'UNREALIZED' };

  if (args.search) {
    filter = {
      ...filter,
      AND: [
        {
          OR: [
            {
              invoice_WITH: {
                OR: [
                  {
                    invoiceNo: args.search,
                  },
                  {
                    customer_WITH: {
                      OR: [
                        { code: args.search },
                        { commercialName_LIKE: args.search },
                        { corporateName_LIKE: args.search },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      ],
    };
  }

  return {
    type: Types.FETCH_REVERSALS,
    cursor: args.cursor,
    filter,
  };
}

export function fetchEffect(args) {
  let filter = { status: 'REALIZED' };

  if (args.search) {
    filter = {
      ...filter,
      AND: [
        {
          OR: [
            {
              invoice_WITH: {
                OR: [
                  {
                    invoiceNo: args.search,
                  },
                  {
                    customer_WITH: {
                      OR: [
                        { code: args.search },
                        { commercialName_LIKE: args.search },
                        { corporateName_LIKE: args.search },
                      ],
                    },
                  },
                ],
              },
            },
          ],
        },
      ],
    };
  }

  return {
    type: Types.FETCH_REVERSALS,
    cursor: args.cursor,
    filter,
  };
}

export function resetState() {
  return {
    type: Types.RESET_STATE,
  };
}
