export const Types = {
  SET_RESULTS: 'logisticCenterShippers/SET_RESULTS',
  NEXT_RESULTS: 'logisticCenterShippers/NEXT_RESULTS',
  SET_PAGE_INFO: 'logisticCenterShippers/SET_PAGE_INFO',
  SET_ERROR_QUERY: 'logisticCenterShippers/SET_ERROR_QUERY',
  SET_ERROR_MUTATION: 'logisticCenterShippers/SET_ERROR_MUTATION',
  SET_SUCCESS_QUERY: 'logisticCenterShippers/SET_SUCCESS_QUERY',
  SET_SUCCESS_MUTATION: 'logisticCenterShippers/SET_SUCCESS_MUTATION',
  LOAD_QUERY: 'logisticCenterShippers/LOAD_QUERY',
  LOAD_MUTATION: 'logisticCenterShippers/LOAD_MUTATION',
  FETCH_LOGISTIC_CENTER_SHIPPERS:
    'logisticCenterShippers/FETCH_LOGISTIC_CENTER_SHIPPERS',
  UPDATE_LOGISTIC_CENTER_SHIPPER:
    'logisticCenterShippers/UPDATE_LOGISTIC_CENTER_SHIPPER',
  CREATE_LOGISTIC_CENTER_SHIPPER:
    'logisticCenterShippers/CREATE_LOGISTIC_CENTER_SHIPPER',
  REMOVE_LOGISTIC_CENTER_SHIPPER:
    'logisticCenterShippers/REMOVE_LOGISTIC_CENTER_SHIPPER',
  RESET_STATE: 'logisticCenterShippers/RESET_STATE',
  USER_LOGOUT: 'USER_LOGOUT',
};

const initialState = {
  results: [],
  resultInfo: {},
  pageInfo: {},
  load_query: false,
  load_mutation: false,
  error_query: {},
  error_mutation: {},
  success_query: {},
  success_mutation: {},
};

export default function logisticCenterShippers(state = initialState, action) {
  switch (action.type) {
    case Types.SET_RESULTS:
      return {
        ...state,
        results: action.results,
        resultInfo: action.resultInfo,
      };
    case Types.NEXT_RESULTS:
      return { ...state, results: [...state.results, ...action.results] };
    case Types.SET_PAGE_INFO:
      return { ...state, pageInfo: action.pageInfo };
    case Types.SET_ERROR_QUERY:
      return { ...state, error_query: action.error };
    case Types.SET_ERROR_MUTATION:
      return { ...state, error_mutation: action.error };
    case Types.SET_SUCCESS_QUERY:
      return { ...state, success_query: action.success };
    case Types.SET_SUCCESS_MUTATION:
      return { ...state, success_mutation: action.success };
    case Types.LOAD_QUERY:
      return { ...state, load_query: action.load };
    case Types.LOAD_MUTATION:
      return { ...state, load_mutation: action.load };
    case Types.RESET_STATE:
    case Types.USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
}

export function fetchLogisticCenterShippers({ logisticCenterRegion, search }) {
  let filter;
  if (search && search.length > 0) {
    filter = {
      contractorShipper_WITH: {
        shipper_WITH: {
          OR: [{ corporateName_LIKE: search }, { commercialName_LIKE: search }],
        },
      },
    };
  }

  return {
    type: Types.FETCH_LOGISTIC_CENTER_SHIPPERS,
    logisticCenterRegion,
    filter,
  };
}

export function resetState() {
  return {
    type: Types.RESET_STATE,
  };
}

export function saveLogisticCenterShipper({ data, edit, success, error }) {
  return {
    type:
      edit === true
        ? Types.UPDATE_LOGISTIC_CENTER_SHIPPER
        : Types.CREATE_LOGISTIC_CENTER_SHIPPER,
    data,
    success,
    error,
  };
}

export function removeLogisticCenterShipper({ id, success, error }) {
  return {
    type: Types.REMOVE_LOGISTIC_CENTER_SHIPPER,
    id,
    success,
    error,
  };
}
