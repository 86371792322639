import { forwardRef } from 'react';
import InvoiceList from './InvoiceList';
import moment from 'moment';
import { CnpjCpf } from '@utils';
import { QRCode } from './QRCode';
import { group, objectSize } from '@utils/Utils';
import NumberFormat from 'react-number-format';
import { permissions } from '@config/permission';

const Format = ({ value }) => {
  value = value || 0;
  return (
    <NumberFormat
      value={value}
      displayType={'text'}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      fixedDecimalScale
    />
  );
};

const Detail = forwardRef((props, ref) => {
  const { source, shipper } = props;

  if (!source) return null;

  const {
    key,
    dispatchedAt,
    deliveredAt,
    plate,
    destination,
    driverName,
    driverCpf,
    invoiceExcerpt,
    redispatcherFreightCost,
    redispatcherInsurancePercentage,
    redispatcherInsuranceCost,
    dispatcherFreightCost,
    dispatcherInsurancePercentage,
    dispatcherInsuranceCost,
    invoices,
    note,
  } = source;

  let aggregateDeliveries = 0;
  if (invoices) {
    aggregateDeliveries = objectSize(group(invoices, 'customer'));
  }
  return (
    <div ref={ref}>
      <div className="mt-6 border-t border-gray-100">
        <div className="grid grid-rows-3 grid-flow-col gap-2">
          <div className="col-span-2">
            <h3 className="text-base font-semibold leading-7 text-gray-900">
              {' '}
              <b>OT:</b> {key}
            </h3>
          </div>
          <div className="col-span-2">
            <div className="text-sm font-medium leading-6 text-gray-900 col-span-2">
              <b>Saída:</b>{' '}
              {dispatchedAt && moment(dispatchedAt).format('DD/MM/YYYY-HH:mm')}{' '}
            </div>
            {deliveredAt && (
              <div className="text-sm font-medium leading-6  text-gray-900">
                <b>Entrega:</b> {moment(deliveredAt).format('DD/MM/YYYY-HH:mm')}
              </div>
            )}
          </div>

          <div className="row-span-3 mt-6">
            <QRCode value={String(key)} />
          </div>
        </div>
      </div>

      {invoiceExcerpt.dispatcher && (
        <div>
          <div className="px-4 sm:px-0">
            <h3 className="text-base font-semibold leading-7 text-gray-900">
              Dados do Transportador
            </h3>
          </div>
          <div className="mt-2 border-t border-gray-100">
            <div className="sm:grid sm:grid-cols-2 sm:gap-2 sm:px-0">
              <div className="text-sm font-medium leading-6 text-gray-900">
                <b>Transportador:</b> {invoiceExcerpt.dispatcher.company.corporateName}
              </div>
              <div className="text-sm font-medium leading-6 text-gray-900">
                <b>CPF/CNPJ:</b> {driverCpf && CnpjCpf.mask(driverCpf)}
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-2 sm:gap-2 sm:px-0">
              <div className="text-sm font-medium leading-6 text-gray-900">
                <b>Motorista:</b> {driverName}
              </div>
              <div className="text-sm font-medium leading-6 text-gray-900">
                <b>Fone:</b> {invoiceExcerpt.dispatcher.company.phone}
              </div>
            </div>
            <div className="sm:grid sm:grid-cols-3 sm:gap-2 sm:px-0">
              <div className="text-sm font-medium leading-6 text-gray-900">
                <b>Destino:</b> {destination}
              </div>
              <div className="text-sm leading-6 text-gray-700 sm:mt-0">
                <b>Placa:</b> {plate}
              </div>
              <div className="text-sm font-medium leading-6 text-gray-900">
                <strong> Entregas Agregadas: </strong> {aggregateDeliveries}
              </div>
            </div>
            {(shipper?.cnpj === invoiceExcerpt.dispatcher?.company?.cnpj ||
              permissions.shippingOrder.viewFreightValue()) && (
              <div className="sm:grid sm:grid-cols-3 sm:gap-2 sm:px-0">
                <div className="text-sm leading-6 text-gray-700 sm:mt-0">
                  <b>Vlr.Segurado:</b>{' '}
                  <Format
                    value={dispatcherInsuranceCost && dispatcherInsuranceCost}
                  />
                </div>
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  <b>Vlr.Frete:</b>{' '}
                  <Format
                    value={dispatcherFreightCost && dispatcherFreightCost}
                  />
                </dt>
                <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                  <b>% Frete:</b>{' '}
                  <Format
                    value={
                      dispatcherInsurancePercentage &&
                      dispatcherInsurancePercentage
                    }
                  />
                </dd>
              </div>
            )}
            <div className="sm:grid sm:gap-2 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                <b>OBS:</b> {note}
              </dt>
            </div>
          </div>
        </div>
      )}

      {invoiceExcerpt.redispatcher && (
        <div className="mt-8">
          <div>
            <div className="px-4 sm:px-0">
              <h3 className="text-base font-semibold leading-7 text-gray-900">
                Dados do Redespacho
              </h3>
            </div>
            <div className="mt-2 border-t border-gray-100">
              <div className="sm:grid sm:grid-cols-2 sm:px-0">
                <div className="text-sm font-medium leading-6 text-gray-900">
                  <b>Redespacho:</b> {invoiceExcerpt.redispatcher.company.corporateName}
                </div>
                <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                  <b>Fone:</b> {invoiceExcerpt.redispatcher.company.phone}
                </dd>
                <dt className="text-sm font-medium leading-6 text-gray-900">
                  <b>Endereço:</b> {invoiceExcerpt.redispatcher.company.address}
                </dt>
                <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                  <b>CPF/CNPJ:</b> {CnpjCpf.mask(invoiceExcerpt.redispatcher.company.cnpj)}
                </dd>
                <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                  <b>Cidade:</b>{' '}
                  {invoiceExcerpt.redispatcher.company.city && invoiceExcerpt.redispatcher.company.city.code
                    ? '(' + invoiceExcerpt.redispatcher.company.city.code + ') '
                    : ''}
                  {invoiceExcerpt.redispatcher.company.city &&
                    invoiceExcerpt.redispatcher.company.city.name +
                      '/' +
                      invoiceExcerpt.redispatcher.company.city.state}
                </dd>
                <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                  <b>Bairro:</b> {invoiceExcerpt.redispatcher.company.neighborhood}
                </dd>
                <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                  <b>Insc.Estadual:</b> {invoiceExcerpt.redispatcher.company.stateInscription}
                </dd>
                <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                  <b>Contato:</b> {invoiceExcerpt.redispatcher.company.contact}
                </dd>
              </div>

              {(shipper?.cnpj === invoiceExcerpt.redispatcher?.company?.cnpj ||
                permissions.shippingOrder.viewFreightValue()) && (
                <div className="sm:grid sm:grid-cols-3 sm:gap-2 sm:px-0">
                  <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                    <b>Vlr.Segurado:</b>{' '}
                    <Format
                      value={
                        redispatcherInsuranceCost && redispatcherInsuranceCost
                      }
                    />
                  </dd>
                  <dt className="text-sm font-medium leading-6 text-gray-900">
                    <b>Vlr.Frete:</b>{' '}
                    <Format
                      value={redispatcherFreightCost && redispatcherFreightCost}
                    />
                  </dt>
                  <dd className="text-sm leading-6 text-gray-700 sm:mt-0">
                    <b>% Frete:</b>{' '}
                    <Format
                      value={
                        redispatcherInsurancePercentage &&
                        redispatcherInsurancePercentage
                      }
                    />
                  </dd>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="mt-6">
        <InvoiceList
          printItem={props.printItem}
          source={invoices}
          shipper={shipper}
        />
      </div>
    </div>
  );
});

Detail.displayName = 'Detail';

export default Detail;
