import NumberFormat from 'react-number-format';

const Format = ({ value }) => {
  value = value || 0;
  return (
    <NumberFormat
      value={value}
      displayType={'text'}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
      fixedDecimalScale
    />
  );
};
const DetailItems = props => {
  const renderItems = () => {
    let { source } = props;
    return source.map((item, index) => {
      return (
        <tr key={index}>
          <td scope="col" className="px-2 py-2 text-sm text-gray-800">
            {index + 1}
          </td>
          <td scope="col" className="px-2 py-2 text-sm text-gray-800">
            {item.sku}
          </td>
          <td scope="col" className="px-2 py-2 text-sm text-gray-800">
            {item.name}
          </td>
          <td scope="col" className="px-2 py-2 text-sm text-gray-800">
            {item.unity}
          </td>
          <td scope="col" className="px-2 py-2 text-sm text-gray-800">
            <Format value={item.quantity} />
          </td>
          <td scope="col" className="px-2 py-2 text-sm text-gray-800">
            <Format value={item.grossPrice} />
          </td>
          <td scope="col" className="px-2 py-2 text-sm text-gray-800">
            <Format value={item.netPrice} />
          </td>
          <td scope="col" className="px-2 py-2 text-sm text-gray-800">
            <Format value={item.totalValue} />
          </td>
        </tr>
      );
    });
  };

  return (
    <div>
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th
              scope="col"
              className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              #
            </th>
            <th
              scope="col"
              className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Código
            </th>
            <th
              scope="col"
              className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Produto
            </th>
            <th
              scope="col"
              className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              UN
            </th>
            <th
              scope="col"
              className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              QTD
            </th>
            <th
              scope="col"
              className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Preço Bruto
            </th>
            <th
              scope="col"
              className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Preço Liquido
            </th>
            <th
              scope="col"
              className="px-2 py-3.5 text-left text-sm font-semibold text-gray-900"
            >
              Valor Total
            </th>
          </tr>
        </thead>

        <tbody>{renderItems()}</tbody>
      </table>
    </div>
  );
};

export default DetailItems;
