import { Modal } from 'semantic-ui-react';
// Assim não aparece o modal, mas pelo menos não dá erro

const Load = ({ active = false, title = 'Aguarde...' }) => {
  return (
    <Modal dimmer="inverted" basic open={active} closeOnDimmerClick={false}>
      <button type="button" className="bg-indigo-500 ..." disabled>
        <svg
          className="animate-spin h-5 w-5 mr-3 ..."
          viewBox="0 0 24 24"
        ></svg>
        {title}
      </button>
    </Modal>
  );
};
export default Load;
