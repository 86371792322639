import { client, closeConnection } from '@config/apollo';
import gql from 'graphql-tag';
import { call, put, select } from 'redux-saga/effects';
import { Types } from '../ducks/auth';
import { Types as ToastTypes } from '../ducks/toast';

export function* validateUser(action) {
  const cli = yield call(client);

  const { login, password } = action.data;

  yield put({ type: Types.LOAD_MUTATION, load: true });

  try {
    const access = yield call(cli.mutate, {
      mutation: gql`
      mutation {
        createWebAppAccessToken(email:"${login}", password:"${password}") {
          accessToken
        }
      }`,
    });

    const cliAuth = yield call(
      client,
      access.data.createWebAppAccessToken.accessToken
    );
    const response = yield call(cliAuth.query, {
      query: gql`
        query {
          me {
            ... on Admin {
              id
              user {
                id
                email
                firstName
                lastName
                role {
                  id
                  name
                  description
                }
                phone
                active
                createdAt
              }
            }
            ... on Operator {
              company {
                cnpj
                licenseSlots
                shippersCount
                operatorsCount
                inviteesCount
                city {
                  name
                }
              }
              user {
                firstName
                lastName
                email
                role {
                  name
                }
              }
            }
            ... on Company {
              cnpj
              licenseSlots
              shippersCount
              operatorsCount
              inviteesCount
              city {
                name
              }
              user {
                firstName
                lastName
                email
                role {
                  name
                }
              }
            }
            ... on Shipper {
              company {
                cnpj
                city {
                  name
                }
              }
              contractors {
                id
                corporateName
                cnpj
              }
              user {
                firstName
                lastName
                email
                role {
                  name
                }
              }
            }
          }
        }
      `,
    });
    const user = response.data.me.user;
    const contractors = response.data?.me?.contractors || [];
    const shipper = response.data?.me?.company || null;
    const cnpjCompany =
      response.data?.me?.cnpj || response.data?.me?.company?.cnpj;
    const cityCompany =
      response.data?.me?.city?.name || response.data?.me?.company?.city?.name;
    const company = contractors[0] || { cnpj: cnpjCompany, city: cityCompany };
    const licenses =
      user.role.name === 'COMPANY'
        ? response.data?.me
        : response.data?.me?.company;

    yield put({
      type: Types.SET_DATA,
      token: access.data.createWebAppAccessToken.accessToken,
      user,
      contractors,
      company,
      shipper,
      licenses,
    });

    if (action.success) action.success();
  } catch (error) {
    if (action.error) {
      action.error(error);
      yield call(closeConnection);
    }
  } finally {
    yield put({ type: Types.LOAD_MUTATION, load: false });
  }
}

export function* refreshUser() {
  const auth = yield select(state => state.auth);
  const cli = yield call(client, auth.token);

  try {
    yield put({ type: Types.LOAD_QUERY, load: true });

    const response = yield call(cli.query, {
      query: gql`
        query {
          me {
            ... on Admin {
              id
              user {
                id
                email
                firstName
                lastName
                role {
                  id
                  name
                  description
                }
                phone
                active
                createdAt
              }
            }
            ... on Operator {
              company {
                cnpj
                licenseSlots
                shippersCount
                operatorsCount
                inviteesCount
                city {
                  name
                }
              }
              user {
                firstName
                lastName
                email
                role {
                  name
                }
              }
            }
            ... on Company {
              cnpj
              licenseSlots
              shippersCount
              operatorsCount
              inviteesCount
              city {
                name
              }
              user {
                firstName
                lastName
                email
                role {
                  name
                }
              }
            }
            ... on Shipper {
              company {
                cnpj
                city {
                  name
                }
              }
              contractors {
                id
                corporateName
                cnpj
              }
              user {
                firstName
                lastName
                email
                role {
                  name
                }
              }
            }
          }
        }
      `,
    });

    const user = response.data.me.user;
    const contractors = response.data?.me?.contractors || [];
    const shipper = response.data?.me?.company || null;
    const cnpjCompany =
      response.data?.me?.cnpj || response.data?.me?.company?.cnpj;
    const cityCompany =
      response.data?.me?.city?.name || response.data?.me?.company?.city?.name;
    const company = contractors[0] || { cnpj: cnpjCompany, city: cityCompany };
    const licenses =
      user.role.name === 'COMPANY'
        ? response.data?.me
        : response.data?.me?.company;

    yield put({
      type: Types.SET_DATA,
      token: auth.token,
      user,
      contractors,
      company,
      shipper,
      licenses,
    });
  } catch (error) {
    yield put({
      type: ToastTypes.SET_MESSAGE,
      message: {
        type: 'error',
        message: error.message,
      },
    });
  } finally {
    yield put({ type: Types.LOAD_QUERY, load: false });
  }
}

export function* logout() {
  yield call(closeConnection);
  yield put({ type: Types.USER_LOGOUT });
}
